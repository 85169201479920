import React from 'react';
import { Grid, Typography, Stack } from '@mui/material';

import { Product } from '../../../component/page';

import ImgCPM from '../../../../img/service/support-helpdesk/CPM.png';
import CustomTypography from '../../../component/customTypography';

const template = () => {
  return (
    <>
      <Grid container direction='column' spacing={0} sx={{ mt: 0 }}>
            {/* Clinical Project Management  */}
          <Grid item>
              <Product
                ImgSrc={ImgCPM}
                Name="Clinical Project Management"
                Summary={
                  <>
                  <CustomTypography variant='body2' sx={{ mt: 1 }}>
                    The project management activities involve overseeing the planning, execution, and monitoring of the clinical data services to ensure 
                    they are completed on time, within budget, and in compliance with agreed terms and milestones, highlights potential risks and 
                    mitigation strategies. 
                    <br></br><br></br>
                    Our expert project team understands the client requirements, performs efficient cross functional coordination between configuration
                    team and the client, for timely configuration of study database, data management team and client for creation of study related reports 
                    and validation, data science team for data validation and statistical reports creation and study closure activities, working with the 
                    sponsors, CROs and Sites.
                </CustomTypography>
                </>
                }
              />
              
          </Grid>
      </Grid>
    </>
  );
}

export default template;