import React from 'react';
import { Grid, Typography } from '@mui/material';

import { Product } from '../../../component/page';

import ImgCSR from '../../../../img/service/clinical-data-management/CSR.png';
import CustomTypography from '../../../component/customTypography';

const template = () => {
  return (
    <>
      <Grid container direction='column' spacing={0} sx={{ mt: 0 }}>
            {/* Clinical study reports (CSR)  */}
          <Grid item>
              <Product
                ImgSrc={ImgCSR}
                Name="Clinical study reports (CSR)"
                Summary={
                  <>
                  <CustomTypography variant='body2' sx={{ mt: 1 }}>
                    CliniOps CSR services provide comprehensive, high-quality reports for clinical trials, ensuring compliance with regulatory standards 
                    like ICH E6 and FDA guidelines. Our expert team prepares detailed, well-structured reports that summarize study objectives, 
                    methodology, results, and statistical analysis. CliniOps ensures that the CSR presents a clear narrative of the trial, including safety 
                    and efficacy outcomes, for submission to regulatory agencies. Our medical writers collaborate closely with clinical trial teams to 
                    ensure accuracy and consistency throughout the report.
                </CustomTypography>
                </>
                }
              />
              
          </Grid>
      </Grid>
    </>
  );
}

export default template;