import React from 'react';
import {Box, Typography} from '@mui/material';

const template = () => {
    return (
        <>
        <Box 
          sx={{ 
              display:'flex', 
              textAlign: "center", 
              alignItems: "center", 
              justifyContent: 'center', 
              // marginBottom:3, 
              marginTop:{xs:-5, md:-18}}}>
            <Typography variant="h1Teal" sx={{lineHeight:'80px',textShadow: "0px 3px 3px rgba(0, 0, 0, 0.16)", mx:{xs:2, md: "", lg: 15}}}>
            Service Areas
            </Typography>
            </Box>
            <Box 
              sx={{ 
                  display:'flex', 
                  textAlign: "center", 
                  alignItems: "center", 
                  justifyContent: 'center', 
                  // marginBottom:3, 
                 }}>
            <Box variant="body6" sx={{ lineHeight:"28px", mx:{xs:2, md: "", lg: 33}}}>
                CliniOps offers comprehensive services as a Technology and Data Partner in three distinguished areas: 
                Study Setup, Clinical Data Services, and Project Support and Helpdesk.
            </Box>
          </Box>

        </>
    );
};
export default template;