import React from 'react';
import { Grid } from '@mui/material';
import { blueGrey } from '@mui/material/colors';

// Page Imports
import _0 from './cookie/_0';
import _1 from './cookie/_1';

const template = () => {
  return (
    <>  
        <_0 /> 
        <Grid item sx={{mt:5,mb:5}}>
          <_1 />
        </Grid>
      
    </>
  );
}

export default template;