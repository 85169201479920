import React from 'react';
import { Box, Typography, Grid, Card, CardHeader, CardMedia, Link } from '@mui/material';

import ImgSCDM from '../../../../img/media/newsroom/_2019/Media-2019-01.png';
import ImgFrostSullivan from '../../../../img/media/newsroom/_2019/Media-2019-02.png';
import ImgGartner from '../../../../img/media/newsroom/_2019/Media-2019-03.png';
import ImgPubMed from '../../../../img/media/newsroom/_2019/Media-Publications-01.png';
import ImgFDA from '../../../../img/media/newsroom/_2019/Media-2019-04.png';
import ImgPlos from '../../../../img/media/newsroom/_2019/Media-2019-06.png';
import ImgUSISPF from '../../../../img/media/newsroom/_2019/Media-2019-15.png';
import ImgDia from '../../../../img/media/newsroom/_2019/Media-2019-07.png';
import ImgIITBayAreaAlumni from '../../../../img/media/newsroom/_2019/Media-2019-08.png';
import ImgTechnologyHeadlines from '../../../../img/media/newsroom/_2019/Media-2019-09.png';
import ImgRedHerringTop100 from '../../../../img/media/newsroom/_2019/Media-2019-10.png';
import ImgResearchMarkets from '../../../../img/media/newsroom/_2019/Media-2019-12.png';
import ImgAfricanMediaAgency from '../../../../img/media/newsroom/_2019/Media-2019-11.png';
import ImgGlobalExcellenceAwards from '../../../../img/media/newsroom/_2019/Media-2019-13.png';
import ImgEndocrinology from '../../../../img/media/newsroom/_2019/Media-2019-14.png';
import ImgVibrantGujaratSummit from '../../../../img/media/newsroom/_2019/Media-2019-16.png';
import ImgPlosNTD from '../../../../img/media/newsroom/_2023/PLOS-NTD_Logo.png';
// import sign from '../../../../img/media/newsroom/Renew/sign.png';


const news2019Data = [
    {
    ImgSrc: ImgSCDM,
    Name: "SCDM Annual Conference, 2019",
    Date: "September 30, 2019",
    Summary: "SCDM Annual Conference, 2019",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "https://scdm.org/scdm-2019-annual-conference/",
  },
  {
    ImgSrc: ImgFrostSullivan,
    Name: "Digitization of Clinical Trials Improving Trial Efficiency",
    Date: "August 30, 2019",
    Summary: "Frost & Sullivan's Asia-Pacific Best Practices Awards Honors the Top Companies in the Region",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
       SINGAPORE, Aug. 29, 2019 /PRNewswire/ -- Frost & Sullivan honored Asia's leading companies at its Asia-Pacific Best Practices Awards banquet held on 29 August at the Shangri-La Hotel in Singapore.
      </Typography>
    ),
    redirectUrl: "https://www.prnewswire.com/in/news-releases/frost-amp-sullivan-s-asia-pacific-best-practices-awards-honors-the-top-companies-in-the-region-807367226.html",
  },
  {
    ImgSrc: ImgGartner,
    Name: "Featured in the Gartner Hype Cycle for Life Science R&D",
    Date: "August 02, 2019",
    Summary: "Life science CIOs are now implementing their organizations’ digital R&D vision, leveraging technologies to more efficiently develop drugs, devices and digital therapies. ",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        Use this Hype Cycle to identify innovation opportunities, create value and cement your place in the broad healthcare ecosystem.
      </Typography>
    ),
    redirectUrl: "https://www.gartner.com/en/documents/3955926",
  },
  {
    ImgSrc: ImgPlosNTD,
    Name: "Dosing pole recommendations for lymphatic filariasis elimination: A height-weight quantile regression modeling approach",
    Date: "July 17, 2019",
    Summary: "Dosing pole recommendations for lymphatic filariasis elimination: A height-weight quantile regression modeling approach",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "https://journals.plos.org/plosntds/article?id=10.1371/journal.pntd.0007541926",
  },
  {
    ImgSrc: ImgFDA,
    Name: "FDA Innovate Today Summit 2019",
    Date: "July 17, 2019",
    Summary: 'The summit, per the FDA, is all about gathering “industry leaders, policymakers, academics and disruptive entrepreneurs to share their insights and approaches for successfully embracing innovations in technology to achieve FDA strategic goals."',
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        FDA staff and members of the general public are also invited to attend and participate in the summit.
      </Typography>
    ),
    redirectUrl: "https://fedscoop.com/fda-innovate-today-summit-2019/",
  },
  {
    ImgSrc:ImgPlos,
    Name: "The safety of double- and triple-drug community mass drug administration for lymphatic filariasis: A multicenter, open-label, cluster-randomized study",
    Date: "June 24, 2019",
    Summary: "The safety of double- and triple-drug community mass drug administration for lymphatic filariasis: A multicenter, open-label, cluster-randomized study",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "https://journals.plos.org/plosmedicine/article?id=10.1371/journal.pmed.1002839",
  },
  {
    ImgSrc:ImgUSISPF,
    Name: "USISPF Delegation to India led by John Chambers",
    Date: "June 24, 2019",
    Summary: "The Prime Minister appreciated the delegation for reposing faith in the Indian Economy. ",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
       He mentioned about the evolving start-up ecosystem in the country, highlighting the entrepreneurial risk taking capacity of India’s youth. He also outlined the steps taken by the Government including Atal Tinkering Labs and conducting Hackathons to boost innovation potential and solve problems using technology.
      </Typography>
    ),
    redirectUrl: "https://www.pmindia.gov.in/en/news_updates/us-india-strategic-partnership-forum-calls-on-pm/",
  },
  {
    ImgSrc:ImgDia,
    Name: "DIA Conference 2019",
    Date: "June 24, 2019",
    Summary: "DIA Conference 2019",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "https://dia2019globalannualmeeting.sched.com/event/KfY4",
  },
  {
    ImgSrc:ImgIITBayAreaAlumni,
    Name: "IIT Bay Area Alumni Conference 2019",
    Date: "June 15, 2019",
    Summary: "IIT Bay Area Alumni Conference 2019",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "https://www.iit-bayarea.org/tracks/Healthcare-and-Medicine-Through-Large-Scale-Data-Analytics",
  },
  {
    ImgSrc:ImgRedHerringTop100,
    Name: "Red Herring Top 100 North America Winners",
    Date: "May 15, 2019",
    Summary: "The 2019 Red Herring Top 100 North America event came to a close on May 15th, as the best and brightest of North American startups were honored.",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        The three-day conference was held at The Westin Pasadena and featured expert speakers, roundtable discussions, and presentations from shortlisted companies.
      </Typography>
    ),
    redirectUrl: "https://www.redherring.com/red-herring-2019-top-100-north-america-winners-press-release/",
  },
  {
    ImgSrc:ImgResearchMarkets,
    Name: "Pharma Clinical Trial Digitization – Companies to Action, 2019",
    Date: "April 29, 2019",
    Summary: "Digitization is set to play a critical role in transforming the clinical trial model from traditional, high-cost, and difficult-to-access centralized settings to more patient-centric and efficient distributed settings",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        As the clinical trial industry transitions to biomarker-based, targeted therapeutics, it demands a pivotal shift in design focus: from one directed by researchers to one driven by the needs of patients and their caregivers. 
      </Typography>
    ),
    redirectUrl: "https://www.businesswire.com/news/home/20190429005805/en/Pharma-Clinical-Trial-Digitization---Companies-to-Action-2019---ResearchAndMarkets.com",
  },
  {
    ImgSrc:ImgAfricanMediaAgency,
    Name: "How Kenya’s Fight Against Elephantiasis is Becoming a Blueprint for Africa and the World",
    Date: "April 14, 2019",
    Summary: "Today, this goal is within reach. Just a few months ago, Kenya proudly piloted the IDA treatment along three of our coastal sub-counties, Lamu East and Lamu West in Lamu county, and Jomvu in Mombasa county, as the first in Africa – and only second in the world.",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
       IDA consists of a combination of three drugs – ivermectin, diethylcarbamazine, and albendazole – and could drastically reduce treatment time, down to two years, compared with traditional dual drug therapy that requires at least five years of drug administration.
      </Typography>
    ),
    redirectUrl: "https://africa.com/how-kenyas-fight-against-elephantiasis-is-becoming-a-blueprint-for-africa-and-the-world",
  },
  {
    ImgSrc:ImgGlobalExcellenceAwards,
    Name: "‘Global Excellence Awards’ for Most Innovative in Clinical Trial Digitalization Solutions 2019 – USA",
    Date: "February 12, 2019",
    Summary: "‘Global Excellence Awards’ for Most Innovative in Clinical Trial Digitalization Solutions 2019 – USA",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "https://www.acquisition-international.com/issues/issue-4-2019/74/",
  },
  {
    ImgSrc:ImgEndocrinology,
    Name: "Endocrinology, A ‘Endocrinology, Diabetes and Metabolism Journal’ Publication",
    Date: "February 09, 2019",
    Summary: "A Polyherbal Indian System of Medicine (Ayush) Preparation for Optimization of Glycemic Control in Newly Diagnosed Type 2 Diabetes and Prediabetes; A Multicenter, Randomised, Double-Blind, Placebo- Controlled Trial",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "https://researchopenworld.com/a-polyherbal-indian-system-of-medicine-ayush-preparation-for-optimization-of-glycemic-control-in-newly-diagnosed-type-2-diabetes-and-prediabetes-a-multicenter-randomised-double-blind-placebo-co/",
  },
]

export default news2019Data;