import React, { useMemo } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CustomCarousel from "../../home/CustomCarousel";
import { customerData as data } from "./_3";
import { HashLink as RouterLink } from "react-router-hash-link";

const template = () => {
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const uniqueRegions = useMemo(() => {
    const regions = new Set();
    data.forEach((item) => {
      if (item.region) {
        regions.add(item.region);
      }
    });
    return Array.from(regions).sort();
  }, []);

  // 2) Gather unique tags (e.g. Press, Publication, Event, etc.)
  const uniqueTags = useMemo(() => {
    const tags = new Set();
    data.forEach((item) => {
      // item.tags might be undefined, so guard it:
      if (item.tags && Array.isArray(item.tags)) {
        item.tags.forEach((tag) => tags.add(tag));
      }
    });
    return Array.from(tags).sort(); // alphabetical or any order you prefer
  }, []);
  return (
    <>
      <CustomCarousel
        slides={data}
        // Pass extraFeatures only if the current page needs extra controls
        extraFeatures={{
          manualChunking: true,
          ChildOverflow: true,
          renderBottomDots: true,
          dynamicRows: false, // opt into dynamic override
          pagingNumerical: true,
          defaultItemsPerPage: 10,
          itemsPerPageOptions: [10, 20, 50],
          pagingFilter: {
            options: [
              { value: "all", label: "All Customer Stories" },
              // { value: "category", label: "Category" },
            ],
            default: "all",
            availableTags: uniqueTags,
            availableRegions: uniqueRegions,
             // Custom label and placeholder for tag filter
           tagLabel: "Therapeutic Area",
           tagPlaceholder: "Select Therapeutic Area",
           tagAllOption:"All Therapeutic Area"
          },
        }}
        renderItem={(slide, index) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column-reverse", md: "row" }, // Switch to column if expanded
              justifyContent: "center",
              alignItems: "stretch",
              gap: "24px",
              padding: "2em",
              backgroundColor: "#FFFFFF",
              border: "1px solid #CED2D4",
              borderRadius: "10px",
              boxShadow: "10px 10px 8px rgba(0, 0, 0, 0.2)",
              // height: { xs: "auto", md: "600px" }, // Fixed height for larger screens
              minHeight: { xs: "auto", md: "auto" },
              height: "auto",
              marginTop: "2em",
              // height:"auto",
            }}
          >
            <Grid container spacing={2} alignItems="strech">
              {/* Left Section - Customer Logo */}
              <Grid item xs={12} md={3}>
                <Box
                  sx={{
                    width: "100%",
                    height: "150px",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <img
                    src={slide.image}
                    alt="Customer Logo"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      objectPosition: "left center",
                    }}
                  />
                </Box>
              </Grid>

              {/* Middle Section - Customer Testimonial */}
              <Grid item xs={12} md={6}>
                <Box>
                  <Typography
                    variant="body3"
                    sx={{
                      lineHeight: "1.6",
                    }}
                  >
                    {slide?.title}
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      lineHeight: "1.6",
                    }}
                  >
                    {slide?.subTitle}
                  </Typography>
                  <Box>
                    {slide.description.split("###").map((line, idx) => (
                      <Typography
                        key={idx}
                        variant="body2"
                        sx={{
                          mb: 1,
                          lineHeight: "1.6",
                        }}
                      >
                        {line}
                      </Typography>
                    ))}
                  </Box>
                </Box>
                <Button
                  LinkComponent={RouterLink}
                  target="_blank"
                  to={slide.link}
                  sx={{
                    mt: 1,
                    ml: -1,
                    textTransform: "capitalize",
                    fontSize: "14px",
                  }}
                >
                  Know More
                </Button>
              </Grid>

              {/* Right Section - Customer Avatar and Details */}
              <Grid item xs={12} md={3}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "250px",
                      height: "250px",
                      borderRadius: "50%",
                      overflow: "hidden",
                      mb: 2,
                    }}
                  >
                    <img
                      src={slide.customerAvatar}
                      alt={slide.customerName}
                      className="customerAvatar"
                    />
                  </Box>
                  {slide.customerName.split("###").map((line, idx) => (
                    <Typography key={idx} variant="h5">
                      {line}
                    </Typography>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
        settings={{
          // Add or override slider settings if needed
          // autoplaySpeed: 7000,
          autoplay: false,
          dots: true,
          sliderPerRow: 1,
          arrows: false,
          dotsPosition: {
            top: isXS ? "10px" : "-60px",
          },
        }}
        customStyles={{
          wrapper: { mt: 2 },
          slide: { p: 2, mb: 2 },
        }}
      />
    </>
    // <>
    // </>
  );
};

export default template;
