import React from 'react';
import { Grid, Typography, Stack } from '@mui/material';

// import { PageSectionLeftImg } from '../../../component/page';
import { Product } from '../../../component/page';

// import ImgTelemedicine from '../../../../img/product/edge/telemedicine.png';
// import ImgTelemedicine from '../../../../img/product/connect/Telemedicine.png';
import ImgTelemedicine from '../../../../img/product/connect/telemedicine-new.png';
import CustomTypography from '../../../component/customTypography';

const template = () => {
  return (
    <>
      {/* <PageSectionLeftImg
        heading={
          <Stack direction="row" spacing={1} alignItems='center' justifyContent={{xs: 'center', md: 'flex-start'}}>
            <Typography variant="h2" component="h2" className="co-page-heading" sx={{ textAlign: { xs: 'center', md: 'left'} }}>
              Telemedicine
            </Typography>
          </Stack>
        }
        imgUrl={ImgTelemedicine}
        imgTitle="Telemedicine"
      >
        <Typography sx={{ mt: 1 }}>
          Telemedicine module offers the study team the option to interact with the patient, via a secure digital platform. The study team can initiate the interaction via the mobile app, or the web portal, over a built-in video conference functionality to connect with the patient on his/her Smartphone, Tablet or Web browser. This not only allows the site, an option to convert some in-person patient visits to tele-visits but also significantly increases the outreach for patient recruitment and brings diversity in clinical trials. It also helps with patient retention, increases patient engagement and protocol adherence, by constantly interacting with patients via alerts, reminders and notifications.
        </Typography>
      </PageSectionLeftImg> */}

      <Grid container direction='column' spacing={0} sx={{ mt: 0 }}>
            {/* Telemedicine  */}
          <Grid item>
              <Product
                ImgSrc={ImgTelemedicine}
                Name="Telemedicine"
                Summary={
                  <>
                  <CustomTypography variant='body2' sx={{ mt: 1 }}>
                    Telemedicine solutions allow the study team to interact with patients via a secure digital platform. Interactions can 
                    be initiated via the mobile app or the web portal over a built-in video conference functionality to connect with the 
                    patient on their Smartphone, Tablet, or Web browser. 
                    <br></br><br></br>
                    This allows the site to convert some in-person patient visits to tele-visits, significantly increase the outreach for 
                    patient recruitment, and bring diversity to clinical trials. Patient retention, engagement, and protocol adherence 
                    have markedly increased with the ability to constantly interact with patients via alerts, reminders, and notifications.
                </CustomTypography>
                </>
                }
              />
              
          </Grid>
      </Grid>
    </>
  );
}

export default template;