import React from 'react';
import { Grid,Box } from '@mui/material';
import waveCustomerstories from '../../../../img/media/newsroom/Renew/wave2 (3).png';
const template = () => {
  return (
    <>
    <Box sx={{
        position:"relative"
    }}>
    <Box
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "50px 0 0 0", 
      overflow: "hidden",
      top: "10px",
      }}
    >
        <img
        src={`${waveCustomerstories}`}
        alt="waveCustomerstories"
        style={{ 
          maxWidth: "100%", 
          maxHeight: "100%", 
          objectFit: "contain" }}
    />
    </Box>
    <Box sx={{
            position: 'absolute',
            top: {xs:'35%', md:'20%'},
            left: '50%',
            transform: 'translate(-50%, -20%)',
            fontSize: { xs: '20px', sm: '40px', md: '50px' },
            fontWeight: 100,
            fontFamily: 'Segoe UI',
            zIndex: 1,
            color: '#FFFFFF',
            textAlign: 'center',
            textShadow: '0 2px 5px rgba(0,0,0,0.8)',
            }}>
          Press Release 
      </Box>
    </Box>
    </>
  );
}

export default template;