import React from 'react';
import { Grid, Typography, Stack } from '@mui/material';

// import { PageSectionRightImg } from '../../../component/page';
import { Conduct } from '../../../component/page';

// import ImgCoderCro from '../../../../img/product/conduct/codercro.png';
import ImgeConsent from '../../../../img/product/conduct/eConsent.png';
import CustomTypography from '../../../component/customTypography';

const template = () => {
  return (
    <>
      {/* <PageSectionRightImg
        heading={
          <Stack direction="row" spacing={1} alignItems='center' justifyContent={{xs: 'center', md: 'flex-start'}}>
            <Typography variant="h2" component="h2" className="co-page-heading" sx={{ textAlign: { xs: 'center', md: 'left'} }}>
              Coder
            </Typography>
          </Stack>
        }
        altHeading={false}
        imgUrl={ImgCoderCro}
        imgTitle="Coder"
      >
        <Typography sx={{ mt: 1 }}>
          CliniOps Coder module provides auto-coding functionality with speed and accuracy to support coding for any clinical trial, including customizable workflows for manual coding when needed. This module maps verbatim terms to standard codes based on World Health Organization Drug Dictionaries (WHODrug) and the Medical Dictionary for Regulatory Affairs (MedDRA). This module seamlessly integrates with EDC and Coded values are stored directly within the CliniOps EDC database.
        </Typography>
      </PageSectionRightImg> */}
      
      <Grid container direction='column' spacing={0} sx={{ mt: 0 }}>
            {/* eConsent  */}
          <Grid item>
              <Conduct
                ImgSrc={ImgeConsent}
                Name="eConsent"
                className="co-image-conduct"
                Summary={
                  <>
                  <CustomTypography variant='body2' sx={{ mt: 1 }}>
                  CliniOps eConsent module facilitates the consent process. Study team can use this module to monitor the compliance of the sites' 
                  consent process without compromising on patient PHI information. PHI information is redacted based on access control. <br></br>
                  Signed informed consent forms with the time stamp and geo location can be accessed through this module.
                </CustomTypography>
                </>
                }
              />
              
          </Grid>
      </Grid>
    </>
  );
}

export default template;