import react from 'react';


import _1 from "./_1";
import _2 from "./_2";
import _3 from "./_3";
import _4 from "./_4";
import { Box, Grid } from '@mui/material';
import waveImage from "../../../img/home/wave2.png";


const template = () =>{
    return (
     <>
      <_1/>
      <Box sx={{backgroundColor:"#F2F2F2",pb:1,mb:2,
       backgroundImage: `url(${waveImage})`,
       backgroundRepeat: "no-repeat",
       backgroundPositionY: "-80px",
       backgroundPositionX:"center",
       backgroundSize: "auto",        // Keeps the image at its original size
    //    backgroundAttachment: "fixed"  // Fixes the image relative to the viewport
      }}>
      <Grid item>
      {/* <_2/> */}
      <_3/>
      <_4/>
      </Grid>

      </Box>
     </>
    )
}

export default template;