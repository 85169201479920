import React from 'react';
import { Grid, Typography, Button, Collapse, Box } from "@mui/material";

const NewsPost = ({
    ImgSrc,
    Name,
    Date,
    Summary,
    FullContent,
    redirectUrl, 
}) => {
    // const [expanded, setExpanded] = React.useState(false);

    // const handleExpandClick = (event) => {
    //     setExpanded(!expanded);
    //     event.target.innerText = expanded ? 'Know More' : 'Show Less';
    // };

    const handleRedirect = () => {
        window.open(redirectUrl, '_blank'); 
    };

    return (
        <Box
            sx={{
                backgroundColor: 'white',
                borderRadius: '16px',
                padding: '24px',
                borderColor:'#CED2D4',
                borderWidth: '1px',
                borderStyle: 'solid',
                boxShadow: "10px 10px 8px rgba(0, 0, 0, 0.2)",
                marginBottom: '8px',  
                position: 'relative', 
            }}
        >

            <Grid container direction="row" spacing={{ xs: 2, md: 6 }}>
                <Grid item xs={12} md={2}>
                    <img
                        src={ImgSrc}
                        className="co-image-news"
                        alt={Name}
                        title={Name}
                    />
                </Grid>
                <Grid item xs={12} md={10}>
                    <Typography variant="h7" component="h2" className="co-news-heading">
                        {Name}
                    </Typography>
                    
                    <Typography variant="h4" component="h3" className="co-date">
                        {Date}
                    </Typography>

                    <Typography variant='body2' sx={{ mt: 1 }}>
                        {Summary}
                    </Typography>
                    <Typography variant='body2' sx={{ mt: 1 }}>
                        {FullContent}
                    </Typography>
                     {/* <Collapse in={expanded} timeout="auto" unmountOnExit>
                        {FullContent}
                    </Collapse> */}
                    <Button  onClick={handleRedirect} sx={{ mt: 1, ml: -1,textTransform:'capitalize',fontSize:"14px" }}>Know More</Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default NewsPost;
