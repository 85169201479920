import React from 'react';
import { Grid, Typography } from '@mui/material';

import { Product } from '../../../component/page';

import ImgStat from '../../../../img/service/clinical-data-management/Stat.png';
import CustomTypography from '../../../component/customTypography';

const template = () => {
  return (
    <>
      <Grid container direction='column' spacing={0} sx={{ mt: 0 }}>
            {/* Statistical Analysis and Programming  */}
          <Grid item>
              <Product
                ImgSrc={ImgStat}
                Name="Statistical Analysis and Programming"
                Summary={
                  <>
                  <CustomTypography variant='body2' sx={{ mt: 1 }}>
                    Comprehensive statistical analysis services include ADaM, SDTM, TLF programing, creation of SDTM 
                    (standard data tabulation model) domains and ADaM (analysis data model) datasets, Tables, Listings 
                    and Figures (TLFs) programming, interim statistical analysis for adaptive designs and data and safety 
                    monitoring boards (DSMBs), Safety data monitoring for SMC (safety monitoring committee), Data analysis 
                    for preparing integrated study reports, exploratory analysis for publications, abstracts, and marketing, 
                    and analysis of non-clinical trial data from epidemiologic studies and pre-clinical studies. Seasoned 
                    biostatisticians are committed to excellence in preparing statistical reports, writing statistical sections 
                    of clinical study reports, and writing statistical portions of manuscripts.
                </CustomTypography>
                </>
                }
              />
              
          </Grid>
      </Grid>
    </>
  );
}

export default template;

//Commenting previous code

// import React from 'react';
// import { Typography, Stack } from '@mui/material';

// import { PageSectionLeftImg } from '../../../component/page';

// import ImgOtherData from '../../../../img/service/clinical-data-management/otherData.jpeg';

// const template = () => {
//   return (
//     <>
//       <PageSectionLeftImg
//         heading={
//           <Stack direction="row" spacing={1} alignItems='center' justifyContent={{xs: 'center', md: 'flex-start'}}>
//             <Typography variant="h2" component="h2" className="co-page-heading" sx={{ textAlign: { xs: 'center', md: 'left'} }}>
//               Other Data Management Services
//             </Typography>
//           </Stack>
//         }
//         imgUrl={ImgOtherData}
//         imgTitle="Other Data Management Services"
//       >
//         <Typography sx={{ mt: 1 }}>
//           <ul style={{ listStylePosition: "inside" }}>
//             <li>e-Source data capture and integration training.</li>
//             <li>Development of dashboards and custom reports.</li>
//             <li>24/7 helpdesk support</li>
//             <li>Data quality control audits</li>
//           </ul>
//         </Typography>
//       </PageSectionLeftImg>
//     </>
//   );
// }

// export default template;