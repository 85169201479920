import React from 'react';
import { Grid, Typography, Button, Collapse, Box, Stack } from "@mui/material";

const CustomProfile = ({
    ImgSrc,
    Name,
    Position,
    Summary,
    FullContent
}) => {
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = (event) => {
        setExpanded(!expanded);
        event.target.innerText = expanded ? 'Know More' : 'Minimize';
    };

    return (
        <Box
            sx={{
                backgroundColor: 'white',
                borderRadius: '16px',
                padding: '24px',
                borderColor: '#CED2D4',
                borderWidth: '1px',
                borderStyle: 'solid',
                boxShadow: "10px 10px 8px rgba(0, 0, 0, 0.2)",
                marginBottom: '8px',
                position: 'relative',
            }}
        >
            <Grid container direction="row" spacing={{ xs: 2, md: 6 }} p={{xs:0.1,md:0}}>
                <Grid item xs={12} md={2}>
                    <img
                        src={ImgSrc}
                        className="co-image"
                        alt={Name}
                        title={Name}
                        style={{ maxHeight: '152px' }}
                    />
                </Grid>
                <Grid item xs={12} md={10}>
                   <Typography variant="subtitle1" component="h2" className="co-page-heading">
                        {Name}{Position}
                    </Typography>
                    <Typography variant='body2' sx={{ mt: 1 }}>
                        {Summary}
                    </Typography>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        {FullContent}
                    </Collapse>
                    <Button
                        variant="contained"
                        style={{
                            background: expanded ? '#07A7F9' : '#042D43',
                            textTransform: 'none',
                        }}
                        onClick={handleExpandClick}
                        sx={{ mt: 1, ml: -0.5 }}
                    >
                        {expanded ? 'Minimize' : 'Know More'}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default CustomProfile;
