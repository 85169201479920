import React from "react";
import { Grid, Box, Typography, Stack,Alert, Link } from "@mui/material";
import { teal, blueGrey } from "@mui/material/colors";

import { PageHero, PageContentV2 } from "../../component/page";
import ImgStephen from "../../../img/media/customer-story/_1/DrStephenHoffman.jpg";
import Imgjci from "../../../img/media/customer-story/_1/JCI-logo.png";
import ImgASTMH from "../../../img/media/customer-story/_1/ASTMHLogo.png";
import ImgIfkara from "../../../img/media/customer-story/_1/IfakaraHealthInstitute.png";
import ImgSwiss from "../../../img/media/customer-story/_1/SwissTPH.png";
import ImgSanaria from "../../../img/media/customer-story/_1/sanaria-logo-small.png";
import ImgMCD from "../../../img/media/customer-story/_1/MCDGlobalHealth.png";


// Page Imports
import _0 from "./customer-story_11/_0";

const template = () => {
  return (
    <>
      <PageHero color="rgba(3, 169, 244, 0.1)">
        <_0 />
      </PageHero>
      <PageContentV2>
        <Grid item>
          <Typography variant="subtitle2" paragraph>
          Off-line Data Collection with sites in Africa: Study to Evaluate Safety, Immunogenicity and Efficacy of PfSPZ Vaccine in HIV Negative and HIV Positive Tanzanian Adults 
          </Typography>
          <Typography variant="body7" paragraph>
          Globally, over 2 million deaths are caused by malaria and HIV each year.<sup>1,2,3</sup> Malaria and HIV may be present worldwide but their co-infection is considered common in sub-Saharan Africa.<sup>1,2,3</sup> Many clinical studies are addressing the prevention of this co-infection.
          </Typography>
          <Typography variant="body7" paragraph>
          Sanaria, a biotech company which develops vaccines to protect against malaria, sponsored a phase 1, randomized, double-blind, placebo-controlled trial to assess the safety, tolerability, immunogenicity and efficacy of PfSPZ Vaccine in HIV negative and HIV positive adults in Tanzania. In collaboration with the Ifakara Health Institute, the Swiss Tropical & Public Health Institute, and Medical Care Development International (now MCD Global Health), Sanaria successfully completed the study in August 2018.
          </Typography>

          <Typography variant="body7" paragraph>
          In many areas of Africa, internet availability is either non-existent or spotty. While it is common practice to record data on paper during public health research, this triggers many data quality issues downstream. With additional time and effort to resolve these quality issues, there is more site burden, eventually leading to significant delays in the study.  
          </Typography>

          <Typography variant="body7" paragraph>
          Anticipating the uncertainty of network dependencies and to ensure better data quality at source,  Sanaria partnered with CliniOps. With its tablet based eSource solution, CliniOps made it possible to gather data in complete offline mode using mobile devices. The site team were able to enter eCRF data on smart tablets even without internet connectivity. Data was then synchronized at regular intervals, or at the end of the day, at specific locations where connectivity was available.    
          </Typography>
          <Typography variant="body7" paragraph>
          This <i>‘offline capture-online synch’</i> process ensured that data entered offline was automatically synchronized in the central repository. Consequently, significant time savings were experienced in data generation, review, reporting, analysis and monitoring. CliniOps directly uploaded the data from safety laboratory testing and immunological assays which enabled the study team to review the clinical and research lab results along with eCRF data.
          </Typography>
          <Typography variant="body7" paragraph>
          “Foremost in our minds was to come up with something innovative to help study teams collect data efficiently when internet availability was poor and in so doing, lighten the site burden,” said Avik Pal, CEO, CliniOps. “We came up with the <i>‘offline capture-online synch’</i> approach that significantly reduced the dependency on paper based options. With smart tablets and complete offline functionality, the study team not only saved time but were assured of data quality. We are always grateful to contribute to important public health research.”
          </Typography>

          <blockquote
            style={{ borderLeft: `4px solid ${blueGrey[100]}`, marginLeft: -1 }}
          >
            <Stack
              spacing={4}
              sx={{ pl: 4 }}
              direction={{ xs: "column", md: "row" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minWidth: "220px",
                  width: "220px",
                  height: "250px",
                  borderRadius: 2,
                  backgroundImage: `url(${ImgStephen})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "top center",
                }}
              />
              <Box>
            <Typography variant="body7" paragraph>
              <em>
              “Prevention of malaria by vaccination remains a strong focus for Sanaria. The phase 1 study to evaluate safety, immunogenicity and efficacy of PfSPZ Vaccine in HIV negative and HIV positive Tanzanian adults was a public health research initiative that we foresaw will help many communities struggling and allow vaccination of all individuals during a malaria mass vaccination campaign without needing to check HIV status.  Data collection would have been difficult without the mobile technology platform from CliniOps, that enabled data collection in complete offline mode. We knew that lack of connectivity would be a challenge but having smart devices open to offline data entry was a big advantage.”</em>
            </Typography>
            <Typography variant="overline" paragraph>
              <strong>
                Dr. Stephen Hoffman
                <br />
                CEO, Sanaria Inc 
              </strong>
            </Typography>
          </Box>
            </Stack>
          </blockquote>

          <Typography variant="body7" paragraph>
          References:    
          </Typography>

          <Typography variant="body7" paragraph>
          1. Tebit E Kwenti, Malaria and HIV coinfection in sub-Saharan Africa: prevalence, impact, and treatment strategies. Res Rep Trop Med. 2018; 9: 123–136. <Link href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6067790/" target="_blank" underline="hover">https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6067790/</Link>
          </Typography>

          <Typography variant="body7" paragraph>
          2. Mukandavire Z, Gumel AB, Garira W, et al. Mathematical analysis of a model for HIV-malaria co-infection. Math Biosci Eng. 2009;6(2):333–362.
          </Typography>

          <Typography variant="body7" paragraph>
          3. World Health Organization . Malaria in HIV/AIDS Patients. Geneva: WHO; 2017.
          </Typography>

        
        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '175px',
                width: '175px',
                height: '80px',
                borderRadius: 2,
                marginTop: '30px',
                backgroundImage: `url(${Imgjci})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
            />
            <Box>
            <Typography paragraph>
            A publication titled, <Link href="https://www.jci.org/" target={"_blank"} underline="hover">"Safety and Protective Efficacy of PfSPZ Vaccine Administered to HIV Negative and Positive Tanzanian Adults”</Link>, was accepted in the Journal of Clinical Investigation, 2023.</Typography>
            </Box>
        </Stack>
        </Alert>
        
        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '175px',
                width: '175px',
                height: '80px',
                borderRadius: 2,
                marginTop: '30px',
                backgroundImage: `url(${ImgASTMH})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
          />
          
           <Box>
           <Typography paragraph>
           The results of this study were presented at the 67th Annual Meeting of the American Society for Tropical Medicine and Hygiene, October 28 - November 1, 2018, 
            Sheraton New Orleans. Presentation # 1486: <Link href="https://www.astmh.org/ASTMH/media/Documents/1-2018-Program-Book.pdf" target={"_blank"} underline="hover">"Tolerability, Safety and Protective Efficacy of Direct Venous Inoculation of Condensed Vaccination Regimen of PfSPZ Vaccine in Tanzanian Adults Including HIV Infected Volunteers"</Link>.
           </Typography>
           </Box>
          </Stack>
        </Alert>
        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
          <stack>
        <Box
                xs="auto"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minWidth: '175px',
                    width: '175px',
                    height: '80px',
                    borderRadius: 2,
                    backgroundImage: `url(${ImgIfkara})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top center'
                }}
            />
            <Box
                    xs="auto"
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minWidth: '175px',
                        width: '175px',
                        height: '80px',
                        borderRadius: 2,
                        backgroundImage: `url(${ImgSwiss})`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'top center'
                    }}
                />
            </stack>
            <Box>
            <Typography paragraph>
            The study was conducted in partnership with <Link href="https://www.ihi.or.tz/" target={"_blank"} underline="hover">Ifakara Health Institute</Link>, and the <Link href="https://www.swisstph.ch/en/" target={"_blank"} underline="hover">Swiss Tropical and Public Health Institute</Link>.
            </Typography>
            </Box>
        </Stack>
        </Alert>
        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Stack display={"block"}>
        <Box
                xs="auto"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minWidth: '175px',
                    width: '175px',
                    height: '30px',
                    borderRadius: 2,
                    backgroundImage: `url(${ImgSanaria})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top center'
                }}
            />
            <Box
                xs="auto"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'left',
                    minWidth: '175px',
                    width: '175px',
                    height: '80px',
                    borderRadius: 2,
                    backgroundImage: `url(${ImgMCD})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top center'
                }}
            /></Stack>
            <Box>
            <Typography paragraph>
            The study was sponsored by <Link href="https://sanaria.com/" target={"_blank"} underline="hover">Sanaria Inc.</Link>
            </Typography>
            <Typography paragraph>
            A US-based non-governmental organization, <Link href="https://www.mcd.org/" target={"_blank"} underline="hover">MCD Global Health</Link> provided logistics and all on-site support.
            </Typography>
            </Box>
        </Stack>
        </Alert>
        </Grid>
      </PageContentV2>
    </>
  );
};

export default template;
