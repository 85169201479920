import React from 'react';
import { Grid, Divider } from '@mui/material';
import { blueGrey } from '@mui/material/colors';

import { PageHero, PageContent } from '../../component/page';

// Page Imports
import _0 from './privacy/_0';
import _1 from './privacy/_1';

const template = () => {
  return (
    <>
        <_0 />
      {/* <PageContent> */}
        <Grid item sx={{mt:5,mb:5}}>
          <_1 />
        </Grid>
      {/* </PageContent> */}
    </>
  );
}

export default template;