import React from 'react';
import { Box, Typography } from '@mui/material';

// import ImgBg from '../../../../img/service/support-helpdesk/SH-Header.png';
import ImgTopWave from "../../../../img/home/wave1.png";
import ImgBottomWave from "../../../../img/home/wave2.png";
import ImgSupportAgent from "../../../../img/service/support-helpdesk/helper.png";

const Template = () => {
  return (
    <>
     <Box
        sx={{
          position: "relative",
          width: "100%",
          // backgroundColor: "#F2F2F2",
          overflow: "visible",
          margin: "50px 0 100px 0",
          // textAlign: "center",
        }}
      >
        {/* Top Wave Background */}
        <Box
          component="img"
          src={ImgTopWave}
          alt="Top Wave"
          sx={{
            position: "absolute",
            top: 0,
            width: "100%",
            height: "auto",
            zIndex: 1,
            objectFit: "contain",
          }}
        />

        {/* Bottom Wave Background */}
        <Box
          component="img"
          src={ImgBottomWave}
          alt="Bottom Wave"
          sx={{
            position: "absolute",
            bottom: {xs:-10,sm:-10,md:-100},
            width: "100%",
            height: "auto",
            zIndex: 3,
            objectFit: "contain",
          }}
        />

        {/* Center Content */}
        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            src={ImgSupportAgent}
            alt=""
            sx={{
              width: {xs:"80%",md:"500px"},
              maxWidth:"100%",
              height: "auto",
              objectFit: "contain",
              zIndex: 2,
            }}
          />

          <Box
            sx={{
              maxWidth: "100%",
              backgroundColor: "#F2F2F2",
              minHeight: "300px",
              zIndex: 1,
              padding: "20px 0",
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
                margin: "auto",
                padding: { xs: "20px", md: "10px 0" },
              }}
            >
              <Typography
              variant='h3'
                sx={{
                  lineHeight: "24px",
                }}
              >
               These services contribute to overall trial efficiency and customer satisfaction, 
                by providing project governance, timely resolutions, handling escalations, 
                flagging risks, providing support and tracking deliverables.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
        }}
      >
        <img
          src={`${ImgBg}`}
          alt="Support & Helpdesk"
          style={{
            width: '100%',
            height: 'auto', 
            objectFit: 'cover', 
          }}
        />
      </Box>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: { xs: 'center', md: 'left' },
          marginTop: { xs: 2, md: 1 },
          paddingX: { xs: 2, md: 10 }, // Add responsive padding
        }}
      >
        <Box
          sx={{
            marginTop: { xs: 1, md: '-50%' },
            maxWidth: '800px', // width of the text
            mx: 'auto', // Center content horizontally for small screens
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: '#003B5C',
              lineHeight: 1.5,
              fontSize: { xs: '14px', md: '16px' },
            }}
          >
            These services contribute to overall trial efficiency and customer satisfaction, 
            by providing project governance, timely resolutions, handling escalations, 
            flagging risks, providing support and tracking deliverables.
          </Typography>
        </Box>
      </Box> */}
    </>
  );
};

export default Template;
