import React from 'react';
import { Box, Typography, Grid, Card, CardHeader, CardMedia, Link } from '@mui/material';

import ImgMedhealthoutlook from '../../../../img/media/newsroom/_2022/medhealthoutlook.png';
import ImgRoche from '../../../../img/media/newsroom/_2022/Media-2020-21.jpg';
import Img360Research from '../../../../img/media/newsroom/_2022/360iresearch.png';
import ImgFeb012022 from '../../../../img/media/newsroom/_2022/20220201.png';
import ImgFeb022022 from '../../../../img/media/newsroom/_2022/20220202.png';
import ImgFeb282022 from '../../../../img/media/newsroom/_2022/20220228.png';
import ImgMay012022 from '../../../../img/media/newsroom/_2022/20220501.png';
import ImgMay182022 from '../../../../img/media/newsroom/_2022/20220518.png';
import ImgJune102022 from '../../../../img/media/newsroom/_2022/20220610.png';
import ImgJune142022 from '../../../../img/media/newsroom/_2022/20220614.png';
import ImgJune232022 from '../../../../img/media/newsroom/_2022/20220623.jpg';
import Img20220904 from '../../../../img/media/newsroom/_2022/20220904.png';
import Img20220830 from '../../../../img/media/newsroom/_2022/20220830.png';
import ImgGartner from '../../../../img/media/newsroom/_2021/Media-2019-03.png';
import Img20220915 from '../../../../img/media/newsroom/_2022/20220915.png';
import Img20220907 from '../../../../img/media/newsroom/_2022/20220907.png';
import Img20220912 from '../../../../img/media/newsroom/_2022/20220912.png';
import Img20220901 from '../../../../img/media/newsroom/_2022/20220901.png';
import Imgtotalhealth from '../../../../img/media/newsroom/_2022/Imgtotalhealth.png';
import ImgForbes from '../../../../img/media/newsroom/_2022/Forbes.png';
import ImgYahoo from '../../../../img/media/newsroom/_2022/ImgYahoo.png';
import Imgjournal from '../../../../img/media/newsroom/_2022/journal.png';
import Imgeverest from '../../../../img/media/newsroom/_2022/everest.jpg';
import ImgResearchAndMarkets from '../../../../img/media/newsroom/_2021/Media-2018-08.png';
import ImgPLOS from '../../../../img/media/newsroom/_2022/PLOS_ntd_h.png';
// import sign from '../../../../img/media/newsroom/Renew/sign.png';


const news2022Data = [
      {
        ImgSrc: ImgForbes,
        Name: "'Stayin' Alive': Patient Centricity In Decentralized Clinical Trials",
        Date: "November 11, 2022",
        Summary: `"Whether you're a brother or whether you're a mother, you're stayin' alive, stayin' alive. Feel the city breakin' and everybody shakin' and we're stayin' alive, stayin' alive." — Bee Gees, "Stayin' Alive"`,
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
            Amid the fear of Covid-19 transmission and consequent lockdowns, the global chorus was to stay alive by staying at home. Even the Bee Gees' "Stayin' Alive" lyrics—"feel the city breakin' and everybody shakin'"—were re-imagined as an urgent call to stay home to stay alive due to the dread and distress of the pandemic.
          </Typography>
        ),
        redirectUrl: "https://www.forbes.com/sites/forbestechcouncil/2022/11/11/stayin-alive-patient-centricity-in-decentralized-clinical-trials/?sh=413f0dd2792e",
      },
      {
        ImgSrc: ImgResearchAndMarkets,
        Name: "Global Virtual Clinical Trials Global Market Report 2022: Increasing Adoption of Telehealth Driving Growth",
        Date: "November 09, 2022",
        Summary: `“Global Virtual Clinical Trials Market (2022-2027) by Study Design, Implication, and Geography, Competitive Analysis and the Impact of Covid-19 with Ansoff Analysis”`,
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
           The Global Virtual Clinical Trials Market is estimated to be USD 3.09 Bn in 2022 and is expected to reach USD 8.45 Bn by 2027, growing at a CAGR of 22.3%
          </Typography>
        ),
        redirectUrl: "https://www.businesswire.com/news/home/20221109005651/en/Global-Virtual-Clinical-Trials-Global-Market-Report-2022-Increasing-Adoption-of-Telehealth-Driving-Growth---ResearchAndMarkets.com",
      },
      {
        ImgSrc: Imgeverest,
        Name: "Decentralized Clinical Trial Platforms PEAK Matrix® Assessment 2023",
        Date: "November 09, 2022",
        Summary: "Decentralized Clinical Trials (DCTs), in which clinical trial data is collected through sensors or remote monitoring devices, can deliver many benefits to pharmaceutical companies, including cost savings, better patient recruitment and retention, flexibility in operations, and improved data quality.",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
           Before the pandemic, although the technology and data to support DCTs existed, only a few pilots were conducted. Today, the pressing need for remote patient- and site-centric trials has increased investments in DCTs by pharma enterprises, and the momentum is expected to accelerate going forward, indicating that DCTs are here to stay. Additionally, technology advances, innovative business models, increased wearables support, US FDA’s push to adopt DCTs, and a holistic approach to clinical trials have strengthened the DCT landscape.
          </Typography>
        ),
        redirectUrl: "https://www2.everestgrp.com/reportaction/EGR-2022-46-R-5610/Marketing",
      },
      {
        ImgSrc: Imgjournal,
        Name: "Case Study: Use of Decentralized Clinical Technologies in Lower Urinary Tract Symptoms",
        Date: "October 31, 2022",
        Summary: "As the pandemic surged in 2021, a clinical trial to evaluate the impact of Crinum latifolium extract, oral Crila® capsules on lower urinary tract symptoms (LUTS) was about to be activated. With not enough information to assess the safety risk of having patients go to the clinical investigation sites, the study could not start. The safety risk was high. The economic cost of delay or discontinuance of the study was equally high. The sponsor and study team had to weigh the risks competently.",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
            The clinical study on LUTS and the use of Crila® capsules commenced in two sites in Mexico as soon as technologies for virtual clinical trials were in place. DCTs like eSource, remote data monitoring and medicine delivery tracking, with built-in electronic data collection were immediately customized for the needs of the LUTS study. The study staff were trained via Zoom.
          </Typography>
        ),
        redirectUrl: "https://www.jmust.org/elib/journal/doi/10.35460/2546-1621.2022-0052/full",
      },
      {
        ImgSrc: ImgYahoo,
        Name: "CliniOps Recognized in 'Gartner Hype Cycle' for Fifth straight year!",
        Date: "October 26, 2022",
        Summary: " CliniOps, Inc., a leading provider of advanced Digital Clinical Trials, today announced that it has been named in the Gartner annual Hype Cycle™ for Life Science Clinical Development, for five consecutive years.",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
            Since first acknowledged as a Sample Vendor for eSource solutions in 2018, CliniOps has risen to the challenge of providing solutions fit for the changing needs of the clinical sponsors, clinical research organizations, investigators and patients.
          </Typography>
        ),
        redirectUrl: "https://finance.yahoo.com/news/cliniops-recognized-gartner-hype-cycles-140000004.html",
      },
      {
        ImgSrc: Imgtotalhealth,
        Name: "CliniOps Announces Strategic Partnership with My Total Health",
        Date: "October 20, 2022",
        Summary: "Collaboration will accelerate and streamline gastroenterology (GI) clinical trials with an end-to-end platform for patient recruitment, enrollment, and management",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
            CliniOps, Inc. announced today a strategic partnership with My Total Health Inc., in partnership with Commonwealth Diagnostics International, Inc. (CDI), in which the companies will collaborate to provide an end-to-end clinical trial solution for the gastroenterology (GI) community. 
          </Typography>
        ),
        redirectUrl: "https://www.newswire.com/news/cliniops-announces-strategic-partnership-with-my-total-health-21853614",
      },
      {
        ImgSrc: Img20220915,
        Name: "CliniOps joins Exostar Secure Access Manager (SAM) to enable Single Sign On (SSO)",
        Date: "October 01, 2022",
        Summary: "CliniOps joins Exostar Secure Access Manager (SAM) to enable Single Sign On (SSO).",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
            {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
          </Typography>
        ),
        redirectUrl: "https://www.exostar.com/product/secure-access-manager/",
      },
      {
        ImgSrc: Img20220915,
        Name: "Centralized Access for Decentralized Trials",
        Date: "September 15, 2022",
        Summary: "CliniOps Application Suite now include seamless access to trials, with advanced industry standard security features.",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
           CliniOps, the leading provider of Hybrid and Decentralized Clinical Trials (DCT) solutions, today announced the integration of its product suite with Exostar’s Secure Access Manager (SAM)™. Exostar® continues to gain momentum as the de-facto identity used by investigative sites to access clinical trial portals and applications to share data between sponsors and their sites.
          </Typography>
        ),
        redirectUrl: "https://www.exostar.com/press/cliniops-connects-with-exostar-to-deliver-a-unified-access-experience-for-digital-clinical-trials/",
      },
      {
        ImgSrc: Img20220907,
        Name: "Forward-Solution Noninvasive Computational Arrhythmia Mapping: The VMAP Study",
        Date: "September 07, 2022",
        Summary: "The VMAP study (Vectorcardiographic Mapping of Arrhythmogenic Probability) was a blinded, multicenter clinical study using an independent core laboratory to validate the performance of a forward-solution, noninvasive arrhythmia mapping system in 7 atrial and ventricular arrhythmias plus atrial and ventricular pacing.",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
           The study protocol was reviewed and approved by governing Institutional Review Boards at all sites and was conducted in accordance with all applicable human subject research requirements and applicable federal regulations.
          </Typography>
        ),
        redirectUrl: "https://www.ahajournals.org/doi/abs/10.1161/CIRCEP.122.010857",
      },
      {
        ImgSrc: Img20220904,
        Name: "European Respiratory Society, International Congress, 2022",
        Date: "September 04, 2022",
        Summary: "European Respiratory Society (ERS) Congress - an annual event that brings together the world’s respiratory experts to showcase all the latest advances in respiratory medicine and science.",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
            {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
          </Typography>
        ),
        redirectUrl: "https://www.ersnet.org/congress-and-events/congress/",
      },
      {
        ImgSrc: Img20220901,
        Name: "The CEO Magazine",
        Date: "September 01, 2022",
        Summary: "Avik Pal, the founder and CEO of CliniOps, a technology and data company with a patented unified platform approach to digitizing clinical trials, finds universal solutions to counter adversities.",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
            {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
          </Typography>
        ),
        redirectUrl: "https://magazine.theceo.in/technology-industry-and-business-leaders-food-and-processing-2022/",
      },
      {
        ImgSrc: Img20220830,
        Name: "Are hybrid clinical trials a pit stop as the pendulum swings to decentralization?",
        Date: "August 30, 2022",
        Summary: "Watching a Formula One race car stop at the pit to refuel, recalibrate or repair thrills enthusiasts as they hold their breaths: A pit stop could spell winning or losing the race. The pit team descends on the car with laser focus and warp speed. Before this pit stop metaphor goes into the rabbit hole of irrelevance to clinical trials (CTs)",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
            However, when a particular CT becomes hybrid, the “pit team” like the sponsors, clinical research organizations (CROs), clinical research technology providers (CRTs), and research investigators certainly calibrate centralized elements of a CT to assign functions that could be decentralized for more efficient and speedy outcomes.
          </Typography>
        ),
        redirectUrl: "https://www.fastcompany.com/90780899/are-hybrid-clinical-trials-a-pit-stop-as-the-pendulum-swings-to-decentralization",
      },
      {
        ImgSrc: ImgGartner,
        Name: "Hype Cycle for Life Science Clinical Development, 2022",
        Date: "July 26, 2022",
        Summary: "Hype Cycle for Life Science Clinical Development, 2022",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
            {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
          </Typography>
        ),
        redirectUrl: "https://www.gartner.com/interactive/hc/4016952",
      },
      {
        ImgSrc: ImgMay012022,
        Name: "In Conversation with Avik Pal | CliniOps | The Enterprise World",
        Date: "July 11, 2022",
        Summary: "Avik Pal, the founder and CEO of CliniOps, a technology and data company with a patented unified platform approach to digitizing clinical trials, finds universal solutions to counter adversities.",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
            CliniOps is a Technology and Data Science company for the Life Science Industry. The company helps Pharma and Biotech companies to conduct Clinical Trials (Hybrid & DCT trials) that makes drug trials accessible, inclusive, faster, and cheaper. The technology platform leverages AI, Mobile, Analytics, Cloud, Sensors and Connected Devices.
          </Typography>
        ),
        redirectUrl: "https://www.youtube.com/watch?v=SehYJmxSqMU",
      },
      {
        ImgSrc: ImgJune232022,
        Name: "The American Society of Pharmacognosy Annual Conference, 2022",
        Date: "June 23, 2022",
        Summary: "The American Society of Pharmacognosy Annual Conference, 2022",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
            {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
          </Typography>
        ),
        redirectUrl: "https://crilaforprostate.com/pages/asp-2022-research",
      },
      {
        ImgSrc: ImgJune142022,
        Name: "Clinical Development Platforms Products PEAK Matrix® Assessment 2022",
        Date: "June 14, 2022",
        Summary: "Clinical development platforms typically evolve with technology advances and scientific breakthroughs.",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
            Notably, the COVID-19 pandemic accelerated the adoption of digital technologies, data science, analytics, and automation tools, enabling remote services and preserving the continuity of care.
          </Typography>
        ),
        redirectUrl: "https://www2.everestgrp.com/reportaction/EGR-2022-46-R-5266/Marketing",
      },
      {
        ImgSrc: ImgJune102022,
        Name: "Meet the Cutting edge tech companies disrupting the industry",
        Date: "June 10, 2022",
        Summary: "Meet the Cutting edge tech companies disrupting the industry",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
            {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
          </Typography>
        ),
        redirectUrl: "https://www.usatoday.com/story/sponsor-story/level-up/2022/06/10/meet-cutting-edge-tech-companies-disrupting-industry/7566391001/",
      },
      {
        ImgSrc: ImgMay182022,
        Name: "Decentralized Studies Victory",
        Date: "May 18, 2022",
        Summary: "Crila is based on a proprietary patented cultivar - Crinum latifolium L var. crilae Tram & Khanh.  It is our privilege, together with Dr. Nguyen Thi Ngoc Tram developer of the original Crila extractions, to add to the knowledge of this remarkable medicinal herb.",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
           Kudos to Dr. Nadina Jose, an experienced global clinical research professional, who supervised our team, and brought in Avik Pal, Founder and CEO at CliniOps.
          </Typography>
        ),
        redirectUrl: "https://www.linkedin.com/pulse/decentralized-studies-victory-sue-mckinney/",
      },
      {
        ImgSrc: ImgMedhealthoutlook,
        Name: "Top 10 Digital Health Solution Providers 2022",
        Date: "May 11, 2022",
        Summary: "Amidst the pandemic and the changing healthcare trends today, patient and consumer expectations of healthcare are changing. ",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
           With the new and upcoming trends, future health systems will need to deliver care that is more accessible, scalable, and equitable.
          </Typography>
        ),
        redirectUrl: "https://medhealthoutlook.com/digital-health-solution-providers-list-2022/",
      },
      {
        ImgSrc: ImgMay012022,
        Name: "Most Promising Digital Health Solution Providers in 2022",
        Date: "May 01, 2022",
        Summary: "Avik Pal, the founder and CEO of CliniOps, a technology and data company with a patented unified platform approach to digitizing clinical trials, finds universal solutions to counter adversities.",
        FullContent: (
          <Typography variant='body2'sx={{ mt: 2 }}>
            {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
          </Typography>
        ),
        redirectUrl: "https://theenterpriseworld.com/digital-health-solution-providers-in-2022/",
      },
      {
        ImgSrc: ImgRoche,
        Name: "Futurist Series talk # 1 - Pharma’s approach to Decentralized Trials",
        Date: "April 19, 2022",
        Summary: "Futurist Series talk # 1 - Pharma’s approach to Decentralized Trials",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
            {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
          </Typography>
        ),
        redirectUrl: "https://www.roche.com/",
      },
      {
        ImgSrc: ImgFeb282022,
        Name: "15 ways to manage your company’s image from the inside out",
        Date: "February 28, 2022",
        Summary: "With the internet in our hands, there’s so much at stake when it comes to attracting and retaining clientele. ",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
           Today’s consumers are bold and savvy about researching the specific products they intend to purchase and they don’t have a problem calling a brand name out over social media regarding its lack of transparency about its merchandise, services, or a timely topic that could affect the bottom line.
          </Typography>
        ),
        redirectUrl: "https://www.fastcompany.com/90722848/16-ways-to-manage-your-companys-image-from-the-inside-out",
      },
      {
        ImgSrc: ImgPLOS,
        Name: "Safety and efficacy of mass drug administration with a single-dose triple-drug regimen of albendazole + diethylcarbamazine + ivermectin for lymphatic filariasis in Papua New Guinea: An open-label, cluster-randomised trial",
        Date: "February 09, 2022",
        Summary: "Safety and efficacy of mass drug administration with a single-dose triple-drug regimen of albendazole + diethylcarbamazine + ivermectin for lymphatic filariasis in Papua New Guinea: An open-label, cluster-randomised trial",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
            {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
          </Typography>
        ),
        redirectUrl: "https://journals.plos.org/plosntds/article?id=10.1371/journal.pntd.0010096",
      },
      {
        ImgSrc: ImgFeb022022,
        Name: "14 ways to crush quarterly goals in 2022",
        Date: "February 02, 2022",
        Summary: "In the business world, it’s important to set and achieve quarterly goals if you want your company to experience success with its clients, in the marketplace, and remain in business.",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
            It’s also necessary for leaders to make those milestones realistic enough for the team to attain them and avoid burnout or attrition from the heavy workload.
          </Typography>
        ),
        redirectUrl: "https://www.fastcompany.com/90722550/15-ways-to-crush-quarterly-goals-in-2022",
      },
      {
        ImgSrc: ImgGartner,
        Name: "Life Science CIOs: Map Your Pathway to Digital Trials",
        Date: "January 17, 2022",
        Summary: "Life Science CIOs: Map Your Pathway to Digital Trials",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
            {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
          </Typography>
        ),
        redirectUrl: "https://www.gartner.com/document/3989224",
      },
]

export default news2022Data;