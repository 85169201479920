import React from "react";
import { Grid, Box, Typography, Stack,Alert, Link } from "@mui/material";
import { teal, blueGrey } from "@mui/material/colors";

import { PageHero, PageContentV2 } from "../../component/page";
import ImgSally from "../../../img/media/customer-story/_1/SallyKinrade.jpg";
import ImgEDCTP from "../../../img/media/customer-story/_1/logo1.png";
import ImgGDPR from "../../../img/media/customer-story/_1/logo2.png";
import ImgMDGH from "../../../img/media/customer-story/_1/MDGH.svg";
import ImgLNRF from "../../../img/media/customer-story/_1/LNRFS.png";
// Page Imports
import _0 from "./customer-story-4/_0";

const template = () => {
  return (
    <>
      <PageHero color="rgba(3, 169, 244, 0.1)">
        <_0 />
      </PageHero>
      <PageContentV2>
        <Grid item>
          <Typography variant="subtitle2" paragraph>
          Onchocerciasis Study in Democratic Republic of Congo and Côte d'Ivoire: Technology and Determination to Reach Underserved Populations
          </Typography>
          <Typography variant="body7" paragraph>
          When a clinical trial involves 12,500 participants living in mostly geographically disparate and physically difficult to access areas, with poor resources and infrastructure,
          the use of innovative digital solutions help reduce the operational challenges facing study teams. 
          </Typography>
          <Typography variant="body7" paragraph>
          Onchocerciasis or ‘river blindness’ is a disease caused by the larvae of the parasitic worm Onchocerca volvulus, 
          that migrate to the skin, eyes, and other organs. It is transmitted from person to person through exposure to 
          repeated bites of infected blackflies<sup>1</sup>. Blackflies breed along fast flowing rivers and streams, 
          hence the name ‘river blindness’. According to the Global Burden of Disease Study 2017, 
          220 million people in affected communities, mostly in Africa, were estimated to need 
          preventive treatment to eliminate onchocerciasis, 14.6 million infected people already 
          had skin disease and 1.15 million vision loss<sup>1</sup>. For more information about Medicines Development 
          for Global Health<sup>2</sup> and the MoxiMultiDoseModproject<sup>3</sup>, please see the websites below.
          </Typography>
          <Typography variant="body7" paragraph>
          Beginning in October 2019, Medicines Development for Global Health (MDGH) collaborated with CliniOps for eSource and mobile technologies to support data handling in two randomized clinical trials evaluating the safety and efficacy of moxidectin compared with ivermectin for treatment of individuals living in onchocerciasis endemic areas in Democratic Republic of Congo. A further site in Côte d'Ivoire was activated in April 2023 to support reaching the enrolment target thanks to a grant from a large Global Health Foundation; the aim being to cover up to 6,000 participants from Côte d'Ivoire.  Target enrollment for all sites combined remains at approximately 12,500, a significant number to curtail the spread of onchocerciasis. 
          </Typography>

          <Typography variant="body7" paragraph>
          CliniOps understands the data quality challenges in conducting research in resource poor regions, 
          and hence the solution has been custom-built to address these issues. It not only removes several 
          inefficiencies and manual processes, but also transforms the clinical trial workflow by 
          streamlining the data collection, data analytics and data submission processes.   
          Researchers can also get instant visibility of study KPI's, with real-time reporting/analytics and several other rich 
          capabilities.

          </Typography>

          <Typography variant="body7" paragraph>
          During participant enrollment and assessment in mostly remote locations, study investigators have the advantage of using eSource technology by directly entering participant information into smart devices and from which specific data is drawn for the purposes of the study. Since the studies were to be conducted in the field with many locations not possessing power, network infrastructure or internet connectivity, CliniOps developed complete offline functionality to enable information gathered in smart devices to be seamlessly synchronized with the database when electronic connectivity became available. Randomization capability was built and scaled with offline features as well and configured further to accommodate the new site in Côte d'Ivoire. 
          CliniOps is also providing electronic data capture (EDC), electronic case report form (eCRF) development, data management, medical coding, randomization, statistical programming, and biostatistics
 
          </Typography>

          <Typography variant="body7" paragraph>
          The study team at MDGH has been able to view clinical trial data in real time, anytime anywhere, 
          which is vital for safety oversight of the subjects in the study, for early detection of emerging trends 
          and for quality oversight.  These enabling technologies have transformed study data collection for the sponsor, 
          contracted monitors and for site staff, and have made the huge task of collecting and managing the data much more 
          straightforward.
          </Typography>
          <blockquote
            style={{ borderLeft: `4px solid ${blueGrey[100]}`, marginLeft: -1 }}
          >
            <Stack
              spacing={4}
              sx={{ pl: 4 }}
              direction={{ xs: "column", md: "row" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minWidth: "220px",
                  width: "220px",
                  height: "220px",
                  borderRadius: 2,
                  backgroundImage: `url(${ImgSally})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "top center",
                }}
              />
              <Box>
              
                <Typography variant="body7" paragraph>
                  <em>
                  "MDGH is committed to developing new medicines and therapies for underserved populations, including to help reach the target for eliminating neglected diseases such as onchocerciasis and lymphatic filariasis. When we initiated our current clinical trials on onchocerciasis in Sub-Saharan Africa, we were determined to address the challenges of conducting research in remote communities and to find the best way to support study teams working with limited or no connectivity infrastructure. Our collaboration with CliniOps to provide eSource technologies with complete offline functionality in a unified platform is making it possible for the study team to recruit and engage participants even in the remotest locations. Our determination to complete our studies as efficiently as possible is significantly enabled by our use of CliniOps’s reliable clinical trial technologies. We look forward to building on our partnership with CliniOps, beyond our current program."
                  </em>
                </Typography>
                <Typography variant="overline" paragraph>
                  <strong>
                     Sally Kinrade, MPH
                     <br />
                    Vice President, Project Leader Onchocerciasis and Lymphatic Filariasis, MDGH
                  </strong>
                </Typography>

              </Box>
            </Stack>
          </blockquote>

           <Stack >
             <Typography variant="body7" paragraph>
               <sup>1.</sup> World Health Organization, Key Facts January 11, 2022:  <Link href="https://www.who.int/news-room/fact-sheets/detail/onchocerciasis" target="_blank" underline="hover">https://www.who.int/news-room/fact-sheets/detail/onchocerciasis
                </Link>
              </Typography>
              <Typography variant="body7" paragraph sx={{marginTop:"-1em"}}>
              <sup>2. </sup> Medicines Development for Global Health website: <Link  href="https://www.medicinesdevelopment.com/" target="_blank" underline="hover">https://www.medicinesdevelopment.com/
                </Link>
              </Typography>
              <Typography variant="body7" paragraph sx={{marginTop:"-1em"}}>
              <sup>3. </sup> MoxiMultiDoseMod project website: <Link  href="https://mox4oncho-multimox.net/" target="_blank" underline="hover">https://mox4oncho-multimox.net/
                </Link>
              </Typography>
           </Stack>

        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '114px',
                width: '114px',
                height: '80px',
                borderRadius: 2,
                backgroundImage: `url(${ImgEDCTP})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
          />
          <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '114px',
                width: '114px',
                height: '80px',
                borderRadius: 0,
                backgroundImage: `url(${ImgGDPR})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
          />
           <Box>
           <Typography paragraph sx={{marginLeft:"3.3em"}}>
           This project is part of the EDCTP2 programme supported by the European Union (grant number RIA2017NCT-1843 MoxiMultiDoseMod)
           </Typography>
           </Box>
          </Stack>
     </Alert>
     <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
     <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
     <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '114px',
                width: '114px',
                height: '55px',
                borderRadius: 2,
                backgroundImage: `url(${ImgMDGH})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
          />
          <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '114px',
                width: '200px',
                height: '55px',
                borderRadius: 2,
                backgroundImage: `url(${ImgLNRF})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
          />
           <Box>
           <Typography paragraph>
            Co-funded by Medicines Development for Global Health and Fond National de Recherche (FNR) Luxembourg
           </Typography>
           </Box>
      </Stack>
     </Alert>

        {/* </Alert> */}

          {/* <blockquote
            style={{ borderLeft: `4px solid ${blueGrey[100]}`, marginLeft: -1 }}
          >
            <Box sx={{ pl: 4 }}>
              <Typography paragraph>
                A Case Study titled ‘Use of Decentralized Clinical Technologies
                in Lower Urinary Tract Symptoms’ is published in the Journal of
                Medicine, University of Santo Tomas, in the October 2022 issue,
                authored by Dr. Nadina Jose & Avik Pal
              </Typography>
            </Box>
          </blockquote> */}
        </Grid>
      </PageContentV2>
    </>
  );
};

export default template;
