import React from "react";
import { Box, Typography } from "@mui/material";

// import ImgBg1 from '../../../../img/service/study-setup/study-setup-header.png';
// import ImgBg from "../../../../img/home/wave2-1.png";
import ImgTopWave from "../../../../img/home/wave1.png";
import ImgBottomWave from "../../../../img/home/wave2.png";
import ImgRecuiters from "../../../../img/about/careers/recuiters.png";

const Template = () => {
  return (
    <>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          // backgroundColor: "#F2F2F2",
          overflow: "visible",
          margin: "50px 0 100px 0",
          // textAlign: "center",
        }}
      >
        {/* Top Wave Background */}
        <Box
          component="img"
          src={ImgTopWave}
          alt="Top Wave"
          sx={{
            position: "absolute",
            top: 0,
            width: "100%",
            height: "auto",
            zIndex: 1,
            objectFit: "contain",
          }}
        />

        {/* Bottom Wave Background */}
        <Box
          component="img"
          src={ImgBottomWave}
          alt="Bottom Wave"
          sx={{
            position: "absolute",
            bottom: -100,
            width: "100%",
            height: "auto",
            zIndex: 3,
            objectFit: "contain",
          }}
        />

        {/* Center Content */}
        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            src={ImgRecuiters}
            alt=""
            sx={{
              width: { xs: "80%", md: "500px" },
              maxWidth: "100%",
              height: "auto",
              objectFit: "contain",
              zIndex: 2,
            }}
          />

          <Box
            sx={{
              maxWidth: "100%",
              backgroundColor: "#F2F2F2",
              minHeight: "700px",
              zIndex: 1,
              padding: "20px 0",
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "70%" },
                margin: "auto",
                padding: { xs: "20px", md: "10px 0" },
              }}
            >
              <Typography variant="subtitle1">Come, join us!</Typography>
              <Typography
                variant="body2"
                sx={{
                  marginTop: "1em",
                  lineHeight: "26px",
                }}
              >
                <span style={{ color: "#042D43", fontSize: "20px" }}>
                  Our dedicated, passionate, and talented CliniOps team is
                  growing rapidly.<br></br>
                  And we need bright minds to help us continue to make a
                  valuable impact on society.
                </span>
                <br></br>
                <br></br>
                <span>
                  Our employee experience is as important to us as the customer
                  experience we provide.<br></br>
                  Our leadership has decades of experience in technology,
                  business, and life sciences, and we foster an environment
                  where minds can learn, grow, and thrive.
                  <br></br>
                  <br></br>
                  CliniOps is looking for dreamers, believers, and builders who
                  enjoy building great products or working with global
                  customers, embrace challenges as opportunities, and thrive in
                  dynamic environments. If you are a seasoned professional or
                  seeing an entry-level opportunity to make a difference, we may
                  have something for you.<br></br><br></br>
                  If you want to explore career opportunities with CliniOps,
                  Please drop us a note at{" "}
                  <a
                    href="mailto:careers@cliniops.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#03A9F5", textDecoration: "none" }}
                  >
                    careers@cliniops.com
                  </a>
                </span>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Template;
