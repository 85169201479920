import React from 'react';
import { Box, Typography, Grid, Card, CardHeader, CardMedia, Link } from '@mui/material';

import Imgscopes from '../../../../img/media/newsroom/_2023/scopes.jpeg';
import Imgnjacts from '../../../../img/media/newsroom/_2023/njacts.jpg';
import Imgdia from '../../../../img/media/newsroom/_2023/DIA-Logo.png';
import Imgacrp from '../../../../img/media/newsroom/_2023/ACRP.jpeg';
import Imgfob from '../../../../img/media/newsroom/_2023/image-logos.png';
import Imgiscr from '../../../../img/media/newsroom/_2023/ISCR Logo.png';
import Imguspto from '../../../../img/media/newsroom/_2023/USPTO-Logo.png';
import Imgbmc from '../../../../img/media/newsroom/_2023/BMC1.jpeg';
import ImgYahoo from '../../../../img/media/newsroom/_2023/ImgYahoo.png';
import ImgPlosNTD from '../../../../img/media/newsroom/_2023/PLOS-NTD_Logo.png';
import ImgFeatCust from '../../../../img/media/newsroom/_2023/FeaturedCustomers.png';
import ImgFastComp from '../../../../img/media/newsroom/_2023/FastCompanyExecutiveBoard_Logo.svg';
import ImgMDGH from '../../../../img/media/newsroom/_2023/MDGHLogo.jpeg';
import ImgWVC from '../../../../img/media/newsroom/_2023/world-vaccine-congress-west-coast-logo-wvcwc-white-background.webp';
import ImgForbes from '../../../../img/media/newsroom/_2022/Forbes.png';
import ImgGartner from '../../../../img/media/newsroom/_2023/Gartner_logo.svg.png';
import ImgAxtria from '../../../../img/media/newsroom/_2023/Axtria_Logo.png';
import ImgAMA from '../../../../img/media/newsroom/_2023/AdvanceMarketAnalytics.webp';
import ImgYahooFinance from '../../../../img/media/newsroom/_2023/Yahoo_Finance_logo.png';
// import sign from '../../../../img/media/newsroom/Renew/sign.png';

const news2023Data = [
      {
        ImgSrc: ImgFastComp,
        Name: "15 ways to keep your product or service relevant after the launch",
        Date: "December 11, 2023",
        Summary: "How do you keep the buzz going about your company’s new product or service when the thrill of the launch is gone?",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
            It starts by treating the initial launch as just the beginning—with no foreseeable end in sight. Brand leaders can master this strategy by creating fresh content and sourcing updated data analysis that will give current and potential customers something to talk about.
          </Typography>
        ),
        redirectUrl: "https://www.fastcompany.com/90994736/15-ways-to-keep-your-product-or-service-relevant-after-the-launch",
      },
      {
        ImgSrc: ImgFastComp,
        Name: "Clinical Trials with AI: Chasing efficiency and inclusive participation",
        Date: "December 01, 2023",
        Summary: "Heightened awareness, discussions, and experimentation of the use of artificial intelligence (AI) in healthcare and life sciences are progressing at a breakneck pace.",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
           While rules and regulations around the use of AI are still a work in progress, the potential of AI to speed up medical research for better patient outcomes is already evident. Recently a USFDA guidance highlighted the benefits of AI in clinical research and the use of technologies to improve diversity and inclusion in clinical research that impact the generalizability of study results. The time has come for us to take a deeper dive to understand how AI can make clinical trials (CTs) more efficient and how AI’s transformative power can increase the diversity and inclusion of underserved populations as study participants. 
          </Typography>
        ),
        redirectUrl: "https://www.fastcompany.com/90990503/clinical-trials-with-ai-chasing-efficiency-and-inclusive-participation",
      },
      {
        ImgSrc: ImgMDGH,
        Name: "Atticus Medical & Medicines Development for Global Health enroll first patient in Phase 2b study of moxidectin in scabies",
        Date: "Nov 28, 2023",
        Summary: "Atticus Medical & Medicines Development",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
            {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
          </Typography>
        ),
        redirectUrl: "https://www.atticusmed.com/news/Blog%20Post%20Title%20One-el8ek",
      },
      {
        ImgSrc: ImgWVC,
        Name: "World Vaccine Congress, West Coast, 2023",
        Date: "Nov 28, 2023",
        Summary: "Workshop A - Antibodies: Discovery vs Computational Design",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
            Workshop B - Infodemiology as a means to engage with health care and public health
            This session is part program launch, and part interactive workshop. The U.S. "House of Medicine" represents all national medical boards in the country. Led by the American Board of Internal Medicine (ABIM) and public health nonprofit PGP, the House of Medicine has launched the Health Care Infodemiology Brief.
          </Typography>
        ),
        redirectUrl: "https://www.terrapinn.com/conference/world-vaccine-immunotherapy-congress-west-coast/agenda.stm",
      },
      {
        ImgSrc: ImgForbes,
        Name: "AI For Efficient Clinical Trials: It's Risky, But Optimism Rules",
        Date: "Nov 14, 2023",
        Summary: "Yerramalli Subramaniam is CTO of CliniOps, a technology leader in DCT and unified platform.",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
           Although AI is on its way to ubiquity, there is a need to regulate its use. In the clinical research industry, the hope is to achieve a more efficient process for delivering essential therapies and improving patient outcomes, all while being cognizant of the need for regulations that safeguard the security and privacy of patient data.
          </Typography>
        ),
        redirectUrl: "https://www.forbes.com/sites/forbestechcouncil/2023/11/14/ai-for-efficient-clinical-trials-its-risky-but-optimism-rules",
      },
      {
        ImgSrc: ImgGartner,
        Name: "Hype Cycle for Life Science Clinical Development, 2023",
        Date: "July 21, 2023",
        Summary: "Life science companies continue to build new capabilities in digital research and early stage drug discovery capabilities. ",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
            CIOs can use this Hype Cycle to identify innovative technologies reshaping their industry and to prioritize their investments accordingly.
          </Typography>
        ),
        redirectUrl: "https://www.gartner.com/doc/4558399",
      },
      {
        ImgSrc: Imgdia,
        Name: "DIA Conference, 2023",
        Date: "June 25, 2023",
        Summary: "DIA 2023 is the essential meeting for catalyzing knowledge creation to ignite healthcare product development. DIA 2023 is more than a meeting, its where brilliant minds come together to create solutions.",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
           Thousands of global innovators will convene to discuss today’s hottest topics in the life sciences field, propose new ways to combat daily challenges, and network to create lasting connections. Through 170+ sessions spanning over 13 unique educational tracks, you will have the opportunity to learn more about your field or dive deep into a new topic of interest.
          </Typography>
        ),
        redirectUrl: "https://www.diaglobal.org/en/flagship/dia-2023/about/conference",
      },
      {
        ImgSrc: ImgFeatCust,
        Name: "The Top Clinical Trial Management Software According to the FeaturedCustomers Summer 2023 Customer Success Report",
        Date: "June 13, 2023",
        Summary: "FeaturedCustomers releases the Summer 2023 Clinical Trial Management Software Customer Success Report.",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
            The highest rated vendors according to the Summer 2023 Clinical Trial Management Software Customer Success Report are:<br/>
            Rising Stars – CliniOps<br/>
            Rising Stars are vendors that do not have the market presence of Market Leaders or Top Performers but understand where the market is going and has disruptive technology. Rising Stars have been around long enough to establish momentum and a minimum amount of customer reference content along with a growing social presence.
          </Typography>
        ),
        redirectUrl: "https://tech.einnews.com/pr_news/639257661/the-top-clinical-trial-management-software-according-to-the-featuredcustomers-summer-2023-customer-success-report",
      },
      {
        ImgSrc: ImgAxtria,
        Name: "Partnership with Axtria",
        Date: "June 05, 2023",
        Summary: "Axtria partners with global technology leaders, cloud enablers, and analytics & insights innovators to bring state-of-the-art solutions to market. ",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
           With our partners, Axtria brings innovative solutions that connect their CRM and HR systems, sales and marketing functions, and the commercial IT ecosystem to bring in the best-in-class commercial excellence. The partnership ensures the best-in-class implementation and creates a measurable impact on the global scale in the life sciences industry.
          </Typography>
        ),
        redirectUrl: "https://www.axtria.com/about-us/partnerships-alliances/",
      },
      {
        ImgSrc: ImgPlosNTD,
        Name: "A randomized, open-label study of the tolerability and efficacy for treatment of onchocerciasis",
        Date: "May 19, 2023",
        Summary: "A randomized, open-label study of the tolerability and efficacy of one or three daily doses of ivermectin plus diethylcarbamazine and albendazole (IDA) versus one dose of ivermectin plus albendazole (IA) for treatment of onchocerciasis",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
            {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
          </Typography>
        ),
        redirectUrl: "https://journals.plos.org/plosntds/article?id=10.1371/journal.pntd.0011365&rev=2",
      },
      {
        ImgSrc: ImgYahooFinance,
        Name: "CliniOps Awarded Patent for Pioneering System to Collect Location Agnostic Clinical and Non-Clinical Data",
        Date: "May 18, 2023",
        Summary: "CliniOps, Inc., a leading technology and data science company for the life science industry, has secured patent for its breakthrough system to address delays and inefficiencies in manual and siloed data collection points across multiple locations, devices and users, during clinical trials (CTs).",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
            The U.S. Patent and Trademark Office (USPTO) awarded CliniOps the U.S. Patent No. US 11,600,396 for: "Systems and Methods for Collecting Location Agnostic Clinical and Non-Clinical Data." Avik Kumar Pal, CEO and Yerramalli Subramaniam, CTO are listed as patent inventors.
          </Typography>
        ),
        redirectUrl: "https://finance.yahoo.com/news/cliniops-awarded-patent-pioneering-system-150000086.html",
      },
      {
        ImgSrc: Imgacrp,
        Name: "ACRP Conference, 2023",
        Date: "April 29, 2023",
        Summary: "Nearly 1,400 clinical research professionals came together in Dallas—and it was an inspiring four days of passion, education, collaboration, and true community!",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
            We loved seeing old friends and meeting new ones at every turn. Thanks to all of you for being a part of ACRP 2023—our most dynamic and energizing conference yet!
          </Typography>
        ),
        redirectUrl: "https://2024.acrpnet.org/highlights-2023",
      },
      {
        ImgSrc: Imgbmc,
        Name: "HIV matters when diagnosing TB in young children",
        Date: "April 17, 2023",
        Summary: "HIV matters when diagnosing TB in young children: an ancillary analysis in children enrolled in the INPUT stepped wedge cluster randomized study",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
            {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
          </Typography>
        ),
        redirectUrl: "https://bmcinfectdis.biomedcentral.com/articles/10.1186/s12879-023-08216-w",
      },
      {
        ImgSrc: ImgAMA,
        Name: "Advance Market Analytics",
        Date: "April 01, 2023",
        Summary: "Pharma Clinical Trial Digitization Comprehensive Study by Application (Pharmaceutical Companies, Biotechnology Companies, Academic and Government Research Institutes, Others),",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
            Trial Types (Digital Continuity across Clinical Trial IT Systems, Patient-Centric Remote And Virtual Trial Design, Direct-To-Patient Home Services), End-Use (Clinical Data Management, Trial Monitoring, Patient Recruitment and Enrollment), Service Type (Drug Dose Adjustment, Drug Impact Monitoring, Medical Prescription System, Bioprinting, Preventive Therapy, Individualized Drug Printing) Players and Region - Global Market Outlook to 2028
          </Typography>
        ),
        redirectUrl: "https://www.advancemarketanalytics.com/reports/131471-global-pharma-clinical-trial-digitization-market",
      },
      {
        ImgSrc: Imgfob,
        Name: "Festival of Biologics USA, 2023",
        Date: "March 20, 2023",
        Summary: "The Festival of Biologics San Diego combines three, outstanding conferences, focusing on antibodies, immunotherapy and clinical trials, all under one roof.",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
            {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
          </Typography>
        ),
        redirectUrl: "https://www.terrapinn.com/conference/festival-of-biologics-usa/speaker-avik-PAL.stm",
      },
      {
        ImgSrc: Imguspto,
        Name: "CliniOps Platform receives Patent approval from USPTO",
        Date: "March 07, 2023",
        Summary: "CliniOps Platform receives Patent approval from USPTO, for collecting location agnostic clinical and non-clinical data",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
            {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
          </Typography>
        ),
        redirectUrl: "https://ppubs.uspto.gov/dirsearch-public/print/downloadPdf/11600396",
      },
      {
        ImgSrc: Imgacrp,
        Name: "Using Disruptive and Convergent Technologies to ‘Catch More Waves’",
        Date: "March 07, 2023",
        Summary: "There are widespread discussions around the use of disruptive technologies for clinical trials, including broader application of data initially recorded in an electronic format, or eSource.",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
          “As decentralized clinical trial (DCT) elements are increasingly applied, sponsors, investigators, and clinical research professionals all need to understand and apply these technologies for more efficient clinical trials and more convenient patient engagement and participation,” says Nadina Jose, MD, an Assistant Professor in the School of Health Professions at Rutgers, The State University of New Jersey. 
          </Typography>
        ),
        redirectUrl: "https://acrpnet.org/2023/03/03/using-disruptive-and-convergent-technologies-to-catch-more-waves/",
      },
      {
        ImgSrc: Imgiscr,
        Name: "ISCR Annual Conference, 2023",
        Date: "February 25, 2023",
        Summary: "ISCR Annual Conference, 2023",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
            {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
          </Typography>
        ),
        redirectUrl: "https://www.iscr.org/pdf/ISCR%20Conf%20Flyer_23-Sept-22.pdf",
      },
      {
        ImgSrc: Imgscopes,
        Name: "Driving Innovation: In Hybrid and Decentralized Clinical Trials (DCT)",
        Date: "February 06, 2023",
        Summary: "Driving Innovation: In Hybrid and Decentralized Clinical Trials (DCT)",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
            {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
          </Typography>
        ),
        redirectUrl: "https://www.scopesummit.com/23/programs",
      },
      {
        ImgSrc: Imgnjacts,
        Name: "Diversity and Inclusion in Site Identification Panel Discussion",
        Date: "January 26, 2023",
        Summary: "Diversity and Inclusion in Site Identification Panel Discussion",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
            {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
          </Typography>
        ),
        redirectUrl: "https://rutgers.mediaspace.kaltura.com/media/GMT20230125-220305_Recording_1920x1080/1_o7i84u2n",
      },
      {
        ImgSrc: Imgnjacts,
        Name: "NJ Acts: Diversity and Inclusion in Site Identification",
        Date: "January 25, 2023",
        Summary: "NJ Acts: Diversity and Inclusion in Site Identification",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
            The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities.
          </Typography>
        ),
        redirectUrl: "https://www.linkedin.com/posts/rutgers-shp-clinical-research-management-network_diversityandinclusion-graduateschool-njacts-activity-7024040046395564032-bO3Q/",
      },

]

export default news2023Data;