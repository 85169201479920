import React from 'react';
import { Box, Typography} from '@mui/material';
import RequestQuoteButton from '../../common/requestQuote';


const template = () => {
    return (
      <>
        <Box 
        sx={{ 
            display:'flex',
            alignItems: "center", 
            justifyContent: 'center',
            padding:{xs:"20px",md:"50px 0 30px 0"}
            }}>
         <RequestQuoteButton/>
        </Box>
      </>
    );
  }
  
  export default template;