import React from 'react';
import { Box, Typography } from '@mui/material';

const template = () => {
    return (
        <>
        <Box 
          sx={{ 
              display:'flex', 
              textAlign: "center", 
              alignItems: "center", 
              justifyContent: 'center', 
              // marginBottom:3, 
              marginTop:{xs:-5, md:-18}}}>
            <Typography variant="h1Teal" sx={{ mx:{xs:2, md: "", lg: 15}}}>
              Add-on Capabilities
            </Typography>
            </Box>
            <Box 
              sx={{ 
                  display:'flex', 
                  textAlign: "center", 
                  alignItems: "center", 
                  justifyContent: 'center', 
                  // marginBottom:3, 
                  }}>
            <Box variant="body6" sx={{ mx:{xs:2, md: "", lg: 33},lineHeight:"35px"}}>
            CliniOps constantly develops add-on capabilities across it's products and other 3rd party product ecosystems to offer more intuitive, scalable, and context-aware user journeys and differentiate their product experiences with greater empathy.
            </Box>
          </Box>

        </>
    );
};
export default template;