// import React from 'react';
// import { Typography } from '@mui/material';

// const template = () => {
//   return (
//     <>
//       <Typography variant="h1" component="h1">
//         Contact Us
//       </Typography>
//       <Typography variant="subtitle1" sx={{ mt: 1 }}>
//       Get in touch
//       </Typography>
//     </>
//   );
// }

// export default template;


import React from 'react';
import { Grid,Box } from '@mui/material';
import { teal } from '@mui/material/colors';

import contactBanner from '../../../../img/contact/contactHero.png';


const template = () => {
  return (
    <>
    <Box>
    <Box sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding:{xs:"70px 0 0 0",md:"90px 0 0 0"}, 
          overflow: "hidden",
    
          }}>
            <img
              src={`${contactBanner}`}
              alt="Contact"
              style={{ 
                maxWidth: "100%", 
                maxHeight: "100%", 
                objectFit: "contain" }}
            />
          </Box>
          <Box sx={{
              position: 'absolute',
              top: { xs: '80px', sm: '75px', md: '100px' },
              left: { xs: '25px', sm: '25px', md: '45px' },
              right: 0,
              bottom: 0,
              display: 'flex',
              color: '#042D43',
              textAlign: 'topleft',
              fontSize: { xs: '17px', sm: '40px', md: '60px' }, // Responsive font size
              fontWeight: 'light',
              textShadow:' 3px 3px 3px rgba(0, 0, 0, 0.16)'
              // fontFamily: 'Segoe UI',
            }}>
         Contact
          </Box>
  </Box>

    </>
  );
}

export default template;