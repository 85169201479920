import React from 'react';
import {Box} from '@mui/material';

import ImgConductTheme from '../../../../img/product/conduct/CONDUCTTHEME.png';

const template = () => {
    return (
        <Box>
            <Box style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            //   padding: "55px 0 0 0", 
            overflow: "hidden",

            }}>
            <img
                src={`${ImgConductTheme}`}
                alt=""
                style={{ 
                maxWidth: "100%", 
                maxHeight: "100%", 
                objectFit: "contain" }}
            />
            </Box>
        </Box>

    );
};

export default template;