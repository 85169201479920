// CustomTypography.js
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const CustomTypography = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: 400,
  color: "#042D43",
}));

export default CustomTypography;