import { createTheme } from '@mui/material/styles';

// import Breakpoint from './breakpoint';

const Theme = createTheme({
  typography: {
    // fontFamily:'Segoe UI Semilight, Segoe UI',
    h1Blue: {
      fontSize: "42px",
      fontWeight: 300,
      color: '#07A7F9', // Logo Primary
      '@media (max-width:900px)': {
        fontSize: '15px',
      },
    },
    h1Teal: {
      fontSize: "42px",
      fontWeight: 300,
      color: '#042D43', // Logo Primary
      '@media (max-width:900px)': {
        fontSize: '25px',
      },
    },
    h1White: {
      fontSize: "42px",
      fontWeight: 300,
      color: '#FFFFFF', 
      '@media (max-width:900px)': {
        fontSize: '25px',
      },
    },


    h2: {
      fontSize: "26px",
      fontWeight: 400,
      color: '#07A7F9', 
    },

    h3: {
      fontSize: "16px",
      fontWeight: 400,
      color: '#042d43', 
    },
    
    h4: {
      fontSize: "16px",
      fontWeight: 600,
      color: '#07A7F9', 
    },

    h5: {
      fontSize: "16px",
      fontWeight: 400,
      color: '#707070', 
    },
    h6: {
      fontSize: "26px",
      fontWeight: 300,
      color: '#042d43', 
    },
    h7: {
      fontSize: "26px",
      fontWeight: 400,
      color: '#042d43', 
    },
    h8: {
      fontSize: "26px",
      fontWeight: 600,
      color: '#042d43', 
    },
    h9: {
      fontSize: "38px",
      fontWeight:400,
      color:'#042d43', 
      lineHeight:'50px'
    },
    h10: {
      fontSize: "20px",
      fontWeight: 400,
      color: '#07A7F9',
    },
    h11: {
      fontSize: "30px",
      fontWeight: 300,
      color: '#07A7F9',
    },
    h12: {
      fontSize: "23px",
      fontWeight: 400,
      color: '#07A7F9',
    },
    subtitle1: {
      fontSize: "26px",
      fontWeight: 600,
      color:"#07A7F9"
    },
    subtitle2: {
      fontSize: "20px",
      lineHeight: "32px",
      letterSpacing: "0.0056em",
      fontWeight: 400,
      color:"042D43"
    }, 
    body1: {
      // fontSize: "16px",
      fontSize: "16px",
      fontWeight: 400,
      color: '#042D43', 
      '@media (max-width:900px)': {
        fontSize: '6px',
      },
    }, 
    body2: {
      fontSize: "16px",
      fontWeight: 400,
      color:'#042D43',
    }, 
    body3: {
      fontSize: "16px",
      fontWeight: 600,
      color:'#042D43',
    }, 
    body4: {
      fontSize: "16px",
      fontWeight: 400,
      color:'#000000',
    }, 
    body5: {
      fontSize: "18px",
      fontWeight: 400,
      color:'#000000',
    },
    body6: {
      fontSize: "18px",
      fontWeight: 300,
      color:'#000000',
    },
    body7: {
      fontSize: "16px",
      fontWeight: 300,
      color:'rgb(1, 24, 36)',
    }, 
    caption: {
      fontSize: "12px",
      lineHeight: "15px",
      letterSpacing: '-0.02em',
      fontWeight: 400
    },
  },
});

export default Theme;


