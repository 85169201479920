import React from 'react';
import { Typography } from '@mui/material';
import { blueGrey } from '@mui/material/colors';

const template = () => {
  return (
    <>
      <Typography variant="h9" sx={{paddingBottom:"15px"}}  component="h1">
      Moving from Paper to Electronic Consent involving patients with substance abuse
      </Typography>
     
      <a href="https://www.sutterhealth.org/research/cpmcri" target={'_blank'} class="linkcolor">California Pacific Medical Center Research Institute</a>
      
    </>
  );
}

export default template;