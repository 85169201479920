import React from 'react';
import { HashLink as RouterLink } from 'react-router-hash-link';
import { Grid, Typography, Box, Button, Link } from '@mui/material';

import { Product } from '../../../component/page';

import RouterList from '../../../router';

import Imgstudysetup from '../../../../img/service/_0/studysetup.png';
import CustomTypography from '../../../component/customTypography';

const template = () => {
  return (
    <>
      <Grid container direction='column' spacing={0} sx={{ mt: 0 }}>
            {/* Study Setup  */}
          <Grid item>
              <Product
                ImgSrc={Imgstudysetup}
                Name="Study Setup"
                Summary={
                  <>
                  <CustomTypography variant='body2' sx={{ mt: 1 }}>
                    During the study setup phase, our experienced configuration team supports the study build including form 
                    development, form configuration, finalizing requirements specification including but not limited to edit checks, 
                    workflows, dashboards, reports, alerts, notifications across all modules. A complete validation is also performed 
                    with CRF completion guidelines and release notes.
                  </CustomTypography>
                <Box sx={{ mt: 2 }}>
                   <Link
                            href={RouterList.service.studysetup}
                            target="_blank"
                            sx={{ fontSize: "14px", fonWeight: "400" }}
                            underline="hover"
                            color="#1E75C6"
                          >
                            Know More...
                          </Link>
                </Box>
                </>
                }
              />
              
          </Grid>
      </Grid>
    </>
  );
}

export default template;

//Commenting previous code

// import React from 'react';
// import { HashLink as RouterLink } from 'react-router-hash-link';
// import { Box, Typography, Button, Stack } from '@mui/material';

// import { PageSectionLeftImg } from '../../../component/page';

// import RouterList from '../../../router';

// import ImgStudySetup from '../../../../img/service/_0/study-setup.jpeg';

// const template = () => {
//   return (
//     <>
//       <PageSectionLeftImg
//         heading={
//           <Stack direction="row" spacing={1} alignItems='center' justifyContent={{xs: 'center', md: 'flex-start'}}>
//             <Typography variant="h2" component="h2" className="co-page-heading" sx={{ textAlign: { xs: 'center', md: 'left'} }}>
//               Study Setup
//             </Typography>
//           </Stack>
//         }
//         imgUrl={ImgStudySetup}
//         imgTitle="Study Setup"
//       >
//         <Typography sx={{ mt: 1 }}>
//           CliniOps offers a comprehensive study setup services. Our customized services are designed to help you configure your trials more efficiently. We will not only complement your internal team to achieve your goals but will also ensure transparency and collaboration through out the duration of your trial. Subject-matter experts who have experience with everything from simple single arm studies to complex multi-arm randomized global studies will form the core of the study setup team and will ensure that real-world issues are taken into account. We ensure timely study build cycles with early review phases and testing opportunities. In fact, before we provide you your study for User Acceptance Testing, it will go through a rigorous quality assurance and clinical review cycle.
//         </Typography>
//         <Box sx={{ mt: 2 }}>
//           <Button variant="text" to={RouterList.service.studysetup} component={RouterLink} sx={{ ml: -1 }}>Read More</Button>
//         </Box>
//       </PageSectionLeftImg>
//     </>
//   );
// }

// export default template;