import React from 'react';
import { Box, Typography, Grid, Card, CardHeader, CardMedia, Link } from '@mui/material';

import ImgBusinessAwards from '../../../../img/media/newsroom/_2021/businessAwards.png';
import ImgUnited from '../../../../img/media/newsroom/_2021/united.jpeg';
import ImgRoche from '../../../../img/media/newsroom/_2021/Media-2020-21.jpg';
import ImgAmazon from '../../../../img/media/newsroom/_2021/amazon.png';
import ImgResearchAndMarkets from '../../../../img/media/newsroom/_2021/Media-2018-08.png';
import ImgGartner from '../../../../img/media/newsroom/_2021/Media-2019-03.png';
import ImgUnitedConferences from '../../../../img/media/newsroom/_2021/Media-2021-apr.jpg';
import ImgHealthXL from '../../../../img/media/newsroom/_2021/Media-2021-01.jpg';
import ImgPlos from '../../../../img/media/newsroom/_2021/Media-2021-plos.jpg';
import ImgPopulationCouncil from '../../../../img/media/newsroom/_2021/Media-2021-lesotho.jpg';
import ImgWashingtonUniversity from '../../../../img/media/newsroom/_2021/Media-2021-jan.jpg';
import ImgInnovatorMD from '../../../../img/media/newsroom/_2021/Media-2020-21.png';
import ImgDec12021 from '../../../../img/media/newsroom/_2021/20211201.png';
import one from '../../../../img/media/newsroom/_2021/PLOS-ONE_logo_nobug.png';
import ImgPlosNTD from '../../../../img/media/newsroom/_2023/PLOS-NTD_Logo.png';
// import sign from '../../../../img/media/newsroom/Renew/sign.png';


const news2021Data = [
      {
        ImgSrc: ImgBusinessAwards,
        Name: "​Best Data Science Company - APAC Business Awards 2021",
        Date: "December 14, 2021",
        Summary: "APAC Insider is hosting the Business Awards programme for its consecutive year and takes great pride in awarding businesses from all industries, big or small, new start-ups or established across the APAC region. ",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
          Many industries and economies within the APAC region have suffered greatly at the hands of Covid-19 but businesses have shown determination and have worked hard to make sure that their customers and clients do not have to suffer as a result.
          </Typography>
        ),
        redirectUrl: "https://www.apac-insider.com/apac-insider-magazine-announces-the-winners-of-the-2021-business-awards/",
      },
      {
        ImgSrc:ImgUnited,
        Name: "Novel and Research Advancements in Generic Medicines, Biosimilars and Vaccines",
        Date: "November 11, 2021",
        Summary: "United conferences extends the possibility of interconnecting the scientific world, networking and knowledge sharing through its scientific meetups.",
        FullContent: (
          <Typography variant='body2'sx={{ mt: 2 }}>
            The B2B meetings held during the conference will provide best dais to develop new partnerships, collaborations globally and can build up business associations among scientific and research industries.
          </Typography>
        ),
        redirectUrl: "https://upjac.com/",
      },
      {
        ImgSrc:ImgRoche,
        Name: "Roche Supplier Day",
        Date: "November 3, 2021",
        Summary: "Roche Supplier Day",
        FullContent: (
          <Typography variant='body2'sx={{ mt: 2 }}>
            {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
          </Typography>
        ),
        redirectUrl: "https://www.roche.com/",
      },
      {
        ImgSrc:ImgAmazon,
        Name: "Running next generation hybrid and virtual clinical trials on AWS",
        Date: "October 27, 2021",
        Summary: "CliniOps offers a Data Science platform for the Life Science industry, to support digital Clinical trials, leveraging AI, mobile, analytics, cloud, sensors & connected devices.",
        FullContent: (
          <Typography variant='body2'sx={{ mt: 2 }}>
            This novel Unified platform approach, with built-in Telemedicine, is custom designed for Patients, Sites and Sponsors, that ensures high data quality at less cost. The platform is also extremely flexible and collaborative, that streamlines the workflow across clinical operations, clinical data management, biostatistics and regulatory functions.
          </Typography>
        ),
        redirectUrl: "https://aws.amazon.com/blogs/industries/running-next-generation-hybrid-and-virtual-clinical-trials-on-aws/",
      },
      {
        ImgSrc:one,
        Name: "A randomized controlled trial",
        Date: "August 24, 2021",
        Summary: "A randomized controlled trial evaluating the effects of a family-centered HIV care model on viral suppression and retention in care of HIV-positive children in Eswatini",
        FullContent: (
          <Typography variant='body2'sx={{ mt: 2 }}>
            {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
          </Typography>
        ),
        redirectUrl: "https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0256256",
      },
      {
        ImgSrc:ImgResearchAndMarkets,
        Name: "Industry Trends and Global Forecasts, 2021-2030",
        Date: "August, 2021",
        Summary: "Clinical Trial Planning and Design Services Market by Phase of Trial, Type of Service, Therapeutic Area, and Geography: Industry Trends and Global Forecasts, 2021-2030",
        FullContent: (
          <Typography variant='body2'sx={{ mt: 2 }}>
            {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
          </Typography>
        ),
        redirectUrl: "https://www.researchandmarkets.com/reports/5450267/clinical-trial-planning-and-design-services",
      },
      {
        ImgSrc:ImgGartner,
        Name: "Hype Cycle for Life Science Research and Development, 2021",
        Date: "July 19, 2021",
        Summary: "Hype Cycle for Life Science Research and Development, 2021",
        FullContent: (
          <Typography variant='body2'sx={{ mt: 2 }}>
            {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
          </Typography>
        ),
        redirectUrl: "https://www.gartner.com/en",
      },
      {
        ImgSrc:ImgUnitedConferences,
        Name: "United Conference of Biometrics and Data Management",
        Date: "April 14, 2021",
        Summary: "United conferences extends the possibility of interconnecting the scientific world, networking and knowledge sharing through its scientific meetups.",
        FullContent: (
          <Typography variant='body2'sx={{ mt: 2 }}>
           The B2B meetings held during the conference will provide best dais to develop new partnerships, collaborations globally and can build up business associations among scientific and research industries. Budding researchers can connect with highly affiliated personalities to gain knowledge, guidance for their scientific career. 
          </Typography>
        ),
        redirectUrl: "https://upjac.com/",
      },
      {
        ImgSrc:ImgPlosNTD,
        Name: "An open label, randomized clinical trial for treatment of brugian filariasis in Indonesia",
        Date: "March 29, 2021",
        Summary: "An open label, randomized clinical trial to compare the tolerability and efficacy of ivermectin plus diethylcarbamazine and albendazole vs. diethylcarbamazine plus albendazole for treatment of brugian filariasis in Indonesia",
        FullContent: (
          <Typography variant='body2'sx={{ mt: 2 }}>
            {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
          </Typography>
        ),
        redirectUrl: "https://journals.plos.org/plosntds/article?id=10.1371/journal.pntd.0009294",
      },
      {
        ImgSrc:ImgResearchAndMarkets,
        Name: "Virtual Clinical Trials Market Research Report 2021",
        Date: "March 25, 2021",
        Summary: "Virtual Clinical Trials Market Research Report 2021: Expanded Access Trials, Interventional Trials, and Observational Trials - Global Forecast to 2025 - ResearchAndMarkets.com",
        FullContent: (
          <Typography variant='body2' sx={{ mt: 2 }}>
           “Virtual Clinical Trials Market Research Report by Study Design (Expanded Access Trials, Interventional Trials, and Observational Trials), by Implication (Cardiovascular Disease and Oncology) - Global Forecast to 2025 - Cumulative Impact of COVID-19”
          </Typography>
        ),
        redirectUrl: "https://www.businesswire.com/news/home/20210325005692/en/Virtual-Clinical-Trials-Market-Research-Report-2021-Expanded-Access-Trials-Interventional-Trials-and-Observational-Trials---Global-Forecast-to-2025---ResearchAndMarkets.com",
      },
      {
        ImgSrc:ImgHealthXL,
        Name: "Clinical Trials Innovation",
        Date: "March 25, 2021",
        Summary: "Clinical Trials Innovation",
        FullContent: (
          <Typography variant='body2'sx={{ mt: 2 }}>
            {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
          </Typography>
        ),
        redirectUrl: "https://portal-beta.healthxl.com/meetings/ba261b72-4c99-45e8-9b80-ab667c1e754d?s=krk45&tab=members",
      },
      {
        ImgSrc:ImgGartner,
        Name: "Industry Insights: Map Your Pathway to Digital Trials",
        Date: "March 24, 2021",
        Summary: "Industry Insights: Map Your Pathway to Digital Trials",
        FullContent: (
          <Typography variant='body2'sx={{ mt: 2 }}>
            {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
          </Typography>
        ),
        redirectUrl: "https://www.gartner.com/en",
      },
      {
        ImgSrc:ImgHealthXL,
        Name: "Capturing Patient-Reported Outcomes in Trials with Tech",
        Date: "February 24, 2021",
        Summary: "Capturing Patient-Reported Outcomes in Trials with Tech",
        FullContent: (
          <Typography variant='body2'sx={{ mt: 2 }}>
            {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
          </Typography>
        ),
        redirectUrl: "https://www.healthxl.com/virtual-events/d9ad92ca-7a89-4d52-a119-affa40519ece",
      },
      {
        ImgSrc:ImgPlos,
        Name: "An open label, block randomized, community study of the safety and efficacy of co-administered ivermectin, diethylcarbamazine plus albendazole vs. diethylcarbamazine plus albendazole for lymphatic filariasis in India",
        Date: "February 16, 2021",
        Summary: "An open label, block randomized, community study of the safety and efficacy of co-administered ivermectin, diethylcarbamazine plus albendazole vs. diethylcarbamazine plus albendazole for lymphatic filariasis in India",
        FullContent: (
          <Typography variant='body2'sx={{ mt: 2 }}>
            {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
          </Typography>
        ),
        redirectUrl: "https://journals.plos.org/plosntds/article?id=10.1371/journal.pntd.0009069",
      },
      {
        ImgSrc:ImgPopulationCouncil,
        Name:'A publication titled, "Optimizing Maternal and Child Health Outcomes Through Use of Multidisciplinary "IMPROVE" Teams in Lesotho"',
        Date: "January 30, 2021",
        Summary: '"Optimizing Maternal and Child Health Outcomes Through Use of Multidisciplinary "IMPROVE" Teams in Lesotho"',
        FullContent: (
          <Typography variant='body2'sx={{ mt: 2 }}>
            {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
          </Typography>
        ),
        redirectUrl: "https://knowledgecommons.popcouncil.org/departments_sbsr-hiv/552/",
      },
      {
        ImgSrc:ImgInnovatorMD,
        Name:"InnovatorMD Global Summit 2021",
        Date: "January 9, 2021",
        Summary: "InnovatorMD Global Summit 2021",
        FullContent: (
          <Typography variant='body2'sx={{ mt: 2 }}>
            {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
          </Typography>
        ),
        redirectUrl: "https://www.youtube.com/watch?v=4-cyN0WzI-w",
      },
]

// const template = () => {
//   return (
//     <>
//       <Box id="2021">
//         <Box sx={{ mb: 1 }}>
//           <Typography variant="caption" color={blueGrey[200]}>
//             <strong>2021</strong>
//           </Typography>
//         </Box>
//         <Grid container spacing={4}>
//           <NewsArticle
//             img={ImgBusinessAwards}
//             alt="Article Header Image"
//             link="https://www.apac-insider.com/apac-insider-magazine-announces-the-winners-of-the-2021-business-awards/"
//             title="​Best Data Science Company - APAC Business Awards 2021"
//             date="December 14, 2021"
//           />
//           <NewsArticle
//             img={ImgUnited}
//             alt="Article Header Image"
//             link="https://upjac.com/"
//             title="Novel and Research Advancements in Generic Medicines, Biosimilars and Vaccines"
//             date="November 11, 2021"
//           />
//           <NewsArticle
//             img={ImgRoche}
//             alt="Article Header Image"
//             link="https://www.roche.com/"
//             title="Roche Supplier Day"
//             date="November 3, 2021"
//           />
//           <NewsArticle
//             img={ImgAmazon}
//             alt="Article Header Image"
//             link="https://aws.amazon.com/blogs/industries/running-next-generation-hybrid-and-virtual-clinical-trials-on-aws/"
//             title="Running next generation hybrid and virtual clinical trials on AWS"
//             date="October 27, 2021"
//           />
//           <NewsArticle
//             img={one}
//             alt="Article Header Image"
//             link="https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0256256"
//             title="A randomized controlled trial evaluating the effects of a family-centered HIV care model on viral suppression and retention in care of HIV-positive children in Eswatini
//             "
//             date="August 24, 2021"
//           />
//           <NewsArticle
//             img={ImgResearchAndMarkets}
//             alt="Article Header Image"
//             link="https://www.researchandmarkets.com/reports/5450267/clinical-trial-planning-and-design-services"
//             title="Industry Trends and Global Forecasts, 2021-2030"
//             date="August, 2021"
//           />
//           <NewsArticle
//             img={ImgGartner}
//             alt="Article Header Image"
//             link="https://www.gartner.com/en"
//             title="Hype Cycle for Life Science Research and Development, 2021"
//             date="July 19, 2021"
//           />
//           <NewsArticle
//             img={ImgUnitedConferences}
//             alt="Article Header Image"
//             link="https://upjac.com/"
//             title="United Conference of Biometrics and Data Management"
//             date="April 14, 2021"
//           />
//            <NewsArticle
//             img={ImgPlosNTD}
//             alt="Article Header Image"
//             link="https://journals.plos.org/plosntds/article?id=10.1371/journal.pntd.0009294"
//             title="An open label, randomized clinical trial for treatment of brugian filariasis in Indonesia"
//             date="March 29, 2021"
//           />
//           <NewsArticle
//             img={ImgResearchAndMarkets}
//             alt="Article Header Image"
//             link="https://www.businesswire.com/news/home/20210325005692/en/Virtual-Clinical-Trials-Market-Research-Report-2021-Expanded-Access-Trials-Interventional-Trials-and-Observational-Trials---Global-Forecast-to-2025---ResearchAndMarkets.com"
//             title="Virtual Clinical Trials Market Research Report 2021"
//             date="March 25, 2021"
//           />
//           <NewsArticle
//             img={ImgHealthXL}
//             alt="Article Header Image"
//             link="https://portal-beta.healthxl.com/meetings/ba261b72-4c99-45e8-9b80-ab667c1e754d?s=krk45&tab=members"
//             title="Clinical Trials Innovation"
//             date="March 25, 2021"
//           />
//           <NewsArticle
//             img={ImgGartner}
//             alt="Article Header Image"
//             link="https://www.gartner.com/en"
//             title="Industry Insights: Map Your Pathway to Digital Trials"
//             date="March 24, 2021"
//           />
//           <NewsArticle
//             img={ImgHealthXL}
//             alt="Article Header Image"
//             link="https://www.healthxl.com/virtual-events/d9ad92ca-7a89-4d52-a119-affa40519ece"
//             title="Capturing Patient-Reported Outcomes in Trials with Tech"
//             date="February 24, 2021"
//           />
//           <NewsArticle
//             img={ImgPlos}
//             alt="Article Header Image"
//             link="https://journals.plos.org/plosntds/article?id=10.1371/journal.pntd.0009069"
//             title="An open label, block randomized, community study of the safety and efficacy of co-administered ivermectin, diethylcarbamazine plus albendazole vs. diethylcarbamazine plus albendazole for lymphatic filariasis in India"
//             date="February 16, 2021"
//           />
//           <NewsArticle
//             img={ImgPopulationCouncil}
//             alt="Article Header Image"
//             link="https://knowledgecommons.popcouncil.org/departments_sbsr-hiv/552/"
//             title='A publication titled, "Optimizing Maternal and Child Health Outcomes Through Use of Multidisciplinary "IMPROVE" Teams in Lesotho"'
//             date="January 30, 2021"
//           />
//           <NewsArticle
//             img={ImgInnovatorMD}
//             alt="Article Header Image"
//             link="https://www.youtube.com/watch?v=4-cyN0WzI-w"
//             title='InnovatorMD Global Summit 2021'
//             date="January 9, 2021"
//           />
//         </Grid>                
//       </Box>
//     </>
//   );
// }

export default news2021Data;