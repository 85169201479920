import React from "react";
import { Grid, Box, Typography,Stack,Alert, Link } from "@mui/material";
import { teal, blueGrey } from "@mui/material/colors";

import { PageHero, PageContentV2 } from "../../component/page";
import ImgSue from "../../../img/media/customer-story/_1/SueMcKinney.jpg";
// Page Imports
import _0 from "./customer-story-2/_0";
import ImgNams from '../../../img/media/customer-story/_1/NAMS.png';
import ImgCrilaHealth from '../../../img/media/customer-story/_1/crila_Health.png';

const template = () => {
  return (
    <>
      <PageHero color="rgba(3, 169, 244, 0.1)">
        <_0 />
      </PageHero>
      <PageContentV2>
        <Grid item>
          <Typography variant="subtitle2" paragraph>
           DCT in Action: Menopause Study Started and Completed During Pandemic, conducted across multiple sites in Mexico
          </Typography>
          <Typography variant="body7" paragraph>
            In early 2020, a clinical study entitled “Prospective Open-Label
            Outcomes Study of Oral CRILA® on Menopausal Symptoms in Female
            Subjects” was in early stages of activation. Two clinical sites in
            Mexico had been identified. However, as Covid-19 pandemic caused
            public health upheavals, the study team had to rethink the
            preparations to ensure safety of the patients who would participate
            in study.{" "}
          </Typography>
          <Typography variant="body7" paragraph>
            Sue McKinney, CEO of Crila Health, as sponsor of the study and
            long-time advocate of women’s health, knew that patient safety is
            paramount and should be addressed first. After consulting with
            experts, Sue decided to call on CliniOps to run interference with
            application of electronic consent (eConsent), a decentralized
            clinical trial (DCT) technology that allowed patients to be easily
            enrolled without reliance on the traditional paper informed consent
            process. CliniOps also made available the use of their telehealth
            features for the clinical study sites.
          </Typography>
          <Typography variant="body7" paragraph>
            CliniOps DCT solutions helped facilitate the completion of the study
            that otherwise would have been more challenging and more of a burden
            on the sites and the patients.
          </Typography>
  
          <blockquote
            style={{ borderLeft: `4px solid ${blueGrey[100]}`, marginLeft: -1 }}
          >
             <Stack spacing={4} sx={{ pl: 4 }} direction={{xs: "column", md: "row"}}>
             <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minWidth: '220px',
                  width: '220px',
                  height: '220px',
                  borderRadius: 2,
                  backgroundImage: `url(${ImgSue})`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'top center'
            }}
            />
            <Box >
            <Typography variant="body7">
             <em>
              "The study of Crila’s effect on Latina women expressing menopausal
              symptoms had already been through more than a year of planning. It
              needed to happen but not without managing the risk to Covid 19
              exposure for both investigators and patients visiting the clinical
              sites. We had heard about decentralized clinical trials (DCT)
              technologies and wondered if they would work in our studies and
              during the pandemic. Based on experts’ recommendations, we called on
              CliniOps. Avik and his team were hands on from day one and wasted no
              time to set up electronic consent and cloud-based data repository
              for easier access. With efficient technologies supporting our
              hardworking investigators and patients, we were able to complete our
              study and more importantly, safely during a pandemic. Our study also
              showed positive outcomes for the Latina women with menopausal
              symptoms who took oral CRILA® daily for 90 days."
             </em>
            </Typography>
            <Typography variant="overline" paragraph>
                <strong>
                  Sue McKinney
                  <br />
                  Executive Director, Crila Health
                </strong>
              </Typography>
            </Box>
            </Stack>
          </blockquote>
         
          <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
          <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4,pt:2 }}>
          <Box
            xs="auto"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: '114px',
              width: '114px',
              height: '80px',
              borderRadius: 2,
              backgroundImage: `url(${ImgNams})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'top center'
            }}
          />
          <Box >
          <Typography paragraph>
                
                  The results of the study titled{" "}
                  <Link href="https://crilahealth.com/pages/nams-2023" target="_blank" underline="hover">
                
                    ‘Crila® Herbal Supplement is Associated with a Clinically
                    Meaningful Effect on Menopausal Symptoms Evaluated Using the
                    Menopause-Specific Quality of Life Survey’
                 
                  </Link>
                  , was part of a poster presentation at the North America
                  Menopause Society, October 12-15, 2022, Atlanta, USA
          
              </Typography>
          </Box>
          </Stack>
          </Alert>
         
          <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
           <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4,pt:1 }}>

           <Box
            xs="auto"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: '114px',
              width: '114px',
              height: '80px',
              borderRadius: 2,
              backgroundImage: `url(${ImgCrilaHealth})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'top center'
            }}
          />

           <Box >
              <Typography paragraph>
                  The success of the study and CliniOps involvement therein was
                  acknowledged by Crila Health, in an article titled{" "}
                  <Link href="https://www.linkedin.com/pulse/decentralized-studies-victory-sue-mckinney/" target='_blank' underline="hover">
                    ‘Decentralized Studies Victory’
                  </Link>{" "}
                  on May 18, 2022

              </Typography>
            </Box>
            </Stack>
        </Alert>
        
        </Grid>
      </PageContentV2>
    </>
  );
};

export default template;
