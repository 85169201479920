import React from "react";
import {
  Typography,
  Box,
  Grid,
  Card,
  CardMedia,
  CardActionArea,
} from "@mui/material";
import ImgAWS from "../../../img/partnerships/awslogo.png";
import ImgHexnode from "../../../img/partnerships/hexnodelogo.png";
import ImgSAS from "../../../img/partnerships/sas.png";
import ImgExostar from "../../../img/partnerships/exostar.png";

const content = [
  { id: 1, name: "AWS", src: ImgAWS, link: "https://aws.amazon.com/partners/" },
  { id: 2, name: "Hexnode", src: ImgHexnode, link: "https://www.hexnode.com/" },
  {
    id: 3,
    name: "SAS",
    src: ImgSAS,
    link: "https://www.sas.com/en_in/home.html",
  },
  { id: 4, name: "Exostar", src: ImgExostar, link: "https://www.exostar.com/" },
];

const template = () => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: "40px 20px",
        }}
      >
        {/* Description */}
        <Typography
          variant="body2"
          sx={{
            lineHeight: 1.6,
            maxWidth: "1000px",
            margin: "0 auto 30px auto",
            textAlign: { xs: "center", md: "left" },
          }}
        >
          Our technology ecosystem partners are key enablers to driving
          innovation and empowering powerful solutions that transform
          industries. We integrate these partnerships into our cutting-edge
          platform to deliver seamless and scalable technologies that address
          complex challenges. Together, we empower healthcare to provide more
          reliable, efficient, and scalable growth in the technology-driven
          world.
        </Typography>

        {/* Partner Logos */}
        <Grid
          container
          spacing={2}
          sx={{
            maxWidth: "90%",
            margin: "auto",
          }}
        >
          {content.map((logo) => (
            <Grid item xs={12} sm={6} md={3} key={logo.id}>
              <Card
                sx={{
                  width: "100%",
                  maxWidth: "250px",
                  margin: "0 auto",
                  height: "200px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  border: "1px solid #707070",
                  borderRadius: "30px",
                  backgroundColor: "#FFFFFF",
                }}
              >
                <CardActionArea
                  component="a"
                  href={logo.link}
                  target="_blank"
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CardMedia
                    component="img"
                    image={logo.src}
                    alt={logo.name}
                    sx={{
                      maxWidth: "50%",
                      maxHeight: "60%",
                      objectFit: "contain",
                    }}
                  />
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default template;
