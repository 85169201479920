import React from 'react';
import { HashLink as RouterLink } from 'react-router-hash-link';
import { Grid, Typography, Stack } from '@mui/material';

import { PageSectionLeftImg } from '../../../component/page';
import { Product } from '../../../component/page';

import RouterList from '../../../router';

import ImgWearables from '../../../../img/product/connect/wearablesnew.png';
import ImgPartRecruit from '../../../../img/product/connect/Partipant_Recruitment.png';
import CustomTypography from '../../../component/customTypography';

const template = () => {
  return (
    <>
    <Grid container direction='column' spacing={0} sx={{ mt: 0 }}>
          {/* Partipant Recruitment  */}
        <Grid item>
            <Product
              ImgSrc={ImgPartRecruit}
              Name="Partipant Recruitment"
              Summary={
                <>
                <CustomTypography variant='body2' sx={{ mt: 1  }}>
                QR codes can significantly enhance the participant recruitment experience in clinical trials and provide potential participants with 
                an easy and quick way to access trial information. By scanning a QR code, individuals can be directed to a study's 
                recruitment page to learn about eligibility criteria, study details, and sign-up instructions. 
                <br></br><br></br>
                This technology reduces barriers to entry by allowing prospective participants to engage in the trial from their 
                smartphones, making it easier for them to express interest. QR codes can also be placed on promotional materials 
                such as flyers, posters, and social media platforms, extending the reach of recruitment efforts. Moreover, integrating 
                QR codes with registration systems allows for seamless data capture, ensuring that interested participants are 
                efficiently introduced and integrated into the recruitment process.
              </CustomTypography>
              </>
              }
            />
            
        </Grid>
    </Grid>
    </>
  );
}

export default template;