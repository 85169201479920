import React from "react";
import { Grid, Box, Typography, Stack,Alert, Link } from "@mui/material";
import { teal, blueGrey } from "@mui/material/colors";

import { PageHero, PageContentV2 } from "../../component/page";
import Imgfda from "../../../img/media/customer-story/_1/FDA-Logo.jpg";
import Imgjohn from "../../../img/media/customer-story/_1/John-Mendelson.jpeg";
import Imgcpmc from "../../../img/media/customer-story/_1/CPMC-Logo.png";

// Page Imports
import _0 from "./customer-story_7/_0";

const template = () => {
  return (
    <>
      <PageHero color="rgba(3, 169, 244, 0.1)">
        <_0 />
      </PageHero>
      <PageContentV2>
        <Grid item>
          <Typography variant="subtitle2" paragraph>
          Moving from Paper to Electronic Consent involving patients with substance abuse
          </Typography>
          <Typography variant="body7" paragraph>
          During a particularly delicate clinical study in California involving patients with substance abuse, the study team led by the principal investigator, Dr. John Mendelson opted to transition from traditional paper records to electronic informed consent. The transition was prompted by the need to recruit and engage with study participants without the challenge of storing volumes of paper records for each recruited participant. The path to digitization meant that paper records no longer multiply and occupy large file cabinets that take up major spaces in hospitals or clinics particularly in expensive real estates like downtown San Francisco. 
          </Typography>
          <Typography variant="body7" paragraph>
          According to Avik Pal, CEO of CliniOps, “this was the first project for CliniOps and also the first time that we developed an electronic input of participants’ consent.  The move to electronic consent certainly helped the study team recruit participants more efficiently and without the cumbersome paper files and storage.”
          </Typography>
          
          
        
          <blockquote
            style={{ borderLeft: `4px solid ${blueGrey[100]}`, marginLeft: -1 }}
          >
            <Stack
              spacing={4}
              sx={{ pl: 4 }}
              direction={{ xs: "column", md: "row" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minWidth: "220px",
                  width: "220px",
                  height: "220px",
                  borderRadius: 2,
                  backgroundImage: `url(${Imgjohn})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "top center",
                }}
              />
              <Box>
              <Typography variant="body7" paragraph>
               <em>
               “CliniOps is innovative, easy to use, perfectly positioned and has the potential to transform how we recruit and consent subjects. I believe that it is a real innovative improvement in clinical research."
                </em>
               </Typography>
               <Typography variant="overline" paragraph>
              <strong>
                Dr. John Mendelson
                <br />
                Chair, IRB, California Pacific Medical Center Research Institute
              </strong>
            </Typography>
             
              </Box>
            </Stack>
          </blockquote>
         
          
        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '114px',
                width: '135px',
                height: '80px',
                borderRadius: 0,
                
                backgroundImage: `url(${Imgcpmc})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
          />
          
           <Box>
           <Typography paragraph>
           <Typography paragraph>
           Dr. John Meldelson sharing his <Link href="https://www.youtube.com/watch?v=-KtmgHhumlU" target={"_blank"} underline="hover">experience working with CliniOps eConsent Application </Link>
          </Typography></Typography>
           </Box>
          
          </Stack>
     </Alert>
     
    

        {/* </Alert> */}

          {/* <blockquote
            style={{ borderLeft: `4px solid ${blueGrey[100]}`, marginLeft: -1 }}
          >
            <Box sx={{ pl: 4 }}>
              <Typography paragraph>
                A Case Study titled ‘Use of Decentralized Clinical Technologies
                in Lower Urinary Tract Symptoms’ is published in the Journal of
                Medicine, University of Santo Tomas, in the October 2022 issue,
                authored by Dr. Nadina Jose & Avik Pal
              </Typography>
            </Box>
          </blockquote> */}
        </Grid>
      </PageContentV2>
    </>
  );
};

export default template;
