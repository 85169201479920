import React from "react";
import { Divider } from "@mui/material";

// Page Imports
import _0 from "./_0/_0";
import _1 from "./_0/_1";
import _2 from "./_0/_2";
import _3 from "./_0/_3";
import _4 from "./_0/_4";
import _5 from "./_0/_5";
import _6 from "./_0/_6";
// import _7 from './_0/_7';

const template = () => {
  return (
    <>
      <_0 />
      <_1 />
      <Divider className="co-divider" style={{ maxWidth: "100%" }} />
      <_2 />
      <Divider className="co-divider" style={{ maxWidth: "100%" }} />
      <_3 />
      {/* <Divider className='co-divider' style={{ maxWidth: '45%'}} /> */}
      <_4 />
      {/* <Divider className='co-divider' style={{ maxWidth: '45%'}} /> */}
      <_5 />
      {/* <Divider className='co-divider' style={{ maxWidth: '45%'}} /> */}
      {/* <_6 /> */}
    </>
  );
};

export default template;
