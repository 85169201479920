import React from 'react';
import { Box, Typography, Grid, Card, CardHeader, CardMedia, Link } from '@mui/material';

import ImgMedia201401 from '../../../../img/media/newsroom/_2014/Media-2014-01.png';
import ImgMedia201402 from '../../../../img/media/newsroom/_2014/Media-2014-02.png';
import ImgMedia201403 from '../../../../img/media/newsroom/_2014/Media-2014-03.png';
import ImgMedia201404 from '../../../../img/media/newsroom/_2014/Media-2014-04.png';
import ImgMedia201405 from '../../../../img/media/newsroom/_2014/Media-2014-05.png';
import ImgMedia201406 from '../../../../img/media/newsroom/_2014/Media-2014-06.png';
import ImgMedia201407 from '../../../../img/media/newsroom/_2014/Media-2014-07.png';
import ImgMedia201408 from '../../../../img/media/newsroom/_2014/Media-2014-08.png';
import ImgMedia201409 from '../../../../img/media/newsroom/_2014/Media-2014-09.png';
import ImgMedia201410 from '../../../../img/media/newsroom/_2014/Media-2014-10.png';
import ImgMedia201411 from '../../../../img/media/newsroom/_2014/Media-2014-11.png';
import ImgMedia201412 from '../../../../img/media/newsroom/_2014/Media-2014-12.png';
import ImgMedia201413 from '../../../../img/media/newsroom/_2014/Media-2014-13.png';
import ImgMedia201414 from '../../../../img/media/newsroom/_2014/Media-2014-14.png';
// import sign from '../../../../img/media/newsroom/Renew/sign.png';


const news2014Data = [
    {
    ImgSrc: ImgMedia201401,
    Name: "9 Healthcare Tech Startups To Watch",
    Date: "December 29, 2014",
    Summary: "As venture capital flows into healthcare startups, look at some new companies that should be on your radar.",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "https://www.informationweek.com/it-leadership/9-healthcare-tech-startups-to-watch#close-modal",
  },
  {
    ImgSrc: ImgMedia201402,
    Name: "Premier 100 IT Leaders 2015, by Computerworld",
    Date: "November 05, 2014",
    Summary: "CliniOps's Yerramalli Subramaniam recognized as Computerworld's Premier 100 IT Leaders for 2015",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
       CliniOps, Inc., a technology startup in the eClinical space, is excited to announce that Yerramalli Subramaniam, the company's Co-Founder and Chief Technology officer, was selected by IDG's Computerworld as a 2015 Premier 100 IT Leader.
      </Typography>
    ),
    redirectUrl: "http://www.briefingwire.com/pr/cliniopss-yerramalli-subramaniam-recognized-as-computerworlds-premier-100-it-leaders-for-2015",
  },
  {
    ImgSrc: ImgMedia201406,
    Name: "CEO CFO Magazine",
    Date: "July 14, 2014",
    Summary: "Avik Pal, the founder and CEO of CliniOps, a technology and data company with a patented unified platform approach to digitizing clinical trials, finds universal solutions to counter adversities.",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
       “Our proven table-based eSource solution, developed through our patent pending-technology, allows for the seamless capture of trusted and secured data, from various study sites around the world, leading to increased protocol adherence and regulatory compliance. We have also introduced multiple innovations and integrations leading to a fully automated clinical trial workflow… At CliniOps, we believe that the clinical trial industry is ripe for disruption and we want to be a leader in the tablet-based eSource space.” - Avik Pal
      </Typography>
    ),
    redirectUrl: "https://ceocfointerviews.com/interviews/CliniOps14.htm",
  },
  {
    ImgSrc: ImgMedia201407,
    Name: "Diversity, Inclusion, &amp; Life Sciences Symposium",
    Date: "June 25, 2014",
    Summary: "CHI is a non-profit research and educational institute making healthcare more equitable.",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "http://www.chisite.org/",
  },
  {
    ImgSrc: ImgMedia201409,
    Name: "The clinical trial landscape and its data collection challenges",
    Date: "June 09, 2014",
    Summary: "Clinical trials are one of the most important components of the drug development process.  They are research studies that involve human volunteers and are used to test new ways to prevent, detect, diagnose or treat diseases.",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        The average drug-to-market time is about 15 years and costs a staggering $1.8 billion.1,2 Clinical trials account for 60 to 70 percent of the time and more than 90 percent of the cost incurred in drug development.3,4
      </Typography>
    ),
    redirectUrl: "https://www.drugdiscoverynews.com/guest-commentary-the-clinical-trial-landscape-and-its-data-collection-challenges-8502",
  },
  {
    ImgSrc: ImgMedia201410,
    Name: "Village Capital",
    Date: "June 04, 2014",
    Summary: "Unlocking capital for impact-creating startups solving the world's biggest problems",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "https://vilcap.com/",
  },
  {
    ImgSrc: ImgMedia201411,
    Name: "TiE 50 Top Startups",
    Date: "May 16, 2014",
    Summary: "CliniOps, Inc. Named in the ‘2014 Global Hot Technology Start-Up Watch List’ for the TiE50 Awards Program",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
       We are truly humbled and honored to be selected to the coveted list, from a pool of more than 2800 companies, as recognition for our tablet based eSource solution, to support clinical trials. - Avik Pal, Founder & CEO
      </Typography>
    ),
    redirectUrl: "http://www.prweb.com/releases/cliniops_inc/esource_solution/prweb11860180.htm",
  },
  {
    ImgSrc: ImgMedia201412,
    Name: "TiE Media Lounge",
    Date: "May 16, 2014",
    Summary: "Media Lounge : Avik Pal of Cliniops interviews with Kiran Malhotra",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "https://www.youtube.com/watch?v=gBoVpmmbpF8",
  },
  {
    ImgSrc: ImgMedia201413,
    Name: "Patient Engagement App Challenge",
    Date: "April 02, 2014",
    Summary: "Our product is a tablet based EDC system designed with a focus to educate, engage, and empower clinical trial participants and in the process improve transparency in the subject registration and data collection process.",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        Educating and empowering participants: Our tablet based EDC system has a work flow that starts with subject registration, subject consenting, and finally moves on to data collection using electronic case report forms. Each step needs to be completed in order to move on to the next step. 
      </Typography>
    ),
    redirectUrl: "https://devpost.com/software/cliniops",
  },
  {
    ImgSrc: ImgMedia201414,
    Name: "EPPICon Conference",
    Date: "March 29, 2014",
    Summary: "EPPICon Conference",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "https://eppicglobal.org/",
  },
]


export default news2014Data;