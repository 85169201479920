import React from 'react';
import { Grid, Divider } from '@mui/material';

import { PageHero, PageContent } from '../../component/page';

import _0 from './insights/_0';
import _1 from './insights/_1';
import _2 from './insights/_2';
import _3 from './insights/_3';
import _4 from './insights/_4';
import _5 from './insights/_5';
import _6 from './insights/_6';
import _7 from './insights/_7';

const template = () =>{
    return (
        <>
         <_0/>

         <Grid item>
          <_1/>
         </Grid>
         <PageContent>
         <Grid item>
          <_2/>
         </Grid>
         <Grid item sx={{ pt: '20px !important' }}>
          <_3/>
         </Grid>
         <Grid item sx={{ pt: '20px !important' }}>
          <_4/>
         </Grid>
         <Grid item sx={{ pt: '20px !important' }}>
          <_5/>
         </Grid>
         <Grid item sx={{ pt: '20px !important' }}>
          <_6/>
         </Grid>
         </PageContent>
         <Grid item>
          <_7/>
         </Grid>
        </>
    )
}

export default template;