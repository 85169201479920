import React from 'react';
import { Box, Grid } from '@mui/material';

import Imgbackground from '../../../../img/product/_0/product-offerings.png';

const template = () => {
  return (
    <>
    <Box>
    <Box sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: {xs:"70px 0 0 0",md:"90px 0 0 0"},
      overflow: "hidden",
 
      }}>
  <img
    src={`${Imgbackground}`}
    alt=""
    style={{
      maxWidth: "100%",
      maxHeight: "100%",
      objectFit: "contain" }}
  />
  </Box>
  <Box sx={{
          position: 'absolute',
          top: { xs: '80px', sm: '70px', md: '100px' },
          left: { xs: '5px', sm: '1px', md: '30px' },
          right: 0,
          bottom: 0,
          display: 'flex',
          color: '#FFF',
          textAlign: 'topleft',
          fontSize: { xs: '25px', sm: '38px', md: '50px' }, // Responsive font size
          fontWeight: 400,
           textShadow: '3px 3px 3px rgba(0, 0, 0, 0.16)'
          // fontFamily: 'Segoe UI',
        }}>
      <Box sx={{display:"flex", gap: '5px', marginLeft: '20px'}}>
        <Box sx={{fontSize:{ xs: '15px', sm: '25px', md: '30px' }, fontWeight: 'light',mt:{xs:1.2,sm:1.5,md:2.5}}}>
          Products
        </Box>
        <Box sx={{fontWeight:'light'}}>
          Overview
        </Box>
      </Box>
  </Box>
  </Box>
 
    </>
  );
}
 
export default template;