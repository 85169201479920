import React from "react";
import { Stack, TextField, Button, Box, Grid, Typography } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ConvertToForm from "./../../../../js/convertFormElementsToJSON";
import { CONTACT_FORM_SUBMIT_URL } from "./../../../../js/consts";
import submitData from "./../../../../js/submitData";

export const textFieldBaseStyles = {
  "& .MuiOutlinedInput-root": {
    backgroundColor: "#FFFFFF",
    "@media (max-width:900px)": {
      "& .MuiInputBase-input": {
        fontSize: "14px",
        lineHeight: 1.5,         // ensure enough line height
        // padding: "8px 34px 8px 14px",     // manually adjust vertical/horizontal padding 
      },
    },
    // boxShadow: "0 3px 3px rgba(0, 0, 0, 0.16)",
    // "& fieldset": {
    //   border: "1px solid #1E75C6",
    // },
    // "&:hover fieldset": {
    //   borderColor: "#1E75C6",
    // },
    // "&.Mui-focused fieldset": {
    //   borderColor: "#1E75C6",
    // },
  },
};

export const typographyBaseStyles = {
  "&.MuiTypography-root": {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#000000",
  },
};

const initialVal = {
  fullName: "",
  email: "",
  organization: "",
  subject: "",
  message: "",
};

const template = () => {
  const [openSnackbar, setOpenSnackBar] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("");

  const handleSnackbarClose = (reason) => {
    setOpenSnackBar(false);
  };

  const actionSnackbar = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  const [disabled, setDisabled] = React.useState(false);
  async function handleSubmit(e) {
    setDisabled(true);
    e.preventDefault();
    const formData = ConvertToForm(e.target.elements, [
      "fullName",
      "email",
      "phone",
      "organization",
      "subject",
      "message",
    ]);
    const data = await submitData({
      formData: {
        data: formData,
        subject: "Contact Form",
      },
      url: CONTACT_FORM_SUBMIT_URL,
    });
    if (data?.message === "Mail send") {
      setOpenSnackBar(true);
      setMessage(
        "Thanks for contacting us! We will be in touch with you shortly."
      );
      setSeverity("success");
      e.target.reset();
    } else {
      setOpenSnackBar(true);
      setMessage("Form submission failed");
      setSeverity("error");
    }
    setDisabled(false);
  }
  return (
    <>
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={message}
        action={actionSnackbar}
        sx={{ mt: "70px", boxShadow: 8 }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={severity}
          variant="filled"
        >
          {message}
        </Alert>
      </Snackbar>
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            border: "1px solid rgba(0, 0, 0, 0.25)",
            mx: { xs: "5%", md: "13%" },
            padding:{xs:"30px",md: "40px 80px 40px 80px"},
            boxShadow: `
            0 3px 3px rgba(0, 0, 0, 0.16),
            inset 0 4px 4px rgba(0, 0, 0, 0.25)
            `,
            borderRadius: "10px",
            backgroundColor: "white",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <Box
              sx={{
                // border: "1px solid black",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: {xs:"25px",md:"42px"},
                color: "#042D43",
                fontWeight: "300",
                padding: "",
                textShadow: "0 3px 3px rgba(0, 0, 0, 0.16)"
              }}
            >
              <Box>Contact Us</Box>
            </Box>
            <Box
              sx={
                {
                  // border: "1px solid black",
                }
              }
            >
              <Stack spacing={3} sx={{ maxWidth: "100%", margin: "0 auto" }}>
                <Stack spacing={0.5}>
                  <Typography sx={typographyBaseStyles}>Full Name</Typography>
                  <TextField
                    sx={textFieldBaseStyles}
                    size="small"
                    placeholder=""
                    name="fullName"
                    variant="outlined"
                    fullWidth
                    required
                  />
                </Stack>
                <Stack spacing={0.5}>
                  <Typography sx={typographyBaseStyles}>Email</Typography>
                  <TextField
                  sx={textFieldBaseStyles}
                    size="small"
                    placeholder=""
                    name="email"
                    type="email"
                    variant="outlined"
                    fullWidth
                    required
                  />
                </Stack>
                <Stack spacing={0.5}>
                  <Typography sx={typographyBaseStyles}>Phone</Typography>
                  <TextField
                  sx={textFieldBaseStyles}
                    size="small"
                    placeholder=""
                    name="phone"
                    variant="outlined"
                    fullWidth
                    required
                  />
                </Stack>
                <Stack spacing={0.5}>
                  <Typography sx={typographyBaseStyles}>Organization</Typography>
                  <TextField
                  sx={textFieldBaseStyles}
                    size="small"
                    name="organization"
                    variant="outlined"
                    fullWidth
                    required
                  />
                </Stack>
                <Stack spacing={0.5}>
                  <Typography sx={typographyBaseStyles}>Subject</Typography>
                  <TextField
                  sx={textFieldBaseStyles}
                    size="small"
                    name="subject"
                    variant="outlined"
                    fullWidth
                    required
                  />
                </Stack>
                <Stack spacing={0.5}>
                  <Typography sx={typographyBaseStyles}>Message</Typography>
                  <TextField
                  sx={textFieldBaseStyles}
                    size="small"
                    name="message"
                    variant="outlined"
                    fullWidth
                    required
                    multiline
                    rows={8}
                  />
                </Stack>
                <Box
                  sx={{
                    display: "flex",
                    width:"100%",
                    alignItems: "center",
                    justifyContent: "center",
                    flexWrap:{xs:'wrap'},
                    gap:{xs:2,md:5},
                  }}
                >
                  <Box sx={{ width: { xs: "100%", md: "auto" } }}>
                    <Button size="large" variant="contained"
                    sx={{
                      width: { xs: "100%", md: "auto" },
                      color:"#FFFFFF",
                      fontWeight:400,
                      fontSize:"14px",
                      borderRadius:"12px",
                      backgroundColor:"#8296A1",
                      textTransform:"capitalize",
                      "&:hover":{
                        backgroundColor:"#8296A1",
                        border:'none'
                      }
                    }}
                    >
                      Cancel
                    </Button>
                  </Box>

                  <Box sx={{ width: { xs: "100%", md: "auto" } }}>
                    <Button
                      disabled={disabled}
                      id="submitButton"
                      size="large"
                      variant="contained"
                      type="submit"
                      sx={{
                      width: { xs: "100%", md: "auto" },
                      color:"#FFFFFF",
                      fontWeight:400,
                      fontSize:"14px",
                      borderRadius:"12px",
                      backgroundColor:"#042D43",
                      textTransform:"capitalize",
                      whiteSpace: "nowrap", 
                      "&:hover":{
                        backgroundColor:"#042D43",
                        border:'none'
                      }
                      }}
                    >
                      Send Message
                    </Button>
                  </Box>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default template;
