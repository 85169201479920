import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import ImgConnect from "../../../../img/home/CliniOpsConnect.png";
import ImgEdge from "../../../../img/home/CliniOpsEdge.png";
import ImgConduct from "../../../../img/home/CliniOpsConduct.png";
import ImgInsight from "../../../../img/home/insights-logo-blue.png";
import ImgConductLogo from "../../../../img/home/conduct_logo_white.png";
import ImgConnectLogo from "../../../../img/home/connect-logo-white.png";
import ImgEdgeLogo from "../../../../img/home/edge-logo-white.png";
import ImgInsightLogo from "../../../../img/home/insight-logo-white.png";
import ImgMultiNurse from "../../../../img/home/multiracial-nurses.png";
import ImgDoctors from "../../../../img/home/conduct_theme.png";
import ImgDoctor from "../../../../img/home/Doc-with-iPad-and-male-patient.png";
import ImgNurse from "../../../../img/home/NurseWithTablet.png";
import ImgOldWoman from "../../../../img/home/OldWomanPhone.png";
import RouterList from "../../../router";
import { HashLink as RouterLink } from "react-router-hash-link";

const template = () => {

    const sections = [
        {
          titleImg: ImgConduct,
          description:
            "A unified platform that streamlines clinical data management and operations, accelerates regulatory submission processes, and enables central and remote monitoring (rSDV).",
          image: ImgDoctors,
          buttonLabel: "Know More...",
          bgColor: "#F5F5F5",
          backgroundImage: ImgConductLogo,
          link:RouterList.product.conduct
        },
        {
          titleImg: ImgEdge,
          description:
            "Runs on standard tablet devices in a complete offline mode, ensuring maximum mobility with all checks and balances and assuring efficient, digitally collected, high-quality data at the point of care.",
          image: ImgNurse,
          buttonLabel: "Know More...",
          bgColor: "#F5F5F5",
          backgroundImage: ImgEdgeLogo,
          link:RouterList.product.edge
        },
        {
          titleImg: ImgConnect,
          description:
            "Puts patients at the center of the clinical trials and enables seamless participation via telemedicine from the comfort of their homes or at their regular care facility.",
          image: ImgOldWoman,
          buttonLabel: "Know More...",
          bgColor: "#F5F5F5",
          backgroundImage: ImgConnectLogo,
          link:RouterList.product.connect
        },
        // {
        //   titleImg: ImgInsight,
        //   description:
        //     "A powerful analytics and reporting module integrated into the CliniOps product suite enables clinical trial teams to access data in real-time, derive data-driven insights, empower faster decision-making, and improve patient outcomes.",
        //   image: ImgDoctor,
        //   buttonLabel: "Know More...",
        //   bgColor: "#F5F5F5",
        //   backgroundImage: ImgInsightLogo,
        //   link:RouterList.product.insights
        // },
      ];    

  return (
    <Box >
    {sections.map((section, index) => (
        <Box
          key={index}
          sx={{
            backgroundColor: section.bgColor,
            backgroundImage: `url(${section.backgroundImage})`,
            // position:"relative",
            // zIndex:1,
            backgroundSize: "auto",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            padding: "20px 0 0 0",
            marginBottom: "0px",
            borderBottom:
              index === sections.length - 1 ? "none" : "1px solid grey",
            // display: "flex",
            // alignItems: "center",
            // justifyContent: "center",
            ...(index === 0 && {
              paddingTop: { xs: "40px", md: "50px" }
            }),
          }}
        >
          <Grid
            container
            spacing={2}
            alignItems="stretch"
            justifyContent="center"
            sx={{
              minHeight: "250px",
              padding: { xs: "0 20px", md: "0 0" },
              alignItems:"stretch"
            }}
          >
            {/* Left-Side Image */}
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              sx={{
                display: "flex",
                // flexDirection:"column",
                justifyContent: "center",
                alignItems: "flex-end",
                minHeight: { xs: "200px", md: "300px"  },
                position: "relative",
                // flexShrink:0,
              }}
            >
            
              <Box
                component="img"
                src={section.image}
                alt={"#"}
                sx={{
                  width: "100%",
                  height: "100%",
                  maxWidth: "500px",
                  maxHeight: "300px",
                  objectFit: "contain",
                  objectPosition: "bottom",
                  display: "block",
                  flexShrink: 0, // Prevent size reduction
                  position: "relative",
                  ...(index === 1 && { 
                    maxWidth:"500px",
                    maxHeight:"350px",
                    objectPosition: "top", // If image has empty space at bottom
                    transform: "translateY(5.5%)" // Adjust positioning visually
                  }),
                //  transform:"scale(1.5)"
                }}
              />
              
            </Grid>

            {/* Right-Side Text */}
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems:{xs:"center",md:'flex-start'},
                justifyContent: "center", // Align text and button centrally
                padding: { xs: "0 10px 0 10px", md: "0 20px 30px 20px" },
                textAlign: { xs: "center", md: "left" },
              }}
            >
              <Box
                component="img"
                src={section.titleImg}
                alt="Title Image"
                sx={{
                  width: "100%",
                  height: "auto",
                  maxWidth: { xs: "200px", md: "200px" },
                  margin: { xs: "0 auto 20px auto", md: "0 0 10px 0" },
                }}
              />
              <Typography
                variant="body2"
                sx={{
                  lineHeight: "1.6",
                  marginBottom: "20px",
                }}
              >
                {section.description}
              </Typography>
              <Box
                  sx={{
                    marginBottom: { xs: "20px", md: "0px" }, // Margin applied only to this wrapper
                  }}
                >
              <Button
                variant="contained"
                component={RouterLink}
                to={section.link}
                sx={{
                  backgroundColor: "#042D43",
                  color: "#FFFFFF",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#03506F",
                  },
                  fontSize:"14px"
                }}
              >
                {section.buttonLabel}
              </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ))}
    </Box>
  )
}

export default template