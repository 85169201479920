import React from 'react';
import { Grid } from '@mui/material';
import { teal } from '@mui/material/colors';

import { PageHero, PageContent } from '../../component/page';

// Page Imports
import _0 from './customer-story/_0';
import _1 from './customer-story/_1';
import _2 from './customer-story/_2';

const template = () => {
  return (
    <>
      {/* <PageHero color={teal}> */}
        <_0 />
        <_2/>
      {/* </PageHero> */}
      
        <Grid container sx={{marginTop:{xs:"-80px",sm:"-100px",md:"-200px"},padding:"5px 20px 80px 20px",}}>
         <Grid item xs={12} md={11} margin="auto">
         <_1 />
         </Grid>
        </Grid>
      
    </>
  );
}

export default template;