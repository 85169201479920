import React from "react";

import {
  Container,
  Box,
  Grid,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  Link,
} from "@mui/material";
import ImgWaveTop from "../../../../img/home/wave1.png";
import CustomCarousel from "../CustomCarousel";
import frostImg from "../../../../img/home/frostAward.png";
import patentAwardImg23 from "../../../../img/home/patentAward2023.png";
import patentAwardImg24 from "../../../../img/home/patentAward2024.png";
import redHerringImg from "../../../../img/home/redHerring.png";
import dstImg from "../../../../img/home/DST.png";

const template = () => {
  const slides = [
    {
      image: patentAwardImg24,
      description: `CliniOps Awarded Patent for an Innovative Offline/Online eSource, or Direct Data Collection (DDC) System, for Global Clinical Trials`,
      location: "Fremont, CA / ACCESSWIRE",
      date: "April 25, 2024",
      additionalInfo: `"Their products were designed to help sites make a technology leap from paper sources to electronic sources, utilizing mobile devices with complete offline capability. "`,
      link:"https://www.newswire.com/news/cliniops-awarded-patent-for-an-innovative-offline-online-esource-or-22307884"
    },
    {
      image: patentAwardImg23,
      description: `CliniOps Platform receives Patent approval from USPTO, for collecting location agnostic clinical and non-clinical data`,
      location: "Fremont, CA / ACCESSWIRE",
      date: "May 18, 2023",
      additionalInfo: `“This patent covers CliniOps' unique approach of clinical and non-clinical data collection, essential to a more holistic and automated decentralized clinical trial (DCT).”`,
      link:"https://www.newswire.com/news/cliniops-awarded-patent-for-pioneering-system-to-collect-location-22039742"
    },
    {
      image: redHerringImg,
      description: `Red Herring Crowns Global e∙dentity™’s Top 100 for North America. CliniOps wins in the software category.`,
      location: "Pasadena, CA",
      date: "May 15, 2019",
      additionalInfo: `While recognizing the continent’s most exciting and innovative private technology companies, Red Herring referred 2019’s crop of Top 100 winners as "among our most intriguing yet..."`,
      link:"https://www.redherring.com/red-herring-2019-top-100-north-america-winners-press-release/"
    },
    {
      image: frostImg,
      description: `CliniOps, Inc. Receives the ‘2017 Frost & Sullivan Technology Leadership Award for Digitalization of Clinical Trials’.`,
      location: "Fremont, CA / ACCESSWIRE",
      date: "June 11, 2017",
      additionalInfo: `“CliniOps simplifies the complexities of clinical trials, reduces its time and cost, and significantly enhances data quality.”`,
      name: "Dr.Sudeep Basu",
      position: "Global Practice Leader, Frost & Sullivan",
      link:"https://www.prweb.com/releases/cliniops_inc_receives_the_2017_frost_sullivan_technology_leadership_award_for_digitalization_of_clinical_trials_/prweb14414347.htm"
    },
    {
      image: dstImg,
      description: `CliniOps, Inc. Receives the 2016 DST-Lockheed Martin India Innovation Growth Award for Digitalization of Clinical Trials’`,
      location: "New Delhi",
      date: "June 03, 2016 ",
      additionalInfo: `“Tablet-based eSource/EDC for clinical trials from CliniOps leverages Social, Mobile, Analytics, and cloud (SMAC) to drastically transform how CTs are conducted, reducing the time and cost of collecting data while improving its quality.”`,
      link:"https://ficci.in/public/storage/PressRelease/2399/FICCI-TCP.pdf"
    },
  ];

  const theme = useTheme();
  const isSm = useMediaQuery(
    theme.breakpoints.down("900")
  );

  const renderItem = (slide) => (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        alignItems: "strech",
        justifyContent: "space-between",
        gap: "24px",
        padding: { xs: "30px", md: "50px" },
        borderRadius: "20px",
        position: "relative",
        // height:'auto'
        // width:"100%"
        // height: "500px",
      }}
    >

      {/* Left Section - Image */}
      <Box
        sx={{
          flex: 1.5,
          textAlign: "center",
          display: "flex",
          alignItems: "strech",
          // justifyContent: "center",
        }}
      >
        <img
          src={slide.image}
          alt="Award Image"
          style={{
            maxWidth: "100%", // Ensures the image doesn't overflow
            height: "auto", // Keeps aspect ratio
            objectFit: "cover", // Maintains proper scaling
          }}
        />
      </Box>

      {/* Right Section - Text Description */}
      <Box
        sx={{
          flex: 1,
          textAlign: "left",
          // position: "relative",
        }}
      >
        <Typography
          variant="body3"
          sx={{
            fontWeight: "400",
            lineHeight: 1.2,
          }}
        >
          {slide.description}
        </Typography>
        {/* Location and Date */}
        <Typography
          variant="h4"
        >
          {slide.location}
        </Typography>
        <Typography
          variant="h4"
          sx={{
            marginBottom: "1em",
          }}
        >
          {slide.date}
        </Typography>
        {/* Additional Information */}
        <Typography
          variant="h6"
          sx={{
            lineHeight: 1,
            marginBottom: "15px",
          }}
        >
          {slide.additionalInfo}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: "#042D43",
            fontSize: "clamp(16px, 1.8vw, 20px)",
            fontWeight: "400",
          }}
        >
          {slide?.name}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: "#042D43",
            fontSize: "clamp(16px, 1.8vw, 20px)",
            fontWeight: "400",
          }}
        >
          {slide.position}
        </Typography>
        <Button
          variant="outlined"
          sx={{
            padding: "8px 6px",
            fontSize: "14px",
            color: "#0073e6",
            textTransform: "none",
            fontWeight: "500",
            background: "transparent",
            border: "none",
            "&:hover": {
              border: "none",
              background: "transparent",
            },
          }}
          href={slide.link}
          target="_blank"
        >
          Know More...
        </Button>
      </Box>
    </Box>
  );

  const customStyles = {
    wrapper: {
      maxWidth: "90%",
      margin: "0 auto",
      border: "1px solid #CED2D4",
      boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
      borderRadius: "20px",
      backgroundColor: "#FFFFFF",
    },
    slide: { width: "100%" },
    arrows: {
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      fontSize: "24px",
      cursor: "pointer",
    },
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    arrowOutside: true,
    arrowOutsideOffset: "-90px", // Place the arrows consistently
    dotsPosition: {
      top: isSm ? "10px" : "20px",
    },
  };

  return (
    <>
      {/* Carousel Section */}
      <Box
        sx={{
          width: "100%",
          textAlign: "center",
          padding: { xs: "20px", md: "50px" },
          backgroundImage: `url(${ImgWaveTop})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top center",
          backgroundSize: "contain",
          // display: "flex",
          // alignItems: "center",
          // flexDirection: "column",
          // justifyContent: "center",
        }}
      >
        {/* Section Title */}
        <Typography
          // variant="h1White"
          sx={{
            color: "#FFFFFF",
            fontWeight: "300",
            fontSize: { xs: "25px", md: "42px" },
            textShadow: "0px 3px 3px rgba(0, 0, 0, 0.1608)",
            marginBottom: "50px",
            marginTop: { xs: "-15px",sm:'-5px', md: 0 },
          }}
        >
          Awards & Patents
        </Typography>

        {/* Carousel */}
        <CustomCarousel
          slides={slides}
          renderItem={renderItem}
          customStyles={customStyles}
          settings={settings}
        />
      </Box>
    </>
  );
};

export default template;
