import React from 'react';
import { Grid, Typography } from '@mui/material';

import { Product } from '../../../component/page';

import ImgDMP from '../../../../img/service/clinical-data-management/DMP.png';
import CustomTypography from '../../../component/customTypography';

const template = () => {
  return (
    <>
      <Grid container direction='column' spacing={0} sx={{ mt: 0 }}>
            {/* DMP, Data Review and Analysis  */}
          <Grid item>
              <Product
                ImgSrc={ImgDMP}
                Name="DMP, Data Review and Analysis"
                Summary={
                  <>
                  <CustomTypography variant='body2' sx={{ mt: 1 }}>
                    CliniOps experienced team can develop the Data Management Plan (DMP) along with comprehensive data review and analysis 
                    services to ensure the accuracy and integrity of clinical trial data. Our team performs real-time data monitoring, identifying 
                    inconsistencies, discrepancies, and potential issues early in the trial process, DSMB support as needed. By utilizing advanced 
                    analytics, CliniOps provides high-quality, cost-effective, best in class data review services, backed by experienced data analysis 
                    and reporting, with actionable insights to improve decision-making and ensure regulatory compliance.
                </CustomTypography>
                </>
                }
              />
              
          </Grid>
      </Grid>
    </>
  );
}

export default template;

//Commenting previous code

// import React from 'react';
// import { Typography, Stack } from '@mui/material';

// import { PageSectionLeftImg } from '../../../component/page';

// import ImgDataManagementPlan from '../../../../img/service/clinical-data-management/dataManagementPlan.jpeg';

// const template = () => {
//   return (
//     <>
//       <PageSectionLeftImg
//         heading={
//           <Stack direction="row" spacing={1} alignItems='center' justifyContent={{xs: 'center', md: 'flex-start'}}>
//             <Typography variant="h2" component="h2" className="co-page-heading" sx={{ textAlign: { xs: 'center', md: 'left'} }}>
//               Data Management Plan (DMP)
//             </Typography>
//           </Stack>
//         }
//         imgUrl={ImgDataManagementPlan}
//         imgTitle="Data Management Plan (DMP)"
//       >
//         <Typography sx={{ mt: 1 }}>
//           We develop comprehensive end to end DMP which includes:
//           <ul style={{ listStylePosition: "inside" }}>
//             <li>Data entry and data tracking guidelines</li>
//             <li>Quality control measures</li>
//             <li>SAE reconciliation guidelines</li>
//             <li>Discrepancy management</li>
//             <li>Data transfer/extraction</li>
//             <li>Database locking guidelines</li>
//             <li>The team works on a robust portfolio and strives to ensure the highest quality.</li>
//           </ul>
//         </Typography>
//       </PageSectionLeftImg>
//     </>
//   );
// }

// export default template;