import React from 'react';
import { Box, Typography, Grid, Card, CardHeader, CardMedia, Link } from '@mui/material';

import ImgMedia201801 from '../../../../img/media/newsroom/_2018/Media-2018-01.png';
import ImgMedia201807 from '../../../../img/media/newsroom/_2018/Media-2018-07.png';
import ImgMedia201802 from '../../../../img/media/newsroom/_2018/Media-2018-02.png';
import ImgMedia201803 from '../../../../img/media/newsroom/_2018/Media-2018-03.png';
import ImgMedia201804 from '../../../../img/media/newsroom/_2018/Media-2018-04.png';
import ImgMedia201805 from '../../../../img/media/newsroom/_2018/Media-2018-05.png';
import ImgMedia201806 from '../../../../img/media/newsroom/_2018/Media-2018-06.png';
import ImgMedia201808 from '../../../../img/media/newsroom/_2018/Media-2018-08.png';
import ImgMedia201809 from '../../../../img/media/newsroom/_2018/New-World-Report-Logo.png';
// import sign from '../../../../img/media/newsroom/Renew/sign.png';


const news2018Data = [
    {
    ImgSrc: ImgMedia201807,
    Name: "Medical Technologies, F&S Executive Mind Exchange",
    Date: "November 28, 2018",
    Summary: "Executive MindXchange and Innovation Workshop & Tour Event Calendar",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "https://ww2.frost.com/event/live-events/",
  },
  {
    ImgSrc: ImgMedia201802,
    Name: "Frost & Sullivan ‘2018 Global eSource Solutions Product Line Strategy Leadership Award’",
    Date: "November 28, 2018",
    Summary: "Singapore, 28 November 2018 – Frost & Sullivan honored Asia’s leading companies at its 11th annual Asia-Pacific Best Practices Awards banquet held on 27 November at the Shangri-La Hotel Singapore.",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
       Mr. Shivaji Das, Partner & Asia-Pacific Managing Director at Frost & Sullivan noted that the annual awards was held to honor and recognize companies that have pushed the boundaries of excellence in Asia-Pacific.
      </Typography>
    ),
    redirectUrl: "https://ww2.frost.com/news/press-releases/asias-top-companies-recognized-at-the-2018-frost-sullivan-asia-pacific-best-practices-awards/",
  },
  {
    ImgSrc: ImgMedia201809,
    Name: "Best Clinical Trials Digital Solutions Company 2018",
    Date: "September 15, 2018",
    Summary: "CliniOps Best Clinical Trials Digital Solutions Company 2018 CliniOps is a Mobile, Cloud-based, Digital Solutions company, for the clinical trial industry and global health research.",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        CliniOps leverages Social, Mobile, Analytics & Cloud (SMAC) technology to develop comprehensive solutions in eSourcing, Monitoring & Patient Engagement.
      </Typography>
    ),
    redirectUrl: "https://www.thenewworldreport.com/winners/2018-cliniops/",
  },
  {
    ImgSrc: ImgMedia201803,
    Name: "DIA Conference 2018",
    Date: "June 24, 2018",
    Summary: "W-06: Challenges, Outcomes and Benefits of Leveraging eSource Solution in Clinical Trials",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "https://www.diaglobal.org/Flagship/DIA-2018/Program/Posters/Poster-Presentations/Poster-Presentations-Details?ParentProductID=6589208&ProductID=7326302&AbstractID=79333",
  },
  {
    ImgSrc: ImgMedia201804,
    Name: "Slalom San Francisco Healthcare Life Sciences podcast",
    Date: "May 23, 2018",
    Summary: "PODCAST: Put yourself in your customers’ shoes; Avik Pal uses technology to change the game with clinical trials",
    FullContent: (
      <Typography  variant='body2'sx={{ mt: 2 }}>In this episode of The Slalom Daily Dose Podcast, we speak with Avik Pal, Founder and CEO at CliniOps. Avik shares the story of his journey from technology maven to social entrepreneur to clinical trials disruptor. Through his alumni work with the Indian Institute of Technology, he continues to create opportunities for education and innovation in the Bay Area. The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities.
      </Typography>
    ),
    redirectUrl: "https://medium.com/the-slalom-daily-dose/the-secret-to-success-bec436e6974c",
  },
  {
    ImgSrc: ImgMedia201806,
    Name: "Top Bay Area Digital Health Companies",
    Date: "April 06, 2018",
    Summary: "Largest Digital Health Companies in the Greater Bay Area",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
       Digital health companies provide technology-based products and services to the healthcare industry. They include telehealth/virtual care platforms and digital clinics, medical data software, AI-driven testing and diagnostics, and wearable devices.
      </Typography>
    ),
    redirectUrl: "https://www.bizjournals.com/sanfrancisco/subscriber-only/2023/04/07/largest-digital-health-companies-in-the-greater-bay-area.html",
  },
  {
    ImgSrc: ImgMedia201808,
    Name: "Growth Opportunities in Clinical Trial Data Management and eClinical Solutions Market, Forecast to 2020",
    Date: "February 23, 2018",
    Summary: "The global clinical trial IT market is expected to grow from $2.63 billion in 2016 to $4.29 billion in 2020 at a compound annual growth rate (CAGR) of more than 13.0%.",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        The research service analyzes the top four growth opportunities in the global CTDM and emerging eClinical solutions.
      </Typography>
    ),
    redirectUrl: "https://www.businesswire.com/news/home/20180223005412/en/Growth-Opportunities-Clinical-Trial-Data-Management-eClinical",
  },
]


export default news2018Data;