import React from 'react';
import { Grid, Typography, Stack } from '@mui/material';

import { PageSectionRightImg } from '../../../component/page';
import { Product } from '../../../component/page';

import ImgSL from "../../../../img/product/insights/safety_listing.png";
import CustomTypography from '../../../component/customTypography';

const template = () => {
  return (
    <>
    <Grid container direction='column' spacing={0} sx={{ mt: 0 }}>
         
        <Grid item>
            <Product
              ImgSrc={ImgSL}
              Name="Safety Listings"
              Summary={
                <>
                <CustomTypography variant='body2' sx={{ mt: 1}}>
                Safety listing reporting in clinical trials is crucial for identifying, tracking, and analyzing adverse events to ensure 
                participant safety and regulatory compliance. 
                <br></br><br></br>
                These reports provide a comprehensive overview of safety data, including the frequency, severity, and relationship 
                to the investigational product. Timely and accurate safety listings enable quick decision-making and help identify 
                potential risks early. 
                <br></br><br></br>
                Regulatory agencies require clear and well-organized safety listings to evaluate a drug or device's safety profile. 
                Effective safety listing reporting ensures your clinical trial maintains the highest participant protection and data 
                integrity standards.
              </CustomTypography>
              </>
              }
            />
            
        </Grid>
    </Grid>
    </>
  );
}

export default template;