import React from 'react';
import {Box, Grid, Typography} from '@mui/material';

import { Product } from '../../../component/page';

import ImgWave from '../../../../img/product/_0/waveup.png';

const template = () => {
  return (
    <>
      <Box>
          <Box style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "55px 0 0 0", 
          overflow: "hidden",
          margin:"80px 0 0 0"

          }}>
          <img
              src={`${ImgWave}`}
              alt=""
              style={{ 
              maxWidth: "100%", 
              maxHeight: "100%", 
              objectFit: "contain" }}
          />
          </Box>
      </Box>
      </>
  );
};

export default template;