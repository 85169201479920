import React from 'react';
import { Grid, Typography, Stack } from '@mui/material';

import { Product } from '../../../component/page';

import ImgIRTConf from '../../../../img/service/study-setup/IRTConfig.png';
import CustomTypography from '../../../component/customTypography';

const template = () => {
  return (
    <>
      <Grid container direction='column' spacing={0} sx={{ mt: 0 }}>
            {/* IRT Configuration  */}
          <Grid item>
              <Product
                ImgSrc={ImgIRTConf}
                Name="IRT Configuration"
                Summary={
                  <>
                  <CustomTypography variant='body2' sx={{ mt: 1 }}>
                    CliniOps provides comprehensive randomization configuration services to ensure robust, statistically valid allocation in clinical trials. 
                    Our team customizes randomization schemes to meet study design requirements, incorporating both simple and complex algorithms. 
                    The configuration ensures compliance with regulatory guidelines while minimizing biases and errors. With a focus on accuracy 
                    and flexibility, CliniOps supports adaptive and stratified randomization methods for diverse trial needs.
                </CustomTypography>
                </>
                }
              />
              
          </Grid>
      </Grid>
    </>
  );
}

export default template;


//Commenting the previous code

// import React from 'react';
// import { Grid, Box, Typography, Button, Stack } from '@mui/material';

// import { PageSectionRightImg } from '../../../component/page';

// import ImgConfig from '../../../../img/service/study-setup/config.jpeg';

// const template = () => {
//   return (
//     <>
//       <PageSectionRightImg
//         heading={
//           <Stack direction="row" spacing={1} alignItems='center' justifyContent={{xs: 'center', md: 'flex-start'}}>
//             <Typography variant="h2" component="h2" className="co-page-heading" sx={{ textAlign: { xs: 'center', md: 'left'} }}>
//               Interactive Response Technology (IRT) setup &amp; Configuration
//             </Typography>
//           </Stack>
//         }
//         imgUrl={ImgConfig}
//         imgTitle="Interactive Response Technology (IRT) setup &amp; Configuration"
//       >
//         <Typography sx={{ mt: 1 }}>
//           Our IVRS/IWRS integrations provide real-time data tracking for randomization.
//         </Typography>
//         <Typography sx={{ mt: 1 }}>
//           It can seamlessly integrate with the eClinical landscape, is compliant with all regulatory requirements, and meets international standards.
//         </Typography>
//       </PageSectionRightImg>
//     </>
//   );
// }

// export default template;