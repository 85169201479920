import React from "react";
import { Grid, Box, Typography, Stack,Alert, Link } from "@mui/material";
import { teal, blueGrey } from "@mui/material/colors";

import { PageHero, PageContentV2 } from "../../component/page";
import ImgJames from "../../../img/media/customer-story/_1/JamesIvie.jpeg";
import ImgAdelante from "../../../img/media/customer-story/_1/Adelante-Healthcare-logo.png";
import ImgBHHSLF from "../../../img/media/customer-story/_1/BHHS+Legacy+Foundation+Logo.png";
import ImgCR from "../../../img/media/customer-story/_1/CardioRenal.png";
import ImgNKFAZ from "../../../img/media/customer-story/_1/NKFAZ_Logo_Orange-and-Black_PNG-file.png";





// Page Imports
import _0 from "./customer-story_13/_0";

const template = () => {
  return (
    <>
      <PageHero color="rgba(3, 169, 244, 0.1)">
        <_0 />
      </PageHero>
      <PageContentV2>
        <Grid item>
          <Typography variant="body7" paragraph>
          In holding free health screenings for public health, sponsoring institutions are traditionally 
          challenged by volumes of paper, data entry errors and consequent delays.  With advance of electronic data capture as technology 
          application to gather and aggregate data, many of the challenges in holding wellness events have been addressed.
          </Typography>
          <Typography variant="body7" paragraph>
          To support its mission to provide awareness, assistance, and hope to Arizonans at-risk and impacted 
          by kidney disease, the National Kidney Foundation of Arizona (NKF AZ)<sup>1</sup> holds “Path to Wellness” yearly 
          events that includes health screening. Sharing AKF AZ’s public health mission, CliniOps was glad to 
          become NKF AZ’s technology partner in its 2015 wellness event. 
          </Typography>

          <Typography variant="body7" paragraph>
          At the Indo American Cultural Center, 2804 W. Maryland Ave, Phoenix on 15 September 2015, approximately 
          200 participants went through screening program. CliniOps positioned its mobile data platform to drive 
          the electronic data capture of participants’ profile, results of lab tests and surveys.  Participants were 
          pre-registered, with each one responding to questionnaires on risk and screening. Data from screening were 
          auto-calculated and included the following: BMI, LDL Cholesterol, Albumin to Creatinine Ratio and Glomerular Filtration Rate (GFR).
          </Typography>

          <Typography variant="body7" paragraph>
          CliniOps marks this collaboration as a significant start to advocating for public health awareness 
          campaigns with health screenings in communities. With no paper-based trail, CliniOps electronically 
          captured and delivered data that formed basis for whether a participant may have kidney disease concerns.  
          </Typography>
          

          <blockquote
            style={{ borderLeft: `4px solid ${blueGrey[100]}`, marginLeft: -1 }}
          >
            <Stack
              spacing={4}
              sx={{ pl: 4 }}
              direction={{ xs: "column", md: "row" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minWidth: "220px",
                  width: "220px",
                  height: "250px",
                  borderRadius: 2,
                  backgroundImage: `url(${ImgJames})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "top center",
                }}
              />
              <Box>
            <Typography variant="body7" paragraph>
              <em>
              “CliniOps customized their system to organize data collection for our comprehensive 
              community health screenings.  Our events collect demographic, physical measurement, 
              and point-of-care laboratory data from blood and urine testing.  CliniOps tablet 
              application was very intuitive and a quick 15 min onsite training was enough for 
              our volunteers to start using the system seamlessly. The team was able to record 
              about 200 patient data, in less than 4 hours, without issues.  Excellent customer service.”
              </em>
            </Typography>
            <Typography variant="overline" paragraph>
              <strong>
              James Ivie, PhD, LCSW 
              <br></br>
              Director of Patient Services, National Kidney Foundation of Arizona
              </strong>
            </Typography>
          </Box>
            </Stack>
          </blockquote>
          <Typography variant="body7" paragraph>
          <sup>1</sup>National Kidnet Foundation of Arizona: <a href= "https://azkidney.org/mission-and-values" target={'_blank'} class="linkcolor">https://azkidney.org/mission-and-values</a>  
          </Typography>
        
        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Stack display={"block"}>
        <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '175px',
                width: '175px',
                height: '70px',
                // borderRadius: 2,
                marginTop: '30px',
                backgroundImage: `url(${ImgNKFAZ})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
            />
            <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '135px',
                width: '135px',
                height: '80px',
                // borderRadius: 2,
                marginTop: '30px',
                backgroundImage: `url(${ImgBHHSLF})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
            /></Stack>
            <Stack display={"block"}>
            <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '145px',
                width: '145px',
                height: '70px',
                // borderRadius: 2,
                marginTop: '30px',
                backgroundImage: `url(${ImgCR})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
            />
            <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '135px',
                width: '135px',
                height: '80px',
                // borderRadius: 2,
                marginTop: '30px',
                backgroundImage: `url(${ImgAdelante})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
            /></Stack>
            <Box>
            <Typography paragraph>
            The Path to Wellness program was co-sponsored by <Link href="https://azkidney.org/" target={"_blank"} underline="hover"> 
            National Kidney Foundation of Arizona</Link>, <Link href="https://cardiorenalsociety.org/" target={"_blank"} underline="hover">
            Cardio renal Society of America</Link>, <Link href="https://bhhslegacy.org/" target={"_blank"} underline="hover">
            BHHS Legacy Foundation</Link>, <Link href="https://www.honorhealth.com/" target={"_blank"} underline="hover">
            John C. Lincoln Gives</Link> (currently known as Honor Health), 
            <Link href="https://adelantehealthcare.com/" target={"_blank"} underline="hover"> Adelante Healhcare</Link> and Arizona Living Well Institute.</Typography>
            </Box>
        </Stack>
        </Alert>
        
        </Grid>
      </PageContentV2>
    </>
  );
};

export default template;
