import React from 'react';
import { Box, Typography} from '@mui/material';


const template = () => {
    return (
      <>
        <Box 
        sx={{ 
            display:'flex', 
            textAlign: "left", 
            alignItems: "center", 
            justifyContent: 'center',
            padding:{xs:"20px",md:"50px 0 30px 0"}
            }}>
          <Typography variant="h7" sx={{lineHeight:'40px'}}>
          CliniOps offers a configurable and scalable Unified Platform for Reimagined 
          Clinical Trials. It is a comprehensive solution to also support Hybrid and Decentralized Clinical Trials 
          (Hybrid DCT) powered by real-time interactions such as Integrated Telemedicine.
          </Typography>
        </Box>
      </>
    );
  }
  
  export default template;