import React from 'react';
import { Grid, Typography, Stack } from '@mui/material';

import { PageSectionRightImg } from '../../../component/page';
import { Product } from '../../../component/page';

import ImgASR from "../../../../img/product/insights/advance_statistical.png";
import CustomTypography from '../../../component/customTypography';

const template = () => {
  return (
    <>
    <Grid container direction='column' spacing={0} sx={{ mt: 0 }}>
         
        <Grid item>
            <Product
              ImgSrc={ImgASR}
              Name="Advanced Statistical Reporting"
              Summary={
                <>
                <CustomTypography variant='body2' sx={{ mt: 1}}>
                Advanced statistical reporting in clinical trials provides in-depth analysis of trial data, enabling researchers to 
                draw meaningful conclusions about a treatment's efficacy and safety. 
                <br></br><br></br>
                Using sophisticated statistical methods, these reports offer insights into trends, variability, and correlations that 
                may not be immediately apparent. They support data-driven decision-making, ensuring clinical outcomes are 
                interpreted precisely and clearly.
              </CustomTypography>
              </>
              }
            />
            
        </Grid>
    </Grid>
    </>
  );
}

export default template;