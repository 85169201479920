import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { teal } from "@mui/material/colors";

import { PageHero, PageContent } from "../../component/page";

import ImgMissionBG from "../../../img/about/mission/missionHero.png";
import ImgTopWave from "../../../img/home/wave1.png";
import ImgBottomWave from "../../../img/home/wave2.png";
import ImgDoctorPatient from "../../../img/about/mission/Doctor_and_patient.png";

const template = () => {
  return (
    <>
      {/* <PageHero color={teal}>
        <Typography variant="h1" component="h1">
          Our Mission
        </Typography>
      </PageHero> */}
      <Box>
      <Box sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding:{xs:"70px 0 0 0",md:"90px 0 0 0"}, 
      overflow: "hidden",

      }}>
        <img
          src={`${ImgMissionBG}`}
          alt="Mission"
          style={{ 
            maxWidth: "100%", 
            maxHeight: "100%", 
            objectFit: "contain" }}
        />
      </Box>
      <Box sx={{
          position: 'absolute',
          top: { xs: '80px', sm: '75px', md: '100px' },
          left: { xs: '25px', sm: '25px', md: '55px' },
          right: 0,
          bottom: 0,
          display: 'flex',
          color: '#FFFFFF',
          textAlign: 'topleft',
          fontSize: { xs: '17px', sm: '40px', md: '60px' }, // Responsive font size
          fontWeight: 'light',
          textShadow: '3px 3px 3px rgba(0, 0, 0, 0.16)'
          // fontFamily: 'Segoe UI',
        }}>
      Mission 
      </Box>
      </Box>

      {/* <PageContent> */}
      <Box
        sx={{
          position: "relative",
          width: "100%",
          // backgroundColor: "#F2F2F2",
          overflow: "visible",
          margin: "50px 0 100px 0",
          // textAlign: "center",
        }}
      >
        {/* Top Wave Background */}
        <Box
          component="img"
          src={ImgTopWave}
          alt="Top Wave"
          sx={{
            position: "absolute",
            top: 0,
            width: "100%",
            height: "auto",
            zIndex: 1,
            objectFit: "contain",
          }}
        />

        {/* Bottom Wave Background */}
        <Box
          component="img"
          src={ImgBottomWave}
          alt="Bottom Wave"
          sx={{
            position: "absolute",
            bottom:-100,
            width: "100%",
            height: "auto",
            zIndex: 3,
            objectFit: "contain",
          }}
        />

        {/* Center Content */}
        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* Doctor and Patient Image */}
          <Box
            component="img"
            src={ImgDoctorPatient}
            alt="Doctor with Patient"
            sx={{
              width: "300px",
              height: "auto",
              objectFit: "contain",
              zIndex: 2,
            }}
          />

          {/* Mission, Vision, Core Values */}
          <Grid
            container
            spacing={1}
            sx={{
              maxWidth: "100%",
              margin: "auto",
              display: "flex",
              backgroundColor: "#F2F2F2",
              justifyContent: "center",
              gap:{xs:0,md:3},
              alignItems: "flex-start",
              zIndex:2,
              minHeight:"500px",
              padding: { xs: "32px", md: "20px" },
            }}
          >
            {/* Mission */}
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                padding:0,
                margin:0
                // paddingX: "16px",
              }}
            >
              <Typography
                variant="h1Teal"
                sx={{
                  lineHeight: {xs:'30px',md:"79.8px"},
                  textShadow: "0px 3px 3px rgba(0, 0, 0, 0.1608)",
                  marginBottom: {xs:"5px",md:"8px"},
                }}
              >
                Mission
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  textAlign: "left",
                }}
              >
                To reimagine clinical research by providing innovative data and
                technology solutions, across the clinical trial value chain.
              </Typography>
            </Grid>

            {/* Divider */}
            <Box
              sx={{
                display: { xs: "none", md: "block" },
                width: "1px",
                height:'250px',
                // margin: "auto", // Center vertically
                backgroundColor: "#CED2D4",
              }}
            />

            {/* Vision */}
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                // paddingX: "16px",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "300",
                  fontSize: {xs:"25px",md:"42px"},
                  lineHeight: {xs:'30px',md:"79.8px"},
                  color: "#07A6F8",
                  textShadow: "0px 3px 3px rgba(0, 0, 0, 0.1608)",
                  marginBottom: {xs:"5px",md:"8px"},
                }}
              >
                Vision
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  textAlign: "left",
                }}
              >
                To be the trusted partner in clinical research and development,
                driving faster, more accessible, and inclusive advancements that
                optimize cost, promote health equity, and improve global health
                outcomes.
              </Typography>
            </Grid>

            {/* Divider */}
            <Box
              sx={{
                display: { xs: "none", md: "block" },
                width: "1px",
                height: "250px",
                backgroundColor: "#CED2D4",
              }}
            />

            {/* Core Values */}
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                // paddingX: "16px",
              }}
            >
              <Typography
                variant="h1Teal"
                sx={{
                  lineHeight: {xs:'30px',md:"79.8px"},
                  textShadow: "0px 3px 3px rgba(0, 0, 0, 0.1608)",
                  marginBottom: {xs:"5px",md:"8px"},
                }}
              >
                Core Values
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  textAlign: "left",
                }}
              >
                Inspirational Excellence with Integrity, Commitment, and
                Compassion.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {/* </PageContent> */}
    </>
  );
};

export default template;
