import React from "react";
import { HashLink as RouterLink } from "react-router-hash-link";
import RouterList from "../../../router";
import {
  Box,
  Grid,
  Typography,
  Divider,
  Button,
  useTheme,
  Link,
} from "@mui/material";
import locationIcon from "../../../../img/about/careers/job_location_img.png";

const content = [
  {
    title: "Project Manager",
    location: "Fremont, CA, USA\nKolkata, India",
    description: `
      We seek an experienced project manager with experience in Information technology 
      and Data Analytics, to join our team in India and support projects globally.
    `,
    link: RouterList.about.career_1,
  },
  {
    title: "Clinical Data Manager",
    location: "Fremont, CA, USA\nKolkata, India",
    description: `
     If you have worked with several cross-functional teams, across geographic locations 
     to manage clinical data, here is your next challenge.
    `,
    link: RouterList.about.career_6,
  },
  {
    title: "Sales Executive",
    location: "Fremont, CA, USA\nKolkata, India",
    description: `
      We seek an experienced Sales and Business Development executive to lead our 
      sales team, and be responsible for the direct sales of CliniOps’s software products 
      and services in the Life Science market. The ideal candidate will be energetic and 
      driven, with an established track record selling enterprise software products and 
      hosted SaaS products, to top tier Pharmaceutical, Biotechnology, Medical Device, 
      and CRO companies. This position will require self-motivation, the ability to 
      communicate at all levels and a good understanding of the CliniOps software solutions.
    `,
    link: RouterList.about.career_3,
  },
  {
    title: "Biostatistician ",
    location: "Fremont, CA, USA\nKolkata, India",
    description: `
     We seek a motivated Senior Biostatistician to join our team and provide statistical 
    support for all phases of clinical development. Responsibilities include reviewing 
    statistical sections of protocols, writing statistical analysis plans (SAP), developing 
    SAS programs, interacting with clients, and participating in FDA meetings as needed.
    `,
    link: RouterList.about.career_2,
  },
  {
    title: "Software Engineer, Full stack",
    location: "Fremont, CA, USA\nKolkata, India",
    description: `
     If you want to do cool things that matter, if you are willing to change the status quo, 
    if you are the one who knows what is optimal and best web, cloud and database 
    technology to solve a specific problem, we may have a challenging position for you.
    `,
    link: RouterList.about.career_4,
  },
  {
    title: "Software Engineer, Mobile Dev.",
    location: "Fremont, CA, USA\nKolkata, India",
    description: `
    If you live and breathe mobile technologies, want to work on cutting-edge iOS and 
    Android app development, and make a paradigm shift in an industry that has limited 
    exposure to mobile technologies so far, we may have a challenging position for you.
    `,
    link: RouterList.about.career_5,
  },
  {
    title: "Manager, Quality Control",
    location: "Fremont, CA, USA\nKolkata, India",
    description: `
   If you have worked with several cross-functional teams across geographic locations 
    to develop a quality management system, information security, and overall quality 
    control, and you are attentive to details - we may have a perfect position for you.
    `,
    link: RouterList.about.career_7,
  },
  {
    title: "Senior Software Engineer",
    location: "Fremont, CA, USA\nKolkata, India",
    description: `
   If you want to do cool things that matter, if you are willing to change the status quo, 
    and if you know what is the optimal and best technology to solve a specific problem, 
    we may have a challenging position for you.
    `,
    link: RouterList.about.career_8,
  },
  // ... Add more jobs as needed
];

const template = () => {
  return (
    <Box sx={{ p: 4, position: "relative", zIndex: 5 }}>
      <Grid container spacing={3} alignItems="stretch">
        {content.map((job, index) => (
          <Grid item xs={12} md={6} key={index} sx={{ display: "flex" }}>
            {/* Card Container */}
            <Box
              sx={{
                flex: 1, // Fill up parent
                borderRadius: 2,
                boxShadow: "10px 10px 8px rgba(0, 0, 0, 0.2)",
                backgroundColor: "#fff",
                border: "1px solid #CED2D4",
                p: 3,
              }}
            >
              {/* Top Row */}
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12} md={8}>
                  <Typography
                    variant="h7"
                  >
                    {job.title}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: { xs: "flex-start", md: "flex-end" },
                      alignItems: "center",
                      textAlign: "right",
                      gap: 2,
                    }}
                  >
                    <Box
                      component="img"
                      src={locationIcon}
                      sx={{
                        maxWidth: { xs: "8%", md: "15%" },
                      }}
                    />
                    {/* For multiple lines: location can be splitted by \n */}
                    <Typography
                      variant="body2"
                      whiteSpace="pre-line"
                      textAlign="left"
                      sx={{
                        lineHeight: "24px",
                      }}
                    >
                      {job.location}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              {/* Divider */}
              <Divider sx={{ my: 2 }} />

              {/* Bottom Section */}
              <Typography
                variant="body2"
                sx={{
                  mb: 2,
                  lineHeight: "24px",
                }}
              >
                {job.description}
              </Typography>
              <Link
              href={job?.link || "#"}
              sx={{ fontSize: "14px", fonWeight: "400" }}
              underline="hover"
              color="#1E75C6"
              >
                Know More...
              </Link>
              {/* <Button
                component={RouterLink}
                to={job?.link || "#"}
                sx={{
                  backgroundColor: "#042D43",
                  color: "#FFFFFF",
                  fontWeight: 400,
                  fontSize: "14px",
                  textTransform: "capitalize",
                  borderRadius: "12px",
                  px: 2,
                  "&:hover": {
                    backgroundColor: "#042D43",
                    boxShadow: "none",
                  },
                }}
              >
                Know More...
              </Button> */}
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default template;

// import React from 'react';
// import { HashLink as RouterLink } from 'react-router-hash-link';
// import { Typography, Link, Grid, Card, CardHeader, CardActions, Button, alpha } from '@mui/material';
// import { blue, blueGrey, grey } from '@mui/material/colors';

// import RouterList from '../../../router';

// const template = () => {
//   return (
//     <>
//       <Grid container spacing={6} sx={{ mt:4 }}>
//         <Grid item xs={6} md={4}>
//           <Card variant="outlined" sx={{ border: 0, backgroundColor: alpha(blueGrey[50], .75), height: '100%' }}>
//             <CardHeader
//               title="Project Manager"
//               subheader={<Typography sx={{ color: 'black' }} variant="body2">Fremont, CA or Kolkata, India</Typography>}
//             />
//             <CardActions disableSpacing>
//               <Button to={RouterList.about.career_1} component={RouterLink}>View Details</Button>
//             </CardActions>
//           </Card>
//         </Grid>
//         <Grid item xs={6} md={4}>
//           <Card variant="outlined" sx={{ border: 0, backgroundColor: alpha(blueGrey[50], .75), height: '100%' }}>
//             <CardHeader
//               title="Biostatistician"
//               subheader={<Typography sx={{ color: 'black' }} variant="body2">Fremont, CA or Kolkata, India</Typography>}
//             />
//             <CardActions disableSpacing>
//               <Button to={RouterList.about.career_2} component={RouterLink}>View Details</Button>
//             </CardActions>
//           </Card>
//         </Grid>
//         <Grid item xs={6} md={4}>
//           <Card variant="outlined" sx={{ border: 0, backgroundColor: alpha(blueGrey[50], .75), height: '100%' }}>
//             <CardHeader
//               title="Sales Executive"
//               subheader={<Typography sx={{ color: 'black' }} variant="body2">Fremont, CA or Kolkata, India</Typography>}
//             />
//             <CardActions disableSpacing>
//               <Button to={RouterList.about.career_3} component={RouterLink}>View Details</Button>
//             </CardActions>
//           </Card>
//         </Grid>
//         <Grid item xs={6} md={4}>
//           <Card variant="outlined" sx={{ border: 0, backgroundColor: alpha(blueGrey[50], .75), height: '100%' }}>
//             <CardHeader
//               title="Software Engineer, Full Stack"
//               subheader={<Typography sx={{ color: 'black' }} variant="body2">Fremont, CA or Kolkata, India</Typography>}
//             />
//             <CardActions disableSpacing>
//               <Button to={RouterList.about.career_4} component={RouterLink}>View Details</Button>
//             </CardActions>
//           </Card>
//         </Grid>
//         <Grid item xs={6} md={4}>
//           <Card variant="outlined" sx={{ border: 0, backgroundColor: alpha(blueGrey[50], .75), height: '100%' }}>
//             <CardHeader
//               title="Software Engineer, Mobile Development"
//               subheader={<Typography sx={{ color: 'black' }} variant="body2">Fremont, CA or Kolkata, India</Typography>}
//             />
//             <CardActions disableSpacing>
//               <Button to={RouterList.about.career_5} component={RouterLink}>View Details</Button>
//             </CardActions>
//           </Card>
//         </Grid>
//         <Grid item xs={6} md={4}>
//           <Card variant="outlined" sx={{ border: 0, backgroundColor: alpha(blueGrey[50], .75), height: '100%' }}>
//             <CardHeader
//               title="Clinical Data Management"
//               subheader={<Typography sx={{ color: 'black' }} variant="body2">Fremont, CA or Kolkata, India</Typography>}
//             />
//             <CardActions disableSpacing>
//               <Button to={RouterList.about.career_6} component={RouterLink}>View Details</Button>
//             </CardActions>
//           </Card>
//         </Grid>
//         <Grid item xs={6} md={4}>
//           <Card variant="outlined" sx={{ border: 0, backgroundColor: alpha(blueGrey[50], .75), height: '100%' }}>
//             <CardHeader
//               title="Manager, Quality Control"
//               subheader={<Typography sx={{ color: 'black' }} variant="body2">Fremont, CA or Kolkata, India</Typography>}
//             />
//             <CardActions disableSpacing>
//               <Button to={RouterList.about.career_7} component={RouterLink}>View Details</Button>
//             </CardActions>
//           </Card>
//         </Grid>
//         <Grid item xs={6} md={4}>
//           <Card variant="outlined" sx={{ border: 0, backgroundColor: alpha(blueGrey[50], .75), height: '100%' }}>
//             <CardHeader
//               title="Senior Software Engineer"
//               subheader={<Typography sx={{ color: 'black' }} variant="body2">Fremont, CA or Kolkata, India</Typography>}
//             />
//             <CardActions disableSpacing>
//               <Button to={RouterList.about.career_8} component={RouterLink}>View Details</Button>
//             </CardActions>
//           </Card>
//         </Grid>
//       </Grid>
//     </>
//   );
// }

// export default template;
