import React from 'react';
import { Grid, Typography, Stack } from '@mui/material';

import { PageSectionRightImg } from '../../../component/page';
import { Product } from '../../../component/page';

import ImgDOR from "../../../../img/product/insights/data_operations.png";
import CustomTypography from '../../../component/customTypography';

const template = () => {
  return (
    <>
    <Grid container direction='column' spacing={0} sx={{ mt: 0 }}>
         
        <Grid item>
            <Product
              ImgSrc={ImgDOR}
              Name="Data Operations Reporting"
              Summary={
                <>
                <CustomTypography variant='body2' sx={{ mt: 1}}>
                Data management operations reporting in clinical trials provides comprehensive oversight of all data-related 
                activities, from collection to final analysis. 
                <br></br><br></br>
                These reports track data entry, query resolution, data cleaning, and validation processes, ensuring that all 
                information is accurate and compliant with regulatory standards. Regular reporting helps identify bottlenecks, 
                optimize workflows, and meet timelines. It also facilitates transparent communication between stakeholders, 
                keeping sponsors and investigators informed of the trial’s progress.
              </CustomTypography>
              </>
              }
            />
            
        </Grid>
    </Grid>
    </>
  );
}

export default template;