import React from 'react';
import { Grid, Divider } from '@mui/material';
import { teal } from '@mui/material/colors';

import { PageHero, PageContent } from '../../component/page';

// Page Imports
import _0 from './_0/_0';
import _1 from './_0/_1';
import _2 from './_0/_2';
import _3 from './_0/_3';
import _4 from './_0/_4';
import _5 from './_0/_5';
import _5_1 from './_0/_5.1';
import _5_2 from './_0/_5.2';
import _6 from './_0/_6';
import _7 from './_0/_7';

const template = () => {
  return (
    <>
      {/* <PageHero color={teal}> */}
        <_0 />
      {/* </PageHero> */}
      <Grid container justifyContent='center' alignItems='center'>
        <Grid item xs={12} md={9}>
        <_6 />
        </Grid>
      </Grid>
      {/* <PageContent> */}
        <Grid container >
          <Grid item xs={12} md={12}>
          <_3 />
          </Grid>
        </Grid>
       <Grid item>
          <_2 />
        </Grid>
        {/* <Grid item>
          <_1 />
        </Grid> */}
        {/* <Grid item >
          <Divider className="co-divider" />
        </Grid> */}
        {/* </PageContent> */}
        {/* <Grid item>
          <_4 />
        </Grid> */}
        {/* <Grid item >
          <Divider className="co-divider" />
        </Grid> */}
        <Grid item>
          <_5 />
        </Grid>
        <Grid item>
          <_5_1 />
        </Grid>
        <PageContent>
        <Grid item>
          <_5_2 />
        </Grid>
        <Grid item>
          <_7/>
        </Grid>
        </PageContent>
    </>
  );
}

export default template;