import React from 'react';
import { Grid, Typography, Stack } from '@mui/material';

// import { PageSectionLeftImg } from '../../../component/page';
import { Product } from '../../../component/page';

// import ImgEhrIntegration from '../../../../img/product/edge/ehr-integration.png';
import ImgVisit from '../../../../img/product/edge/VisitCalendar.png';
import CustomTypography from '../../../component/customTypography';

const template = () => {
  return (
    <>
      {/* <PageSectionLeftImg
        heading={
          <Stack direction="row" spacing={1} alignItems='center' justifyContent={{xs: 'center', md: 'flex-start'}}>
            <Typography variant="h2" component="h2" className="co-page-heading" sx={{ textAlign: { xs: 'center', md: 'left'} }}>
              EHR Integration
            </Typography>
          </Stack>
        }
        imgUrl={ImgEhrIntegration}
        imgTitle="EHR Integration"
      >
        <Typography sx={{ mt: 1 }}>
          CliniOps offers standard APIs to integrate with 3rd party applications. Electronic Health Records have remained isolated Islands for years. Now, with CliniOps and its partner ecosystem, you have access to over 450 sites, available with integrated EHR to EDC data to get your clinical trials started rapidly. SMART on FHIR standards for data transfer GCP-compliant process for controlling the data. CliniOps is agnostic to the Data Source and makes standardized data available from over 55 EHR systems.
        </Typography>
      </PageSectionLeftImg> */}

      <Grid container direction='column' spacing={0} sx={{ mt: 0 }}>
            {/* Visit Calendar  */}
          <Grid item>
              <Product
                ImgSrc={ImgVisit}
                Name="Visit Calendar"
                Summary={
                  <>
                  <CustomTypography variant='body2' sx={{ mt: 1 }}>
                    CliniOps Edge automatically generates the visit calendar, a crucial tool for scheduling and managing patient 
                    appointments throughout the study. It outlines all required visits, including screening, treatment, follow-up, and 
                    other protocol-specific appointments. 
                    <br></br><br></br>
                    This calendar ensures that patients adhere to the study timeline, reducing the risk of missed visits and delays in 
                    data collection. The visit calendar can automatically trigger reminders for patients and clinical staff, helping maintain 
                    consistent trial progress. Additionally, it enhances site coordination, ensuring investigators and research teams stay 
                    on track and comply with protocol adherence with regulatory and procedural requirements.
                </CustomTypography>
                </>
                }
              />
              
          </Grid>
      </Grid>
    </>
  );
}

export default template;