import React, { useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";

const PageHero = (props) => {
    const [color] = useState(props.color);
    console.log(color)
    return (
        <>
            <Box
                sx={{ pb: {xs: 5, md: 10}, pt: {xs:`calc(72px + 40px)`, md:`calc(72px + 80px)`} }}
                style={{
                    backgroundColor:color,
                    borderBottom: '8px solid',
                    borderImageSlice: 1,
                    borderWidth: '8px',
                    borderBlockEndColor:color
                }}>
                <Container>
                    {props.children}
                </Container>
            </Box>
        </>
    )
};

const PageContent = (props) => {
    const [color] = useState(props.color);
    
    return (
        <>
            <Container maxWidth="xl">
                <Grid container spacing={{xs:5, md:10}} direction='column' sx={{ py: 5}}>
                    {props.children}
                </Grid>
            </Container>
        </>
    )
};

const PageContentV2 = (props) => {
  const [color] = useState(props.color);
  
  return (
      <>
          <Container maxWidth="lg">
              <Grid container spacing={{xs:5, md:10}} direction='column' sx={{ py: 5}}>
                  {props.children}
              </Grid>
          </Container>
      </>
  )
};

const PageSectionLeftImg = (props) => {
  const heading = useState(props.heading);
  const [imgUrl] = useState(props.imgUrl);
  const [imgTitle] = useState(props.imgTitle);

  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={6} sx={{ display: { xs: 'none', md: 'block' } }}>
          <img
            src={imgUrl}
            className='co-image'
            alt={imgTitle}
            title={imgTitle}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {heading}
          <Box sx={{ mt: 1, textAlign: {xs: 'center', md: 'left'} }}>
            {props.children}
          </Box>
          <Box sx={{ display: {sx: 'block', md: 'none'}, mt: 2, textAlign: 'center' }}>
            <img
              src={imgUrl}
              className='co-image'
              alt={imgTitle}
              title={imgTitle}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  )
};

const PageSectionRightImg = (props) => {
  const heading = useState(props.heading);
  const [imgUrl] = useState(props.imgUrl);
  const imgTitle = useState(props.imgTitle);

  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          {heading}
          <Box sx={{ mt: 1, textAlign: {xs: 'center', md: 'left'} }}>
            {props.children}
          </Box>
          <Box sx={{ display: {sx: 'block', md: 'none'}, mt: 2, textAlign: 'center' }}>
            <img
              src={imgUrl}
              className='co-image'
              alt={imgTitle}
              title={imgTitle}
            />
          </Box>
        </Grid>
        <Grid item xs={6} sx={{ display: { xs: 'none', md: 'block' } }}>
          <img
            src={imgUrl}
            className='co-image'
            alt={imgTitle}
            title={imgTitle}
          />
        </Grid>
      </Grid>
    </>
  )
};

const Product = (props) => {
  const Name = useState(props.Name);
  const [ImgSrc] = useState(props.ImgSrc);
  const Summary = useState(props.Summary);

    return (
        <Box
            sx={{
                backgroundColor: 'white',
                borderRadius: '16px',
                padding: '24px',
                borderColor:'#CED2D4',
                borderWidth: '1px',
                borderStyle: 'solid',
                boxShadow: "10px 10px 8px rgba(0, 0, 0, 0.2)",
                marginBottom: '8px', 
                position: 'relative', 
            }}
        >
            <Grid container direction="row" spacing={{ xs: 2, md: 6 }} sx={{ alignItems: 'center' }}>
                <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: { xs: 'flex-start', md: 'flex-start' },alignItems:"center" }}>
                    <img
                        src={ImgSrc}
                        className="co-image-connect"
                        alt={Name}
                        title={Name}
                    />
                </Grid>
                <Grid item xs={12} md={8} sx={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                    <Typography variant="h2" className="co-page-heading">
                        {Name}
                    </Typography>
                    <Typography sx={{ mt: 0 }}>
                        {Summary}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

const Conduct = (props) => {
  const Name = useState(props.Name);
  const [ImgSrc] = useState(props.ImgSrc);
  const Summary = useState(props.Summary);

    return (
        <Box
            sx={{
                backgroundColor: 'white',
                borderRadius: '16px',
                padding: '24px',
                borderColor:'#CED2D4',
                borderWidth: '1px',
                borderStyle: 'solid',
                boxShadow: "10px 10px 8px rgba(0, 0, 0, 0.2)",
                marginBottom: '8px',
                position: 'relative', 
            }}
        >
            <Grid container direction="row" spacing={{ xs: 2, md: 6 }} sx={{ alignItems: 'stretch' }}>
                <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: { xs: 'flex-start', md: 'flex-start' },alignItems:"center" }}>
                    <img
                        src={ImgSrc}
                        className="co-image-conduct"
                        alt={Name}
                        title={Name}
                        // style={{ maxHeight: '' }}
                    />
                </Grid>
                <Grid item xs={12} md={9} sx={{display:"flex",alignItems:"flex-start",flexDirection:"column",justifyContent:'center'}}>
                    <Typography variant="subtitle1" className="co-page-heading">
                        {Name}
                    </Typography>
                    <Typography sx={{ mt: 0 }}>
                        {Summary}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

const UniqueVal = (props) => {
  const [ImgSrc] = useState(props.ImgSrc);
  const [Summary] = useState(props.Summary);

  return (
    <Box
      sx={{
        backgroundColor: "white",
        borderRadius: "16px",
        padding: "6px",
        borderColor: "#07A7F9",
        borderWidth: "1px",
        borderStyle: "solid",
        boxShadow: "10px 10px 8px rgba(0, 0, 0, 0.2)",
        marginBottom: {xs: "4px", md: "16px"},
        display: "flex",
        position: "relative",
        flexDirection: "row",
        alignItems: "center",
        maxWidth: "50%"
      }}
    >
      <Grid container direction={{ xs: 'column', md: 'row' }} spacing={12} alignItems="center" justifyContent="left" >
        <Grid item xs={12} md={12} sx={{
          display: "flex",
          justifyContent: { xs: "center", md: "flex-start" },
          alignItems: "center",
        }}>
          <img
            src={ImgSrc}
            alt="Unique Value Icon"
            style={{
              width: window.innerWidth >= 768 ? "50px" : "20px",
              height: "auto",
              objectFit: "cover",
            }}
          />
          <Typography>
            {Summary}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};


export { PageHero, PageContent,PageContentV2, PageSectionLeftImg, PageSectionRightImg, Product, Conduct, UniqueVal};