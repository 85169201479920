import React from 'react';
import { Grid, Typography, Stack } from '@mui/material';

// import { PageSectionLeftImg } from '../../../component/page';
import { Product } from '../../../component/page';

// import ImgESource from '../../../../img/product/edge/esource.png';
import ImgOffeSource from '../../../../img/product/edge/DDC.png';
import CustomTypography from '../../../component/customTypography';

const template = () => {
  return (
    <>
      {/* <PageSectionLeftImg
        heading={
          <Stack direction="row" spacing={1} alignItems='center' justifyContent={{xs: 'center', md: 'flex-start'}}>
            <Typography variant="h2" component="h2" className="co-page-heading" sx={{ textAlign: { xs: 'center', md: 'left'} }}>
              eSource
            </Typography>
          </Stack>
        }
        imgUrl={ImgESource}
        imgTitle="eSource"
      >
        <Typography sx={{ mt: 1 }}>
          CliniOps eSource solution is a mobile, cloud-based digital solution where data is collected at sites, via mobile tablets, with complete offline functionality. Powerful edit checks within the eSource solution ensures that data collected at the 'point of care' is of the highest quality. Data is then automatically uploaded in near real-time to the central data hub, once network connection is available. CliniOps eSource solution has been designed as per FDA guidelines and the data collected on the mobile device is considered 'source data' (aka eSource), that eliminates the need for a separate paper record. Data can then be accessed and analyzed by the different modules of the CliniOps Suite.
        </Typography>
      </PageSectionLeftImg> */}
      
      <Grid container direction='column' spacing={0} sx={{ mt: 0 }}>
            {/* Offline eSource / DDC  */}
          <Grid item>
              <Product
                ImgSrc={ImgOffeSource}
                Name="Offline eSource / DDC"
                Summary={
                  <>
                  <CustomTypography variant='body2' sx={{ mt: 1 }}>
                    CliniOps eSource or DDC (Direct Data Capture) solution is a mobile, cloud-based digital solution that collects data 
                    at sites via mobile tablets with complete offline functionality. Powerful edit checks within the eSource solution 
                    ensure that data collected at the 'point of care' is of the highest quality. 
                    <br></br><br></br>
                    Once the network connection is available, data is automatically uploaded to the central data hub in near real-time.  
                    The CliniOps eSource solution has been designed per FDA guidelines. The data collected on the mobile device is 
                    considered 'source data' (aka eSource), eliminating the need for a separate paper record. 
                    <br></br><br></br>
                    The different modules of the CliniOps Suite can then access and analyze the data.
                </CustomTypography>
                </>
                }
              />
              
          </Grid>
      </Grid>
    </>
  );
}

export default template;