import React from 'react';
import { Typography } from '@mui/material';
import { blueGrey } from '@mui/material/colors';

const template = () => {
  return (
    <>
      <Typography variant="h9" sx={{paddingBottom:"15px"}}  component="h1">
      Clinical performance of a smartphone-enabled home based urine testing platform compared to lab based standard of care testing
      </Typography>
     
      <a href="https://healthy.io/" target={'_blank'} class="linkcolor">inui Health (formerly Scanadu)</a>
      
    </>
  );
}

export default template;