import React from 'react';
import { HashLink as RouterLink } from 'react-router-hash-link';
import { Grid, Typography, Stack } from '@mui/material';

import { PageSectionLeftImg } from '../../../component/page';
import { Product } from '../../../component/page';

import RouterList from '../../../router';

import ImgEcoa from '../../../../img/product/connect/ecoa.png';
import ImgePRO from '../../../../img/product/connect/ePRO_eDiary.png';
import CustomTypography from '../../../component/customTypography';

const template = () => {
  return (
    <>
    <Grid container direction='column' spacing={0} sx={{ mt: 0 }}>
          {/* ePRO & eDiary  */}
        <Grid item>
            <Product
              ImgSrc={ImgePRO}
              Name="ePRO & eDiary"
              Summary={
                <>
                <CustomTypography variant='body2' sx={{ mt: 1 }}>
                CliniOps ePRO (Electronic Patient Reported Outcome) module can be designed and configured in the device 
                or medium that is easiest for the patient to use. The platform allows for seamless site-patient interactions with 
                various patient engagement features. 
                <br></br><br></br>
                This module has been proven to increase both patient retention and protocol adherence. 
                Patient data can be collected on a provisioned device or in a BYOD (Bring Your Own Device) model. 
                It supports automated alerts and notifications to patients and sites via SMS, email, or push notifications.
              </CustomTypography>
              </>
              }
            />
            
        </Grid>
    </Grid>
    </>
  );
}

export default template;