import React from 'react';
import { HashLink as RouterLink } from 'react-router-hash-link';
import { Grid, Typography, Box, Button, Link } from '@mui/material';

import { Product } from '../../../component/page';

import RouterList from '../../../router';

import ImgSupport from '../../../../img/service/_0/support-helpdesk.png';
import CustomTypography from '../../../component/customTypography';

const template = () => {
  return (
    <>
      <Grid container direction='column' spacing={0} sx={{ mt: 0 }}>
            {/* Support &amp; Helpdesks  */}
          <Grid item>
              <Product
                ImgSrc={ImgSupport}
                Name="Support &amp; Helpdesk"
                Summary={
                  <>
                  <CustomTypography variant='body2' sx={{ mt: 1 }}>
                    These services contribute to overall trial efficiency and customer satisfaction, by providing project governance, 
                    timely resolutions, handling escalations, flagging risks, providing support and tracking deliverables.
                  </CustomTypography>
                <Box sx={{ mt: 2 }}>
                   <Link
                   href={RouterList.service.supporthelpdesk}
                   target="_blank"
                   sx={{ fontSize: "14px", fonWeight: "400" }}
                   underline="hover"
                   color="#1E75C6"
                   >
                  Know More...
                  </Link>
                </Box>
                </>
                }
              />
              
          </Grid>
      </Grid>
    </>
  );
}

export default template;


//Commenting previous code

// import React from 'react';
// import { HashLink as RouterLink } from 'react-router-hash-link';
// import { Grid, Box, Typography, Button, Stack } from '@mui/material';

// import { PageSectionLeftImg } from '../../../component/page';

// import RouterList from '../../../router';

// // import ImgBiostatistics from '../../../../img/service/_0/biostatistics.jpeg';
// import ImgSupport from '../../../../img/service/_0/support-helpdesk.png';

// const template = () => {
//   return (
//     <>
//       <PageSectionLeftImg
//         heading={
//           <Stack direction="row" spacing={1} alignItems='center' justifyContent={{xs: 'center', md: 'flex-start'}}>
//             <Typography variant="h2" component="h2" className="co-page-heading" sx={{ textAlign: {xs: 'center', md: 'left'} }}>
//               {/* Biostatistics &amp; Statistical Programming */}
//               Support &amp; Helpdesk
//             </Typography>
//           </Stack>
//         }
//         imgUrl={ImgSupport}
//         // imgTitle="Biostatistics &amp; Statistical Programming"
//         imgTitle="Support &amp; Helpdesk"
//       >
//         <Typography sx={{ mt: 1 }}>
//           These services contribute to overall trial efficiency and customer satisfaction, by providing project governance, 
//           timely resolutions, handling escalations, flagging risks, providing support and tracking deliverables.
//         </Typography>
//         <Box sx={{ mt: 2 }}>
//           {/* <Button variant="text" to={RouterList.service.biostatisticprogramming} component={RouterLink} sx={{ ml: -1 }}>Read More</Button> */}
//           <Button variant="text" to={RouterList.service.supporthelpdesk} component={RouterLink} sx={{ ml: -1 }}>Read More</Button>
//         </Box>
//       </PageSectionLeftImg>
//     </>
//   );
// }

// export default template;