import React from 'react';
import { Grid,Box,Typography } from '@mui/material';

import ImgStudySetup from '../../../../img/service/study-setup/study-setup.png';

const template = () => {
  return (
    <>
    <Box>
    <Box sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding:{xs:"70px 0 0 0",md:"90px 0 0 0"}, 
      overflow: "hidden",

      }}>
        <img
          src={`${ImgStudySetup}`}
          alt="Study Setup"
          style={{ 
            maxWidth: "100%", 
            maxHeight: "100%", 
            objectFit: "contain" }}
        />
      </Box>
      <Box sx={{
          position: 'absolute',
          top: { xs: '80px', sm: '75px', md: '100px' },
          left: { xs: '25px', sm: '25px', md: '55px' },
          right: 0,
          bottom: 0,
          display: 'flex',
          color: '#FFFFFF',
          textAlign: 'topleft',
          fontSize: { xs: '17px', sm: '40px', md: '60px' }, // Responsive font size
          fontWeight: 'light',
          textShadow: '3px 3px 3px rgba(0, 0, 0, 0.16)'
          // fontFamily: 'Segoe UI',
        }}>
      Study Setup 
      </Box>
      </Box>

    </>
  );
}

export default template;