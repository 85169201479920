import React from 'react';
import { Grid, Typography } from '@mui/material';

import { Product } from '../../../component/page';

import ImgCDS1 from '../../../../img/service/clinical-data-management/CDS_other.png';
import CustomTypography from '../../../component/customTypography';

const template = () => {
  return (
    <>
      <Grid container direction='column' spacing={0} sx={{ mt: 0 }}>
            {/*   */}
          <Grid item>
              <Product
                ImgSrc={ImgCDS1}
                Name="Database Lock"
                Summary={
                  <>
                  <CustomTypography variant='body2' sx={{ mt: 1 }}>
                    At CliniOps, we provide secure and reliable database lock services designed to streamline the end-of-study process in clinical trials. 
                    Our expert team ensures that your trial data is meticulously verified, accurate, and fully compliant with regulatory standards before 
                    locking. We prioritize data integrity, confidentiality, and efficiency, allowing for a seamless transition to final analysis. Post DB lock, 
                    data transfer is performed as per agreed SOPs ensuring complete compliance w.r.t. PHI and PII data.
                </CustomTypography>
                </>
                }
              />
              
          </Grid>
      </Grid>
    </>
  );
}

export default template;

//Commenting previous code

// import React from 'react';
// import { Typography, Stack } from '@mui/material';

// import { PageSectionLeftImg } from '../../../component/page';

// import ImgMedicalCoding from '../../../../img/service/clinical-data-management/medicalCoding.jpeg';

// const template = () => {
//   return (
//     <>
//       <PageSectionLeftImg
//         heading={
//           <Stack direction="row" spacing={1} alignItems='center' justifyContent={{xs: 'center', md: 'flex-start'}}>
//             <Typography variant="h2" component="h2" className="co-page-heading" sx={{ textAlign: { xs: 'center', md: 'left'} }}>
//               Medical Coding
//             </Typography>
//           </Stack>
//         }
//         imgUrl={ImgMedicalCoding}
//         imgTitle="Medical Coding"
//       >
//         <Typography sx={{ mt: 1 }}>
//           Irrespective of the type of dictionary your study needs, our highly adaptable and automated dictionary coding platform will accurately and reproducibly code your medical history, therapy, procedure, and adverse event terms.
//         </Typography>
//       </PageSectionLeftImg>
//     </>
//   );
// }

// export default template;