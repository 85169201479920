import React from "react";
import { Grid, Typography, Button, Box } from "@mui/material";

const PressRelease = ({
  ImgSrc,
  Name,
  Date,
  Summary,
  FullContent,
  redirectUrl,
  PRImg,
}) => {
  // const [expanded, setExpanded] = React.useState(false);

  // const handleExpandClick = (event) => {
  //     setExpanded(!expanded);
  //     event.target.innerText = expanded ? 'Know More' : 'Show Less';
  // };

  const handleRedirect = () => {
    window.open(redirectUrl, "_blank");
  };

  return (
    <Box
      sx={{
        backgroundColor: "white",
        borderRadius: "16px",
        padding: "24px",
        borderColor: "#CED2D4",
        borderWidth: "1px",
        borderStyle: "solid",
        // boxShadow: "10px 10px 8px rgba(0, 0, 0, 0.2)",
        boxShadow: "10px 10px 8px rgba(0, 0, 0, 0.2)",
        marginBottom: "8px",
        position: "relative",
      }}
    >
      <Grid container direction="row" spacing={{ xs: 2, md: 6 }}>
        <Grid item xs={12} md={2}>
          <img src={ImgSrc} className="co-image-news" alt={Name} title={Name} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h7" component="h2" className="co-news-heading">
            {Name}
          </Typography>

          <Typography variant="h4" component="h3" className="co-date">
            {Date}
          </Typography>

          <Typography variant="body2" sx={{ mt: 1 }}>
            {Summary}
          </Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
            {FullContent}
          </Typography>
          <Button
            onClick={handleRedirect}
            sx={{
              mt: 1,
              ml: -1,
              textTransform: "capitalize",
              fontSize: "14px",
            }}
          >
            Know More
          </Button>
        </Grid>
        <Grid item xs={12} md={4} alignSelf={'start'}>
          <Box
            sx={{
              display: "flex",
              flexDirection:"column",
              alignItems:"center",
              justifyContent:"center",
            }}
          >
            <img
              src={PRImg}
              alt={Name}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PressRelease;

// import React, { useState } from "react";
// import { Grid, Typography, Link, Box } from "@mui/material";
// import { blueGrey } from "@mui/material/colors";

// const PressRelease = (props) => {
// const [img] = useState(props.img);
// const [alt] = useState(props.alt);
// const [link] = useState(props.link);
// const [title] = useState(props.title);
// const [date] = useState(props.date);

//     return (
//         <>
//             <Grid item xs={6} md={3}>
//                 <Box
//                     sx={{
//                         display: 'flex',
//                         justifyContent: 'center',
//                         alignItems: 'center',
//                         height: '170px',
//                         border: `1px solid ${blueGrey[100]}`,
//                         borderRadius: 2,
//                         padding:"1em"
//                     }}
//                 >
//                     <img
//                         src={img}
//                         alt={alt}
//                         title={alt}
//                         style={{ maxWidth: '100%', maxHeight: '100%' }}
//                     />
//                 </Box>
//                 <Box mt={1}>
//                     <Link
//                         href={link}
//                         target="_blank"
//                         underline="none"
//                         variant="body1"
//                         >
//                         {title}
//                     </Link>
//                     <Typography variant="body2">{date}</Typography>
//                 </Box>
//             </Grid>
//         </>
//     )
// };

// export default PressRelease;
