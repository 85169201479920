import React from 'react';
import { Box, Typography, Grid, Card, CardHeader, CardMedia, Link } from '@mui/material';

import ImgMedia201601 from '../../../../img/media/newsroom/_2016/Media-2016-01.png';
import ImgMedia201602 from '../../../../img/media/newsroom/_2016/Media-2016-02.png';
import ImgMedia201603 from '../../../../img/media/newsroom/_2016/Media-2016-03.png';
import ImgMedia201604 from '../../../../img/media/newsroom/_2016/Media-2016-04.png';
import ImgMedia201605 from '../../../../img/media/newsroom/_2016/Media-2016-05.png';
import ImgMedia201606 from '../../../../img/media/newsroom/_2016/Media-2016-06.png';
import ImgMedia201607 from '../../../../img/media/newsroom/_2016/Media-2016-07.png';
import ImgMedia201608 from '../../../../img/media/newsroom/_2016/Media-2016-08.png';
import ImgMedia201609 from '../../../../img/media/newsroom/_2016/Media-2016-09.png';
import ImgMedia201610 from '../../../../img/media/newsroom/_2016/Media-2016-10.png';
import ImgMedia201611 from '../../../../img/media/newsroom/_2016/Media-2016-11.png';
import ImgMedia201612 from '../../../../img/media/newsroom/_2016/Media-2016-12.png';
import ImgMedia201613 from '../../../../img/media/newsroom/_2016/Media-2016-13.png';
import ImgMedia201614 from '../../../../img/media/newsroom/_2016/Media-2016-14.png';
// import sign from '../../../../img/media/newsroom/Renew/sign.png';


const news2016Data = [
    {
    ImgSrc: ImgMedia201601,
    Name: "Neglected Tropical Disease Conference",
    Date: "November 10, 2016",
    Summary: "DOLF Shows off Innovative Electronic Data Capture Tool for Clinical Trials at COR-NTD Meeting – November 10, 2016",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
       Last November at the COR-NTD Innovation Lab in Atlanta DOLF Project Manager, Joshua Bogus, demonstrated the capabilities of a tablet-based electronic data capture system that is being used in the Community Based Safety Study of 2-drug vs. 3-drug Therapy for Lymphatic Filariasis.
      </Typography>
    ),
    redirectUrl: "https://dolfproject.wustl.edu/dolf-shows-off-innovative-electronic-data-capture-tool-for-clinical-trials-at-cor-ntd-meeting-november-10-2016/",
  },
  {
    ImgSrc: ImgMedia201604,
    Name: "Stanford Medicine",
    Date: "October 11, 2016",
    Summary: "Neonatology ranks fifth in nation by U.S. News & World Report",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        U.S. News & World Report has released its 2024-25 Best Children's Hospitals survey. Our neonatology service at Lucile Packard Children's Hospital Stanford is ranked as the "Best in the West" and #5 in the nation.
      </Typography>
    ),
    redirectUrl: "https://neonatology.stanford.edu/",
  },
  {
    ImgSrc: ImgMedia201605,
    Name: "Frost & Sullivan",
    Date: "September 28, 2016",
    Summary: "Emerging Technologies Energizing the Future of the Pharmaceutical Sector ",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
       Emerging technologies are changing the dynamics of various industries, globally. With the current global healthcare and economy challenges, along with the changing needs of patients, care providers, and regulatory authorities, the pharmaceutical industry is also trying to adopt a whole host of emerging technologies, with the aim to address the industry challenges and to push the borders of innovation.
      </Typography>
    ),
    redirectUrl: "https://store.frost.com/emerging-technologies-energizing-the-future-of-the-pharmaceutical-sector.html",
  },
  {
    ImgSrc: ImgMedia201613,
    Name: "FICCI",
    Date: "September 28, 2016",
    Summary: "FICCI",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "https://www.youtube.com/watch?v=oqMhWs-3Gm8",
  },
  {
    ImgSrc:ImgMedia201608,
    Name: "Ministry of MSME, Govt of India",
    Date: "August 13, 2016",
    Summary: "Ministry of MSME, Govt of India",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "http://www.dcmsme.gov.in/SAMACHAR/MSME%20August%202016.pdf",
  },
  {
    ImgSrc:ImgMedia201609,
    Name: "Dept of Science & Tech (DST), Govt of India",
    Date: "August 04, 2016",
    Summary: "CliniOps: Tablet based eSource/EDC for clinical trials – Avik Kumar Pal, CliniOps, Inc.",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
       The founding team recognizes that trials with global sites face several data challenges (data collection, data quality & data security), and benefits of low cost ‘point-of-care' diagnostics to bring efficiency in the process, and has designed the product to work effectively in such conditions.
      </Typography>
    ),
    redirectUrl: "http://www.techno-preneur.n et/technology/new-technologies/imp/lmp16/CliniOps.htm",
  },
  {
    ImgSrc:ImgMedia201610,
    Name: "DST-Lockheed Martin Innovation Award",
    Date: "June 12, 2016",
    Summary: "DST-Lockheed Martin Innovation Award",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "https://ficci.in/public/storage/PressRelease/2399/FICCI-TCP.pdf",
  },
  {
    ImgSrc:ImgMedia201614,
    Name: "Scope Summit 2016",
    Date: "February 23, 2016",
    Summary: "SCOPE 2016 at a glance",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        The 7th Annual SCOPE Summit, held February 23-25, 2016 in Miami, Florida, had record attendance with more than 1,150 industry leaders joining 3 days of in-depth discussions covering important issues in clinical trial planning and management.
      </Typography>
    ),
    redirectUrl: "https://www.scopesummit.com/16/",
  },
]

export default news2016Data;