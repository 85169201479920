import React from 'react';
import { Box, Typography, Grid, Card, CardHeader, CardMedia, Link } from '@mui/material';

import NewsArticle from '../../../component/news-article';
import Imgfob from '../../../../img/media/newsroom/_2023/image-logos.png';
import Img20220901 from '../../../../img/media/newsroom/_2024/20220901.png';
import ImgResearchAndMarkets from '../../../../img/media/newsroom/_2021/Media-2018-08.png';
import Imgdia from '../../../../img/media/newsroom/_2023/DIA-Logo.png';
import ImgWorldVaccineCongress from '../../../../img/media/newsroom/_2024/World_Vaccine_Congress-DC.png';
import Imguspto from '../../../../img/media/newsroom/_2023/USPTO-Logo.png';
import ImgYahooFinance from '../../../../img/media/newsroom/_2023/Yahoo_Finance_logo.png';
import ImgOpenPR from '../../../../img/media/newsroom/_2024/OpenPR.png';
import ImgCUDenver from '../../../../img/media/newsroom/_2024/CU_Denver_logo.png';
import ImgForbesBusinessCouncil from '../../../../img/media/newsroom/_2024/Forbes_BusinessCouncil.png'
// import sign from '../../../../img/media/newsroom/Renew/sign.png';


const news2024Data = [
   {
      ImgSrc: ImgForbesBusinessCouncil,
      Name: "Eliminate ZIP Codes As A Barrier In Clinical Trials",
      Date: "December 03, 2024",
      Summary: 'I think the aphorism "rising tides lift all boats" is an apt way to describe the changes happening in the field of clinical trials (CTs). The Covid-19 pandemic challenged the traditional, centralized model of CTs, setting new standards in trial conduct.',
      FullContent: (
        <Typography variant='body2' sx={{ mt: 2 }}>
         {/* While entrepreneurs can hire and reliably lean on accounting professionals to help them manage their business finances,having even basic financial literacy skills enables business owners to confidently make informed decisions and maintain smooth operations. */}
        </Typography>
      ),
      redirectUrl: "https://www.forbes.com/councils/forbesbusinesscouncil/2024/12/03/eliminate-zip-codes-as-a-barrier-in-clinical-trials/",
      tags: ["Press"], 
    },
   {
    ImgSrc: ImgForbesBusinessCouncil,
    Name: "20 Ways Business Leaders Can Increase Their Financial Knowledge",
    Date: "Sep 25, 2024",
    Summary: "You have to know your numbers to run a successful business.",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
       While entrepreneurs can hire and reliably lean on accounting professionals to help them manage their business finances,having even basic financial literacy skills enables business owners to confidently make informed decisions and maintain smooth operations.
      </Typography>
    ),
    redirectUrl: "https://www.forbes.com/councils/forbesbusinesscouncil/2024/09/25/20-ways-business-leaders-can-increase-their-financial-knowledge/",
    tags: ["Press", "Publication"], 
  },
  {
    ImgSrc:ImgForbesBusinessCouncil,
    Name: "Counting Africa In For Clinical Trials",
    Date: "Sep 24, 2024",
    Summary: "Avik Pal, the founder and CEO of CliniOps, a technology and data company with a patented unified platform approach to digitizing clinical trials, finds universal solutions to counter adversities.",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities.
      </Typography>
    ),
    redirectUrl: "https://www.forbes.com/councils/forbesbusinesscouncil/2024/09/24/counting-africa-in-for-clinical-trials/",
    tags: ["Publication"],  
  },
  {
    ImgSrc:ImgOpenPR,
    Name: "Current and Future Analysis of Clinical Operation Platform Market With New Business Strategies and Forecast by 2031 | Veeva Systems, Seascape Clinical, CliniOps, Medable",
    Date: "July 02, 2024",
    Summary: 'The latest competent intelligence report published by WMR with the title "An Increase in Demand and Opportunities for Global Clinical Operation Platform Market 2024"',
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        At present, the Clinical Operation Platform market is possessing a presence over the globe. The Research report presents a complete judgment of the market which consists of future trends, growth factors, consumption, production volume, CAGR value, attentive opinions, profit margin, price, and industry-validated market data.
      </Typography>
    ),
    redirectUrl: "https://www.openpr.com/news/3562123/current-and-future-analysis-of-clinical-operation-platform",
    tags: ["Event"],
  },
  {
    ImgSrc:Imgdia,
    Name: "DIA Global, 2024",
    Date: "June 16, 2024",
    Summary: "The DIA 2024 Global Annual Meeting invites industry, regulators, governments, academics, innovators, and patients to network, problem-solve, and discuss global and local challenges facing the life sciences community.",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
       Hosted in San Diego, DIA 2024 will amplify different perspectives while highlighting expertise across the globe to reimagine current processes that better enhance health and well-being.
      </Typography>
    ),
    redirectUrl: "https://www.diaglobal.org/en/flagship/dia-2024",
    tags: ["Event"],
  },
  {
    ImgSrc:ImgYahooFinance,
    Name: "CliniOps Awarded Patent for an Innovative Offline/Online eSource, or Direct Data Collection (DDC) System, for Global Clinical Trials",
    Date: "April 25, 2024",
    Summary: "Custom-Built Platform to Support Clinical Trials (CTs) Across Africa and Low & Middle Income Countries (LMIC)",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        CliniOps, Inc., an innovative technology and data science company for the life science industry, has once again demonstrated its capability to innovate and respond to unmet needs in CTs, particularly, the challenge of managing data collection in remote and disparate regions across Africa and LMIC countries.
      </Typography>
    ),
    redirectUrl: "https://finance.yahoo.com/news/cliniops-awarded-patent-innovative-offline-150000970.html",
  },
  {
    ImgSrc:Imguspto,
    Name: "CliniOps Platform receives Patent approval from USPTO, for offline data collection and synchronization for managing a clinical trial",
    Date: "April 23, 2024",
    Summary: "CliniOps Platform receives Patent approval from USPTO, for offline data collection and synchronization for managing a clinical trial.",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "https://ppubs.uspto.gov/dirsearch-public/print/downloadPdf/11967402",
  },
  {
    ImgSrc:Imgfob,
    Name: "Festival of Biologics USA, 2024",
    Date: "April 15, 2024",
    Summary: "The Festival of Biologics San Diego combines three, outstanding conferences, focusing on antibodies, immunotherapy and clinical trials, all under one roof.",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "https://www.terrapinn.com/conference/festival-of-biologics-usa/speaker-avik-PAL.stm",
  },
  {
    ImgSrc:ImgWorldVaccineCongress,
    Name: "World Vaccine Congress, 2024",
    Date: "April 01, 2024",
    Summary: "R&D + Strategic Partnering for the Global Vaccine Industry",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "https://www.terrapinn.com/conference/world-vaccine-congress-washington/index.stm",
  },
  {
    ImgSrc:Img20220901,
    Name: "Celebrating National Science Day: Insights and Reflections from Industry Leaders",
    Date: "February 28, 2024",
    Summary: "As part of India’s National Science Day celebration, The CEO Magazine features insights from industry leaders on the significance of science and technology in shaping India’s future. Avik Pal, the CEO of CliniOps, is featured on the list.",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        India’s National Science Day is a celebration of scientific achievements and innovation, commemorating the discovery of the ‘Raman Effect’ by Sir C.V. Raman. This year’s National Science Day theme, “Indigenous Technologies for Viksit Bharat,” underscores the importance of home-grown innovations in driving the nation forward.
      </Typography>
    ),
    redirectUrl: "https://www.theceo.in/business-experts/celebrating-national-science-day-insights-and-reflections-from-industry-leaders",
  },
  {
    ImgSrc:ImgResearchAndMarkets,
    Name: "Global ePRO, E-Patient Diaries and eCOA Market to Reach $4.7 Billion by 2030",
    Date: "February 23, 2024",
    Summary: "The global market for ePRO, E-Patient Diaries and eCOA was valued at US$2.2 Billion in 2024 and is projected to reach US$5.3 Billion by 2030, growing at a CAGR of 15.4% from 2024 to 2030. ",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        This comprehensive report provides an in-depth analysis of market trends, drivers, and forecasts, helping you make informed business decisions.
      </Typography>
    ),
    redirectUrl: "https://www.researchandmarkets.com/reports/5139503/epro-e-patient-diaries-and-ecoa-global",
  },
  {
    ImgSrc:ImgCUDenver,
    Name: "Driving innovative research to transform novel ideas to better human life",
    Date: "January 10, 2024",
    Summary: "The research community across CU Anschutz Medical Campus performs hundreds of clinical trials in a broad range of research fields – all crucial to answering difficult scientific and health questions, which can lead to the development of new and enhanced drugs and treatments. ",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
       The Novel Clinical Trials Program strives to provide the necessary expertise and resources to expand the research infrastructure to support new and innovative study designs. The program will primarily focus on Decentralized Clinical Trials (DCT).
      </Typography>
    ),
    redirectUrl: "https://research.cuanschutz.edu/cros/novel-clinical-trials#ac-cliniops-1",
  },
]

export default news2024Data;