import React from 'react';
import { Grid, Typography, Stack } from '@mui/material';

// import { PageSectionRightImg } from '../../../component/page';
import { Product } from '../../../component/page';

// import ImgEConsent from '../../../../img/product/edge/econsent.png';
import ImgeconOnsite from '../../../../img/product/edge/eConsentOnsite.png';
import CustomTypography from '../../../component/customTypography';

const template = () => {
  return (
    <>
      {/* <PageSectionRightImg
        heading={
          <Stack direction="row" spacing={1} alignItems='center' justifyContent={{xs: 'center', md: 'flex-start'}}>
            <Typography variant="h2" component="h2" className="co-page-heading" sx={{ textAlign: { xs: 'center', md: 'left'} }}>
              eConsent
            </Typography>
          </Stack>
        }
        imgUrl={ImgEConsent}
        imgTitle="eConsent"
      >
        <Typography sx={{ mt: 1 }}>
        CliniOps eConsent module ensures patient understanding of the study requirements and expectations and enforces study compliance. This allows the sites to conduct the eConsent process either in-person or remotely, including Ascent, LAR (Legally Authorized Representative) and other modes of consent. The module provides a controlled workflow with checks and balances to ensure the consent form is signed before any other study data is collected. During the consent process, the patient signature is seamlessly captured on the mobile device, along with the geo-location and the system-timestamp. Thus making the whole consenting process completely paperless and transparent. It also has the capabilities to ensure that the patient has the appropriate understanding of the study before they provide the consent.
        </Typography>
      </PageSectionRightImg> */}

      <Grid container direction='column' spacing={0} sx={{ mt: 0 }}>
            {/* eConsent (onsite)  */}
          <Grid item>
              <Product
                ImgSrc={ImgeconOnsite}
                Name="eConsent (onsite)"
                Summary={
                  <>
                  <CustomTypography variant='body2' sx={{ mt: 1 }}>
                    CliniOps' eConsent solution ensures that the patient understands the study requirements and expectations and 
                    enforces study compliance. This allows the sites to conduct the eConsent process in person or remotely, including 
                    Ascent, Witness, LAR (Legally Authorized Representative), and other modes of consent. 
                    <br></br><br></br>
                    A controlled workflow with checks and balances ensures the consent form is signed before collecting other study data. 
                    During the consent process, the patient's signature is seamlessly captured on the mobile device, along with the 
                    geo-location and the system timestamp, making the process completely paperless and transparent. 
                    This can also ensure that the patient understands the study appropriately before giving consent.
                </CustomTypography>
                </>
                }
              />
              
          </Grid>
      </Grid>
    </>
  );
}

export default template;