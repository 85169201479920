import React from 'react';
import { Grid, Typography, Stack } from '@mui/material';

import { Product } from '../../../component/page';

import ImgDesign from '../../../../img/service/study-setup/Design.png';
import CustomTypography from '../../../component/customTypography';

const template = () => {
  return (
    <>
      <Grid container direction='column' spacing={0} sx={{ mt: 0 }}>
            {/* CRF Form Design  */}
          <Grid item>
              <Product
                ImgSrc={ImgDesign}
                Name="CRF Form Design"
                Summary={
                  <>
                  <CustomTypography variant='body2' sx={{ mt: 1 }}>
                    CliniOps offers specialized CRF (Case Report Form) design services to streamline data collection for clinical trials. 
                    Our team ensures that CRFs are fully compliant with regulatory standards and tailored to study-specific requirements. 
                    This activity is performed trough transparent collaboration between CliniOps and Client’s internal teams, supported by SMEs 
                    experienced with everything from simple single arm studies to complex multi-arm randomized global studies.
                </CustomTypography>
                </>
                }
              />
              
          </Grid>
      </Grid>
    </>
  );
}

export default template;

//Commenting previous code

// import React from 'react';
// import { Typography, Stack } from '@mui/material';

// import { PageSectionLeftImg } from '../../../component/page';

// import ImgProtocol from '../../../../img/service/study-setup/protocol.jpeg';

// const template = () => {
//   return (
//     <>
//       <PageSectionLeftImg
//         heading={
//           <Stack direction="row" spacing={1} alignItems='center' justifyContent={{xs: 'center', md: 'flex-start'}}>
//             <Typography variant="h2" component="h2" className="co-page-heading" sx={{ textAlign: { xs: 'center', md: 'left'} }}>
//               Protocol Development
//             </Typography>
//           </Stack>
//         }
//         imgUrl={ImgProtocol}
//         imgTitle="Protocol Development"
//       >
//         <Typography sx={{ mt: 1 }}>
//           We offer comprehensive protocol development services utilizing our extensive and cross-functional scientific expertise.
//         </Typography>
//         <Typography sx={{ mt: 1 }}>
//           We form an early partnership with clients to ensure development of an executable trial with minimal protocol amendments.
//         </Typography>
//         <Typography sx={{ mt: 1 }}>
//           Our expertise in regulatory affairs and knowledge of acceptable endpoints increases the probability that our protocols will be accepted by regulatory agencies across the world.
//         </Typography>
//       </PageSectionLeftImg>
//     </>
//   );
// }

// export default template;