import React from 'react';
import { Box, Typography, Grid, Card, CardHeader, CardMedia, Link } from '@mui/material';

import ImgMedia201501 from '../../../../img/media/newsroom/_2015/Media-2015-01.png';
import ImgMedia201502 from '../../../../img/media/newsroom/_2015/Media-2015-02.png';
import ImgMedia201503 from '../../../../img/media/newsroom/_2015/Media-2015-03.png';
import ImgMedia201504 from '../../../../img/media/newsroom/_2015/Media-2015-04.png';
import ImgMedia201505 from '../../../../img/media/newsroom/_2015/Media-2015-05.png';
import ImgMedia201506 from '../../../../img/media/newsroom/_2015/Media-2015-06.png';
import ImgMedia201507 from '../../../../img/media/newsroom/_2015/Media-2015-07.png';
import ImgMedia201508 from '../../../../img/media/newsroom/_2015/Media-2015-08.png';
import ImgMedia201509 from '../../../../img/media/newsroom/_2015/Media-2015-09.png';
import ImgMedia201510 from '../../../../img/media/newsroom/_2015/Media-2015-10.png';
import ImgMedia201511 from '../../../../img/media/newsroom/_2015/Media-2015-11.png';
import ImgMedia201512 from '../../../../img/media/newsroom/_2015/Media-2015-12.png';
import ImgMedia201513 from '../../../../img/media/newsroom/_2015/Media-2015-13.png';
import ImgMedia201514 from '../../../../img/media/newsroom/_2015/Media-2015-14.png';
import ImgMedia201515 from '../../../../img/media/newsroom/_2015/Media-2015-15.png';
// import sign from '../../../../img/media/newsroom/Renew/sign.png';


const news2015Data = [
    {
    ImgSrc: ImgMedia201501,
    Name: "California and Kolkata-based CliniOps helps the biopharma industry manage its data using technology",
    Date: "December 14, 2015",
    Summary: "It was in a little Thai restaurant in downtown San Francisco, in 2012, that Avik Pal had his Eureka moment on data management for clinical trials.",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
       CliniOps, founded in 2013, offers a tablet-based solution in clinical trials, for the biopharma industry, using technologies in social, mobile, analytics and cloud. It captures all the data electronically at the source.
      </Typography>
    ),
    redirectUrl: "https://yourstory.com/2015/12/cliniops",
  },
  {
    ImgSrc: ImgMedia201502,
    Name: "Andreessen Horowitz: 4 areas where it should consider investments",
    Date: "November 21, 2015",
    Summary: "Take a memo, Andreessen Horowitz: 4 areas where it should consider investments",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
       This week, Andreessen Horowitz said it’s launching a fund dedicated to investments in bioinformatics and “beyond the pill” technology.  The venture capital firm had already gotten its feet wet with investments in these areas, but a dedicated fund is something new and indicates an interest in ramping up its strategy. 
      </Typography>
    ),
    redirectUrl: "https://medcitynews.com/2015/11/andreessen-horowitz-investments/?utm_term&rf=1",
  },
  {
    ImgSrc: ImgMedia201503,
    Name: "Radiant Insights",
    Date: "November 11, 2015",
    Summary: "Global eHealth market is expected to reach USD 308.0 billion by 2022, according to a new report by Grand View Research Inc.",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        The transition of the healthcare industry into digital healthcare system for management and analysis of patient health is expected to be the most vital driver of the market.
      </Typography>
    ),
    redirectUrl: "https://www.radiantinsights.com/research/ehealth-market-analysis-by-product-ehr-eprescribing-clinical-decision-support-telemedicine-consumer-health-information-mhealth-health-management-information-system-by-services-monitoring-diagnostic-healthcare-strengthening-by-end-use-providers-insurers-government-healthcare-consumers-and-segment-forecasts-to-2022",
  },
  {
    ImgSrc: ImgMedia201505,
    Name: "Software Innovations in Clinical Drug Development and Safety",
    Date: "September 16, 2015",
    Summary: "Software Innovations in Clinical Drug Development and Safety is a comprehensive resource analyzing the integration of software engineering for the purpose of drug discovery, clinical trials, genomics, and drug safety testing.",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
       Taking a multi-faceted approach to the application of computational methods to pharmaceutical science, this publication is ideal for healthcare professionals, pharmacists, computer scientists, researchers, and students seeking the latest information on the architecture and design of software in clinical settings, the impact of clinical technologies on business models, and the safety and privacy of patients and patient data.
      </Typography>
    ),
    redirectUrl: "https://www.igi-global.com/book/software-innovations-clinical-drug-development/127502",
  },
  {
    ImgSrc:ImgMedia201507,
    Name: "DIA Conference 2015",
    Date: "June 16, 2015",
    Summary: "DIA Conference 2015",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "https://www.diaglobal.org/Tools/Content.aspx?type=eopdf&file=%2fproductfiles%2f3404584%2fdia2015_finalprogram%2Epdf",
  },
  {
    ImgSrc:ImgMedia201506,
    Name: "Founder's Guide",
    Date: "July 09, 2015",
    Summary: "Startups flourishing in America’s 5 Major Industries. Keep an Eye on these Successful Commercial Spots",
    FullContent: (
      <Typography  variant='body2'sx={{ mt: 2 }}>
       Six years have gone by after the end of the last recession. The two-year economic slump and the subsequent recovery period were certainly tough on business but the present trends are showing a solid momentum for many industries, startups included.
      </Typography>
    ),
    redirectUrl: "http://foundersguide.com/startups-flourishing-in-americas-5-leading-industries/",
  },
  {
    ImgSrc:ImgMedia201509,
    Name: "India West",
    Date: "May 15, 2015",
    Summary: "India West",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "https://indiawest.com/",
  },
  {
    ImgSrc:ImgMedia201510,
    Name: "Innovations: Technologies",
    Date: "April 01, 2015",
    Summary: "CliniOps’ Technology Shakes Up Clinical Trial Data Collection and Management",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
      CliniOps wants to put the squeeze on clinical trials. The company thinks that a complete digitalization process will reduce time and cost as well as improve trial efficiencies. Through its global solutions technology for eSourcing, monitoring, and patient engagement, the company hopes to address the problems of data capture at trial sites lacking Internet access, among other issues faced by the industry.
      </Typography>
    ),
    redirectUrl: "http://www.pharmavoice.com/article/innovations-technologies/",
  },
  {
    ImgSrc:ImgMedia201511,
    Name: "Agenda15 Conference",
    Date: "March 30, 2015",
    Summary: "The Digital Edge 25 Award recognizes a select group of digital achievers whose organizations have made great strides toward being a digital-centric business.",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "http://www.marketwired.com/press-release/business-digital-transformation-recognized-computerworlds-2015-premier-100-award-1964194.htm",
  },
  {
    ImgSrc:ImgMedia201514,
    Name: "Can Mobile Health Streamline the Clinical Trial Workflow?",
    Date: "February 10, 2015",
    Summary: "CliniOps Avik Pal explains why the time to revolutionize clinical trials has come.",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
       The latest area calling for a mobile movement: clinical trials. The growing consensus: the time to clean up the clinical trial work flow has come— and mobile-driven solutions are the tools to get it done.
      </Typography>
    ),
    redirectUrl: "http://hitconsultant.net/2015/02/10/can-mobile-health-streamline-the-clinical-trial-workflow/",
  },
  {
    ImgSrc:ImgMedia201515,
    Name: "CliniOps develops clinical trial support tools to avoid Internet and paper dependence",
    Date: "February 02, 2015",
    Summary: "Its CliniTrial Lite tool is designed to capture data offline using a tablet and synchs online. It does instant edit checks, and photo and video upload.",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
         It provides a way to access site data without the need for Internet connection. It also helps integrate data from medical devices and is HIPAA compliant.
      </Typography>
    ),
    redirectUrl: "https://medcitynews.com/2015/02/cliniops-develops-offline-approach-download-data-clinical-trial-sites/",
  },
]

export default news2015Data;