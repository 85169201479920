import React from 'react';
import { Grid, Typography } from '@mui/material';

import { Product } from '../../../component/page';

import ImgSAP from '../../../../img/service/clinical-data-management/SAP.png';
import CustomTypography from '../../../component/customTypography';

const template = () => {
  return (
    <>
      <Grid container direction='column' spacing={0} sx={{ mt: 0 }}>
            {/* Statistical Analysis Plan (SAP)  */}
          <Grid item>
              <Product
                ImgSrc={ImgSAP}
                Name="Statistical Analysis Plan (SAP)"
                Summary={
                  <>
                  <CustomTypography variant='body2' sx={{ mt: 1 }}>
                    Knowledgeable and experienced biostatisticians and programmers work closely with the study team to develop study design, 
                    perform sample size calculation and power determination, develop randomization scheme, write statistical analysis section of 
                    protocols, and write SAP detailing statistical methodologies. The SAP includes detailed descriptions of data consistency checks, 
                    data listings, tables and graphs along with their layouts. Professional programmers create and finalize programming processes for 
                    data consistency checks, data listings, and graphics.
                </CustomTypography>
                </>
                }
              />
              
          </Grid>
      </Grid>
    </>
  );
}

export default template;

//Commenting previous code

// import React from 'react';
// import { Typography, Stack } from '@mui/material';

// import { PageSectionRightImg } from '../../../component/page';

// import ImgExportData from '../../../../img/service/clinical-data-management/exportData.jpeg';

// const template = () => {
//   return (
//     <>
//       <PageSectionRightImg
//         heading={
//           <Stack direction="row" spacing={1} alignItems='center' justifyContent={{xs: 'center', md: 'flex-start'}}>
//             <Typography variant="h2" component="h2" className="co-page-heading" sx={{ textAlign: {xs: 'center', md: 'left'} }}>
//               Import of External Data
//             </Typography>
//           </Stack>
//         }
//         imgUrl={ImgExportData}
//         imgTitle="Import of External Data"
//       >
//         <Typography sx={{ mt: 1 }}>
//           Import, Integration, and cleaning of external data (lab data, ECG, biological assays, Patient Diary, data from remote clinical monitoring devices, etc.) are an integral part of all studies. We design and validate programs to import and format data from third party vendors to ensure the integrity of the data. Our programs use verification checks to guarantee that the data imported from each external vendor is accurate and complete.
//         </Typography>
//       </PageSectionRightImg>
//     </>
//   );
// }

// export default template;