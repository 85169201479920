import React from "react";

import {
  Container,
  Box,
  Grid,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { blue } from "@mui/material/colors";

import { ProductTile } from "../../../component/home";

import RouterList from "../../../router";
// import ImgGroupMultiNurse from "../../../../img/home/multiracial-nurses.png";
// import ImgMultiNurseV2 from "../../../../img/home/multiiracial-nurses-v4.png";
import ImgMultiNurseV5 from "../../../../img/home/multiiracial-nurses-v5.png";

import _2_0 from "./_2_0";

const template = () => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(
    theme.breakpoints.between("1300", "1450")
  );
  const isMediumScreen = useMediaQuery(
    theme.breakpoints.between("1150", "1300")
  );
  // const isMiniScreen = useMediaQuery(theme.breakpoints.between("966", "1150"));
  const isTabScreen = useMediaQuery(theme.breakpoints.between("900", "1050"));
  const isMobileScreen = useMediaQuery(theme.breakpoints.between("500", "900"));
  const isMiniMobileScreen = useMediaQuery(theme.breakpoints.down("500"));

  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "0px", md: "0px" },
          marginTop: "1.5em",
          position: "relative",
          overflow: "hidden",
        }}
      >
       
        <Box
          sx={{
            textAlign: "center",
            // marginBottom:'120px'
          }}
        >
          <Typography
          variant='h1Teal'
            sx={{
              marginBottom: "1em",
              lineHeight: 1,
            }}
          >
            CliniOps App Suite
          </Typography>
        </Box>

        
        {/* <Box sx={{position:"relative",minHeight:"200px"}}> */}
        <Box
          component="img"
          src={`${ImgMultiNurseV5}`}
          alt="Doctors"
          sx={{
            width: "100%",
            maxWidth: "1100px",
            margin: "0 auto",
            display: "block",
            objectFit: "contain",
            marginBottom: { xs: "-13px", sm: "-25px", md: "-30px" }, // Responsive negative margin
            position: "relative",
            zIndex: 1,
          }}
        />
        {/* </Box> */}

       
        <Box> 
        <_2_0/>
        </Box>
      </Box>

    
    </>
  );
};

export default template;
