import React from "react";
import {
  Typography,
  Box,
  Grid,
  Card,
  CardMedia,
  CardActionArea,
} from "@mui/material";
import ImgSCDM from "../../../img/partnerships/scdm.png";
import ImgMedDra from "../../../img/partnerships/MedDra.png";
import ImgUppsala from "../../../img/partnerships/uppsala.png";
import ImgCdisc from "../../../img/partnerships/cdisc.png";

const content = [
  { id: 1, name: "Medra", src: ImgMedDra, link: "https://www.meddra.org/" },
  { id: 2, name: "Uppsala", src: ImgUppsala, link: "https://who-umc.org/" },
  { id: 3, name: "SCDM", src: ImgSCDM, link: "https://scdm.org/" },
  { id: 4, name: "Cdisc", src: ImgCdisc, link: "https://www.cdisc.org/" },
];

const template = () => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: "5px 20px 50px 20px",
        }}
      >
        {/*title*/}
        <Typography
          sx={{
            fontWeight: 300,
            fontSize: { xs: "25px", md: "42px" },
            lineHeight: "30px",
            color: "#042D43",
            textAlign: "center",
            textShadow: "0px 3px 3px rgba(0, 0, 0, 0.16)",
          }}
        >
          Industry Consortium Partners
        </Typography>
        {/* Description */}
        <Typography
          variant="body2"
          sx={{
            lineHeight: "24px",
            maxWidth: "1000px",
            margin: "20px auto 30px auto",
            textAlign: { xs: "center", md: "left" },
          }}
        >
         Our Industry Consortium partners are at the forefront of driving innovation and shaping the future of technology across diverse sectors. By collaborating with leading industry consortiums, we foster a shared vision to tackle complex industry challenges and accelerate digital transformation. Together, we create impactful solutions that redefine standards and pave the way for sustainable growth and progress.
        </Typography>

        {/* Partner Logos */}
        <Grid
          container
          spacing={2}
          sx={{
            maxWidth: "90%",
            margin: "auto",
          }}
        >
          {content.map((logo) => (
            <Grid item xs={12} sm={6} md={3} key={logo.id}>
              <Card
                sx={{
                  width: "100%",
                  maxWidth: "250px",
                  margin: "0 auto",
                  height: "200px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  border: "1px solid #707070",
                  borderRadius: "30px",
                  backgroundColor: "#FFFFFF",
                }}
              >
                <CardActionArea
                  component="a"
                  href={logo.link}
                  target="_blank"
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CardMedia
                    component="img"
                    image={logo.src}
                    alt={logo.name}
                    sx={{
                      maxWidth: "50%",
                      maxHeight: "60%",
                      objectFit: "contain",
                    }}
                  />
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default template;
