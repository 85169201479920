import React from 'react';
import { Typography } from '@mui/material';
import { blueGrey } from '@mui/material/colors';

const template = () => {
  return (
    <>
      <Typography variant="h9" sx={{paddingBottom:"15px"}}  component="h1">
        DCT in Action: Menopause Study Started and Completed During Pandemic, conducted across multiple sites in Mexico  </Typography>
     
      <a href="https://crilahealth.com/" target={'_blank'} class="linkcolor">Crila Health</a>
     
    </>
  );
}

export default template;