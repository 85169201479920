import React from "react";

import ImgHiveHealth from '../../../../img/media/newsroom/_2025/Hiive_Health.jpeg';
import Imgdia from '../../../../img/media/newsroom/_2023/DIA-Logo.png';
import ImgWorldVaccineCongress from '../../../../img/media/newsroom/_2024/World_Vaccine_Congress-DC.png';
import Imgscopes from '../../../../img/media/newsroom/_2023/scopes.jpeg';
import ImgJPMC from '../../../../img/media/newsroom/_2025/JPMC_Logo.jpg';
import ImgCIP from '../../../../img/media/newsroom/_2025/Logo_Indegene.jpg'
import { Typography } from "@mui/material";




const news2025Data = [
  {
    ImgSrc: ImgHiveHealth,
    Name: "Modernizing Clinical Trials: Hiive Health and CliniOps Partner to Launch an EMR-Integrated, Adaptive Workflow Solution",
    Date: "March 20, 2025",
    Summary: " Hiive Health is a no-code digital care enablement platform that works with your existing infrastructure—extending its capabilities, simplifying workflows, and putting care delivery back in the hands of your team.",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
       {/* While entrepreneurs can hire and reliably lean on accounting professionals to help them manage their business finances,having even basic financial literacy skills enables business owners to confidently make informed decisions and maintain smooth operations. */}
      </Typography>
    ),
    redirectUrl: "https://hiivehealth.com/resources/modernizing-clinical-trials-hiive-health-and-cliniops-partner-to-launch-an-emr-integrated-adaptive-workflow-solution",
    tags: ["Press"], 
  },
  {
    ImgSrc: Imgdia,
    Name: "DIA Global Annual Meeting, 2025",
    Date: "June 15, 2025",
    Summary: " DIA is a global association that mobilizes life science professionals from across all areas of expertise to engage with patients, peers and thought leaders in a neutral environment on the issues of today and the possibilities for tomorrow.",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
       {/* While entrepreneurs can hire and reliably lean on accounting professionals to help them manage their business finances,having even basic financial literacy skills enables business owners to confidently make informed decisions and maintain smooth operations. */}
      </Typography>
    ),
    redirectUrl: "https://www.diaglobal.org/Flagship/DIA-2025",
    tags: ["Event"], 
  },
  {
    ImgSrc: ImgWorldVaccineCongress,
    Name: "World Vaccine Congress, 2025",
    Date: "April 21, 2025",
    Summary: "R&D + Strategic Partnering for the Global Vaccine Industry",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
       {/* While entrepreneurs can hire and reliably lean on accounting professionals to help them manage their business finances,having even basic financial literacy skills enables business owners to confidently make informed decisions and maintain smooth operations. */}
      </Typography>
    ),
    redirectUrl: "https://www.terrapinn.com/conference/world-vaccine-congress-washington/index.stm",
    tags: ["Event"], 
  },
  {
    ImgSrc: Imgscopes,
    Name: "SCOPE Summit, 2025",
    Date: "February 03, 2025",
    Summary: "Driving Innovation in Clinical Trials and Digital Health",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
       {/* While entrepreneurs can hire and reliably lean on accounting professionals to help them manage their business finances,having even basic financial literacy skills enables business owners to confidently make informed decisions and maintain smooth operations. */}
      </Typography>
    ),
    redirectUrl: "https://www.scopesummit.com/",
    tags: ["Event"], 
  },
  {
    ImgSrc: ImgJPMC,
    Name: "JP Morgan Healthcare Conference, 2025",
    Date: "January 13, 2025",
    Summary: "This premier conference is the largest and most informative health care investment symposium in the industry which connects global industry leaders, emerging fast-growth companies, innovative technology creators and members of the investment community.",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
       {/* While entrepreneurs can hire and reliably lean on accounting professionals to help them manage their business finances,having even basic financial literacy skills enables business owners to confidently make informed decisions and maintain smooth operations. */}
      </Typography>
    ),
    redirectUrl: "https://www.jpmorgan.com/about-us/events-conferences/health-care-conference",
    tags: ["Event"], 
  },
  {
    ImgSrc: ImgCIP,
    Name: "CliniOps Announces Strategic Partnership with Indegene to Accelerate Clinical Trials",
    Date: "January 09, 2025",
    Summary: " CliniOps, Inc., a leading provider of advanced Digital Clinical Trial solutions, today announced a strategic partnership with Indegene, a digital-first, life sciences commercialization company, to drive innovative digital transformation in clinical trial processes and achieve better patient outcomes. This collaboration marks a significant milestone in advancing the efficiency and effectiveness of clinical trial operations globally.",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
       {/* While entrepreneurs can hire and reliably lean on accounting professionals to help them manage their business finances,having even basic financial literacy skills enables business owners to confidently make informed decisions and maintain smooth operations. */}
      </Typography>
    ),
    redirectUrl: "https://www.indegene.com/news/cliniops-announces-strategic-partnership-with-indegene-to-accelerate-clinical-trials",
    tags: ["Press"], 
  },
]

export default news2025Data