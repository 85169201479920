import React from 'react';
import { Box, Grid, Typography, Container } from '@mui/material';

import ImgCliniOpsConnect from '../../../../img/product/connect/connect-logo-blue.png';

const template = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="row"
      sx={{ py: 4 }}
    >
    <Container maxWidth="xl">
    <Grid 
        container
        direction={{ xs: 'column', md: 'row' }} 
        spacing={{ xs: 4, md: 12 }}
        alignItems={{ xs: 'center', md: 'flex-start' }}
        justifyContent={{ xs: 'center', md: 'flex-start' }}
    >
    <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-start' } }}>
      <img 
        src={ImgCliniOpsConnect} 
        className="co-image-product"
        alt="CliniOps Connect" 
        style={{ width: '100%', maxWidth: '250px' }} 
      />
      </Grid>
      <Grid item xs={12} md={9}>
      <Typography variant="h7" sx={{ maxWidth: '1000px',lineHeight:"40px", textAlign: { xs: 'center', md: 'left' }, py:1 }}>
        Puts patients at the center of the clinical trials and enables seamless participation
        via telemedicine from the comfort of their homes or at their regular care facility.
      </Typography>
      <br></br><br></br>
      <Typography variant="body5" sx={{mt:2,lineHeight:'28px'}}>
        CliniOps Connect puts patients at the heart of the clinical trials, with a comprehensive 
        evaluation and mapping of their usage journey. It enables seamless participation via telemedicine 
        from the comfort of their homes or at their regular care facility, using their smartphones or mobile devices. 
        This patient-centric approach fosters empathy and care in the audience and always prioritizes patient 
        comfort and convenience. Regular reminders, notifications, alerts, access to educational materials, etc., 
        increases patient engagement, retention, and protocol adherence. 
        </Typography> 
      </Grid>
      </Grid>
      </Container>
    </Box>
  );
};

export default template;
