import React from "react";
import { Box, Typography } from "@mui/material";

// import ImgBg1 from '../../../../img/service/study-setup/study-setup-header.png';
// import ImgBg from "../../../../img/home/wave2-1.png";
import ImgTopWave from "../../../../img/home/wave1.png";
import ImgBottomWave from "../../../../img/home/wave2.png";
import ImgLaptopWithHuman from "../../../../img/service/study-setup/lady_with_laptop_1.png";

const Template = () => {
  return (
    <>
    <Box
        sx={{
          position: "relative",
          width: "100%",
          // backgroundColor: "#F2F2F2",
          overflow: "visible",
          margin: "50px 0 100px 0",
          // textAlign: "center",
        }}
      >
        {/* Top Wave Background */}
        <Box
          component="img"
          src={ImgTopWave}
          alt="Top Wave"
          sx={{
            position: "absolute",
            top: 0,
            width: "100%",
            height: "auto",
            zIndex: 1,
            objectFit: "contain",
          }}
        />

        {/* Bottom Wave Background */}
        <Box
          component="img"
          src={ImgBottomWave}
          alt="Bottom Wave"
          sx={{
            position: "absolute",
            bottom: {xs:-50,sm:-40,md:-100},
            width: "100%",
            height: "auto",
            zIndex: 3,
            objectFit: "contain",
          }}
        />

        {/* Center Content */}
        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            src={ImgLaptopWithHuman}
            alt=""
            sx={{
              width: {xs:"80%",md:"500px"},
              maxWidth:"100%",
              height: "auto",
              objectFit: "contain",
              zIndex: 2,
            }}
          />

          <Box
            sx={{
              maxWidth: "100%",
              backgroundColor: "#F2F2F2",
              minHeight: "350px",
              zIndex: 1,
              padding: "20px 0",
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
                margin: "auto",
                padding: { xs: "20px", md: "10px 0" },
              }}
            >
              <Typography
              variant="h3"
                sx={{
                  lineHeight: "24px",
                }}
              >
                During the study setup phase, our experienced configuration team supports the study build including form development, form configuration, finalizing requirements specification including but not limited to edit checks, workflows, dashboards, reports, alerts, notifications across all modules. A complete validation is also performed with CRF completion guidelines and release notes.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
    //  <Box
    //   sx={{
    //     display: 'flex',
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //     overflow: 'hidden',
    //   }}
    // >
    //   <img
    //     src={`${ImgBg}`}
    //     alt="Study Setup Header"
    //     style={{
    //       width: '100%',
    //       height: 'auto',
    //       objectFit: 'cover',
    //     }}
    //   />
    // </Box>
    // <Box
    //   sx={{
    //     position: 'relative',
    //     display: 'flex',
    //     flexDirection: 'column',
    //     alignItems: 'center',
    //     justifyContent: 'center',
    //     textAlign: { xs: 'center', md: 'left' },
    //     marginTop: { xs: 2, md: 1 },
    //     paddingX: { xs: 2, md: 10 },
    //   }}
    // >
    //   <Box
    //     sx={{
    //       marginTop: { xs: '-40%', md: '-45%' },
    //       maxWidth: '900px',
    //       mx: 'auto',
    //     }}
    //   >
    //     <Typography
    //       variant="body2"
    //       sx={{
    //         color: '#003B5C',
    //         lineHeight: 1.5,
    //         fontSize: { xs: '14px', md: '20px' },
    //       }}
    //     >
    //       During the study setup phase, our experienced configuration team supports the study
    //       build including form development, form configuration, finalizing requirements
    //       specification including but not limited to edit checks, workflows, dashboards, reports,
    //       alerts, notifications across all modules. A complete validation is also performed with
    //       CRF completion guidelines and release notes.
    //     </Typography>
    //   </Box>
    // </Box>
  );
};

export default Template;
