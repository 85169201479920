import React from 'react';
import { Grid } from '@mui/material';

import { PageHero, PageContent } from '../../component/page';

// Page Imports
import _0 from './support-helpdesk/_0';
import _1 from './support-helpdesk/_1';
import _2 from './support-helpdesk/_2';
import _3 from './support-helpdesk/_3';

const template = () => {
  return (
    <>
      {/* <PageHero color={teal}> */}
        <_0 />
      {/* </PageHero> */}
        <Grid item>
          <_1 />
        </Grid>
      <PageContent>
        {/* <Grid item>
          <Divider className="co-divider" />
        </Grid> */}
        <Grid item>
          <_2 />
        </Grid>
        {/* <Grid item>
          <Divider className="co-divider" />
        </Grid> */}
        <Grid item sx={{ pt: '20px !important' }}>
          <_3 />
        </Grid>
      </PageContent>
    </>
  );
}

export default template;