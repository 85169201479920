import React from 'react';
import { Typography,Box } from '@mui/material';
import ImgPartners from '../../../img/partnerships/partners.png';
import _1 from './_1';
import _2 from './_2';
import _3 from './_3';
// import _4 from './_4';
import _5 from './_5';






const template = () => {
  return (
    <>
      <_1/>
      <_2/>
      <_3/>
      {/* <_4/> */}
      <_5/>
      


    </>
  );
}

export default template;