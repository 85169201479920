import React from "react";
import ImgWave from "../../../../img/home/wave1.png";
import { Box, Typography } from "@mui/material";


const template = () => {
 return (
    <Box sx={{ pt: 5,marginBottom:{xs:"-150px",sm:"-170px",md:"-180px"} }}>
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        textAlign: 'center',
        zIndex:1
      }}
    >
      {/* Wave Background */}
      <img
        src={ImgWave}
        alt="Wave Background"
        style={{
          width: '100%',
          height: 'auto',
          maxHeight: '40vh',
        }}
      />
      {/* Text */}
      <Box
        sx={{
          position: 'absolute',
          top: '20%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 1,
          color: '#FFFFFF',
          textAlign: 'center',
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: '25px',md: '42px' },
            fontWeight: '300',
            color: '#FFFFFF',
            textShadow: '0 2px 5px rgba(0,0,0,0.8)',
          }}
        >
          Current Openings
        </Typography>
      </Box>
    </Box>
    </Box>
 )

}

export default template