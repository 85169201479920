import React, { useEffect, useMemo,useRef } from "react";
import Slider from "react-slick";
import { Box, Button, Chip, MenuItem, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import prevArrow from "../../../img/home/prevArrow.png";
import nextArrow from "../../../img/home/nextArrow.png";
import "../../../css/style.css";


const textFieldBaseStyles = {
  "& .MuiOutlinedInput-root": {
    boxShadow: "0 3px 3px rgba(0, 0, 0, 0.16)",
    background:"#fff",
    "& fieldset": {
      border: "1px solid #1E75C6",
    },
    "&:hover fieldset": {
      borderColor: "#1E75C6",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#1E75C6",
    },
    "@media (max-width:900px)": {
      "& .MuiInputBase-input": {
        fontSize: "14px",
        lineHeight: 1.5,         // ensure enough line height
        padding: "8px 34px 8px 14px",     // manually adjust vertical/horizontal padding 
      },
    },
  },
};

const CustomArrow = ({
  className,
  style,
  onClick,
  imgSrc,
  isLeft,
  settings,
}) => (
  <Box
    onClick={onClick}
    style={{
      ...style,
      position: "absolute",
      [isLeft ? "left" : "right"]: settings?.arrowOutside
        ? settings.arrowOutsideOffset || "-70px" // If `arrowOutside` is true, apply offset
        : "20px", // Default offset
      top:settings?.arrowTop || "50%",
      transform: "translateY(-50%)",
      zIndex: 5,
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "40px",
      width: "40px",
      background: "transparent", // Transparent background
    }}
    className={className} // Retain className for compatibility
  >
    <img
      src={imgSrc}
      alt={isLeft ? "Previous" : "Next"}
      style={{
        height: "100%", // Fit image inside the container
        width: "100%",
        objectFit: "contain",
      }}
    />
  </Box>
);


// Helper to break an array into sub-arrays of 'size' length:
function chunkArray(array, size) {
  const chunks = [];
  for (let i = 0; i < array.length; i += size) {
    chunks.push(array.slice(i, i + size));
  }
  return chunks;
}

// ExtraDots component that renders a set of dots based on total pages and current slide
const ExtraDots = ({ totalPages, currentSlide, onDotClick }) => (
  <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
    {Array.from({ length: totalPages }, (_, i) => (
      <Box
        key={i}
        onClick={() => onDotClick(i)}
        sx={{
          width: "12px",
          height: "12px",
          borderRadius: "50%",
          border: "2px solid #1E75C6",
          backgroundColor: currentSlide === i ? "#1E75C6" : "#FFFFFF",
          margin: "0 5px",
          cursor: "pointer",
        }}
      />
    ))}
  </Box>
);




const CustomCarousel = ({ slides, customStyles, renderItem, settings, extraFeatures }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const sliderRef = useRef(null);
  // Define a container ref so we can scroll it into view.
  const containerRef = useRef(null);

  // "Showing" dropdown: controls number of items per page
  const [itemsPerPage, setItemsPerPage] = React.useState(
    extraFeatures?.defaultItemsPerPage || 10
  );

  // "Filter" dropdown: controls whether to show all items or only recent ones
  const [pagingFilter, setPagingFilter] = React.useState(
    extraFeatures?.pagingFilter?.default || "all"
  );

   // New states for extra filter buttons:
  const [selectedYear, setSelectedYear] = React.useState(null); // e.g., 2024
  const [selectedTags, setSelectedTags] = React.useState([]);   // e.g., "Event"
  const [selectedRegion, setSelectedRegion] = React.useState(null); // e.g., "USA"
  const [currentSlide, setCurrentSlide] = React.useState(0);
  
  // Reset currentSlide to 0 whenever itemsPerPage changes.
  useEffect(() => {
    setCurrentSlide(0);
  }, [itemsPerPage]);

  // --------------------------------------------------------------------
  //   EXTRACT LABELS / PLACEHOLDERS WITH FALLBACKS
  // --------------------------------------------------------------------
  const tagLabel = extraFeatures?.pagingFilter?.tagLabel ?? "Select Category";
  const tagPlaceholder = extraFeatures?.pagingFilter?.tagPlaceholder ?? "Select Category";
  const tagAllOption = extraFeatures?.pagingFilter?.tagAllOption ?? "All Categories";
    // --------------------
  // FILTERING LOGIC
  // --------------------
  const filteredSlides = useMemo(() => {
    let updatedSlides = [...slides];

    // Only apply tag/year/region filters if pagingFilter is "category"
    if (pagingFilter === "category") {
      // Filter by year if user selected a year
      if (selectedYear) {
        updatedSlides = updatedSlides.filter((slide) => {
          const slideYear = new Date(slide.Date).getFullYear();
          return slideYear === selectedYear;
        });
      }

      // Filter by Region
      if (selectedRegion) {
        updatedSlides = updatedSlides.filter((slide) => {
          return slide.region === selectedRegion;
        });
      }

      // If “__ALL__” is in selectedTags, skip tag filtering => show all
      if (selectedTags.includes("__ALL__")) {
        // do nothing => show everything
      }
      // Otherwise, if we have one or more real tags, filter
      else if (Array.isArray(selectedTags) && selectedTags.length > 0) {
        updatedSlides = updatedSlides.filter((slide) => {
          const itemTags = Array.isArray(slide.tags) ? slide.tags : [];
          return selectedTags.some((tag) => itemTags.includes(tag));
        });
      }
    }
    return updatedSlides;
  }, [slides, pagingFilter, selectedYear, selectedRegion, selectedTags]);
  // We'll track currentSlide for the pagination text (xx of yy).

  // --------------------------------------------------------------------
  //  CHUNKING LOGIC – used only if extraFeatures?.manualChunking === true
  // --------------------------------------------------------------------
  // If manualChunking is set, chunk your 'filteredSlides' into arrays
  // of size 'itemsPerPage'. Each chunk is effectively "one page".
  const chunkedSlides = useMemo(() => {
    if (extraFeatures?.manualChunking) {
      return chunkArray(filteredSlides, itemsPerPage);
    }
    return null;
  }, [filteredSlides, itemsPerPage, extraFeatures]);

  // --------------------------------------------------------------------
  //   DYNAMIC ROWS LOGIC – only if extraFeatures?.dynamicRows === true
  //   (and NOT manualChunking)
  // --------------------------------------------------------------------
  const dynamicOverride =
    extraFeatures?.dynamicRows === true && !extraFeatures?.manualChunking;

  const effectiveItemsPerPage = dynamicOverride
    ? Math.min(filteredSlides.length, itemsPerPage)
    : undefined;

  const computedRows = dynamicOverride
    ? effectiveItemsPerPage
    : settings?.rows || 1;

  const computedSlidesPerRow = dynamicOverride
    ? settings?.slidesPerRow || 1
    : settings?.slidesPerRow || 1;

  const itemsPerSlide = dynamicOverride
    ? computedRows * computedSlidesPerRow
    : (settings?.rows || 1) * (settings?.slidesPerRow || 1);

  // Slick's infinite property can be set to false if there's not enough items to form a full slide
  const infiniteValue = filteredSlides.length > itemsPerSlide;
  // Key changes if rows/slidesPerRow changes
  const sliderKey = dynamicOverride
    ? `${computedRows}-${computedSlidesPerRow}`
    : undefined;

  // --------------------------------------------------------------------
  //   BUILD THE ACTUAL SLICK SETTINGS
  // --------------------------------------------------------------------
  const defaultSettings = {
    ...settings,
    // if we do manual chunking, we want just 1 row, 1 slide per row
    ...(extraFeatures?.manualChunking && {
      rows: 1,
      slidesPerRow: 1,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      adaptiveHeight:true,
    }),
    // if dynamicOverride is on (and not manual chunking), then set rows/slidesPerRow
    ...(dynamicOverride && {
      rows: computedRows,
      slidesPerRow: computedSlidesPerRow,
      infinite: infiniteValue,
    }),
    dots: true,
    speed: 500,
    autoplay: settings && typeof settings.autoplay !== "undefined" ? settings.autoplay : true,
    autoplaySpeed: 5000,
    arrows: !isSmallScreen && (settings?.arrows ?? true),
    prevArrow: (
      <CustomArrow
        imgSrc={prevArrow}
        isLeft={true}
        settings={settings}
      />
    ),
    nextArrow: (
      <CustomArrow
        imgSrc={nextArrow}
        isLeft={false}
        settings={settings}
      />
    ),
    beforeChange: (oldIndex, newIndex) => {
      // For manual chunking with infinite mode disabled, newIndex is the actual index.
      setCurrentSlide(newIndex);
    },
    appendDots: (dots) => (
      <Box
        sx={{
          position: "absolute",
          top: {
            xs: settings.dotsPosition?.top || "auto", // On small screens, use "auto" or the child's default top value
            sm: pagingFilter === "category"
            ? "-240px" // New position when filters are active on larger screens
            : settings.dotsPosition?.top || "auto",
            md: pagingFilter === "category"
                ? "-230px" // New position when filters are active on larger screens
                : settings.dotsPosition?.top || "auto",
          },
          width: "100%",
          display: "flex",
          justifyContent: "center",
          zIndex: 1,
        }}
      >
        <ul
          style={{ margin: 0, padding: 0, listStyle: "none", display: "flex" }}
        >
          {dots.map((dot, index) => (
            <li key={index} style={{ margin: "0 5px" }}>
              {React.cloneElement(dot)}
            </li>
          ))}
        </ul>
      </Box>
    ),
    customPaging: (i) => (
      <Box
        sx={{
          width: "12px",
          height: "12px",
          backgroundColor: "#FFFFFF",
          border: "2px solid #1E75C6",
          borderRadius: "50%",
          cursor: "pointer",
          transition: "background-color 0.3s ease",
          "&.slick-active": {
            backgroundColor: "#FF5733",
          },
        }}
      />
    ),
    dotsClass: "custom-dots",
  };

  // --------------------------------------------------------------------
  //   RENDERING THE DROPDOWNS & PAGING NUMERICALS
  // --------------------------------------------------------------------
  const totalItems = filteredSlides.length;
  // const currentPage = currentSlide + 1;

  // If manualChunking is ON, "currentSlide" means "which chunk" you're on.
  // So items go from (currentSlide * itemsPerPage + 1) to ...
  let startItem = 0;
  let endItem = 0;
  if (extraFeatures?.manualChunking && chunkedSlides) {
    startItem = currentSlide * itemsPerPage + 1;
    endItem = Math.min(startItem + itemsPerPage - 1, totalItems);
  } else {
    // Use the existing logic for dynamic rows or single row
    // (Essentially each "slide" has 'itemsPerSlide' items in dynamic mode, or 1 in normal mode).
    const realItemsPerSlide = itemsPerSlide || 1;
    startItem = currentSlide * realItemsPerSlide + 1;
    endItem = Math.min(startItem + realItemsPerSlide - 1, totalItems);
  }

  // Compute total pages for dots.
  const totalPages =
    extraFeatures?.manualChunking && chunkedSlides
      ? chunkedSlides.length
      : Math.ceil(filteredSlides.length / itemsPerSlide);

  // Handle extra dots click.
  const handleDotClick = (index) => {
     if (containerRef && containerRef.current) {
    // Scroll first
    containerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    
    // Then, after a delay, trigger the slide change
    setTimeout(() => {
      if (sliderRef && sliderRef.current) {
        sliderRef.current.slickGoTo(index);
      }
    }, 600);
  }

  };
  
  return (
    <Box ref={containerRef} sx={{ position: "relative", ...customStyles.wrapper }}>
      {extraFeatures && (
        <Box
          sx={{
            position:"relative",
            mb: 0.1,
            display: "flex",
            flexDirection: "row",
            flexWrap: { xs: "wrap", sm: "nowrap" },
            justifyContent: { xs: "center", sm: "space-between" },
            alignItems: "center",
            gap:{xs:0.5,md:2}
          }}
        >
          {/* Showing Dropdown */}
          <Box 
          sx={{
            width: { xs: "40%", sm: "auto" },
            order: { xs: 1, sm: 1 },
          }}
          >
            <Typography variant="body2">Showing</Typography>
            <TextField
            fullWidth
              select
              size="small"
              value={itemsPerPage}
              onChange={(e) => setItemsPerPage(Number(e.target.value))}
              sx={{ ...textFieldBaseStyles, width: { xs: "100%", md: "auto", } }}
              SelectProps={{
                MenuProps: {
                  sx: {
                    "& .MuiMenuItem-root": {
                      fontSize: "14px", // Adjust as needed
                    },
                  },
                },
              }}
            >
              {(extraFeatures?.itemsPerPageOptions || [10, 20, 50]).map(
                (option) => (
                  <MenuItem key={option} value={option}>
                    {option} / Page
                  </MenuItem>
                )
              )}
            </TextField>
          </Box>


          {/* Filter Dropdown */}
          {extraFeatures.pagingFilter && (
            <Box 
            sx={{
              width: { xs: "40%", sm: "auto" },
              order: { xs: 2, sm: 3 },
            }}
            >
              <Typography variant="body2">Sort By</Typography>
              <TextField
                select
                size="small"
                value={pagingFilter}
                onChange={(e) => {
                  setPagingFilter(e.target.value);
                  // Reset extra filter selections when changing primary filter
                  setSelectedYear(null);
                  // setSelectedTag(null);
                }}
                sx={{ ...textFieldBaseStyles, width: { xs: "100%", sm: "auto" } }}
                SelectProps={{
                  MenuProps: {
                    sx: {
                      "& .MuiMenuItem-root": {
                        fontSize: "14px", // Adjust as needed
                      },
                    },
                  },
                }}
              >
                {extraFeatures.pagingFilter.options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          )}

          {/* Paging Numerical (xx - yy of total) */}
          {extraFeatures.pagingNumerical && (
            <Box 
            sx={{
              mt: { xs: 2, sm: 0 },
              textAlign: "center",
              width: { xs: "100%", sm: "auto" },
              order: { xs: 3, sm: 2 },
              display:{xs:'none',sm:'block'}
            }}
            >
              <Typography
                sx={{ color: "#042D43", fontSize:{xs:"20px",md:"20px"}, fontWeight: 400,mt:{xs:1,md:5} }}
              >
                <span style={{ color: "#ABB0B2" }}>News Items</span>{" "}
                {String(startItem).padStart(2, "0")} -{" "}
                {String(endItem).padStart(2, "0")}{" "}
                <span style={{ color: "#ABB0B2" }}>of</span> {totalItems}
              </Typography>
            </Box>
          )}
        </Box>
      )}

      
      {/* EXTRA FILTER BUTTONS (rendered based on primary selection) */}
      {/* Render the Tag and Year buttons only if the primary filter value is "category" */}
      {pagingFilter === "category" && extraFeatures?.pagingFilter?.availableTags && (
        <Box sx={{ mt:2,mb: 2,textAlign:{xs:"center",sm:"left"} }}>
        <Typography variant="body2" sx={{ mb: 1 }}>
        {tagLabel}
        </Typography>
        <TextField
          select
          size="small"
          value={selectedTags}
          onChange={(e) => {
            const oldValue = selectedTags;     // previous selection
            let newValue = e.target.value;      // MUI's new array

            const hadAllBefore = oldValue.includes("__ALL__");
            const hasAllNow    = newValue.includes("__ALL__");

            // CASE 1: If we did NOT have "All" before, but now we do => user toggled "All" on
            // => keep only "__ALL__"
            if (!hadAllBefore && hasAllNow) {
              newValue = ["__ALL__"];
            }
            // CASE 2: If we HAD "All" before, and we still do, but there's also real tags => user toggled a real tag on
            // => remove "__ALL__" (switch from All to real tags)
            else if (hadAllBefore && hasAllNow && newValue.length > 1) {
              newValue = newValue.filter((val) => val !== "__ALL__");
            }
            // CASE 3: If we had "All" before, but now we don't => user toggled "All" off => do nothing special
            // CASE 4: If we never had "All" and still don't => user is toggling real tags => do nothing

            setSelectedTags(newValue);
          }}
          sx={{
            width: { xs: "80%", sm: "200px" },
            ...textFieldBaseStyles,
          }}
          SelectProps={{
            multiple: true,
            displayEmpty: true,
            MenuProps: {
              sx: {
                "& .MuiMenuItem-root": {
                  fontSize: "14px", // Adjust as needed
                },
              },
            },
            renderValue: (selected) => {
              // If none selected, show placeholder
              if (!selected || selected.length === 0) {
                return <em>{tagPlaceholder}</em>;
              }
              // If “All” token is present, show “All Category” (or “All Area”)
              if (selected.includes("__ALL__")) {
                return <em>{tagAllOption}</em>;
              }
            // Otherwise, show chips for each tag
    
              return (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={value}
                       // Stop the click from reopening the menu
                        onMouseDown={(e) => {
                          e.stopPropagation();
                        }}
                      onDelete={() =>
                        setSelectedTags((prev) => prev.filter((tag) => tag !== value))
                      }
                    />
                  ))}
                </Box>
              );
            },
          }}
        >
          {/* “All Category” item */}
        <MenuItem value="__ALL__">
          <em>{tagAllOption}</em>
        </MenuItem>

        {/* Real tag items */}
        {extraFeatures.pagingFilter.availableTags.map((tag) => (
          <MenuItem key={tag} value={tag}>
            {tag}
          </MenuItem>
          ))}
        </TextField>
      </Box>
      )}
      
      {pagingFilter === "category" && extraFeatures?.pagingFilter?.availableYears && (
        <Box sx={{ mt:1, mb: 2,textAlign:{xs:"center",sm:"left"} }}>
          <Typography variant="body2" sx={{ mb: 1 }}>
            Sort by Year
          </Typography>
          <TextField
            select
            size="small"
            value={selectedYear === null ? "DEFAULT" : selectedYear}
            onChange={(e) => {
              const val = e.target.value;
              if (val === "DEFAULT") {
                //  => "Select Year"
                setSelectedYear(null);
              } else if (val === "") {
                //  => "All Years"
                setSelectedYear("");
              } else {
                //  => A numeric year
                setSelectedYear(Number(val));
              }
            }}
            sx={{
              width: { xs: "80%", sm: "200px" }, // adjust as needed
              ...textFieldBaseStyles,
            }}
            SelectProps={{
              displayEmpty: true,
              MenuProps: {
                sx: {
                  "& .MuiMenuItem-root": {
                    fontSize: "14px", // Adjust as needed
                  },
                },
              },
              renderValue: (selected) => {
                if (selected === "DEFAULT") {
                  return "Select Year"; 
                }
                if (selected === "") {
                  return "All Years";
                }
                return selected; // The numeric year
              },
            }}
          >
            {/* "All" option */}
            <MenuItem value="DEFAULT">
            <em>Select Year</em>
          </MenuItem>

          {/* All => "All Years" */}
          <MenuItem value="">
            <em>All Years</em>
          </MenuItem>
            {/* Render each available year as a dropdown option */}
            {extraFeatures.pagingFilter.availableYears.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      )}

      {/* Region Dropdown */}
{pagingFilter === "category" && extraFeatures?.pagingFilter?.availableRegions && (
  <Box
    sx={{
      textAlign:{xs:"center",sm:"left"},
      order: { xs: 3, sm: 4 },
    }}
  >
    <Typography variant="body2">Region</Typography>
    <TextField
      select
      size="small"
      value={selectedRegion || ""}
      onChange={(e) => setSelectedRegion(e.target.value || null)}
      sx={{ ...textFieldBaseStyles, width: { xs: "80%", sm: "200px" } }}
      SelectProps={{
        displayEmpty: true,
        MenuProps: {
          sx: {
            "& .MuiMenuItem-root": {
              fontSize: "14px", // Adjust as needed
            },
          },
        },
        renderValue: (selected) => {
          if (selected === "") {
            return "All Region";
          }
          return selected;
        },
      }}
    >
       {/* Placeholder / "All" option */}
        <MenuItem value="">
          <em>All Region</em>
        </MenuItem>
      {extraFeatures.pagingFilter.availableRegions.map((region) => (
        <MenuItem key={region} value={region}>
          {region}
        </MenuItem>
      ))}
    </TextField>
  </Box>
)}


     {extraFeatures?.pagingNumerical && (
            <Box 
            sx={{
              mt: { xs: 2, sm: 0 },
              textAlign: "center",
              width: { xs: "100%", sm: "auto" },
              order: { xs: 3, sm: 2 },
              display:{xs:'block',sm:'none'}
            }}
            >
              <Typography
                sx={{ color: "#042D43", fontSize:{xs:"20px",md:"20px"}, fontWeight: 400,mt:{xs:1,md:5} }}
              >
                <span style={{ color: "#ABB0B2" }}>News Items</span>{" "}
                {String(startItem).padStart(2, "0")} -{" "}
                {String(endItem).padStart(2, "0")}{" "}
                <span style={{ color: "#ABB0B2" }}>of</span> {totalItems}
              </Typography>
            </Box>
          )}

      {/**
       * IF manualChunking is ON, each "slide" is an array of items
       * (the chunk). ELSE, each "slide" is just a single item from
       * filteredSlides.
       */}
      <Slider ref={sliderRef} key={extraFeatures?.manualChunking ? `slider-${itemsPerPage}-${chunkedSlides?.length}` : sliderKey} {...defaultSettings}>
        {extraFeatures?.manualChunking && chunkedSlides
          ? chunkedSlides.map((chunk, i) => (
              <Box key={i} sx={{ ...customStyles.slide }}>
                {/* each chunk might have up to itemsPerPage items */}
                {chunk.map((item, idx) =>
                  renderItem ? (
                    <Box key={idx}>{renderItem(item, idx)}</Box>
                  ) : (
                    <img key={idx} src={item.image} alt="" style={{ width: "100%" }} />
                  )
                )}
              </Box>
            ))
          : // ---------------------------------------------------
            //   ORIGINAL RENDER: ONE ITEM PER "SLICK" ITEM
            //   or multi-row if dynamicOverride is true
            // ---------------------------------------------------
            filteredSlides.map((slide, index) => (
              <Box key={index} sx={{ ...customStyles.slide }}>
                {renderItem ? (
                  renderItem(slide, index)
                ) : (
                  <img src={slide.image} alt="" style={{ width: "100%" }} />
                )}
              </Box>
            ))}
      </Slider>
      {/* Render extra dots at the bottom if the boolean flag is enabled */}
     {extraFeatures && (
      <Box>
         {extraFeatures?.renderBottomDots && totalPages > 1 && (
        <ExtraDots totalPages={totalPages} currentSlide={currentSlide} onDotClick={handleDotClick} />
      )}
      {extraFeatures?.pagingNumerical && (
            <Box sx={{ mt: 2, textAlign: "center" }}>
              <Typography
                sx={{ color: "#042D43", fontSize:{xs:"20px",md:"20px"}, fontWeight: 400 }}
              >
                <span style={{ color: "#ABB0B2" }}>News Items</span>{" "}
                {String(startItem).padStart(2, "0")} -{" "}
                {String(endItem).padStart(2, "0")}{" "}
                <span style={{ color: "#ABB0B2" }}>of</span> {totalItems}
              </Typography>
            </Box>
          )}
      </Box>
     )}
    </Box>
  );
};


export default CustomCarousel;
