import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import CarouselImg1 from "../../../../img/home/carousel_img_1.png";
import CarouselImg2 from "../../../../img/home/carousel_img_2.png";
import CarouselImg3 from "../../../../img/home/carousel_img_3.png";
import CarouselImg4 from "../../../../img/home/carousel_img_4.png";
import CarouselImg5 from "../../../../img/home/carousel_img_5.png";
import CarouselImg6 from "../../../../img/home/carousel_img_6.png";
import CustomCarousel from "../CustomCarousel";

export const HeroCarousel = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(
    theme.breakpoints.between("900", "1080")
  );
  const isSm = useMediaQuery(theme.breakpoints.between(300,900));
  const hideDots = useMediaQuery(theme.breakpoints.down(300));

  const slides = [
    {
      image: CarouselImg2,
      title: "Unified Platform",
      description:
        "An average site uses a minimum of 12 different electronic systems to collect and capture clinical study data." ,
      desp2:"CliniOps Conduct, Edge and Connect, designed on an Unified platform provides a seamless experience for the study team.",
    },
    {
      image: CarouselImg1,
      title: "Offline eSource",
      description:
        "Acceleration of eSource adoption is a critical step in modernizing the conduct of clinical trials.",
      desp2:"CliniOps's patented application runs on mobile devices and can operate completely offline, enabling digital trials even in resource-poor settings.",
    },
    {
      image: CarouselImg3,
      title: "Hybrid DCT",
      description:
        "In a recent survey, about 77% of sponsors said they plan to run a hybrid trial in the next 12 months compared to 59% for the previous year", 
      desp2:"CliniOps patented location agnostic platform supports home-visit, site-visit, lab-visit, tele-visit while reducing burden for both sites and patients",
    },
    {
      image: CarouselImg4,
      title: "Inclusive Trials",
      description:
        "50% of FDA clinical trials in the US are conducted in only 1-2% of zip codes",
      desp2:"Through inclusive clinical trials, CliniOps recognizes diversity, develops effective treatments, and reduces biases in clinical research.",
    },
    {
      image: CarouselImg6,
      title: "Count Africa In",
      description:
        "From 2012 to 2023, clinical trials conducted in Africa were only 2.2% of the global percentage.",
      desp2:"CliniOps platform is used in 35+ Africa, LMIC countries, embraces Africa's genetic diversity, addresses healthcare disparities and promotes equity.",
    },
    {
      image: CarouselImg5,
      title: "Clinical Innovation",
      description:
        "Generative AI is expected to produce $60 billion to $110 billion in annual value across the pharmaceutical industry value chain.", 
      desp2:"CliniOps utilizes AI to optimize data analysis for better outcomes and transform Clinical trial Efficiency.",
    },
  ];

  const renderItem = (slide) => (
    <Box
      sx={{
        position: "relative",
        textAlign: "center",
        color: "#fff",
        marginTop: { xs: "70px", md: "95px" }, // Adjust for header height
        width: "100%",
        overflow: "hidden", // Prevent horizontal scroll
      }}
    >
      {/* Image Section */}
      <img
        src={slide.image}
        alt={slide.title}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      />
      {/* Title and Description Section */}
      <Box
        sx={{
          padding: { xs: "2px 10px", md: "15px 20px" },
          backgroundColor: "#042D43",
          color: "#fff",
          textAlign: "left",
          display: "flex",
          flexDirection: { xs: "row", md: "row" },
          justifyContent: "flex-start",
          alignItems: "center",
          gap: { xs: "0.5em", md: "0.5em" },
          height:'auto',
          minHeight:isMediumScreen ? "100px" : { xs: "70px", md: 'auto' }, // Apply dynamic height
          // maxHeight:"200px"
        }}
      >
        <Typography
        variant="h1Blue"
          sx={{
            textAlign: { xs: "left", md: "left" },
            lineHeight: 1,
            whiteSpace:'nowrap'
          }}
        >
          {slide.title}
        </Typography>
        <Box
          sx={{
            borderLeft: "0.1px solid #FFFFFF",
            height: { xs: "50px", md: "100px" },
            margin:'0 1rem'
          }}
        ></Box>
        {/* Description Section */}
        <Box>
          {/* {slide.description
            .split(/(?<=\.\s)|(?<=\byear\b)|(?<=\bcodes\b)(?![0-9])/g) // Updated regex
            .map((sentence, idx) => (
              <Typography
                key={idx}
                variant="body1"
                sx={{
                  mb: 1,
                  lineHeight: 1.2,
                  fontWeight: 400,
                  fontSize: { xs: "6px", md: "20px" },
                  color: "#FFFFFF",
                  // whiteSpace:'nowrap'
                }}
              >
                {sentence.trim()}
              </Typography>
            ))} */}
             
              <Typography
                // key={idx}
                variant="body1"
                sx={{
                  mb: {xs:0.5,md:1},
                  lineHeight:{xs:1.5,md:1.2},
                  color: "#FFFFFF",
                  // whiteSpace:'nowrap'
                }}
              >
                {slide.description}
              </Typography>
              <Typography
                // key={idx}
                variant="body1"
                sx={{
                  // mb: 1,
                  lineHeight: {xs:1.5,md:1.2},
                  color: "#FFFFFF",
                  // whiteSpace:'nowrap'
                }}
              >
                {slide.desp2}
              </Typography>
           
        </Box>
      </Box>
    </Box>
  );

  const customStyles = {
    wrapper: {
      width: "100%",
      overflow: "hidden", // Prevent overflow causing horizontal scrollbars
    },
    slide: {
      padding: "0px",
    },
    arrows: {
      position: "absolute",
      left: "50px",
      top:'50%',
      transform: "translateY(-50%)",
      fontSize: "24px",
      cursor: "pointer",
    },
  };

  const settings = {
    // autoplay:true,
    arrowTop: isSm? '35%' : isMediumScreen ? '40%' : '50%',
    dotsPosition: {
      top:hideDots? '0' : isSm ? "80px" : "120px",
    },
  };

  return (
    <CustomCarousel
      slides={slides}
      renderItem={(slide, index) => renderItem(slide, index)}
      customStyles={customStyles}
      settings={settings}
    />
  );
};

export default HeroCarousel;

// // import { Box, Grid } from '@mui/material';

// import Imgimage1 from '../../../../img/home/image2.png';

// // const template = () => {
// //   return (
// //     <>
// //       <Box
// //         sx={{
// //           pt: { xs: 15, md: 9 },
// //           pb: { xs: 5, md: 3 },
// //           width: '100%',
// //           display: 'flex',
// //           justifyContent: 'center',
// //           alignItems: 'center',
// //         }}
// //       >
// //         <Grid container spacing={6} alignItems="center">
// //           <Grid item xs={12}>
// //             <img
// //               src={`${Imgimage1}`}
// //               alt="Unified Platform"
// //               title="Unified Platform"
// //               style={{ width: '100%', height: 'auto', display: 'block' }}
// //             />
// //           </Grid>
// //         </Grid>
// //       </Box>
// //     </>
// //   );
// // };

// // export default template;

// import React from 'react';
// import { Box, Divider } from '@mui/material';

// // import Imgbackground from '../../../../img/product/_0/product-offerings.png';

// const template = () => {
//   return (
//     <>
//     <Box>
//     <Box style={{
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//       padding: "50px 0 0 0",
//       overflow: "hidden",

//       }}>
//         <img
//           src={`${Imgimage1}`}
//           alt="Unified Platform"
//           style={{
//             maxWidth: "100%",
//             maxHeight: "100%",
//             objectFit: "contain" }}
//         />
//       </Box>
//       </Box>
//       <Divider/>
//     </>
//   );
// }

// export default template;
