import React from "react";
import { Box, Stack, TextField, Button, Typography,InputAdornment, IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import cliniopsLogo from '../../../img/product/conduct/CliniOpsConduct.png'
// import logoImage from "../path/to/logo.png";

const template = () => {

    const [showPassword, setShowPassword] = React.useState(false);

  return (
    <>
    {/* <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={message}
        action={actionSnackbar}
        sx={{ mt: "70px", boxShadow: 8 }}
      >
        <Alert onClose={handleSnackbarClose} severity={severity} variant="filled">
          {message}
        </Alert>
      </Snackbar> */}
      <form >
        <Box
          sx={{
            // White card container with box shadow, centered
            border: "1px solid rgba(0, 0, 0, 0.25)",
            mx: "auto",
            mt: { xs: 2, md: 4 },            // Responsive top margin
            py: { xs: 3, md: 8 },            // Responsive vertical padding
            px: { xs: 2, md: 1 }, 
            mb:10,
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.16)",
            borderRadius: "10px",
            width: { xs: "90%", md: "651px" }, // 90% width on extra-small screens, 40% on medium and up
            backgroundColor: "white",
            backgroundColor: "white",
            textAlign: "center",
            position:"relative",
            zIndex:5,
          }}
        >
          {/* Logo / Title */}
         <Box
         component='img'
         src={cliniopsLogo}
         sx={{
            display: "block",
            mx: "auto",
            mb: 4,
            width: { xs: "120px", sm: "180px" }, // scale up on bigger screens
            height: "auto",
          }}
         />

          {/* Username & Password fields */}
          <Stack spacing={2} sx={{ mb: 4,alignItems:"center" }}>
            <TextField
              size="small"
              placeholder="User Name"
              name="userName"
              variant="outlined"
            //   fullWidth
              required
              sx={{
                width: { xs: "80%", sm: "60%" },
                "& .MuiOutlinedInput-root": {
                  borderRadius: "17px",
                  "& fieldset": {
                    borderRadius: "17px",
                  },
                  "@media (max-width:900px)": {
                    "& .MuiInputBase-input": {
                      fontSize: "14px",
                      lineHeight: 1.5,         // ensure enough line height
                      // padding: "8px 34px 8px 14px",     // manually adjust vertical/horizontal padding 
                    },
                  },
                },
                // mx: "auto",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircleOutlinedIcon sx={{ color: "#999" }} />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              size="small"
              placeholder="Password"
              type={showPassword ? 'text' : 'password'}
              name="password"
              variant="outlined"
            //   fullWidth
              required
              sx={{
                width: { xs: "80%", sm: "60%" },
                "& .MuiOutlinedInput-root": {
                  borderRadius: "17px",
                  "& fieldset": {
                    borderRadius: "17px",
                  },
                  "@media (max-width:900px)": {
                    "& .MuiInputBase-input": {
                      fontSize: "14px",
                      lineHeight: 1.5,         // ensure enough line height
                      // padding: "8px 34px 8px 14px",     // manually adjust vertical/horizontal padding 
                    },
                  },
                },
                // mx: "auto",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <KeyOutlinedIcon sx={{ color: "#999" }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          {/* Login Button */}
          <Button
            // disabled={disabled}
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: "#042D43",
              color: "#FFFFFF",
              fontWeight: 400,
              fontSize: "14px",
              borderRadius: "12px",
              textTransform: "capitalize",
              px: 4,
              "&:hover": {
                backgroundColor: "#042D43",
              },
              mb:1,
            }}
          >
            Log In
          </Button>

          {/* Forgot Password link */}
          <Box
            sx={{
              mt: 1,
              fontSize: 14,
              textDecoration: "underline",
              cursor: "pointer",
              mb:2
            }}
          >
            Forgot Password?
          </Box>

          {/* Divider with 3 dots */}
          <Box sx={{ display: "flex", alignItems: "center", my: 3 }}>
          <Box sx={{ flex: 1, borderBottom: "1px solid #ccc" }} />
          <Box sx={{ mx: 1, display: "flex", gap: "8px" }}>
            {/* First circle: dark blue fill */}
            <Box
              sx={{
                width: 12,
                height: 12,
                borderRadius: "50%",
                backgroundColor: "#042D43",
              }}
            />
            {/* Second circle: transparent fill + dark blue border */}
            <Box
              sx={{
                width: 12,
                height: 12,
                borderRadius: "50%",
                backgroundColor: "transparent",
                border: "2px solid #1E75C6",
              }}
            />
            {/* Third circle: bright blue fill */}
            <Box
              sx={{
                width: 12,
                height: 12,
                borderRadius: "50%",
                backgroundColor: "#07A7F9",
              }}
            />
          </Box>
          <Box sx={{ flex: 1, borderBottom: "1px solid #ccc" }} />
        </Box>

          {/* "OR" text */}
          <Typography sx={{ mb: 2 }}>OR</Typography>

          {/* SSO Button */}
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#8296A1",
              color: "#FFFFFF",
              fontWeight: 400,
              fontSize: "14px",
              borderRadius: "12px",
              textTransform: "capitalize",
              px: 4,
              "&:hover": {
                backgroundColor: "#8296A1",
              },
            }}
            href="https://ui.secureaccess.exostartest.com/iamui/mfa/userReference"
            target="_blank"
          >
            Log in with SSO
          </Button>
        </Box>
      </form>
    </>
  );
}
export default template;