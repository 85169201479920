import React from 'react';
import { Grid, Divider, Box } from '@mui/material';

import { PageHero, PageContent } from '../../component/page';
import bottomWave from "../../../img/home/wave2.png";

// Page Imports
import _0 from './demo/_0';
import _1 from './demo/_1';
import _2 from './demo/_2';
import _3 from './demo/_3';

const template = () => {
  return (
    <>
      <_0 />
      <Box 
      sx={{
        backgroundColor: "#F2F2F2",
        //   backgroundImage: `url(${bottomWave})`,
        //   backgroundRepeat: "no-repeat",
        //   backgroundPositionY: "0",
        //   backgroundSize: "contain",
        //   // display: "flex",
        //   // alignItems: "center",
        //   // justifyContent: "center",
        //   py: 4,

      }}
      >
        <Grid item sx={{
          pt:4,
        }}>
          <_2/>
          <_3/>
          <_1 />
        </Grid>
      </Box>
    </>
  );
}

export default template;