import React from 'react';
import { Grid, Typography, Stack } from '@mui/material';

import { Product } from '../../../component/page';

import ImgeCRF from '../../../../img/service/study-setup/eCRF.png';
import CustomTypography from '../../../component/customTypography';

const template = () => {
  return (
    <>
      <Grid container direction='column' spacing={0} sx={{ mt: 0 }}>
            {/* eCRF Configuration  */}
          <Grid item>
              <Product
                ImgSrc={ImgeCRF}
                Name="eCRF Configuration"
                Summary={
                  <>
                  <CustomTypography variant='body2' sx={{ mt: 1 }}>
                  CliniOps provides CRF configuration services for clinical trials, ensuring alignment with CDISC (Clinical Data Interchange Standards Consortium) standards. Our team configures CRFs to optimize data collection, enhancing consistency and regulatory compliance. By adhering to CDISC standards, CliniOps ensures seamless integration of clinical trial data across systems. Our approach streamlines data management, minimizes errors, and accelerates trial timelines. 
                  <br></br><br></br>
                  Our trusted experts delve into complicated electronic data collection forms, design branching logics, and develop in-form and cross-form validations and edit checks to maximize data cleaning at the field level. Our platform offers a library of forms in various therapeutic areas which are CDASH and CDISC compliant as per industry standards. We also provide configuration support for multi-lingual eCRF forms, and any localization support needed to configure the application workflow to align with the site’s local processes.
                </CustomTypography>
                </>
                }
              />
              
          </Grid>
      </Grid>
    </>
  );
}

export default template;

//Commenting Previous code

// import React from 'react';
// import { Typography, Stack } from '@mui/material';

// import { PageSectionRightImg } from '../../../component/page';

// import ImgDataConfig from '../../../../img/service/study-setup/dataConfig.jpeg';

// const template = () => {
//   return (
//     <>
//       <PageSectionRightImg
//         heading={
//           <Stack direction="row" spacing={1} alignItems='center' justifyContent={{xs: 'center', md: 'flex-start'}}>
//             <Typography variant="h2" component="h2" className="co-page-heading" sx={{ textAlign: {xs: 'center', md: 'left'} }}>
//               eCRF and ePRO Configuration
//             </Typography>
//           </Stack>
//         }
//         imgUrl={ImgDataConfig}
//         imgTitle="eCRF and ePRO Configuration"
//       >
//         <Typography sx={{ mt: 1 }}>
//           Our experts will take care of your complicated electronic data collection forms, design branching questions, and develop in-form and cross-form validations and edit checks to maximize data cleaning at the field level.
//         </Typography>
//         <Typography sx={{ mt: 1 }}>
//           Our product also offers a library of forms in various therapeutic areas which are CDASH and CDISC compliant as per industry standards.
//         </Typography>
//         <Typography sx={{ mt: 1 }}>
//           We also provide configuration support for multi-lingual eCRF forms, and any localization support needed to configure the application workflow to align with the site’s local processes.
//         </Typography>
//       </PageSectionRightImg>
//     </>
//   );
// }

// export default template;