import react from 'react';

import bottomWave from "../../../../img/home/wave2.png";
import { Box } from '@mui/material';


const template = () =>{
    return (
      
 <Box sx={{ position: "relative",zIndex:1
 }}>
  {/* Absolutely positioned wave */}
  <Box
    component="img"
    src={bottomWave}
    alt="wave"
    sx={{
      position: "absolute",
      top: {xs:'130px',md:10},
      left: 0,
      width: "100%",
      height: "auto",      // or if you want it pinned to bottom:
      // bottom: 0, top: "auto",
    }}
  />
  {/* actual content here */}
</Box>

    )
}

export default template;