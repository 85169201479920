const RouterList = ({
  home: {
    _0: '/',
  },
  product: {
    _0: '/products/',
    connect: '/products/cliniops-connect/',
    edge: '/products/cliniops-edge/',
    conduct: '/products/cliniops-conduct/',
    insights:  '/products/cliniops-insights/',
  },
  service: {
    _0: '/services/',
    studysetup: '/services/study-setup/',
    clinicaldatamanagement: '/services/clinical-data-management/',
    // biostatisticprogramming: '/services/biostatistics-programming/',
    supporthelpdesk: '/services/support-helpdesk/',
  },
  partnerships: {
    partnerships:'/partnerships/',
  },
  about: {
    mission: '/about/mission/',
    leadershipteam: '/about/leadership-team/',
    career: '/about/careers/',
    career_1: '/about/careers/project-manager/',
    career_2: '/about/careers/biostatistician/',
    career_3: '/about/careers/sales-executive/',
    career_4: '/about/careers/software-engineer-full-stack/',
    career_5: '/about/careers/software-engineer-mobile-development/',
    career_6: '/about/careers/clinical-data-management/',
    career_7: '/about/careers/manager-quality-control/',
    career_8: '/about/careers/senior-software-engineer/'
  },
  media: {
    newsroom: '/media/newsroom/',
    customerstory: '/media/customer-stories/',
    customerstory_1: '/media/customer-stories/configurable-platform-speeds-development-of-complex-workflows-in-ground-breaking-clinical-study',
    customerstory_2: '/media/customer-stories/DCT-in-Action-Menopause-Study-Started-and-Completed-During-Pandemic-conducted-across-multiple-sites-in-Mexico',
    customerstory_3: '/media/customer-stories/Partnership-with-Crila-Health-Clinical-Trial-on-Prostate-Health-conducted-across-multiple-sites-in-Mexico',
    customerstory_4: '/media/customer-stories/Onchocerciasis-Study-in-Democratic-Republic-of-Congo-and-Cote-d-Ivoire-Technology-and-Determination-to-Reach-Underserved-Populations',
    customerstory_5: '/media/customer-stories/A-cluster-randomized-trial-to-study-the-effect-of-Family-centered-Model-of-HIV-Care-FAM-CARE',
    customerstory_6: '/media/customer-stories/Clinical-performance-of-a-smartphone-enabled-home-based-urine-testing-platform-compared-to-lab-based-standard-of-care-testing',
    customerstory_7: '/media/customer-stories/Moving-from-Paper-to-Electronic-Consent-involving-patients-with-substance-abuse',
    customerstory_8: '/media/customer-stories/A-cluster-randomized-trial-to-improve-Maternal-Child-Outcomes-for-HIV-positive-pregnant-women-in-Lesotho-Africa-IMPROVE-using-Disruptive-Technologies',
    customerstory_9: '/media/customer-stories/Enabling-Remote-Monitoring-in-a-Malaria-study-Regimen-Optimization-Trial-of-PfSPZ-Vaccine-in-Equatorial-Guinea',
    customerstory_10: '/media/customer-stories/Onchocerciasis-Study-in-Ghana-Deploying-Mobile-Technologies-for-High-Quality-Data-Capture-in-Remote-Sites',
    customerstory_11: '/media/customer-stories/Offline-Data-Collection-with-sites-in-Africa-Study-to-Evaluate-Safety-Immunogenicity-and-Efficacy-of-PfSPZ-Vaccine-in-HIV-Negative-and-HIV-Positive-Tanzanian-Adults',
    customerstory_12: '/media/customer-stories/Unified-Technology-Platform-support-for-Phase-1B-dose-escalation-study-on-subjects-with-chronic-kidney-disease-CKD',
    customerstory_13: '/media/customer-stories/Electronic-Data-Capture-for-NKF-Initiative-Path-to-Wellness-Serving-Population-At-Risk-of-Kidney-Disease',
    customerstory_14: '/media/customer-stories/Type-2-Diabetes-Hepatitis-B-and-Chronic-Bronchial-Asthma-studies-One-Technology-Platform-across-multiple-sites-in-India',
    customerstory_15: '/media/customer-stories/Stepping-in-for-Data-Management-and-Analysis-Study-on-Catheter-related-blood-stream-infections-CRBSI-for-post-marketing-experience-with-Neutrolin-at-20-dialysis-units-across-Germany',
    customerstory_16: '/media/customer-stories/Mobile-Technologies-Assist-Intervention-Study-to-Integrate-Pediatric-TB-Services-Into-Child-Healthcare-Services-INPUT-Study-in-Cameroon-and-Kenya',
    customerstory_17: '/media/customer-stories/Lymphatic-Filariasis-study-in-Papua-New-Guinea-Indonesia-India-Haiti-and-Fiji-A-Clinical-trial-with-26836-participants-in-LMIC-countries-with-offline-online-capture-using-mobile-devices',
    customerstory_18: '/media/customer-stories/Patient-Centricity-through-Telemedicine-Amidst-Pandemic-A-Clinical-Trial-for-Treatment-of-COVID-19-in-India-across-20-sites-supported-in-10-languages',

    pressrelease: '/media/press-release/',
    blog: '/media/blogs/',
    blog_1: '/media/blogs/diversity-in-clinical-research',
    blog_2: '/media/blogs/what-the-future-holds-for-clinical-trials-post-covid-19',
    blog_3: '/media/blogs/initial-impact-of-covid-19-on-clinical-trials-and-technology-solutions',
    blog_4: '/media/blogs/telemedicine-saves-stalled-trials-by-covid-19-pandemic',
    blog_5: '/media/blogs/covid-19-pandemic-stalls-clinical-trials-worldwide'
  },
  contact: {
    _0: '/contact/',
    quote: '/contact/request-quote',
    demo: '/contact/request-demo/'
  },
  login:{
    _0:'/login'
  },
  policy: {
    privacy: '/policy/privacy',
    cookie: '/policy/cookie/'
  },
});

export const Website = {
    vektor:'https://www.vektormedical.com/',
    crila:'https://crilahealth.com/',
    Mdgh:'https://www.medicinesdevelopment.com/'
}

export default RouterList;