import React from 'react';
import { Typography } from '@mui/material';

import { blueGrey } from '@mui/material/colors';

const template = () => {
  return (
    <>
      <Typography variant="h9" sx={{paddingBottom:"15px"}} component="h1">
      Configurable Platform for handling adjudication, complex workflows and imaging data, Speeds Development of Ground-Breaking Clinical trial on cardiac arrhythmia
      </Typography>
        <a href="https://www.vektormedical.com/" target={'_blank'} class="linkcolor">Vektor Medical, Inc.</a>
    </>
  );
}

export default template;