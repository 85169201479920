import React from "react";
import { Box, Typography, Link, useMediaQuery, useTheme } from "@mui/material";
import CustomCarousel from "../CustomCarousel";
import forbesBusinessLogo from "../../../../img/home/forbesBusiness_logo.png";
import forbesTechLogo from "../../../../img/home/forbesTech_logo.png";
import fastCompanyLogo from "../../../../img/home/fastCompany_logo.png";
import ceoMagazineLogo from "../../../../img/home/ceoMagazine_logo.png";
import ACRPLogo from "../../../../img/home/ACRP_logo.png";
import PLOSLogo from "../../../../img/home/PLOS_logo.png";
import rutgersLogo from "../../../../img/home/rutgersHealth_logo.png";
import playCircleLogo from "../../../../img/home/play_circle_logo.png";
import "../../../../css/style.css";

const template = () => {
  const slides = [
    {
      title: "Counting Africa In For Clinical Trials",
      date: "Sep 24, 2024",
      logo: forbesBusinessLogo,
      description:
        "Avik Pal, the founder and CEO of CliniOps, a technology and data company with a patented unified platform approach to digitizing clinical trials, finds universal solutions to counter adversities.",
      link: "https://www.forbes.com/councils/forbesbusinesscouncil/2024/09/24/counting-africa-in-for-clinical-trials/",
    },
    {
      title:
        "Clinical Trials with AI: Chasing efficiency and inclusive participation",
      date: "Jan 12, 2023",
      logo: fastCompanyLogo,
      description:
        "Innovations using AI in the field of clinical trials are here to stay. But, with great power comes great responsibilities.",
      link: "https://www.fastcompany.com/90990503/clinical-trials-with-ai-chasing-efficiency-and-inclusive-participation",
    },
    {
      title: "Celebrating National Science Day: Insights from Industry Leaders",
      date: "Feb 28, 2024",
      logo: ceoMagazineLogo,
      description:
        "As part of India’s National Science Day celebration, The CEO Magazine features insights from industry leaders on the significance of science and technology in shaping India’s future. Avik Pal, the CEO of CliniOps, is featured on the list.",
      link: "https://www.theceo.in/business-experts/celebrating-national-science-day-insights-and-reflections-from-industry-leaders",
    },
    {
      title: "Innovations using AI in clinical trials are here to stay.",
      date: "Nov 14, 2023",
      logo: forbesTechLogo,
      description:
        "Yerramalli Subramaniam, CTO of CliniOps and a technology leader in DCT and unified platform, emphasizes balances in applying AI to enhance clinical trials (CTs) and ensuring the safety and security of patient data.",
      link: "https://www.forbes.com/councils/forbestechcouncil/2023/11/14/ai-for-efficient-clinical-trials-its-risky-but-optimism-rules/",
    },
    {
      title:
        "Using Disruptive and Convergent Technologies to ‘Catch More Waves’",
      date: "Mar 03, 2023",
      logo: ACRPLogo,
      description:
        "Nadina Jose, MD, Assistant Professor at the School of Health Professions at Rutgers, and Avik Pal, CEO of CliniOps, provide insights and tactics to streamline the decision-making process with disruptive technologies for clinical trials.",
      link: "https://acrpnet.org/2023/03/03/using-disruptive-and-convergent-technologies-to-catch-more-waves",
    },
    {
      title: "PLOS Publication, Onchocerciasis",
      date: "May 19, 2023",
      logo: PLOSLogo,
      description:
        "The study reports results from a randomized clinical trial comparing the tolerability and efficacy of IDA vs. a comparator treatment (ivermectin plus albendazole, IA) in persons with onchocerciasis. It used an EDC system developed by CliniOps to capture and transfer clinical data.",
      link: "https://journals.plos.org/plosntds/article?id=10.1371/journal.pntd.0011365&rev=2",
    },
    {
      title: "Diversity and Inclusion in Site Identification- Part II",
      subTitle: "Yasheca Ebanks",
      date: "Jan 26, 2023  ",
      logo: rutgersLogo,
      despLogo: playCircleLogo,
      link: "https://rutgers.mediaspace.kaltura.com/media/GMT20230125-220305_Recording_1920x1080/1_o7i84u2n",
    },
    {
      title:
        "'Stayin' Alive': Patient Centricity In Decentralized Clinical Trials",
      date: "Nov 11, 2022",
      logo: forbesTechLogo,
      description:
        "Yerramalli Subramaniam, CTO of CliniOps , discusses lingering challenges such as ensuring Patient Safety, creating Equity, and following the Single Source Of Truth in Hybrid and decentralized clinical trials.",
      link: "https://www.forbes.com/councils/forbestechcouncil/2022/11/11/stayin-alive-patient-centricity-in-decentralized-clinical-trials/",
    },
  ];
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(
    theme.breakpoints.between("900", "1150")
  );
  const smallScreen = useMediaQuery(theme.breakpoints.down("600"));

  const customStyles = {
    wrapper: {
      maxWidth: "85%",
      margin: "0 auto",
      position: "relative",
    },
    slide: {
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)", // Two columns per row
      gap: "20px", // Space between cards
      justifyContent: "center",
      alignItems: "strech", // Ensure all cards stretch to the same height
      padding: "10px",
      
    },
    card: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "20px",
      // boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
      boxShadow: "5px 5px 8px rgba(0, 0, 0, 0.2)",
      borderRadius: "10px",
      background: "#fff",
      border: "1px solid #ddd",
      height: "100%",
      minHeight: "320px", // Dynamically adapt to tallest card
      boxSizing: "border-box",
      position: "relative",
      flexDirection: smallScreen
        ? "column-reverse"
        : isMediumScreen
        ? "column-reverse"
        : "row",
    },
    content: {
      textAlign: "left",
      // flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },

    image: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      // flexDirection:"column",
    },
    logo: {
      width: isMediumScreen ? "200px" : "250px",
      height: isMediumScreen ? "100px" : "200px",
      objectFit: isMediumScreen ? "contain" : "contain", // Ensure logos fit within the specified dimensions
    },
    navIcon: {
      position: "absolute",
      top: "10px",
      right: "10px",
      width: "24px",
      height: "24px",
      cursor: "pointer",
    },
    despLogo: {
      width: isMediumScreen ? "40px" : "100px",
    },
  };

  const renderItem = (slide) => (
    <Box sx={customStyles.card}>
      <Box sx={customStyles.content}>
        <Typography
          variant="body3"
          gutterBottom
        >
          {slide.title}
        </Typography>
        {slide?.subTitle ? (
          <Typography
            variant="h4"
            sx={{
              marginBottom: "10px",
            }}
          >
            {slide.subTitle}
          </Typography>
        ) : null}
        <Typography
          variant="h4"
          gutterBottom
        >
          {slide.date}
        </Typography>
        <Typography
          variant="body4"
          className="responsive-clamp"
          gutterBottom
        >
          {slide.description}
        </Typography>
        {slide?.despLogo ? (
          <img src={slide.despLogo} style={customStyles.despLogo} />
        ) : null}
        <Link
          href={slide.link}
          target="_blank"
          sx={{ fontSize: "14px", fonWeight: "400" }}
          underline="hover"
          color="#1E75C6"
        >
          Know More...
        </Link>
      </Box>
      <Box sx={customStyles.image}>
        <img
          src={slide.logo}
          alt={slide.title}
          // className="newsroom-logos"
          style={customStyles.logo}
        />
        <Box />
      </Box>
    </Box>
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2, // Show 1 "grid" per slide
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 4000,
    rows: 2, // Render 2 rows per slide
    arrowOutside: true,
    arrowOutSideOffSet: isMediumScreen ? "-80px" : "-50px",
    dotsPosition: {
      top: "-10px",
    },
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1, // Adjust to single column on smaller screens
          slidesToScroll: 1,
          rows: 4, // Single row on smaller screens
          arrowOutside: true, // Set arrow position for smaller screens
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1, // Adjust to single column on smaller screens
          slidesToScroll: 1,
          rows: 1, // Single row on smaller screens
          arrowOutside: false, // Set arrow position for smaller screens
        },
      },
    ],
  };

  return (
    <Box sx={{ margin: { xs: "3em 0 0 0", md: "3em 0 3em 0" } }}>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{
          color: "#042D43",
          fontSize: { xs: "30px", md: "48px" },
          fontWeight: "300",
          marginBottom: "40px",
        }}
      >
        Newsroom
      </Typography>
      <CustomCarousel
        slides={slides}
        customStyles={customStyles}
        renderItem={renderItem}
        settings={settings}
      />
    </Box>
  );
};

export default template;
