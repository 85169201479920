import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import { teal } from '@mui/material/colors';

import { PageHero } from '../../component/page';

import ImgLeadership from '../../../img/about/leadership-team/LeadershipHero.png';
import ImgWave from '../../../img/about/leadership-team/management/wave2.png';

// Page Imports
import _1 from './leadership-team/_1';
import _2 from './leadership-team/_2';

const template = () => {
  return (
    <>
    <Box>
     <Box sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding:{xs:"70px 0 0 0",md:"100px 0 0 0"}, 
          overflow: "hidden",
    
          }}>
            <img
              src={`${ImgLeadership}`}
              alt="leadership"
              style={{ 
                maxWidth: "100%", 
                maxHeight: "100%", 
                objectFit: "contain" }}
            />
      </Box>
      <Box sx={{
              position: 'absolute',
              top: { xs: '80px', sm: '75px', md: '100px' },
              left: { xs: '25px', sm: '25px', md: '40px' },
              right: 0,
              bottom: 0,
              display: 'flex',
              color: '#FFFFFF',
              textAlign: 'topleft',
              fontSize: { xs: '17px', sm: '40px', md: '60px' }, // Responsive font size
              fontWeight: 'light',
              textShadow: '3px 3px 3px rgba(0, 0, 0, 0.16)'
              // fontFamily: 'Segoe UI',
            }}>
            CliniOps Leadership 
      </Box>
    </Box>
      <Box sx={{ pt: 5 }}>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          textAlign: 'center',
        }}
      >
        {/* Wave Background */}
        <img
          src={ImgWave}
          alt="Wave Background"
          style={{
            width: '100%',
            height: 'auto',
            maxHeight: '40vh',
          }}
        />
        {/* Management Text */}
        <Box
          sx={{
            position: 'absolute',
            top: '20%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1,
            color: '#FFFFFF',
            textAlign: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: '25px', md: '42px' },
              fontWeight: '300',
              color: '#FFFFFF',
              textShadow: '0 2px 5px rgba(0,0,0,0.8)',
            }}
          >
            Management
          </Typography>
        </Box>
      </Box>
      </Box>
      <Box
      sx={{
          position: 'relative',
          marginTop: { xs: '-10vh', sm: '-25vh', md: '-30vh' },
          zIndex: 2,
        }}
      >
      <Container>
        <_1 />
      </Container>
      </Box>
      <Box sx={{ pt: 5 }}>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          textAlign: 'center',
        }}
      >
        {/* Wave Background */}
        <img
          src={ImgWave}
          alt="Wave Background"
          style={{
            width: '100%',
            height: 'auto',
            maxHeight: '40vh',
          }}
        />
        {/* Advisory Text */}
        <Box
          sx={{
            position: 'absolute',
            top: '20%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1,
            color: '#FFFFFF',
            textAlign: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: '25px', md: '42px' },
              fontWeight: '300',
              color: '#FFFFFF',
              textShadow: '0 2px 5px rgba(0,0,0,0.8)',
            }}
          >
            Advisory
          </Typography>
        </Box>
      </Box>
      </Box>
      <Box
      sx={{
          position: 'relative',
          marginTop: { xs: '-10vh', sm: '-25vh', md: '-30vh' },
          zIndex: 2,
        }}
      >
      <Container sx={{paddingBottom: "50px"}}>
        <_2 />
      </Container>
      </Box>
    </>
  );
}

export default template;