import React from 'react';
import { Grid, Typography } from '@mui/material';

import { Product } from '../../../component/page';

import ImgeTMFConfig from '../../../../img/service/study-setup/eTMFConfig.png';
import CustomTypography from '../../../component/customTypography';

const template = () => {
  return (
    <>
      <Grid container direction='column' spacing={0} sx={{ mt: 0 }}>
            {/* eTMF / eISF Configuration  */}
          <Grid item>
              <Product
                ImgSrc={ImgeTMFConfig}
                Name="eTMF / eISF Configuration"
                Summary={
                  <>
                  <CustomTypography variant='body2' sx={{ mt: 1 }}>
                    CliniOps provides expert eTMF (electronic Trial Master File) configuration services to streamline document management of clinical 
                    trials, and the eISF configuration for the Sites. Our team customizes eTMF / eISF systems to ensure compliance with regulatory 
                    requirements and study-specific needs, enhancing document tracking and retrieval. CliniOps' configuration services support secure, 
                    real-time access to essential trial documents, improving collaboration and audit readiness. <br></br>
                    With a focus on efficiency and compliance, CliniOps delivers scalable eTMF configurations for global clinical trials.
                </CustomTypography>
                </>
                }
              />
              
          </Grid>
      </Grid>
    </>
  );
}

export default template;