import react from 'react';

import ImgVektorMedical from '../../../../img/home/vektor_medical_logo.png';
import ImgCrilaHealth from '../../../../img/media/customer-story/_1/crila_Health.png';
import ImgMDGH from '../../../../img/media/customer-story/_1/MDGH.svg'
import Imgegpaf from '../../../../img/media/customer-story/_1/EGPAF_Logo.png';
import Imgscanadu from '../../../../img/media/customer-story/_1/scanadu.jpg';
import Imgcpmc from "../../../../img/media/customer-story/_1/CPMC-Logo.png";
// import Imgsanaria from "../../../../img/media/customer-story/_1/sanaria-logo-small.png";
import Imgsanaria from "../../../../img/home/sanaria_logo_v2.png";
import ImgWashU from '../../../../img/media/customer-story/_1/WashU-logo.png';
import ImgRenibus from '../../../../img/media/customer-story/_1/Renibus_Logo.jpg';
import ImgNKF from '../../../../img/media/customer-story/_1/national-kidney-foundation-inc-logo-vector.png';
import ImgNH from '../../../../img/media/customer-story/_1/Narayana-Hrudayalaya-Limited-New-Logo4.jpg';
import ImgCorMedix from '../../../../img/media/customer-story/_1/CorMedix.png';
import BioconBiologics from '../../../../img/media/customer-story/_1/BioconBiologics.png';
import ImgGarywellAvatar from '../../../../img/media/customer-story/_1/Gary.png';
import ImgAppolinaireAvatar from '../../../../img/media/customer-story/_1/appolinaireTiam.png';
import ImgRobertAvatar from '../../../../img/media/customer-story/_1/krummenEsq.png';
import ImgSandeepAvatar from '../../../../img/media/customer-story/_1/sandeepAthalye.png';
import ImgStephenAvatar from '../../../../img/media/customer-story/_1/stephenHoffman.png';
import ImgSallyAvatar from '../../../../img/media/customer-story/_1/Sally_Kinrade.png';
import ImgSueAvatar from '../../../../img/media/customer-story/_1/sue_mckinney.png';
import ImgBhupinderAvatar from '../../../../img/media/customer-story/_1/Bhupinder_Singh.png';
import ImgLizAvatar from '../../../../img/media/customer-story/_1/Liz_M_Hurlburt.png';
import ImgAlbenAvatar from '../../../../img/media/customer-story/_1/Dr_Alben_Sigamani.png';
import ImgDayaAvatar from '../../../../img/media/customer-story/_1/Daya_Ranamukhaarachchi.png';
import ImgJamesAvatar from '../../../../img/media/customer-story/_1/James_lvie.png';
import ImgJohnAvatar from '../../../../img/media/customer-story/_1/Dr_John_Mendelson.png';
import ImgLiseAvatar from '../../../../img/media/customer-story/_1/Lise_Denoeud.png';
import ImgLauraAvatar from '../../../../img/media/customer-story/_1/Laura_guay.png';
import RouterList from "../../../router";

const customerData = [
    {
      image: BioconBiologics,
      title: `Patient Centricity Through Telemedicine`,
      subTitle: `A Clinical Trial for Treatment of COVID-19 in India, across 20+ sites`,
      description: `“Biocon’s goal is to improve patient outcomes with approaches both novel and time tested to meet every challenge, pandemic included.###
      We are a strong believer of digital transformation of clinical trials, and our partnership with  CliniOps helped us with an innovative platform that offered complete digital experience for sites and patients.###
      With telemedicine, participants were enrolled and engaged online via the platform. The participants’ safety and convenience were always a priority in line with our strategic imperative of patient centricity. We enjoyed our partnership with CliniOps and their very responsive customer support team”`,
      customerAvatar: ImgSandeepAvatar,
      customerName:
      "Dr. Sandeep Athalye, MD ###Chief Development Officer ###Biocon Biologics",
      link:RouterList.media.customerstory_18,
      tags:['Cardiology/Vascular'],
      region:'Asia Pacific'
    },
    {
      image: ImgCrilaHealth,
      title: `Partnership with Crila Health:`,
      subTitle: `Clinical Trial on Prostate Health`,
      description: `“Deciding to proceed with our clinical study on prostate health at the height of the pandemic was very difficult and risky.###
      Our partnership with CliniOps was significant in making it possible for the study team to safely enroll patients through eConsent and for data to be reliably and securely gathered and stored in a cloud-based repository.###
      The study was completed and outcomes demonstrated Crila’s effectiveness in promoting prostate health. In no small measure, do we attribute the successful completion of our studies to the innovative use of CliniOps technology.”`,
      customerAvatar: ImgSueAvatar,
      customerName: "Sue McKinney ###Executive Director ###Crila Health",
      link:RouterList.media.customerstory_3,
      tags:['Cosmetics'],
      region:'N. America'
    },
    {
      image: ImgVektorMedical,
      title: `Configurable Platform for handling adjudication, complex workflows and imaging data `,
      subTitle: `Speeds development in ground-breaking clinical trial on Cardiac Arrhythmia`,
      description: `We found CliniOps’s platform to be flexible and adaptable, offering features that handled our complex clinical workflow seamlessly.###
      Their team was excellent and their responsiveness was impressive. CliniOps listened to our requirements and configured the system the way it needed to be done.###CliniOps’s platform was a key reason that we were able to successfully complete 
      our study on time and on budget. In terms of our clinical trial plans going forward, when we are ready for our next study, CliniOps will be among our first calls.
      `,
      customerAvatar: ImgRobertAvatar,
      customerName:
        "Krummen, Esq., ###President & Chief Executive Officer, ###Vektor Medical, Inc. ",
        link:RouterList.media.customerstory_1,
        tags:['Musculoskeletal/Sports Medicine'],
        region:'N. America'
    },
    {
        image: ImgCrilaHealth,
        title: `DCT in Action:`,
        subTitle: `Menopause Study Started and Completed During Pandemic`,
        description: `“Based on experts’ recommendations, we called on CliniOps. Avik and his team were hands on from day one and wasted no time to set up electronic consent and cloud-based data repository for easier access.###
        With efficient technologies supporting our hardworking investigators and patients, we were able to complete our study and more importantly, safely during a pandemic. Our study also showed positive outcomes for the Latina women with menopausal symptoms who took oral CRILA® daily for 90 days.”`,
        customerAvatar: ImgSueAvatar,
        customerName: "Sue McKinney ###Executive Director ###Crila Health",
        link:RouterList.media.customerstory_2,
        tags:["Immunology/Infectious Diseases"],
        region: 'N. America'
      },
    {
        image: ImgMDGH,
        title: `Onchocerciasis Study in Sub Saharan Africa:`,
        subTitle: `Technology and Determination to Reach Underserved Populations`,
        description: `“Our collaboration with CliniOps to provide eSource technologies with complete offline functionality in a unified platform is making it possible for the study team to recruit and engage participants even in the remotest locations.###
        Our determination to complete our studies as efficiently as possible is significantly enabled by our use of CliniOps’s reliable clinical trial technologies. We look forward to building on our partnership with CliniOps, beyond our current program.”`,
        customerAvatar: ImgSallyAvatar,
        customerName: "Sally Kinrade, MPH ###Vice President, Project Leader Onchocerciasis and Lymphatic Filariasis, ###MDGH",
        link:RouterList.media.customerstory_4,
        tags:['Hepatology'],
        region:'Africa'
      },
    {
        image: Imgegpaf,
        title: `Mobile Technologies Assist Intervention Study to Integrate Pediatric TB Services`,
        subTitle: `Into Child Healthcare Services (INPUT Study), in Cameroon and Kenya`,
        description: `“To minimize the burden on sites and most specially on the patient, considering high data collection volume in 12 sites with 1,716 participants, EGPAF is grateful for the strong and reliable technology support of CliniOps through their unified and mobile technologies.”`,
        customerAvatar: ImgLiseAvatar,
        customerName:
          "Lise Denoeud, MD, PhD, ###Senior Research Officer, ###Elizabeth Glaser Pediatric Aids Foundation",
        link:RouterList.media.customerstory_16,
        tags:['Hematology'],
        region:'Africa'
    },  
   {
    image: ImgWashU,
    title: `Onchocerciasis Study in Ghana:`,
    subTitle: `Deploying Mobile Technologies for High Quality Data Capture in Remote Sites`,
    description: `“This clinical trial showed that a novel combination treatment was somewhat more effective against adult worms than the current treatment that is used in the global onchoceriasis elimination program.###
    The study was a collaboration between the University of Health and Allied Sciences, Hohoe, Ghana and Washington University in St. Louis We are grateful to CliniOps who provided technologies for secure, reliable and high quality capture of data particularly with the challenge of working in remote sites”`,
    customerAvatar: ImgGarywellAvatar,
    customerName:
    "Gary J. Weil, MD Professor ###Washington University School of Medicine",
    link:RouterList.media.customerstory_10,
    tags:['Genetic Disease'],
    region:'Africa'
  },
  {
    image: Imgsanaria,
    title: `Enabling Remote Monitoring in a Malaria study`,
    subTitle: `Regimen Optimization Trial of PfSPZ Vaccine in Equatorial Guinea`,
    description: `“With our vaccines, Sanaria is fully committed to contribute to the prevention of malaria infection and transmission in exposed individuals and communities. Our regimen optimization trial of Sanaria® PfSPZ Vaccine in Equatorial Guinea involved efficient study monitoring.###
    When travel constraints hindered regular on-site visits by monitors, CliniOps remote monitoring capabilities helped us bring efficiency to the process and complete the study, leveraging smart technologies in difficult geographies in Africa.”`,
    customerAvatar: ImgStephenAvatar,
    customerName: "Dr. Stephen Hoffman ###CEO ###Sanaria, Inc",
    link:RouterList.media.customerstory_9,
    tags:['Gastroenterology'],
    region:'Africa'
  },
  {
    image: ImgRenibus,
    title: `Unified Technology Platform support for`,
    subTitle: `Phase 1B dose-escalation study on subjects with chronic kidney disease (CKD)`,
    description: `“Application of CliniOps’ unified technologies was valuable for the seamless conduct of the studies and consequently helped to lessen the burden for both study team and participants”`,
    customerAvatar: ImgBhupinderAvatar,
    customerName: "Bhupinder Singh, MD, ###Chief Medical Officer, ###Renibus Therapeutics",
    link:RouterList.media.customerstory_12,
    tags:['Epidemiology'],
    region:'N. America'
  },
  {
    image: Imgsanaria,
    title: `Off-line Data Collection with sites in Africa:`,
    subTitle: `Study to Evaluate Safety, Immunogenicity and Efficacy of PfSPZ Vaccine in HIV Negative and HIV Positive Tanzanian Adults`,
    description: `“Data collection would have been difficult without the mobile technology platform from CliniOps, that enabled data collection in complete offline mode.###
    We knew that lack of connectivity would be a challenge but having smart devices open to offline data entry was a big advantage.”`,
    customerAvatar: ImgStephenAvatar,
    customerName: "Dr. Stephen Hoffman ###CEO ###Sanaria, Inc",
    link:RouterList.media.customerstory_11,
    tags:['Endocrinology'],
    region:'Africa'
  },
  {
    image: ImgCorMedix,
    title: `Stepping in for Data Management and Analysis:`,
    subTitle: `Study on Catheter-related blood stream infections (CRBSI)`,
    description: `“In our post marketing study, we called upon CliniOps to manage and analyze our data. CliniOps was very easy to work with, quite agile and adaptable.###
    The on-time completion of this important study and its results have led CorMedix to further expand into a much larger study, LOCK IT-100.”`,
    customerAvatar: ImgLizAvatar,
    customerName: "Liz M. Hurlburt, M.S., ###Executive Vice President & Head, Clinical and Medical Affairs, ###Cormedix Inc.",
    link:RouterList.media.customerstory_15,
    tags:['Dermatology/Plastic Surgery'],
    region:'Europe'
  },
  {
    image: Imgegpaf,
    title: `A cluster randomized trial to study the effect of Family-centered Model`,
    subTitle: `HIV-positive Children in Eswatini (formerly Swaziland)`,
    description: `“I have to say that now personally having gone through each and every eCRF form on the tablet, I really appreciate what a great system this is and how it will help us to have better data in the long run.”`,
    customerAvatar: ImgLauraAvatar,
    customerName:
      "Dr. Laura Guay ###Vice President for Research, ###Elizabeth Glaser Pediatric Aids Foundation",
    link:RouterList.media.customerstory_5,
    tags:['Dental and Oral Health'],
    region:'Africa'
  },
  {
    image: ImgNH,
    title: `Diabetes, Hepatitis B and Bronchial Asthma studies:`,
    subTitle: `One Technology Platform across multiple sites in India`,
    description: `“CliniOps App is a really cool application to make source documentation in any hospital, for research or clinical trials, really easy and user friendly.###
    We are using them on a few trials at NH and find it quite interesting.”`,
    customerAvatar: ImgAlbenAvatar,
    customerName: "Dr. Alben Sigamani ###Principal Investigator, Group Head Clinical Research, ###Narayana Health",
    link:RouterList.media.customerstory_14,
    tags:['Oncology'],
    region:'Asia Pacific'
  },
  {
    image: ImgWashU,
    title: `Lymphatic Filariasis study in Papua New Guinea, Indonesia, India, Haiti, and Fiji`,
    subTitle: `A clinical trial with 26,836 participants in LMIC countries, with offline/online capture using mobile devices.`,
    description: `“With many thousands of study participants, our investigators and site teams worked tirelessly to complete the project(s) in a professional and timely manner. Our study benefitted from the assistance of CliniOps, which provided a reliable, high-level data collection platform that met stringent regulatory requirements and included offline capability for data entry and management in remote areas. Local personnel in several of our sites were not tech savvy, but they learned to use the tablet-based data system quickly and benefitted from training and prompt technical support from CliniOps.”`,
    customerAvatar: ImgGarywellAvatar,
    customerName:
    "Gary J. Weil, MD Professor ###Washington University School of Medicine",
    link:RouterList.media.customerstory_17,
    tags:['Obstetrics/Gynecology'],
    region:'Global'
  },
  {
    image: Imgscanadu,
    title: `Clinical performance of a smartphone-enabled home based urine testing platform`,
    subTitle: `Compared to lab based standard of care testing`,
    description: `“eSource solution is a great compliment for clinical trials. The off-line data capture with point-of-care edit checks gives us quality data in real time.###
    The solution provides integrated workflows, flexible configuration and a simple user interface design.###
    CliniOps provided excellent customer support and stood by us for clinical trials supporting FDA submissions.”`,
    customerAvatar: ImgDayaAvatar,
    customerName: "Daya Ranamukhaarachchi, PhD###Vice President, Regulatory Affairs, ###Scanadu",
    link:RouterList.media.customerstory_6,
    tags:['Nutrition and Weight Loss'],
    region:'N. America'
  },
  {
    image: Imgegpaf,
    title: `A cluster randomized trial to improve Maternal-Child Outcomes`,
    subTitle: `HIV-positive pregnant women in Lesotho, Africa`,
    description: `"Our collaboration with CliniOps made it possible for us to use smart solutions to recruit participants in remote sites as well as track their tests and visits efficiently in complete offline mode.###
    The accomplishment of the study, with assistance from CliniOps technology platform, is another step towards our objective to strengthen local healthcare response to needs of mother, child, or family affected by HIV."`,
    customerAvatar: ImgAppolinaireAvatar,
    customerName:
      "Dr.Appolinaire Tiam VP, ###Technical Strategies & Innovation ###Elizabeth Glaser Pediatric Aids Foundation",
    link:RouterList.media.customerstory_8,
    tags:['Neurology'],
    region:'Africa'
  },
  {
    image: ImgNKF,
    title: `Electronic Data Capture for NKF Initiative ‘Path to Wellness’:`,
    subTitle: `Serving Population At-Risk of Kidney Disease`,
    description: `“CliniOps tablet application was very intuitive and a quick 15 min onsite training was enough for our volunteers to start using the system seamlessly.###
    The team was able to record about 200 patient data, in less than 4 hours, without issues. Excellent customer service.”`,
    customerAvatar: ImgJamesAvatar,
    customerName: "James Ivie, PhD, LCSW###Director of Patient Services, ###National Kidney Foundation of Arizona",
    link:RouterList.media.customerstory_13,
    tags:['Nephrology/Urology'],
    region:'N. America'
  },
  {
    image: Imgcpmc,
    title: `Moving from Paper to Electronic Consent`,
    subTitle: `involving patients with substance abuse`,
    description: `“CliniOps is innovative, easy to use, perfectly positioned and has the potential to transform how we recruit and consent subjects.###
     I believe that it is a real innovative improvement in clinical research.”`,
    customerAvatar: ImgJohnAvatar,
    customerName: "Dr. John Mendelson###Chair, IRB, ###California Pacific Medical Center Research Institute",
    link:RouterList.media.customerstory_7,
    tags:['Otolaryngology','Opthamlogy'],
    region:'N. America'
  },
];

export {customerData};