import React, { useMemo } from 'react';
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

import RouterList from '../../../router';
import PressRelease from '../../../component/press-release';

// import ImgDiversity from '../../../../img/media/blog/diversity.jpg';
// import ImgClinicalTrial from '../../../../img/media/blog/clinical-trials.png';
// import ImgInitialImpact from '../../../../img/media/blog/initial-impace.jpg';
// import ImgTelemedicine from '../../../../img/media/blog/telemedicine.jpg';
// import ImgCovid19 from '../../../../img/media/blog/covid19.jpg';

import ImgBriefing from '../../../../img/media/pressrelease/briefingwire.jpg';
import ImgNewsWire from '../../../../img/media/pressrelease/Newswire-logo.jpg';
import ImgCision from '../../../../img/media/pressrelease/cision.png';
import CustomCarousel from '../../home/CustomCarousel';
import ImgeConsent from '../../../../img/media/pressrelease/PR-eConsent.png';
import ImgPatentAward2024 from '../../../../img/media/pressrelease/patentAward2024.png'
import ImgPatentAward2023 from '../../../../img/media/pressrelease/patentAward2023.png'
import ImgGartnerHype from '../../../../img/media/pressrelease/PR_GartnerHypeCycle.jpg';
import ImgCliniopsIndegene from '../../../../img/media/pressrelease/CliniOps_Indegene_Partnership.png';
import ImgTIE50 from '../../../../img/media/pressrelease/TiE_50.jpg';
import ImgSubbu from '../../../../img/media/pressrelease/subbu.jpg';
import ImgExostar from '../../../../img/media/pressrelease/Exostar.jpg';
import ImgTotalHealth from '../../../../img/media/pressrelease/Mytotalhealth.jpg';
import ImgFrostAward from '../../../../img/media/pressrelease/frostAward.png';

// import sign from '../../../../img/media/newsroom/Renew/sign.png';

const data = [
  {
    ImgSrc: ImgNewsWire,
    Name: " CliniOps Announces Strategic Partnership with Indegene to Accelerate Clinical Trials",
    Date: "Jan 09, 2025",
    Summary: "CliniOps, Inc., a leading provider of advanced Digital Clinical Trial solutions, today announced a strategic partnership with Indegene, a digital-first, life sciences commercialization company, to drive innovative digital transformation in clinical trial processes and achieve better patient outcomes.",
    FullContent: (
      <Typography sx={{ mt: 2 }}>
        {/* With this advanced eConsent, stakeholders from pharmaceutical companies, Clinical Research Organizations (CROs), Investigators, and site staff can educate and enroll patients in clinical trials (CTs) based on latest regulatory requirements and guidelines issued by governing institutions. */}
      </Typography>
    ),
    redirectUrl: "https://www.newswire.com/news/cliniops-announces-strategic-partnership-with-indegene-to-accelerate-22490663",
    PRImg:ImgCliniopsIndegene,
  },
  {
    ImgSrc: ImgNewsWire,
    Name: "In Pursuit of Excellence: State of the Art Electronic Consent for Clinical Trials",
    Date: "August 22, 2024",
    Summary: "CliniOps, Inc., a premier technology, and data science company for the life science industry, has relaunched its state-of-the-art electronic informed consent (eConsent) module.",
    FullContent: (
      <Typography sx={{ mt: 2 }}>
        {/* With this advanced eConsent, stakeholders from pharmaceutical companies, Clinical Research Organizations (CROs), Investigators, and site staff can educate and enroll patients in clinical trials (CTs) based on latest regulatory requirements and guidelines issued by governing institutions. */}
      </Typography>
    ),
    redirectUrl: "https://www.newswire.com/news/in-pursuit-of-excellence-state-of-the-art-electronic-consent-for-22331074",
    PRImg:ImgeConsent,
  },
  {
    ImgSrc: ImgNewsWire,
    Name: "CliniOps Awarded Patent for an Innovative Offline/Online eSource or Direct Data Collection (DDC) System, for Global Clinical Trials",
    Date: "April 25, 2024",
    Summary: "CliniOps, Inc., an innovative technology and data science company for the life science industry, has once again demonstrated its capability to innovate and respond to unmet needs in CTs, particularly, the challenge of managing data collection in remote and disparate regions across Africa and LMIC countries.",
    FullContent: (
      <Typography sx={{ mt: 2 }}>
     {/* The U.S. Patent and Trademark Office (USPTO) awarded CliniOps the U.S. Patent No. 11,967,402 on April 23, 2024, titled “System and Method for Offline Data Collection and Synchronization for Managing a Clinical Trial.” Avik Kumar Pal, CEO, and Yerramalli Subramaniam, CTO, are listed as patent inventors. 
     CliniOps' products were designed to help sites make a technology leap from paper sources to electronic sources, utilizing mobile devices with complete offline capability. */}
      </Typography>
    ),
    redirectUrl: "https://www.newswire.com/news/cliniops-awarded-patent-for-an-innovative-offline-online-esource-or-22307884",
    PRImg:ImgPatentAward2024,
  },
  {
    ImgSrc: ImgNewsWire,
    Name: "CliniOps Awarded Patent for Pioneering System to Collect Location Agnostic Clinical and Non-Clinical Data",
    Date: "May 18, 2023",
    Summary: "CliniOps, Inc., a leading technology and data science company for the life science industry, has secured patent for its breakthrough system to address delays and inefficiencies in manual and siloed data collection points across multiple locations, devices and users, during clinical trials (CTs).",
    FullContent: (
      <Typography sx={{ mt: 2 }}>
      {/* The U.S. Patent and Trademark Office (USPTO) awarded CliniOps the U.S. Patent No. US 11,600,396 for: "Systems and Methods for Collecting Location Agnostic Clinical and Non-Clinical Data." Avik Kumar Pal, CEO and Yerramalli Subramaniam, CTO are listed as patent inventors.  */}
      </Typography>
    ),
    redirectUrl: "https://www.newswire.com/news/cliniops-awarded-patent-for-pioneering-system-to-collect-location-22039742",
    PRImg:ImgPatentAward2023,
  },
  {
    ImgSrc: ImgNewsWire,
    Name: "CliniOps Recognized in '𝐆𝐚𝐫𝐭𝐧𝐞𝐫 𝐇𝐲𝐩𝐞 𝐂𝐲𝐜𝐥𝐞' for Fifth straight year!",
    Date: "October 26, 2022",
    Summary: "CliniOps, Inc., a leading provider of advanced Digital Clinical Trials, today announced that it has been named in the Gartner annual Hype Cycle™ for Life Science Clinical Development, for five consecutive years. ",
    FullContent: (
      <Typography sx={{ mt: 2 }}>
      {/* Since first acknowledged as a Sample Vendor for eSource solutions in 2018, CliniOps has risen to the challenge of providing solutions fit for the changing needs of the clinical sponsors, clinical research organizations, investigators and patients. During the pandemic, CliniOps was among the clinical trial technologies readily available to impart eSource and other solutions to promote continuity of studies.  */}
      </Typography>
    ),
    redirectUrl: "https://www.newswire.com/news/cliniops-recognized-in-gartner-hype-cycles-for-fifth-straight-year-21854369",
    PRImg:ImgGartnerHype,
  },
  {
    ImgSrc: ImgNewsWire,
    Name: "CliniOps Announces Strategic Partnership with My Total Health",
    Date: "October 20, 2022",
    Summary: "CliniOps, Inc. announced today a strategic partnership with My Total Health Inc., in partnership with Commonwealth Diagnostics International, Inc. (CDI), in which the companies will collaborate to provide an end-to-end clinical trial solution for the gastroenterology (GI) community. ",
    FullContent: (
      <Typography sx={{ mt: 2 }}>
       {/* "CliniOps is well known as an eClinical innovator for its comprehensive, digital platform and data science services," said Craig S. Strasnick, CEO of My Total Health and CDI. "Our collaboration will support current and future clinical trials that will contribute to more knowledge around the broad range of GI conditions that adversely affect millions of people around the world." */}
      </Typography>
    ),
    redirectUrl: "https://www.newswire.com/news/cliniops-announces-strategic-partnership-with-my-total-health-21853614",
    PRImg:ImgTotalHealth,
  },
  {
    ImgSrc: ImgNewsWire,
    Name: "CliniOps Connects With Exostar to Deliver a Unified Access Experience for Digital Clinical Trials",
    Date: "September 15, 2022",
    Summary: "CliniOps, Inc., the leading provider of Hybrid and Decentralized Clinical Trials (DCT) solutions, today announced the integration of its product suite with Exostar's Secure Access Manager (SAM). ",
    FullContent: (
      <Typography sx={{ mt: 2 }}>
       {/* "User experience and accessibility are the highlights of this partnership. By connecting our application to Exostar, CliniOps is now able to offer the clinical trial investigators community an industry-standard capability to quickly, easily, and painlessly switch between clinical trial platforms as they navigate the complex clinical trials process," said Yerramalli Subramaniam, CTO of CliniOps.  */}
      </Typography>
    ),
    redirectUrl: "https://www.newswire.com/news/cliniops-connects-with-exostar-to-deliver-a-unified-access-experience-21823556",
    PRImg:ImgExostar,
  },
  {
    ImgSrc: ImgCision,
    Name: "CliniOps, Inc. Receives the ‘2017 Frost & Sullivan Technology Leadership Award for Digitalization of Clinical Trials’",
    Date: "June 11, 2017",
    Summary: "Based on its recent analysis of the eClinical solutions market, Frost & Sullivan recognized CliniOps with the 2017 North American Digitalization of Clinical Trials Technology Leadership Award.",
    FullContent: (
      <Typography sx={{ mt: 2 }}>
       {/* The award recognizes CliniOps’ innovative eSource solution, leveraging cutting edge SMAC (Social, Mobile, Analytics and Cloud) technologies, and establishes CliniOps as a leader in this space. */}
      </Typography>
    ),
    redirectUrl: "https://www.prweb.com/releases/2017/06/prweb14414347.htm",
    PRImg:ImgFrostAward,
  },
  {
    ImgSrc: ImgCision,
    Name: "CliniOps, Inc. Named in the ‘2014 Global Hot Technology Start-Up Watch List’ for the TiE50 Awards Program",
    Date: "May 16, 2014",
    Summary: "“We are truly humbled and honored to be selected to the coveted list, from a pool of more than 2800 companies, as recognition for our tablet based eSource solution, to support clinical trials,” said Avik Pal, Founder and CEO of CliniOps.",
    FullContent: (
      <Typography sx={{ mt: 2 }}>
       {/* “We’ve worked with researchers, medical practitioners, pharma and academia to understand the challenges, and believe that the eClinical industry is ripe for disruption and we want to position ourselves as a leader in this space. This recognition reinforces our goal to continue our efforts towards a fully automated clinical trial workflow, through our patent pending technology.” */}
      </Typography>
    ),
    redirectUrl: "https://www.prweb.com/releases/cliniops_inc/esource_solution/prweb11860180.htm",
    PRImg:ImgTIE50,
  },
  {
    ImgSrc: ImgBriefing,
    Name: "CliniOps's Yerramalli Subramaniam recognized as Computerworld's Premier 100 IT Leaders for 2015",
    Date: "November 05, 2014",
    Summary: "CliniOps, Inc., a technology startup in the eClinical space, is excited to announce that Yerramalli Subramaniam, the company's Co-Founder and Chief Technology officer, was selected by IDG's Computerworld as a 2015 Premier 100 IT Leader.",
    FullContent: (
      <Typography sx={{ mt: 2 }}>
      {/* "I am truly honored and humbled with this prestigious recognition. " said Yerramalli Subramaniam, CTO of CliniOps, Inc. "It is exciting to see how technology adoption is solving problems across industries and paving the way for cross-pollination of ideas among the top IT leaders from these industries" */}
      </Typography>
    ),
    redirectUrl: "https://www.briefingwire.com/pr/cliniopss-yerramalli-subramaniam-recognized-as-computerworlds-premier-100-it-leaders-for-2015",
    PRImg:ImgSubbu,
  },
  
];

data.sort((a, b) => new Date(b.Date) - new Date(a.Date));

const template = () => {
      const theme = useTheme();
      const isXS = useMediaQuery(theme.breakpoints.down("sm"));
    
      const [expanded, setExpanded] = React.useState(false);
    
      const handleExpandClick = () => {
        setExpanded(!expanded);
      };

       // 2) Gather unique years in descending order
        const uniqueYears = useMemo(() => {
          const yearSet = new Set();
          data.forEach((item) => {
            const year = new Date(item.Date).getFullYear();
            yearSet.add(year);
          });
          return Array.from(yearSet).sort((a, b) => b - a);
        }, []);
    
    return (

     <>
       <CustomCarousel
      slides={data}
      // Pass extraFeatures only if the current page needs extra controls
      extraFeatures={{
        manualChunking:true,
        ChildOverflow:true,
        renderBottomDots:true,
        dynamicRows: false, // opt into dynamic override
        pagingNumerical: true,
        defaultItemsPerPage: 10,
        itemsPerPageOptions: [10, 20, 50],
        pagingFilter: {
          options:[
            { value: "all", label: "All Press Release" },
            { value: "category", label: "Category" },
          ],
          default: "all",
          availableYears: uniqueYears, // e.g., [2024, 2023, ...]
        },

      }}
      renderItem={(slide, index) => (
        <Grid container direction='column' spacing={6} sx={{ mt: 1 }}>
   
          <Grid item>
          <PressRelease
            ImgSrc={slide.ImgSrc}
            Name={slide.Name}
            Date={slide.Date}
            Summary={slide.Summary}
            FullContent={slide.FullContent}
            redirectUrl={slide.redirectUrl}
            PRImg={slide.PRImg}
          />
          </Grid>
          
        </Grid>
      )}
      settings={{
        // Add or override slider settings if needed
        // autoplaySpeed: 7000,
        autoplay:false,
        dots:true,
        slidesPerRow:1,
        arrows:false,
        dotsPosition: {
          top: isXS ? "10px" : "-70px",
        },
      }}
      customStyles={{
        wrapper: { mt: 2 },
        slide: { p: 2,mb:1}
      }}
     />
     </>
      );
};
export default template;

// const template = () => {
//   return (
//     <>
      
//       <Grid container columnSpacing={4} rowSpacing={8}>
//       <PressRelease
//         img={ImgNewsWire}
//         link="https://www.newswire.com/news/in-pursuit-of-excellence-state-of-the-art-electronic-consent-for-22331074"
//         title= "In Pursuit of Excellence: State of the Art Electronic Consent for Clinical Trials"
//         date="August 22, 2024"
//       />
//       <PressRelease
//         img={ImgNewsWire}
//         link="https://www.newswire.com/news/cliniops-awarded-patent-for-an-innovative-offline-online-esource-or-22307884"
//         title= "CliniOps Awarded Patent for an Innovative Offline/Online eSource or Direct Data Collection (DDC) System, for Global Clinical Trials"
//         date="April 25, 2024"
//       />
//       <PressRelease
//         img={ImgNewsWire}
//         link="https://www.newswire.com/news/cliniops-awarded-patent-for-pioneering-system-to-collect-location-22039742"
//         title= "CliniOps Awarded Patent for Pioneering System to Collect Location Agnostic Clinical and Non-Clinical Data"
//         date="May 18, 2023"
//       />
//       <PressRelease
//           img={ImgNewsWire}
//           link="https://www.newswire.com/news/cliniops-recognized-in-gartner-hype-cycles-for-fifth-straight-year-21854369"
//           title= "CliniOps Recognized in '𝐆𝐚𝐫𝐭𝐧𝐞𝐫 𝐇𝐲𝐩𝐞 𝐂𝐲𝐜𝐥𝐞' for Fifth straight year!"
//           date="October 26, 2022"
//         />
//       <PressRelease
//           img={ImgNewsWire}
//           link="https://www.newswire.com/news/cliniops-announces-strategic-partnership-with-my-total-health-21853614"
//           title= "CliniOps Announces Strategic Partnership with My Total Health"
//           date="October 20, 2022"
//         />
//         <PressRelease
//           img={ImgNewsWire}
//           link="https://www.newswire.com/news/cliniops-connects-with-exostar-to-deliver-a-unified-access-experience-21823556"
//           title="CliniOps Connects With Exostar to Deliver a Unified Access Experience for Digital Clinical Trials"
//           date="September 15, 2022"
//         />
//         <PressRelease
//           img={ImgCision}
//           link="https://www.prweb.com/releases/2017/06/prweb14414347.htm"
//           title="CliniOps, Inc. Receives the ‘2017 Frost & Sullivan Technology Leadership Award for Digitalization of Clinical Trials’"
//           date="June 11, 2017"
//         />
//         <PressRelease
//           img={ImgCision}
//           link=" https://www.prweb.com/releases/cliniops_inc/esource_solution/prweb11860180.htm"
//           title='CliniOps, Inc. Named in the ‘2014 Global Hot Technology Start-Up Watch List’ for the TiE50 Awards Program'
//           date="May 16, 2014"
//         />
//         <PressRelease
//           img={ImgBriefing}
//           link="  https://www.briefingwire.com/pr/cliniopss-yerramalli-subramaniam-recognized-as-computerworlds-premier-100-it-leaders-for-2015"
//           title="CliniOps's Yerramalli Subramaniam recognized as Computerworld's Premier 100 IT Leaders for 2015"
//           date="November 05, 2014"
//         />
        
//       </Grid>
//     </>
//   );
// }

// export default template;