import React from 'react';
import { Box, Typography, Grid, Card, CardHeader, CardMedia, Link } from '@mui/material';

import ImgMedia201701 from '../../../../img/media/newsroom/_2017/Media-2017-01.png';
import ImgMedia201702 from '../../../../img/media/newsroom/_2017/Media-2017-02.png';
import ImgMedia201703 from '../../../../img/media/newsroom/_2017/Media-2017-03.png';
import ImgMedia201709 from '../../../../img/media/newsroom/_2017/Media-2017-09.png';
import ImgMedia201704 from '../../../../img/media/newsroom/_2017/Media-2017-04.png';
import ImgMedia201705 from '../../../../img/media/newsroom/_2017/Media-2017-05.png';
import ImgMedia201706 from '../../../../img/media/newsroom/_2017/Media-2017-06.png';
import ImgMedia201707 from '../../../../img/media/newsroom/_2017/Media-2017-07.png';
import ImgMedia201708 from '../../../../img/media/newsroom/_2017/Media-2017-08.png';
import ImgMedia201710 from '../../../../img/media/newsroom/_2017/Media-2017-10.png';
import ImgMedia201711 from '../../../../img/media/newsroom/_2017/Media-2017-11.png';
import ImgMedia201713 from '../../../../img/media/newsroom/_2017/Media-2017-13.png';
import ImgMedia201712 from '../../../../img/media/newsroom/_2017/Media-2017-12.png';
import ImgMedia201714 from '../../../../img/media/newsroom/_2017/Media-2017-14.png';
import ImgMedia201715 from '../../../../img/media/newsroom/_2017/Media-2017-15.png';
import ImgMedia201718 from '../../../../img/media/newsroom/_2017/Media-2017-18.png';
import ImgMedia201717 from '../../../../img/media/newsroom/_2017/Media-2017-17.png';
import ImgMedia201716 from '../../../../img/media/newsroom/_2017/Media-2017-16.png';
import ImgMedia201719 from '../../../../img/media/newsroom/_2017/Media-2017-19.png';
import ImgMedia201720 from '../../../../img/media/newsroom/_2017/Media-2017-20.png';
import ImgMedia201721 from '../../../../img/media/newsroom/_2017/Media-2017-21.png';
import ImgMedia201722 from '../../../../img/media/newsroom/_2017/Media-2017-22.png';
import ImgMedia201723 from '../../../../img/media/newsroom/_2017/Media-2017-23.png';
import ImgMedia201724 from '../../../../img/media/newsroom/_2017/Media-2017-24.png';
import ImgMedia201725 from '../../../../img/media/newsroom/_2017/Media-2017-25.png';
import ImgIC from '../../../../img/media/newsroom/_2017/informa-connect-logo.png';
// import sign from '../../../../img/media/newsroom/Renew/sign.png';


const news2017Data = [
    {
    ImgSrc: ImgMedia201701,
    Name: "Optimize Clinical Data Collection & Reporting with eSource",
    Date: "December 29, 2017",
    Summary: "Optimize Clinical Data Collection & Reporting with eSource",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "https://www.hcltech.com/life-sciences-healthcare/esource",
  },
  {
    ImgSrc: ImgMedia201702,
    Name: "A Memorable GES 2017",
    Date: "December 16, 2017",
    Summary: "Headquartered in Silicon Valley, CliniOps is a leading mobile, cloud-based, digital solutions company, for the Clinical Trial industry. ",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
       CliniOps technology is transforming the way clinical trials are conducted, by bringing cutting edge technology innovations and leveraging SMAC (Social, Mobile, Analytics & Cloud). BioPharma and Med Device companies can now bring drugs & medical devices much faster to market, with high focus on data quality and less cost. CliniOps also has an office in Kolkata, India.
      </Typography>
    ),
    redirectUrl: "https://siliconeer.com/current/a-memorable-ges-2017/",
  },
  {
    ImgSrc: ImgMedia201709,
    Name: "Global Entrepreneurship Summit",
    Date: "November 28, 2017",
    Summary: "Following the announcement by President Trump and Prime Minister Modi in Washington that the United States and India will co-host the 2017 Global Entrepreneurship Summit (GES)",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
       The theme of the Summit will be Women First, Prosperity for All, highlighting the critical role women play in fostering global growth and prosperity.  Ivanka Trump, Advisor to the President, will lead the U.S. delegation.
      </Typography>
    ),
    redirectUrl: "https://in.usembassy.gov/global-entrepreneurship-summit-2017-held-hyderabad-november-28-30/",
  },
  {
    ImgSrc: ImgMedia201704,
    Name: "NDTV, Agenda at GES 2017",
    Date: "November 27, 2017",
    Summary: "Global Entrepreneur Summit 2017: Putting Women Entrepreneurs First",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
       As Ivanka Trump addresses women entrepreneurs from 150 nations at the Global entrepreneur Summit 2017 in Hyderabad, we speak with women entrepreneurs from India and abroad and ask them about their expectations from the summit and about India's changing business ecosystem and what are the challenges faced by women entrepreneurs across the globe.
      </Typography>
    ),
    redirectUrl: "https://www.ndtv.com/video/shows/agenda/global-entrepreneur-summit-2017-putting-women-entrepreneurs-first-473307",
  },
  {
    ImgSrc: ImgMedia201705,
    Name: "US Department of State Delegate at GES 2017",
    Date: "November 16, 2017",
    Summary: "State Department recognizes CliniOps’s role in transforming digital med tech",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        Avik Pal US delegate at Global Entrepreneurship Summit
      </Typography>
    ),
    redirectUrl: "https://indicanews.com/2017/11/16/avik-pal-represents-us-global-entrepreneurship-summitges/",
  },
  {
    ImgSrc: ImgIC,
    Name: "Clinical data from wearables: Quality, regulatory and privacy concerns",
    Date: "November 10, 2017",
    Summary: "We asked five experts working in clintech what they see as the biggest challenges with mHealth and how they can be overcome.",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
       Yerramalli Subramaniam, Co-Founder and CTO, CliniOps<br></br>
       “One of the key regulatory challenges of collecting data from a patient’s smartphones in either provisioned or BYOD model is the patient authenticity - is the data from the patient or someone else? Over the last few years, the smartphone industry has made big strides in user authentication with technologies such as fingerprint (touch-ID), iris scanner, facial recognition and others. Most of these technologies are mature and follow the general precept of just authenticating the user instead of giving access to raw data of underlying modality, thus keeping the user’s biometric data safe.
      </Typography>
    ),
    redirectUrl: "https://knect365.com/clinical-trials-innovation/article/a5d394f9-7488-48e3-8471-7b3b3563f095/clinical-data-wearables-quality-regulatory-privacy?utm_campaign=Share+Widget&utm_medium=Article+Share&utm_source=twitter",
  },
  {
    ImgSrc: ImgMedia201707,
    Name: "American Society of Tropical Medicine and Hygiene (ASTMH) Annual Conference",
    Date: "November 07, 2017",
    Summary: "American Society of Tropical Medicine and Hygiene (ASTMH) Annual Conference",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "https://www.astmh.org/ASTMH/media/2017-Annual-Meeting/ASTMH-2017-Abstract-Book.pdf",
  },
  {
    ImgSrc: ImgMedia201713,
    Name: "AAMA Healthcare Conference",
    Date: "October 12, 2017",
    Summary: "AAMA is Silicon Valley's leading business network promoting the success of technology enterprises in the Pacific Rim.",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        Having deep roots and wide reach in the local Silicon Valley community and on to greater Asia, AAMA today has a worldwide network of 10,000+ representing well over 2,000 companies.
      </Typography>
    ),
    redirectUrl: "https://www.aamasv.com/",
  },
  {
    ImgSrc: ImgMedia201712,
    Name: "Squeezing Out The Waste with Digital Health Innovation",
    Date: "October 05, 2017",
    Summary: "CliniOps: Making clinical trials faster, cheaper, and with high data quality",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        The CTO of the company, Yerramalli Subramaniam (a.k.a. Subbu), demoed CliniOps’ eSource solution in the Health2.0 breakout titled Personalized Health: Turning Data into Value. In order to appreciate the value they are bringing, you need to understand how clinical trial data is currently collected.
      </Typography>
    ),
    redirectUrl: "https://thedoctorweighsin.com/squeezing-out-the-waste-with-digital-health-innovation/",
  },
  {
    ImgSrc: ImgMedia201718,
    Name: "Frost Sullivan Excellence in Best Practices Awards Gala",
    Date: "July 20, 2017",
    Summary: "Frost & Sullivan Awards Gala Draws in Top Industry Leaders for Prestigious Recognition",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
       CliniOps - 2017 North American Digitalization of Clinical Trials Technology Leadership Award
      </Typography>
    ),
    redirectUrl: "https://www.prnewswire.com/news-releases/frost--sullivan-awards-gala-draws-in-top-industry-leaders-for-prestigious-recognition-300489371.html",
  },
  {
    ImgSrc: ImgMedia201716,
    Name: "Global CEO Excellence Award",
    Date: "July 18, 2017",
    Summary: "Cliniops - Avik Pal eClinical Technology CEO of the Year 2017 - West USA & Best eClinical Solutions Company - California",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "https://www.ceo-review.com/winners/cliniops-avik-pal/",
  },
  {
    ImgSrc: ImgMedia201719,
    Name: "Frost & Sullivan Tech Leadership Award",
    Date: "June 11, 2017",
    Summary: "CliniOps, Inc. Receives the ‘2017 Frost & Sullivan Technology Leadership Award for Digitalization of Clinical Trials’",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
       Based on its recent analysis of the eClinical solutions market, Frost & Sullivan recognized CliniOps with the 2017 North American Digitalization of Clinical Trials Technology Leadership Award. 
      </Typography>
    ),
    redirectUrl: "https://www.prweb.com/releases/cliniops_inc_receives_the_2017_frost_sullivan_technology_leadership_award_for_digitalization_of_clinical_trials_/prweb14414347.htm",
  },
  {
    ImgSrc: ImgMedia201721,
    Name: "Mobile Health Apps in OB-GYN-Embedded Psychiatric Care",
    Date: "June 10, 2017",
    Summary: "Mobile Health Apps in OB-GYN-Embedded Psychiatric Care",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "http://mhealth.jmir.org/2017/10/e152/",
  },
  {
    ImgSrc: ImgMedia201722,
    Name: "Pediatric Academic Societies (PAS)",
    Date: "May 07, 2017",
    Summary: "Pediatric Academic Societies (PAS)",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "https://www.xcdsystem.com/pas/program/2017/",
  },
  {
    ImgSrc: ImgMedia201723,
    Name: "Everest Group",
    Date: "April 10, 2017",
    Summary: "Everest Group",
    FullContent: (
      <Typography  variant='body2'sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "https://www.everestgrp.com/tag/healthcare/page/13/",
  },
  {
    ImgSrc: ImgMedia201724,
    Name: "Society of Physician Entrepreneurs (SoPE)",
    Date: "January 19, 2017",
    Summary: "Startup presentation at Society of Physician Entrepreneurs, San Francisco Bay Area Chapter on January 19, 2017.",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        CliniOps developed products and services for setting up and analyzing studies on a web-based application with integration capabilities to automate and streamline the data collection process into an efficient system for reporting and analysis in near real-time.
      </Typography>
    ),
    redirectUrl: "https://www.youtube.com/watch?v=QXPUWDA0s7s",
  },
  {
    ImgSrc: ImgMedia201725,
    Name: "Open-source mobile digital platform for clinical trial data collection in low-resource settings",
    Date: "January 06, 2017",
    Summary: "Open-source mobile digital platform for clinical trial data collection in low-resource setting",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "http://innovations.bmj.com/content/3/1/26",
  },
]

export default news2017Data;