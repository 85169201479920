import React from 'react';
import { Box, Typography, Grid, Card, CardHeader, CardMedia, Link } from '@mui/material';

import ImgHealthXL from '../../../../img/media/newsroom/_2020/Media-2020-07.png';
import ImgScopeSummit from '../../../../img/media/newsroom/_2020/Media-2020-05.png';
import ImgISCR from '../../../../img/media/newsroom/_2020/Media-2020-06.png';
import ImgOutsourcingClinicalTrial from '../../../../img/media/newsroom/_2020/Media-2020-04.png';
import ImgPubMed from '../../../../img/media/newsroom/_2020/Media-Publications-01.png';
import ImgElectraLabs from '../../../../img/media/newsroom/_2020/Media-2020-14.png';
import ImgDataBridge from '../../../../img/media/newsroom/_2020/Media-2020-22.jpg';
import ImgStefanini from '../../../../img/media/newsroom/_2020/Media-2020-17.png';
import ImgMarketCorrespondent from '../../../../img/media/newsroom/_2020/Media-2020-23.jpg';
import ImgDBusiness from '../../../../img/media/newsroom/_2020/Media-2020-11.png';
import ImgVoicebot from '../../../../img/media/newsroom/_2020/Media-2020-13.png';
import ImgAIPoweredHealthcare from '../../../../img/media/newsroom/_2020/Media-2020-12.png';
import ImgInfinit from '../../../../img/media/newsroom/_2020/Media-2020-18.png';
import ImgClinoSol from '../../../../img/media/newsroom/_2020/Media-2020-16.png';
import ImgSoT from '../../../../img/media/newsroom/_2020/Media-2020-19.png';
import ImgTechCrunch from '../../../../img/media/newsroom/_2020/Media-2020-01.png';
import ImgGartner from '../../../../img/media/newsroom/_2020/Media-2019-03.png';
import ImgRoche from '../../../../img/media/newsroom/_2020/Media-2020-21.jpg';
import ImgStefTalks from '../../../../img/media/newsroom/_2020/Media-2020-20.jpg';
import ImgEverestGroup from '../../../../img/media/newsroom/_2020/Media-2020-22-(1).jpg';
import ImgODI from '../../../../img/media/newsroom/_2020/odi.jpg';
import ImgBecker from '../../../../img/media/newsroom/_2020/becker.png';
import ImgInnovatorMD from '../../../../img/media/newsroom/_2020/Media-2020-21.png';
import ImgTexasLifeScience from '../../../../img/media/newsroom/_2020/Media-2020-20.png';
import ImgPlos from '../../../../img/media/newsroom/_2021/Media-2021-plos.jpg';
// import sign from '../../../../img/media/newsroom/Renew/sign.png';



const news2020Data = [
    {
    ImgSrc:ImgODI,
    Name: "CliniOps collaborates with Microsoft, iKure & McGill, for AI in the treatment of non-communicable disease (NCD)",
    Date: "December 15, 2020",
    Summary: "The ODI has selected six collaborations who will receive funding and guidance from the ODI and Microsoft, to more effectively address the challenges they face.",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "https://theodi.org/article/the-six-data-collaborations-chosen-for-our-new-peer-learning-network/",
  },
  {
    ImgSrc:ImgBecker,
    Name: "Michael Akinyele talks about CliniOps in The Scott Becker Podcast",
    Date: "December 11, 2020",
    Summary: "Michael Akinyele talks about CliniOps in The Scott Becker Podcast",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "https://beckergroupbusinessleadership.com/michael-akinyele-founder-managing-partner-at-the-maximizer-group-on-private-equity-in-healthcare-12-11-20/",
  },
  {
    ImgSrc:ImgInnovatorMD,
    Name: "Clinical Trials for Physicians",
    Date: "November 19, 2020",
    Summary: "Clinical Trials for Physicians",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "https://www.youtube.com/watch?v=6aR70IgJ9AI",
  },
  {
    ImgSrc:ImgTexasLifeScience,
    Name: "The Premier Life Science Conference in Texas",
    Date: "November 10, 2020",
    Summary: "Avik Pal, the founder and CEO of CliniOps, a technology and data company with a patented unified platform approach to digitizing clinical trials, finds universal solutions to counter adversities.",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "https://txlifescienceforum.org/",
  },
  {
    ImgSrc:ImgStefanini,
    Name: "Patient Centricity with Digitization &amp; Decentralization of Trials",
    Date: "November 05, 2020",
    Summary: "Patient Centricity with Digitization &amp; Decentralization of Trials",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "https://www.youtube.com/watch?v=ni15AkbZk8Q&list=PLpFaxUHOsZso1ashQjXMt7vAFmfQ60W5O&index=1",
  },
  {
    ImgSrc:ImgEverestGroup,
    Name: "CliniOps featured in Everest Group’s Clinical Development Platforms - Products - PEAK Matrix®, 2020",
    Date: "September 24, 2020",
    Summary: "This research studies the following clinical development platform vendors: ",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
       Accenture, ArisGlobal, BioClinica, Bio-Optronics, Castor, CliniOps, Cognizant, Covance, Datatrak, eClinicalHealth, Ennov, IBM, IQVIA, Medidata, Mednet, Navitas Life Sciences, Oracle, Parexel, PRA Health Sciences, TCS, and Veeva Systems.
      </Typography>
    ),
    redirectUrl: "https://www2.everestgrp.com/reportaction/EGR-2020-46-R-3958/Marketing",
  },
  {
    ImgSrc:ImgStefTalks,
    Name: "Navigating COVID-19 with Smart and Virtual Trials",
    Date: "September 10, 2020",
    Summary: "Navigating COVID-19 with Smart and Virtual Trials",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "https://register.gotowebinar.com/recording/recordingView?webinarKey=6322605685021700366&registrantEmail=nagesh.jadhav%40stefanini.com",
  },
  {
    ImgSrc:ImgRoche,
    Name: "Roche Supplier Day",
    Date: "September 02, 2020",
    Summary: "Roche’s (virtual) supplier day focuses on innovation and speed of delivery",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "https://procurementleaders.com/content/roches-virtual-supplier-day-focuses-on-innovation-and-speed-of-delivery/",
  },
  {
    ImgSrc:ImgGartner,
    Name: "Life Science CIOs: Map Your Pathway to Digital Trials",
    Date: "August 18, 2020",
    Summary: "There has been a great deal of hype around digital clinical trial approaches, with advocates noting the improved patient centricity, efficiency and recruitment.",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        Life science CIOs must separate fact from fiction, and develop a well-crafted strategic roadmap to achieve their digital trial objectives.
      </Typography>
    ),
    redirectUrl: "https://www.gartner.com/en/documents/3989224/life-science-cios-map-your-pathway-to-digital-trials",
  },
  {
    ImgSrc:ImgGartner,
    Name: "Hype Cycle for Life Science Research and Development, 2020",
    Date: "August 13, 2020",
    Summary: "Life science CIOs are building fully digital R&D organizations in cooperation with their industry peers.",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        Use this Hype Cycle to select technologies and identify innovations to build digital capabilities, products and therapies that improve patient outcomes and create new therapeutic value.
      </Typography>
    ),
    redirectUrl: "https://www.gartner.com/en/documents/3989136/hype-cycle-for-life-science-research-and-development-202",
  },
  {
    ImgSrc:ImgTechCrunch,
    Name: "Tech Crunch Early Stage, SF",
    Date: "July 21, 2020",
    Summary: "TechCrunch covers a lot of bases in the tech startup world, but none is more important than supporting founders, especially early stage founders. ",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
       TC Early Stage is a brand-new event series that offers founders an unrivaled opportunity to learn from top experts how best to move ahead in the startup game. 
      </Typography>
    ),
    redirectUrl: "https://techcrunch.com/events/tc-early-stage-sf-2020/",
  },
  {
    ImgSrc:ImgSoT,
    Name: 'Avik Pal spoke at the Society of Toxicology (SOT), at the 2020 Spring Virtual Symposium, on "Data Science for Life Science: In the post COVID-era',
    Date: "June 25, 2020",
    Summary: 'Avik Pal spoke at the Society of Toxicology (SOT), at the 2020 Spring Virtual Symposium, on "Data Science for Life Science: In the post COVID-era',
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "https://www.toxicology.org/groups/rc/ncac/events.asp",
  },
  {
    ImgSrc:ImgClinoSol,
    Name: 'Avik Pal on "ClinicalTrials in the post COVID era"',
    Date: "June 22, 2020",
    Summary: 'Avik Pal on "ClinicalTrials in the post COVID era"',
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "https://www.instagram.com/tv/CBqQfniBTT5/",
  },
  {
    ImgSrc:ImgInfinit,
    Name: 'Avik Pal Discussing, "The Future of The Pharma Industry - Where Is It Now And Where Is It Going?',
    Date: "June 10, 2020",
    Summary: 'Avik Pal Discussing, "The Future of The Pharma Industry - Where Is It Now And Where Is It Going?',
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "https://www.facebook.com/114576326848229/posts/149256763380185/?vh=e&d=n",
  },
  {
    ImgSrc:ImgAIPoweredHealthcare,
    Name: 'Collaborative effort taps AI to enhance clinical trials',
    Date: "June 05, 2020",
    Summary: "We’ve noted a couple times recently the move to enlist AI in the development of new drugs, and now a similar move is being made to use the new technologies to speed up and improve clinical trials.",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        Digital life sciences technology developers Stefanini Group and CliniOps have announced the release of a new platform, dubbed TRUST, that is aimed at helping medical researchers digitize and automate trials from the study building phase to support decentralized, virtual and hybrid trial capabilities.
      </Typography>
    ),
    redirectUrl: "https://www.healthcareitnews.com/ai-powered-healthcare/collaborative-effort-taps-ai-enhance-clinical-trials",
  },
  {
    ImgSrc:ImgVoicebot,
    Name: 'How a Virtual Assistant May Speed Up Medical Clinical Trials',
    Date: "June 04, 2020",
    Summary: "Digital life sciences technology developers Stefanini Group and CliniOps have launched a new platform designed to accelerate and upgrade clinical trials. ",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        The platform, called Trust, uses Stefanini’s Sophie virtual assistant to aid medical researchers.
      </Typography>
    ),
    redirectUrl: "https://voicebot.ai/2020/06/04/how-a-virtual-assistant-may-speed-up-medical-clinical-trials/",
  },
  {
    ImgSrc:ImgDBusiness,
    Name: 'Stefanini Group in Southfield Launches AI Virtual Assistant “Sophie” to Power New Life Science Portal',
    Date: "June 03, 2020",
    Summary: "Stefanini Group, a $1 billion global technology company specializing in life science digital solutions that has its North American headquarters in Southfield, on Tuesday launched the Trust platform to improve clinical trials.",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        Formed in partnership with CliniOps, the platform helps digitize and automate trials from the study building phase to support decentralized, virtual, and hybrid trial capabilities.
      </Typography>
    ),
    redirectUrl: "https://www.dbusiness.com/daily-news/stefanini-group-in-southfield-launches-ai-virtual-assistant-sophie-to-power-new-life-science-portal/",
  },
  {
    ImgSrc:ImgStefanini,
    Name: 'Stefanini Group partners with CliniOps',
    Date: "May 26, 2020",
    Summary: "Partnership with CliniOps results in digital, automated research suite",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
       We are excited to announce the launch of our new TRUST platform to transform clinical trials. Formed in partnership with CliniOps, the platform helps digitize and automate trials from the study building phase to support decentralized, virtual and hybrid trial capabilities
      </Typography>
    ),
    redirectUrl: "https://stefanini.com/en/trends/news/stefanini-group-announces-trust-platform-to-transform-clinical-trials",
  },
  {
    ImgSrc:ImgDataBridge,
    Name: 'Global Pharma Clinical Trial Digitization Market Report: Market Trends and Forecast to 2027',
    Date: "May 05, 2020",
    Summary: "Data Bridge Market Research analyses that the pharma clinical trial digitization market to account growing at a CAGR of 6.2% in the forecast period of 2022-2029. ",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
       The growing demand for personalised medicine is expected to open up new opportunities for the pharmaceutical clinical trial digitization market.
      </Typography>
    ),
    redirectUrl: "https://www.databridgemarketresearch.com/reports/global-pharma-clinical-trial-digitization-market",
  },
  {
    ImgSrc:ImgPlos,
    Name: 'The safety of combined triple drug therapy with ivermectin, diethylcarbamazine and albendazole in the neglected tropical diseases co-endemic setting of Fiji: A cluster randomised trial',
    Date: "March 16, 2020",
    Summary: "The safety of combined triple drug therapy with ivermectin, diethylcarbamazine and albendazole in the neglected tropical diseases co-endemic setting of Fiji: A cluster randomised trial",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "https://journals.plos.org/plosntds/article?id=10.1371/journal.pntd.0008106",
  },
  {
    ImgSrc:ImgOutsourcingClinicalTrial,
    Name: 'Outsourcing in Clinical Trials, West Coast',
    Date: "March 03, 2020",
    Summary: "Outsourcing in Clinical Trials, West Coast",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "https://www.arena-international.com/octwestcoast",
  },
  {
    ImgSrc:ImgISCR,
    Name: 'ISCR Annual Conference, 2020',
    Date: "January 24, 2020",
    Summary: "ISCR Annual Conference, 2020",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "https://www.iscr.org/PastEvent_Details.aspx?Guid=f3a52d23-3329-450e-b80f-26845045b67c",
  },
  {
    ImgSrc:ImgScopeSummit,
    Name: 'Scope Summit 2020',
    Date: "January 24, 2020",
    Summary: "SCOPE 2020 attracts record attendance with more than 2,200 participant",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
       The impressive executive audience at SCOPE came together for invaluable networking opportunities with more than 53% of the attendees titled as a decision-maker from leading Pharma (18%), Biotech companies (19%) and, CRO (36%). Industry leaders and key decision-makers representing 28 different countries and more than 770 unique organizations shared case studies, collaborated on best practices, and provided unique perspectives on poignant issues that the field currently faces.
      </Typography>
    ),
    redirectUrl: "https://www.scopesummit.com/20",
  },
  {
    ImgSrc:ImgHealthXL,
    Name: 'HealthXL Report: Clinical Trials Optimization with Digital Health',
    Date: "January 16, 2020",
    Summary: "HealthXL Report: Clinical Trials Optimization with Digital Health",
    FullContent: (
      <Typography variant='body2' sx={{ mt: 2 }}>
        {/* The African proverb “rain does not fall on one roof alone” suggests that adversity can strike anyone. Hence, we need to find universal solutions to counter such adversities. */}
      </Typography>
    ),
    redirectUrl: "https://www.healthxl.com/blog/healthxl-report-clinical-trials-optimization-with-digital-health",
  },
]

export default news2020Data;