import React from 'react';
import { Grid, Typography, Stack } from '@mui/material';

// import { PageSectionLeftImg } from '../../../component/page';
import { Conduct } from '../../../component/page';

// import ImgCTMS from '../../../../img/product/conduct/ctmscro.png';
import ImgeTMF from '../../../../img/product/conduct/eTMF_Conduct.png';
import CustomTypography from '../../../component/customTypography';

const template = () => {
  return (
    <>
      {/* <PageSectionLeftImg
        heading={
          <Stack direction="row" spacing={1} alignItems='center' justifyContent={{xs: 'center', md: 'flex-start'}}>
            <Typography variant="h2" component="h2" className="co-page-heading" sx={{ textAlign: { xs: 'center', md: 'left'} }}>
              CTMS
            </Typography>
          </Stack>
        }
        imgUrl={ImgCTMS}
        imgTitle="CTMS"
      >
        <Typography sx={{ mt: 1 }}>
        CliniOps CTMS module is designed to monitor study progress and performance, along with tracking deadlines and milestones. This module supports the clinical operations team with an adaptive framework, and exceptional breadth of functionality that enables rapid and flexible deployment, all with the power of other built-in modules. This module offers comprehensive management of trial planning, document management, country and site progress, monitoring activities, along with state-of-the-art reporting capabilities.
        </Typography>
      </PageSectionLeftImg> */}
      
      <Grid container direction='column' spacing={0} sx={{ mt: 0 }}>
            {/* eTMF/ eISF  */}
          <Grid item>
              <Conduct
                ImgSrc={ImgeTMF}
                Name="eTMF/ eISF"
                className="co-image-conduct"
                Summary={
                  <>
                  <CustomTypography variant='body2' sx={{ mt: 1 }}>
                    CliniOps eTMF (Electronic Trial Master File) module is architected in compliance with the DIA Reference model. 
                    It brings the trial master file requirements from the source to usher in a very rich trial experience. 
                    <br></br><br></br>
                    The eISF (electronic Investigator Site File) module is the Study Binder for sites to streamline the management of 
                    essential documents for each site, enabled by access control of the corresponding documents at the site level. 
                    It allows clinical research sites to store, organize, and share regulatory and trial-related documents in a secure, compliant, 
                    and easily accessible manner. Complete Audit Trails help stay inspection-ready throughout the study and meet compliance 
                    requirements. Customizable dashboards keep track of essential study metrics on document status.
                </CustomTypography>
                </>
                }
              />
              
          </Grid>
      </Grid>
    </>
  );
}

export default template;