import React from 'react';
import { Typography } from '@mui/material';
import { blueGrey } from '@mui/material/colors';

const template = () => {
  return (
    <>
      <Typography variant="h9" sx={{paddingBottom:"15px"}}  component="h1">
      Enabling Remote Monitoring in a Malaria study: Regimen Optimization Trial of PfSPZ Vaccine in Equatorial Guinea 
      </Typography>
     
      <a href="https://sanaria.com/" target={'_blank'} class="linkcolor">Sanaria</a>
      
    </>
  );
}

export default template;