import React from 'react';
import { Grid, Typography, Stack } from '@mui/material';

import { Product } from '../../../component/page';

import ImgSH from '../../../../img/service/support-helpdesk/SH.png';
import CustomTypography from '../../../component/customTypography';

const template = () => {
  return (
    <>
      <Grid container direction='column' spacing={0} sx={{ mt: 0 }}>
            {/* Support & Helpdesk  */}
          <Grid item>
              <Product
                ImgSrc={ImgSH}
                Name="Support & Helpdesk"
                Summary={
                  <>
                  <CustomTypography variant='body2' sx={{ mt: 1 }}>
                  Support teams provide essential assistance throughout the trial, offering guidance on standard software support and maintenance of the platform, hosting and digital infrastructure, version control and release management activities.
                    <br></br><br></br>
                    Our dedicated helpdesk team serves as a central resource for addressing any issues or questions that arise during the trial, whether related to user management or troubleshooting issues. Effective project management and support, helps ensure a smooth delivery.
                </CustomTypography>
                </>
                }
              />
              
          </Grid>
      </Grid>
    </>
  );
}

export default template;