import React from 'react';
import { HashLink as RouterLink } from 'react-router-hash-link';
import { Grid, Typography, Box, Button, Link } from '@mui/material';

import { Product } from '../../../component/page';

import RouterList from '../../../router';

import ImgCDS from '../../../../img/service/_0/cds.png';
import CustomTypography from '../../../component/customTypography';

const template = () => {
  return (
    <>
      <Grid container direction='column' spacing={0} sx={{ mt: 0 }}>
            {/* Clinical Data Services  */}
          <Grid item>
              <Product
                ImgSrc={ImgCDS}
                Name="Clinical Data Services"
                Summary={
                  <>
                  <CustomTypography variant='body2' sx={{ mt: 1 }}>
                    CliniOps offers comprehensive services as a Technology and Data Partner. CliniOps offers a partnership built on an 
                    Unified platform geared towards addressing needs of biopharma industry from concept to commercialization. 
                    Promotes heightened efficiency to enable the realization of having one secure, flexible, and affordable holistic solution 
                    for effective clinical trial management. 
                    <br></br><br></br>
                    Our services enhances collaborative partnerships with every stakeholder through 
                    a platform that seamlessly integrates existing systems via a proprietary patented technology that is easily accessible 
                    offline or online. Delivers centralized and decentralized clinical trial methodologies founded on innovative data science 
                    for continuity of studies even during times of uncertainty. Equips the industry with an agile platform, capable of data 
                    collection at point of care, for real time and accurate evidence generation and to support study investigator’s ability to 
                    make informed decisions.
                  </CustomTypography>
                <Box sx={{ mt: 2 }}>
                   <Link
                      href={RouterList.service.clinicaldatamanagement}
                      target="_blank"
                      sx={{ fontSize: "14px", fonWeight: "400" }}
                      underline="hover"
                      color="#1E75C6"
                    >
                        Know More...
                    </Link>
                </Box>
                </>
                }
              />
              
          </Grid>
      </Grid>
    </>
  );
}

export default template;

//Commenting the previous code

// import React from 'react';
// import { HashLink as RouterLink } from 'react-router-hash-link';
// import { Grid, Box, Typography, Button, Stack } from '@mui/material';

// import { PageSectionRightImg } from '../../../component/page';

// import RouterList from '../../../router';

// import ImgClinicalDataManagement from '../../../../img/service/_0/data-mgmt.jpeg';

// const template = () => {
//   return (
//     <>
//       <PageSectionRightImg
//         heading={
//           <Stack direction="row" spacing={1} alignItems='center' justifyContent={{xs: 'center', md: 'flex-start'}}>
//             <Typography variant="h2" component="h2" className="co-page-heading" sx={{ textAlign: { xs: 'center', md: 'left'} }}>
//               Clinical Data Management
//             </Typography>
//           </Stack>
//         }
//         imgUrl={ImgClinicalDataManagement}
//         imgTitle="Clinical Data Management"
//       >
//         <Typography sx={{ mt: 1 }}>
//           Our team of experienced professionals can help you in all aspects of clinical data management challenges and collaborate with you in the process of collection, cleaning, and management of subject data in compliance with regulatory standards. Our goal is to provide you with error free, valid and statistically sound data for analysis. Our clinical data management specialists will translate your clinical trial protocol into an intuitively designed data capture system that will accurately meet the data capture requirements of your study. So, while our team of professionals manages all your data, you spend less time on training and more time running the trial efficiently. With continuous access to your data and total transparency in the entire process, you stay in complete control of your data.
//         </Typography>
//         <Box sx={{ mt: 2 }}>
//           <Button variant="text" to={RouterList.service.clinicaldatamanagement} component={RouterLink} sx={{ ml: -1 }}>Read More</Button>
//         </Box>
//       </PageSectionRightImg>
//     </>
//   );
// }

// export default template;