import React from 'react';
import { HashLink as RouterLink } from 'react-router-hash-link';

import { Container, Grid, Box, Divider, Typography, Link, useTheme, useMediaQuery } from '@mui/material';
import { grey } from '@mui/material/colors';

import RouterList from '../../router';

import ImgLogoCliniOps from '../../../img/CliniOpswithoutTM.png';
import ImgLogoGDPR from '../../../img/footer/logo-gdpr.png';
import ImgLogoUKPrivacy from '../../../img/footer/logo-uk-privacy.png';
import ImgLogoIso27001 from '../../../img/footer/iso27001.png';
import ImgLogoHipaa from '../../../img/footer/hipaa.png';
import ImgLogoFda from '../../../img/footer/LogoFDA2x.png';
import ImgLogoCdisc from '../../../img/footer/cdisc.svg';
import ImgLogoFacebook from '../../../img/footer/LogoFacebook.svg';
import ImgLogoTwitter from '../../../img/footer/LogoTwitter.svg';
import ImgLogoLinkedIn from '../../../img/footer/LogoLinkedin.svg';
import ImgLogoYoutube from '../../../img/footer/LogoYouTube.svg';

const template = () => {
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("330"))
  return (
    <>
      <Box sx={{ py: 6, backgroundColor: grey[200] }}>
        <Container>
          <Grid container direction='column' spacing={4}>
            <Grid item>
              <Grid container spacing={1} justifyContent="center" alignItems="center">
                {/* <Grid item>
                  <img
                    width={'168px'}
                    height={'38px'}
                    src={`${ImgLogoCliniOps}`}
                    alt='Logo'
                    title='CliniOps Logo'
                  />
                </Grid> */}
                <Grid item>
                  <Grid container  spacing={{xs: 2, lg: 19}} alignItems="center">
                    <Grid item>
                      <a href='https://prighter.com/verify/13827389066' target='_blank'>
                        <img
                          src={`${ImgLogoGDPR}`}
                          alt='Logo'
                          title='GDPR Logo'
                        />
                      </a>
                    </Grid>
                    <Grid item>
                      <a href='https://prighter.com/ukrep/verify/13827389066' target='_blank'>
                        <img
                          src={`${ImgLogoUKPrivacy}`}
                          alt='Logo'
                          title='UK Privacy Logo'
                        />
                      </a>
                    </Grid>
                    <Grid item>
                      {/* <a href='https://prighter.com/ukrep/verify/13827389066' target='_blank'> */}
                        <img
                          src={`${ImgLogoHipaa}`}
                          alt='Logo'
                          title='HIPAA Compliant'
                          style={{ maxHeight: "60px"}}
                        />
                      {/* </a> */}
                    </Grid>
                    <Grid item>
                      {/* <a href='https://prighter.com/ukrep/verify/13827389066' target='_blank'> */}
                        <img
                          src={`${ImgLogoIso27001}`}
                          alt='Logo'
                          title='ISO 27001 Certified'
                          style={{ maxWidth: "109px", maxHeight: "60px"}}
                        />
                      {/* </a> */}
                    </Grid>
                    <Grid item>
                      {/* <a href='https://prighter.com/ukrep/verify/13827389066' target='_blank'> */}
                        <img
                          src={`${ImgLogoFda}`}
                          alt='Logo'
                          title='FDA 21 CFR Part 11'
                          style={{ maxWidth: "109px", maxHeight: "60px"}}
                        />
                      {/* </a> */}
                    </Grid>
                    {/* <Grid item> */}
                      {/* <a href='https://prighter.com/ukrep/verify/13827389066' target='_blank'> */}
                        {/* <img
                          src={`${ImgLogoCdisc}`}
                          alt='Logo'
                          title='CDISC'
                          style={{ maxWidth: "109px", maxHeight: "60px"}}
                        /> */}
                      {/* </a> */}
                    {/* </Grid> */}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>  
        </Container>    
            {/* Divider */}
            <Grid item xs={12} sx={{py:5}}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  width: "100%",
                }}
              >
                {/* Left Line */}
                <Box
                  sx={{
                    flexGrow: 1,
                    height: "1px",
                    backgroundColor: "#c4c4c4",
                    marginRight: "8px",
                  }}
                />
                {/* Dots Container */}
                <Box
                  sx={{
                    display: "flex",
                    gap: "8px",
                  }}
                >
                  {/* Dot 1 */}
                  <Box
                    sx={{
                      width: "10px",
                      height: "10px",
                      backgroundColor: "#002D5E", // Dark dot color
                      borderRadius: "50%",
                    }}
                  />
                  {/* Dot 2 */}
                  <Box
                    sx={{
                      width: "10px",
                      height: "10px",
                      backgroundColor: "transparent",
                      border: "2px solid #1E75C6", // Light dot border
                      borderRadius: "50%",
                    }}
                  />
                  {/* Dot 3 */}
                  <Box
                    sx={{
                      width: "10px",
                      height: "10px",
                      backgroundColor: "#03a9f4", // Blue dot color
                      borderRadius: "50%",
                    }}
                  />
                </Box>
                {/* Right Line */}
                <Box
                  sx={{
                    flexGrow: 1,
                    height: "1px",
                    backgroundColor: "#c4c4c4",
                    marginLeft: "8px",
                  }}
                />
              </Box>
            </Grid>          
            {/* End of the Divider */}
            <Container>
            <Grid item>
              <Grid container direction="row" spacing={4} alignItems="flex-start" justifyContent={{xs:'center',md:'space-evenly'}}>
                <Grid item xs={isXS ? 12 : 6} md={2}>
                  <Grid container direction="column" spacing={0.3}>
                    <Grid item>
                      <Box className='co-ftr__text'>Home</Box>
                    </Grid>
                    <Grid item>
                      <a className='co-ftr__link' href={RouterList.about.mission} component={RouterLink}>Mission</a>
                    </Grid>
                    <Grid item>
                      <a className='co-ftr__link' href={RouterList.media.newsroom} component={RouterLink}>Newsroom</a>
                    </Grid>
                    <Grid item>
                      <a className='co-ftr__link' href={RouterList.about.leadershipteam} component={RouterLink}>Leadership</a>
                    </Grid>
                    <Grid item>
                      <a className='co-ftr__link' href={RouterList.about.career} component={RouterLink}>Careers</a>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={isXS ? 12 : 6} md={3}>
                  <Grid container direction="column" spacing={0.3}>
                    <Grid item>
                      <Box className='co-ftr__text'>Products</Box>
                    </Grid>
                    <Grid item>
                      <a className='co-ftr__link' href={RouterList.product.connect} component={RouterLink}>CliniOps Connect</a>
                    </Grid>
                    <Grid item>
                      <a className='co-ftr__link' href={RouterList.product.edge} component={RouterLink}>CliniOps Edge</a>
                    </Grid>
                    <Grid item>
                      <a className='co-ftr__link' href={RouterList.product.conduct} component={RouterLink}>CliniOps Conduct</a>
                    </Grid>
                    {/* <Grid item>
                      <a className='co-ftr__link' href={RouterList.product.insights} component={RouterLink}>CliniOps Insights</a>
                    </Grid> */}
                  </Grid>
                </Grid>
                <Grid item xs={isXS ? 12 : 6} md={4}>
                  <Grid container direction="column" spacing={0.3}>
                    <Grid item>
                      <Box className='co-ftr__text'>Services</Box>
                    </Grid>
                    <Grid item>
                      <a className='co-ftr__link' href={RouterList.service.studysetup} component={RouterLink}>Study Setup</a>
                    </Grid>
                    <Grid item>
                      <a className='co-ftr__link' href={RouterList.service.clinicaldatamanagement} component={RouterLink}>Clinical Data Services</a>
                    </Grid>
                    <Grid item>
                      {/* <a className='co-ftr__link' href={RouterList.service.biostatisticprogramming} component={RouterLink}>Biostatistics &amp; Programming</a> */}
                      <a className='co-ftr__link' href={RouterList.service.supporthelpdesk} component={RouterLink}>Support &amp; Helpdesk</a>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={isXS ? 12 : 6} md={3}>
                  <Grid container direction="column" spacing={0.3}>
                    <Grid item>
                      <Box className='co-ftr__text'>Contact Us</Box>
                    </Grid>
                    <Grid item>
                      <a className='co-ftr__link' href="mailto:info@cliniops.com">info@cliniops.com</a>
                    </Grid>
                    <Grid item>
                      <a className='co-ftr__link' style={{color:"#000000"}}>+1-888-687-5014</a>
                    </Grid>
                    <Grid item>
                      <a className='co-ftr__link' href="https://www.cliniops.com">www.cliniops.com</a>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item>
              <Divider />
            </Grid> */}
            {/* </Grid> */}
            </Container>
            </Box>
            <Box sx={{ py: 2, backgroundColor: 'white' }}></Box>
            <Grid item xs={12} md = 'auto'>
              <Grid container spacing={1} alignItems="center" justifyContent='center'>
                <Grid item>
                  <img
                    width={'168px'}
                    height={'38px'}
                    src={`${ImgLogoCliniOps}`}
                    alt='Logo'
                    title='CliniOps Logo'
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md='auto'>
              <Grid container spacing={1} alignItems="center" justifyContent='center'>
                <Grid item>
                  <Typography variant="body2" sx={{ fontSize: "14px" }}>
                    © 2025 CliniOps, Inc. <b>|</b> 
                  </Typography>
                </Grid>
                <Grid item>
                  <Link variant="body2" underline="hover" sx={{ fontSize: "14px" }} to={RouterList.policy.privacy} component={RouterLink}>Privacy Policy</Link>
                </Grid>
                <Grid item>
                  <Typography variant="body2" sx={{ fontSize: "14px" }}>
                    <b>|</b> 
                  </Typography>
                </Grid>
                <Grid item>
                  <Link variant="body2" underline="hover" sx={{ fontSize: "14px" }} to={RouterList.policy.cookie} component={RouterLink}>Cookie Policy</Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md='auto' sx={{margin:"10px 0 50px 0"}}>
              <Grid container spacing={1} justifyContent="center" alignItems="center">
                <Grid item xs={12} md='auto'>
                  <Grid container spacing={2} alignItems="center" justifyContent='center'>
                    <Grid item>
                      <a href='https://www.facebook.com/cliniops/' target='_blank'>
                        <img
                          width={'30px'}
                          height={'30px'}
                          src={`${ImgLogoFacebook}`}
                          alt='Facebook Logo'
                          title='CliniOps Facebook Page'
                        />
                      </a>
                    </Grid>
                    <Grid item>
                      <a href='https://www.linkedin.com/company/cliniops-inc/' target='_blank'>
                        <img
                          width={'30px'}
                          height={'30px'}
                          src={`${ImgLogoLinkedIn}`}
                          alt='LinkedIn Logo'
                          title='CliniOps LinkedIn Page'
                        />
                      </a>
                    </Grid>
                    <Grid item>
                      <a href='https://twitter.com/cliniops' target='_blank'>
                        <img
                          width={'30px'}
                          height={'30px'}
                          src={`${ImgLogoTwitter}`}
                          alt='Twitter Logo'
                          title='CliniOps Twitter Page'
                        />
                      </a>
                    </Grid>
                    <Grid item>
                      <a href='https://www.youtube.com/channel/UCTjLNXcWwlyaFvLoAblzm0Q' target='_blank'>
                        <img
                          width={'30px'}
                          height={'30px'}
                          src={`${ImgLogoYoutube}`}
                          alt='YouTube Logo'
                          title='CliniOps YouTube Page'
                        />
                      </a>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
    </>
  );
}

export default template;