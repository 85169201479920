import React from "react";
import { Grid, Box, Stack } from "@mui/material";
import { teal } from "@mui/material/colors";

import pinContact from "../../../../img/contact/pinContact.png";
import phoneContact from "../../../../img/contact/phoneContact.png";
import mailContact from "../../../../img/contact/mailContact.png";

const template = () => {
  
  return (
    <>
      <Box
        sx={{
          // border:"1px solid red",
          mx: { xs: "5%", md: "13%" },
          padding: {xs:"30px",md:"40px"},
          boxShadow: "0 3px 3px rgba(0, 0, 0, 0.16)",
          borderRadius: "10px",
          backgroundColor: "white",
        }}
      >
        <Stack sx={{ gap: "25px" }}>
          <Box>
            <Grid
              container
              alignItems="center" spacing={2}
            >
              <Grid
                item
                xs={12}
                md={3}
                sx={{
                  // border:"1px solid black",
                  display: "flex",
                  alignItems: "center",
                  fontSize: {xs:"25px",md:"38px"},
                  color: "#042D43",
                  fontWeight: "300",
                  padding: "",
                  textShadow: "0 3px 3px rgba(0, 0, 0, 0.16)"
                }}
              >
                <Box>USA Contact</Box>
              </Grid>
              <Grid item xs={12} md={9}>
                <Grid container alignItems='center' spacing={2}>
                <Grid
                item
                xs={12}
                sm={5}
                sx={{
                  // border:"1px solid black",
                  borderRight: {xs:"none",sm:"1px solid #CED2D4"},
                  display: "flex",
                  justifyContent: { xs: "flex-start", sm: "center" },
                  alignItems: { xs: "center", sm: "center" },
                  color: "#1E75C6",
                  padding: "",
                  gap: "10px",
                }}
              >
                <Box>
                  <img src={`${pinContact}`} alt="" />
                </Box>
                <Box sx={{ fontSize: "12px", lineHeight: "14px" }}>
                  38750 Paseo Padre Parkway, Suite C8 <br />
                  Fremont, CA 94536, USA
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                sm={3.5}
                sx={{
                  // border:"1px solid black",
                  borderRight: {xs:"none",sm:"1px solid #CED2D4"},
                  display: "flex",
                  justifyContent: { xs: "flex-start", sm: "center" },
                  alignItems: { xs: "center", sm: "center" },
                  fontSize: "12px",
                  color: "#042D43",
                  padding: "",
                  gap: "7px",
                  marginLeft:{xs:0,md:'-10px'}
                }}
              >
                <Box>
                  <img src={`${phoneContact}`} alt="" />
                </Box>
                <Box>+1 888.687.5014</Box>
              </Grid>

              <Grid
                item
                xs={12}
                sm={3.5}
                sx={{
                  // border:"1px solid black"
                  display: "flex",
                  justifyContent: { xs: "flex-start", sm: "center" },
                  alignItems: { xs: "center", sm: "center" },
                  fontSize: "12px",
                  color: "#042D43",
                  padding: "",
                  gap: "7px",
                }}
              >
                <Box>
                  <img src={`${mailContact}`} alt="" />
                </Box>
                <Box>info@cliniops.com</Box>
              </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              // border:"1px solid blue",
              borderRadius: "8px",
            }}
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5319.380887986923!2d-121.98377985553265!3d37.556882602828736!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fc085d455ae0b%3A0xf3c0cc7a328847d5!2sMurco%20Building%2C%2038750%20Paseo%20Padre%20Pkwy%2C%20Fremont%2C%20CA%2094536!5e0!3m2!1sen!2sus!4v1655078304751!5m2!1sen!2sus"
              width="100%"
              height="370px"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default template;
