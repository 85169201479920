import React from 'react';
import { Grid } from '@mui/material';
import { teal } from '@mui/material/colors';

import { PageHero, PageContent } from '../../component/page';

// Page Imports
import _0 from './career/_0';
import _1 from './career/_1';
import _2 from './career/_2';
import _3 from './career/_3';


const template = () => {
  return (
    <>
      {/* <PageHero color={teal}> */}
        <_0 />
        <_2/>
        <_3/>
      {/* </PageHero> */}
      <PageContent>
        <Grid item>
          <_1 />
        </Grid>
      </PageContent>
    </>
  );
}

export default template;