import React from "react";
import { Box, Typography, Button, useTheme, useMediaQuery } from "@mui/material";
import NurseImg from '../../../../img/home/NURSE01.png';
import WaveImg from '../../../../img/home/wave2.png'
import { HashLink as RouterLink } from "react-router-hash-link";
import RouterList from "../../../router";

const InnovationLayout = () => {

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('900','1080'))

  return (
    <Box
      sx={{
        width: "100%",
        backgroundImage: `url(${WaveImg})`,
        backgroundSize: "contain",
        backgroundPosition: "center bottom",
        backgroundRepeat: "no-repeat",
        padding: { xs: "10px",md:"1.5em 0 0 0" },
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor:"#F9F9F9",
        position:"relative"
      }}
    >
      {/* Title Section */}
      <Typography
        variant="h1Teal"
        sx={{
          lineHeight:1,
          mb: { xs: 2, md: 2 },
          padding:{xs:1,md:1},
        }}
      >
        Delivering industry-leading Innovations
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" }, // Stack vertically on small screens
          justifyContent: "center",
          alignItems: {xs:'center',md:'flex-start'}, // Stretch to align nurse image and text
          gap: "20px", // Adjust gap between image and text
          width: "100%", // Ensure the entire width is used
        }}
      >
        {/* Left Section - Nurse Image */}
        <Box
          sx={{
            flex: 1,
            maxWidth: { xs: "100%", md: "40%" }, // Full width on small screens, 40% on large
            // height: {xs:'300px',md:'650px'}, // Full height to ensure no bottom gap
            marginBottom:"-5px",
            position:'relative',
          }}
        >
          <img
            src={`${NurseImg}`} 
            alt="Healthcare Worker"
            style={{
              position:"absoulte",
              bottom:0,
              width: "100%",
              maxWidth: { xs: "300px", md: "100%" },
              height: isMediumScreen ? "600px" : "auto",
              objectFit: isMediumScreen ? 'cover' :  "contain", // Ensures the image covers the container
              objectPosition: "bottom", // Aligns the image to the bottom
            }}
          />
        </Box>

        {/* Right Section - Text */}
        <Box
          sx={{
            flex: 1,
            // maxWidth: "700px",
            maxWidth: { xs: "100%", md: "50%" },
            textAlign: "left",
            padding: "10px",
            margin:{xs:'auto',md:0},
            // border:'1px solid red'
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start", // Center content vertically
            alignItems:"flex-start"
          }}
        >
          {/* Subtitle Section */}
          <Typography
            variant="h2"
            sx={{
              lineHeight:1.2,
              mb: { xs: 2, md: 2 },
              display:{xs:"none",md:"block"}
            }}
          >
            Entrust your Clinical Trials with our <br></br> unified platform approach
          </Typography>
          <Typography
            variant="h2"
            sx={{
              lineHeight:1.2,
              mb: { xs: 2, md: 2 },
              display:{xs:"block",md:"none"}
            }}
          >
            Entrust your Clinical Trials with our unified platform approach
          </Typography>
        
          <Box component="ul" sx={{ listStylePosition: "outside", paddingLeft: "20px" }}>
          <Typography
            component="li"
            variant='body2'
            sx={{
              textIndent: "0em",
              lineHeight: "1.6",
            }}
          >
            Direct data capture (DDC) in complete offline mode (offline eSource) using mobile devices
          </Typography>
          <Typography
            component="li"
            variant='body2'
            sx={{
              textIndent: "0em",
              lineHeight: "1.6",
            }}
          >
           Medical device integration & continuous data collection
          </Typography>
          <Typography
            component="li"
            variant='body2'
            sx={{
              textIndent: "0em",
              lineHeight: "1.6",
            }}
          >
           Electronic patient enrollment with eConsent
          </Typography>
          <Typography
            component="li"
            variant='body2'
            sx={{
              textIndent: "0em",
              lineHeight: "1.6",
            }}
          >
           Remote Monitoring & remote source data verification (rSDV)
          </Typography>
          <Typography
            component="li"
            variant='body2'
            sx={{
              textIndent: "0em",
              lineHeight: "1.6",
            }}
          >
           Smart study setup, supports self-serve model
          </Typography>
          <Typography
            component="li"
            variant='body2'
            sx={{
              textIndent: "0em",
              lineHeight: "1.6",
            }}
          >
           Patient Engagement & Protocol Adherence
          </Typography>
          <Typography
            component="li"
            variant='body2'
            sx={{
              textIndent: "0em",
              lineHeight: "1.6",
            }}
          >
           Built-in Telemedicine in Clinical Trials
          </Typography>
          <Typography
            component="li"
            variant='body2'
            sx={{
              textIndent: "0em",
              lineHeight: "1.6",
            }}
          >
           Cloud-first architecture with AWS
          </Typography>
          <Typography
            component="li"
            variant='body2'
            sx={{
              textIndent: "0em",
              lineHeight: "1.6",
            }}
          >
          Built-in Data Science (AI/ML/NLP)
          </Typography>
          </Box>
          
          <Button
            component={RouterLink}
            to={RouterList.product._0}
            variant="contained"
            color="primary"
            sx={{
              mt: 3,
              textTransform: "none",
              backgroundColor: "#042D43",
              "&:hover": {
                backgroundColor: "#005580",
              },
              fontSize:"14px"
            }}
          >
            Know More...
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default InnovationLayout;
