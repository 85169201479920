import React from "react";
import { Grid, Typography, Stack } from "@mui/material";

// import { PageSectionLeftImg } from '../../../component/page';
import { Conduct } from "../../../component/page";

// import ImgRSDV from '../../../../img/product/conduct/rsdv.png';
import ImgeCOA from "../../../../img/product/conduct/eCOA.png";
import CustomTypography from "../../../component/customTypography";

const template = () => {
  return (
    <>
      {/* <PageSectionLeftImg
        heading={
          <Stack direction="row" spacing={1} alignItems='center' justifyContent={{xs: 'center', md: 'flex-start'}}>
            <Typography variant="h2" component="h2" className="co-page-heading" sx={{ textAlign: { xs: 'center', md: 'left'} }}>
              rSDV
            </Typography>
          </Stack>
        }
        imgUrl={ImgRSDV}
        imgTitle="rSDV"
      >
        <Typography sx={{ mt: 1 }}>
          CliniOps offers remote video interaction between CROs and sites for site initiation and other remote monitoring activities. CliniOps also offers remote source data verification through centralized access control of specific users to access site data remotely. In addition, data that requires SDV are configured ahead of time that drastically minimizes the on-site SDV effort, since most data is collected as electronic source (eSource) at the site, and may not need SDV. The ability to remotely perform this task in lieu of on-site SDV saves significant time, money and effort, reduces travel to sites, and keeps the study moving forward without interruptions.
        </Typography>
      </PageSectionLeftImg> */}

      <Grid container direction="column" spacing={0} sx={{ mt: 0 }}>
        {/* eCOA  */}
        <Grid item>
          <Conduct
            ImgSrc={ImgeCOA}
            Name="eCOA"
            className="co-image-conduct"
            Summary={
              <>
                <CustomTypography variant="body2" sx={{ mt: 1 }}>
                  eCOA (electronic Clinical Outcome Assessment) is a digital
                  solution that facilitates the collection of various patient
                  outcomes, such as patient-reported outcomes (ePRO),
                  clinician-reported outcomes (eClinRO), and performance
                  outcomes (ePerfO), and other health data in clinical trials.
                  It streamlines data capture through secure, user-friendly
                  interfaces across multiple digital devices such as
                  smartphones, tablets, and computers.
                  <br></br>
                  <br></br>
                  By replacing traditional paper-based assessments, eCOA
                  improves data accuracy, reduces human error, and accelerates
                  the trial process. Real-time data collection also enables
                  researchers to monitor patient progress and make informed
                  decisions more efficiently. Ultimately, eCOA enhances the
                  quality of clinical trials by ensuring faster, more reliable,
                  and cost-effective data gathering.
                </CustomTypography>
              </>
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default template;
