import React from 'react';
import { Grid, Typography, Stack } from '@mui/material';

import { PageSectionRightImg } from '../../../component/page';
import { Product } from '../../../component/page';

import ImgEConsent from '../../../../img/product/connect/econsent.png';
import ImgRemConsent from '../../../../img/product/connect/remoteconsent.png';
import CustomTypography from '../../../component/customTypography';

const template = () => {
  return (
    <>
    <Grid container direction='column' spacing={0} sx={{ mt: 0 }}>
          {/* eConsent (Remote)  */}
        <Grid item>
            <Product
              ImgSrc={ImgRemConsent}
              Name="eConsent (Remote)"
              Summary={
                <>
                <CustomTypography variant='body2' sx={{ mt: 1}}>
                The CliniOps eConsent (Remote) module ensures the patient understands the study requirements and expectations, 
                thereby enforcing study compliance. This module guides the subject through the consent process to obtain all the 
                right information regarding the risks and benefits of the trials, helping them make more informed decisions about 
                enrolling in a study.
                <br></br><br></br>
                By allowing subjects to sign the informed consent either in person at the clinic or remotely over a tele-visit session, 
                the module provides flexibility and convenience while ensuring that the consent process is transparent and fully 
                documented, as required by the country's regulations. Subjects can sign the informed consent form in-person at 
                the clinic or remotely during a tele-visit session. The CliniOps platform also allows recording the session if required by 
                the country’s regulations.
              </CustomTypography>
              </>
              }
            />
            
        </Grid>
    </Grid>
    </>
  );
}

export default template;