import React from 'react';
import { Box, Typography } from '@mui/material';
import ImgPolicyHero from "../../../../img/privacy-policy-hero.png";

const Template = () => {
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: 'auto',
        marginTop: {xs:'70px',md:'85px'}
      }}
    >
      {/* Image */}
      <img
        src={ImgPolicyHero}
        alt="Partners"
        style={{
          width: '100%',
          height: 'auto',
          display: 'block',
          objectFit: 'cover',
        }}
      />

      {/* Text Overlay */}
      <Box
        sx={{
          position: 'absolute',
          top: { xs: '5px', sm: '15px', md: '20px' },
          left: { xs: '25px', sm: '15px', md: '30px' },
          right: 0,
          bottom: 0,
          display: 'flex',
          color: '#FFF',
          textAlign: 'topleft',
          fontSize: { xs: '15px', sm: '40px', md: '60px' }, // Responsive font size
          fontWeight: 300,
          textShadow: '3px 3px 3px rgba(0, 0, 0, 0.16)'
        }}
      >
        Privacy Policy
      </Box>
    </Box>
  );
};

export default Template;
