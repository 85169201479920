import React from "react";
import { Grid, Box, Typography,Stack,Alert, Link } from "@mui/material";
import { teal, blueGrey } from "@mui/material/colors";

import { PageHero, PageContentV2 } from "../../component/page";
import ImgGary from "../../../img/media/customer-story/_1/Gary_Weil.jpg";
import ImgPlosNTD from '../../../img/media/customer-story/_1/PLOS-NTD_Logo.png';
import ImgWashU from '../../../img/media/customer-story/_1/WashU-logo.png';
import ImgUHAS from '../../../img/media/customer-story/_1/UHAS-logo.png';

// Page Imports
import _0 from "./customer-story_10/_0";

const template = () => {
  return (
    <>
      <PageHero color="rgba(3, 169, 244, 0.1)">
        <_0 />
      </PageHero>
      <PageContentV2>
        <Grid item>
          <Typography variant="subtitle2" paragraph>
          Onchocerciasis Study in Ghana: Deploying Mobile Technologies for High Quality Data Capture in Remote Sites
          </Typography>
          <Typography variant="body7" paragraph>
          How does a study team assure high quality data capture in remote sites? Particularly for public health studies on infectious diseases like onchocerciases endemic in Sub Saharan Africa, there is a need to deploy mobile and smart technologies for high quality data capture, with complete offline functionality.
          </Typography>
          <Typography variant="body7" paragraph>
          In the Volta region of Ghana, 154 participants with microfiladermia and palpable subcutaneous nodules indicative of onchocerciasis were involved in the  interventional study conducted by the School of Public Health, University of Health and Allied Sciences, Hohoe, Ghana in collaboration with Washington University School of Medicine.
          </Typography>
          <Typography variant="body7" paragraph>
          Caused by parasitic round worms transmitted by black flies, Onchocerciasis or “River Blindness” is a serious public health concern affecting about 25 million people in Sub Saharan Africa.<sup>1,2</sup> Those affected have severe skin and ocular diseases that need medical intervention particularly in permanently eradicating the adult parasites.
          </Typography>
          <Typography variant="body7" paragraph>
          Randomized and open-label, the study aimed to test the tolerability and efficacy of one or three daily doses of ivermectin plus diethylcarbamazine and albendazole (IDA) versus one dose of ivermectin plus albendazole (IA) for treatment of onchocerciasis. The study started in December 2019 and completed in June 2022.
          </Typography>
          <Typography variant="body7" paragraph>
          As the technology partner for the study, CliniOps provided the offline eSource data acquisition platform using digital tablets, and electronic data capture (EDC) application for data management activities. The platform is 21 CFR Part 11 compliant, and validated. Electronic case report forms (eCRFs) were developed to comply with International Council for Harmonization on Good Clinical Practice (ICH GCP) and CDASH/CDISC standards.
          </Typography>
          <Typography variant="body7" paragraph>
          Site personnel checked and evaluated each participant 13 times throughout the study. This means a total of 2,002 times of data entry for all 154 participants aside from registration and informed consent. Participants’ data were collected and entered in digital tablet by site personnel as per the schedule for evaluation: daily checks for 7 days after treatment; ophthalmological examinations on day of treatment, on days 3 and 7 of treatment and 3 months after treatment. There was also a skin snip test performed on each participant before treatment, as well as on the 12th and 18th month after treatment.
          </Typography>
          <Typography variant="body7" paragraph>
          At point of data entry in designated sites, high level of quality data was assured with the eSource-EDC programmed with validation checks and automated alert checks. Every day, encrypted data were uploaded to a secured cloud server via the internet. To protect against data loss, the server deployed multi-available zone and geo-redundant backups. Irrespective of  the location of the site, the data is reliably secured upon entry.
          </Typography>
          <blockquote
            style={{ borderLeft: `4px solid ${blueGrey[100]}`, marginLeft: -1 }}
          >
            <Stack spacing={4} sx={{ pl: 4 }} direction={{xs: "column", md: "row"}}>
            <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: '220px',
              width: '220px',
              height: '270px',
              borderRadius: 2,
              backgroundImage: `url(${ImgGary})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'top center'
            }}
          />
            <Box >
              <Typography variant="body7" paragraph>
                <em>
                 "This clinical trial showed that a novel combination treatment was somewhat more effective against adult worms than the current treatment that is used in the global onchoceriasis elimination program. The study was a collaboration between the  University of Health and Allied Sciences, Hohoe, Ghana and Washington University in St. Louis We are grateful to CliniOps who provided technologies for secure, reliable and high quality capture of data particularly with the challenge of working in remote sites"
                </em>
              </Typography>
              <Typography variant="overline" paragraph>
                <strong>
                Gary J. Weil, MD
                <br />
                Professor, Washington University School of Medicine
                </strong>
              </Typography>
            </Box>
            </Stack>
          </blockquote>

          <Typography variant="body7" paragraph>
          References:    
          </Typography>

          <Typography variant="body7" paragraph>
          1. World Health Organization, Key Facts January 11, 2022:<Link href="https://www.who.int/news-room/fact-sheets/detail/onchocerciasis" target="_blank" underline="hover"> https://www.who.int/news-room/fact-sheets/detail/onchocerciasis</Link>
          </Typography>

          <Typography variant="body7" paragraph>
          2. Jongo SA, Church LWP, Nchama VUNN, et al. Multi-Dose Priming Regimens of PfSPZ Vaccine: Safety and Efficacy against Controlled Human Malaria Infection in Equatoguinean Adults. The American Journal of Tropical Medicine and Hygiene. 2022;106(4):1215-1226.
          </Typography>
            
          <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
             <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }}>
          <Box
            xs="auto"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: '175px',
              width: '175px',
              height: '120px',
              borderRadius: 2,
              backgroundImage: `url(${ImgPlosNTD})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'top center'
            }}
          />
            <Box >
              <Typography paragraph>
              A publication titled, {" "}
                <Link href="https://journals.plos.org/plosntds/article?id=10.1371/journal.pntd.0011365" target='_blank' underline="hover">
               
                "A randomized, open-label study of the tolerability and efficacy of one or three daily doses of ivermectin plus diethylcarbamazine and albendazole (IDA) versus one dose of ivermectin plus albendazole (IA) for treatment of onchocerciasis"
              
                </Link>
                , was published in the PLOS Neglected Tropical Diseases Journal, on May 19, 2023.
              </Typography>
            </Box>
            </Stack>
            </Alert>
          
            <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
             <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
             <Stack display={"block"}>
             <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '175px',
                width: '175px',
                height: '100px',
                borderRadius: 2,
                backgroundImage: `url(${ImgUHAS})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
          />
            <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '175px',
                width: '175px',
                height: '80px',
                borderRadius: 2,
                backgroundImage: `url(${ImgWashU})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
          /></Stack>
             <Box >
              <Typography paragraph>
              The study was conducted by the{" "}
                <Link  href="https://sph.uhas.edu.gh/en/" target="_blank" underline="hover">
                
                School of Public Health, University of Health and Allied Sciences               
                </Link>
                , Hohoe, Ghana in collaboration with {" "}
                    <Link href="https://medicine.wustl.edu/" target="_blank" underline="hover">
                        Washington University School of Medicine
                    </Link>
                    , St. Louis, USA.
              </Typography>
            </Box>
            </Stack>
            </Alert>
        </Grid>
      </PageContentV2>
    </>
  );
};

export default template;
