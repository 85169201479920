import React from 'react';
import { Grid, Divider } from '@mui/material';
// import { teal } from '@mui/material/colors';

import { PageHero, PageContent } from '../../component/page';

// Page Imports
import _0 from './conduct/_0';
import _1 from './conduct/_1';
import _2 from './conduct/_2';
import _3 from './conduct/_3';
import _4 from './conduct/_4';
import _5 from './conduct/_5';
import _6 from './conduct/_6';
import _7 from './conduct/_7';
import _8 from './conduct/_8';
import _9 from './conduct/_9';
import _10 from './conduct/_10';
import _11 from './conduct/_11';

const template = () => {
  return (
    <>
      {/* <PageHero color={teal}> */}
        <_0 />
      {/* </PageHero> */}
      <Grid item>
        <_9 />
      </Grid>
      <PageContent>
        <Grid item>
          <_1 />
        </Grid>
        {/* <Grid item>
          <Divider className="co-divider" />
        </Grid> */}
        <Grid item sx={{ pt: '20px !important' }}>
          <_2 />
        </Grid>
        {/* <Grid item>
          <Divider className="co-divider" />
        </Grid> */}
        <Grid item sx={{ pt: '20px !important' }}>
          <_3 />
        </Grid>
        {/* <Grid item>
          <Divider className="co-divider" />
        </Grid> */}
        <Grid item sx={{ pt: '20px !important' }}>
          <_4 />
        </Grid>
        {/* <Grid item>
          <Divider className="co-divider" />
        </Grid> */}
        <Grid item sx={{ pt: '20px !important' }}>
          <_5 />
        </Grid>
        {/* <Grid item>
          <Divider className="co-divider" />
        </Grid> */}
        <Grid item sx={{ pt: '20px !important' }}>
          <_6 />
        </Grid>
        {/* <Grid item>
          <Divider className="co-divider" />
        </Grid> */}
        <Grid item sx={{ pt: '20px !important' }}>
          <_7 />
        </Grid>
        {/* <Grid item>
          <Divider className="co-divider" />
        </Grid> */}
        <Grid item sx={{ pt: '20px !important' }}>
          <_8 />
        </Grid>
        <Grid item sx={{ pt: '20px !important' }}>
          <_10 />
        </Grid>
      </PageContent>
        <Grid item>
          <_11 />
        </Grid>
    </>
  );
}

export default template;