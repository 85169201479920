import React, { useMemo } from 'react';
import { Grid,Typography, useMediaQuery, useTheme} from '@mui/material'; 
import NewsPost from '../../../component/news-post';
import CustomCarousel from "../../home/CustomCarousel";
import news2024Data from './_2024';
import news2023Data from './_2023';
import news2022Data from './_2022';
import news2021Data from './_2021';
import news2020Data from './_2020';
import news2019Data from './_2019';
import news2018Data from './_2018';
import news2017Data from './_2017';
import news2016Data from './_2016';
import news2015Data from './_2015';
import news2014Data from './_2014';
import news2025Data from './_2025';

const allData = [
  ...news2025Data,
  ...news2024Data,
  ...news2023Data,
  ...news2022Data,
  ...news2021Data,
  ...news2020Data,
  ...news2019Data,
  ...news2018Data,
  ...news2017Data,
  ...news2016Data,
  ...news2015Data,
  ...news2014Data
]

allData.sort((a, b) => new Date(b.Date) - new Date(a.Date));

const template = () => {
      const theme = useTheme();
      const isXS = useMediaQuery(theme.breakpoints.down("sm"));
    
      const [expanded, setExpanded] = React.useState(false);
    
      const handleExpandClick = () => {
        setExpanded(!expanded);
      };

      // 2) Gather unique years in descending order
  const uniqueYears = useMemo(() => {
    const yearSet = new Set();
    allData.forEach((item) => {
      const year = new Date(item.Date).getFullYear();
      yearSet.add(year);
    });
    return Array.from(yearSet).sort((a, b) => b - a);
  }, []);

  // 2) Gather unique tags (e.g. Press, Publication, Event, etc.)
  const uniqueTags = useMemo(() => {
    const tags = new Set();
    allData.forEach((item) => {
      // item.tags might be undefined, so guard it:
      if (item.tags && Array.isArray(item.tags)) {
        item.tags.forEach((tag) => tags.add(tag));
      }
    });
    return Array.from(tags).sort(); // alphabetical or any order you prefer
  }, []);
    
    return (

      <CustomCarousel
      slides={allData}
      // Pass extraFeatures only if the current page needs extra controls
      extraFeatures={{
        manualChunking:true,
        ChildOverflow:true,
        renderBottomDots:true,
        dynamicRows: false, // opt into dynamic override
        pagingNumerical: true,
        defaultItemsPerPage: 10,
        itemsPerPageOptions: [10, 20, 50],
        useFixedTopDots: true,
        pagingFilter: {
          options:[
            { value: "all", label: "All News" },
            // { value: "category", label: "Category" },
          ],
          default: "all",
          availableYears: uniqueYears, // e.g., [2024, 2023, ...]
          availableTags: uniqueTags,
          tagAllOption: "All Category",
        },

      }}
      renderItem={(slide, index) => (
        <Grid container direction='column' spacing={6} sx={{ mt: 1 }}>
   
          <Grid item >
          <NewsPost
            ImgSrc={slide.ImgSrc}
            Name={slide.Name}
            Date={slide.Date}
            Summary={slide.Summary}
            FullContent={slide.FullContent}
            redirectUrl={slide.redirectUrl}
          />
          </Grid>
          
        </Grid>
      )}
      settings={{
        // Add or override slider settings if needed
        // autoplaySpeed: 7000,
        autoplay:false,
        dots:true,
        slidesPerRow:1,
        arrows:false,
        dotsPosition: {
          top: isXS ? "10px" : "-60px",
        },
      }}
      customStyles={{
        wrapper: {mt: 2},
        slide: { p: 2, mb:1}
      }}
    />
      );
};
export default template;