import React from 'react';
import { Grid, Typography, Stack } from '@mui/material';

import { Product } from '../../../component/page';

import ImgeConConf from '../../../../img/service/study-setup/eConsentConfig.png';
import CustomTypography from '../../../component/customTypography';

const template = () => {
  return (
    <>
      <Grid container direction='column' spacing={0} sx={{ mt: 0 }}>
            {/* eConsent Configuration  */}
          <Grid item>
              <Product
                ImgSrc={ImgeConConf}
                Name="eConsent Configuration"
                Summary={
                  <>
                  <CustomTypography variant='body2' sx={{ mt: 1 }}>
                  CliniOps offers eConsent configuration services to streamline the informed consent process in clinical trials. Our team customizes electronic consent forms to ensure compliance with regulatory requirements and study-specific protocols. Our services also support LAR (legally authorized representatives), Witness, Ascent signatures workflows as per study requirements, including multi lingual configuration. By leveraging intuitive, user-friendly interfaces, CliniOps enhances participant understanding and engagement while reducing administrative burdens. With a focus on improving participant experience and trial efficiency, CliniOps delivers scalable, compliant eConsent configurations for global studies.
                 </CustomTypography>
                </>
                }
              />
              
          </Grid>
      </Grid>
    </>
  );
}

export default template;

//Commenting the previous code

// import React from 'react';
// import { Grid, Typography, Stack } from '@mui/material';

// import { PageSectionLeftImg } from '../../../component/page';

// import ImgUat from '../../../../img/service/study-setup/uat.jpeg';

// const template = () => {
//   return (
//     <>
//       <PageSectionLeftImg
//         heading={
//           <Stack direction="row" spacing={1} alignItems='center' justifyContent={{xs: 'center', md: 'flex-start'}}>
//             <Typography variant="h2" component="h2" className="co-page-heading" sx={{ textAlign: { xs: 'center', md: 'left'} }}>
//               Validation &amp; UAT
//             </Typography>
//           </Stack>
//         }
//         imgUrl={ImgUat}
//         imgTitle="Validation &amp; UAT"
//       >
//         <Typography sx={{ mt: 1 }}>
//           We perform self-testing of the configuration and setup before releasing it for UAT.
//         </Typography>
//         <Typography sx={{ mt: 1 }}>
//           Our designers address UAT findings to finally build a data entry system that will be accurate, complete, and user friendly.
//         </Typography>
//         <Typography sx={{ mt: 1 }}>
//           We provide complete validation documentation (IQ, OQ &amp; PQ) as per regulatory standards.
//         </Typography>
//       </PageSectionLeftImg>
//     </>
//   );
// }

// export default template;