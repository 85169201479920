import React from 'react';
import { Box, Typography } from '@mui/material';

const template = () => {
    return (
      <>
        <Box 
        sx={{ 
            display:'flex', 
            textAlign: {xs: "center", md: "left"}, 
            alignItems: "center", 
            justifyContent: 'center', 
            backgroundColor: "#F2F2F2",
            padding:"20px"
            }}>
          <Typography variant="h7" sx={{lineHeight:"40px",marginTop:3, mx:{xs:2, md:25}}}>
          CliniOps offers comprehensive services as a Technology and Data Partner to augment 
          your in-house team, and/or the services provided by your CRO partner. Five key factors 
          distinguish our services footprint during the study startup, study conduct and study closeout phases.
          </Typography>

        </Box>
      </>
    );
  }
  
  export default template;