import React from "react";
import { HashLink as RouterLink } from "react-router-hash-link";

import {
  Box,
  Container,
  Grid,
  Divider,
  Link,
  Button,
  Popper,
  Stack,
  Collapse,
  useTheme,
  useMediaQuery,
  Fade,
  Paper,
} from "@mui/material";
import { ClickAwayListener } from "@mui/base";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { blueGrey, common } from "@mui/material/colors";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

import ImgCliniOps from "../../../img/CliniOpswithoutTM.png";

import RouterList from "../../router";

const template = () => {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(
    theme.breakpoints.between("900", "1470")
  );
  const hideButtons = useMediaQuery(theme.breakpoints.between("900", "1265"));
  // const renderMobMenu = useMediaQuery(theme.breakpoints.up("950"));

  // ------------------------------
  // PRODUCTS MENU
  // ------------------------------
  const [anchorProduct, setAnchorProduct] = React.useState(null);
  const productPopperTimer = React.useRef(null);

  const handleOpenProduct = (event) => {
    // Clear any pending timer so it won't close if user re-enters quickly
    clearTimeout(productPopperTimer.current);
    if (anchorProduct !== event.currentTarget) {
      handleCloseSubMenu(); // close other menus
      setAnchorProduct(event.currentTarget);
      document.querySelector("#co-hdr-menu-product").classList.add("hover");
    }
  };

  // Link mouse leave → schedule close
  const handleLinkMouseLeaveProduct = () => {
    productPopperTimer.current = setTimeout(() => {
      handleCloseProduct();
    }, 200); // delay in ms; adjust as needed
  };

  // Popper mouse enter → cancel scheduled close
  const handlePopperMouseEnterProduct = () => {
    clearTimeout(productPopperTimer.current);
  };

  // Popper mouse leave → close immediately
  const handlePopperMouseLeaveProduct = () => {
    handleCloseProduct();
  };

  const handleCloseProduct = () => {
    document.querySelector("#co-hdr-menu-product").classList.remove("hover");
    setAnchorProduct(null);
    handleCloseMobile();
  };

  // ------------------------------
  // SERVICES MENU
  // ------------------------------
  const [anchorService, setAnchorService] = React.useState(null);
  const servicePopperTimer = React.useRef(null);

  const handleOpenService = (event) => {
    clearTimeout(servicePopperTimer.current);
    if (anchorService !== event.currentTarget) {
      handleCloseSubMenu();
      setAnchorService(event.currentTarget);
      document.querySelector("#co-hdr-menu-service").classList.add("hover");
    }
  };

  // Link mouse leave → schedule close
  const handleLinkMouseLeaveService = () => {
    servicePopperTimer.current = setTimeout(() => {
      handleCloseService();
    }, 200);
  };

  // Popper mouse enter → cancel scheduled close
  const handlePopperMouseEnterService = () => {
    clearTimeout(servicePopperTimer.current);
  };

  // Popper mouse leave → close immediately
  const handlePopperMouseLeaveService = () => {
    handleCloseService();
  };

  const handleCloseService = () => {
    document.querySelector("#co-hdr-menu-service").classList.remove("hover");
    setAnchorService(null);
    handleCloseMobile();
  };

  // ------------------------------
  // ABOUT MENU
  // ------------------------------
  const [anchorAbout, setAnchorAbout] = React.useState(null);
  const aboutPopperTimer = React.useRef(null);

  const handleOpenAbout = (event) => {
    clearTimeout(aboutPopperTimer.current);
    if (anchorAbout !== event.currentTarget) {
      handleCloseSubMenu();
      setAnchorAbout(event.currentTarget);
      document.querySelector("#co-hdr-menu-about").classList.add("hover");
    }
  };

  const handleLinkMouseLeaveAbout = () => {
    aboutPopperTimer.current = setTimeout(() => {
      handleCloseAbout();
    }, 200);
  };

  const handlePopperMouseEnterAbout = () => {
    clearTimeout(aboutPopperTimer.current);
  };

  const handlePopperMouseLeaveAbout = () => {
    handleCloseAbout();
  };

  const handleCloseAbout = () => {
    document.querySelector("#co-hdr-menu-about").classList.remove("hover");
    setAnchorAbout(null);
    handleCloseMobile();
  };

  // ------------------------------
  // MEDIA MENU
  // ------------------------------
  const [anchorMedia, setAnchorMedia] = React.useState(null);
  const mediaPopperTimer = React.useRef(null);

  const handleOpenMedia = (event) => {
    clearTimeout(mediaPopperTimer.current);
    if (anchorMedia !== event.currentTarget) {
      handleCloseSubMenu();
      setAnchorMedia(event.currentTarget);
      document.querySelector("#co-hdr-menu-media").classList.add("hover");
    }
  };

  const handleLinkMouseLeaveMedia = () => {
    mediaPopperTimer.current = setTimeout(() => {
      handleCloseMedia();
    }, 200);
  };

  const handlePopperMouseEnterMedia = () => {
    clearTimeout(mediaPopperTimer.current);
  };

  const handlePopperMouseLeaveMedia = () => {
    handleCloseMedia();
  };

  const handleCloseMedia = () => {
    document.querySelector("#co-hdr-menu-media").classList.remove("hover");
    setAnchorMedia(null);
    handleCloseMobile();
  };

  const mobileIconRef = React.useState(null);
  const [anchorMobile, setAnchorMobile] = React.useState(null);
  const [open,setOpen] = React.useState(false);
  const handleOpenMobile = (event) => {
    console.log('opening popper')
    setAnchorMobile(event.currentTarget);
    setOpen(true);

    //Remove any exsiting scroll event to prevent duplicate binding
    window.removeEventListener('scroll', handleCloseMobile)

    //Add scroll event listener to close popper when scrolling
    window.addEventListener('scroll', handleCloseMobile, {passive:true});

  };
  const handleCloseMobile = () => {
    console.log("closing popper from handleCloseMobile");
    setAnchorMobile(null);
    setOpen(false);

    //Remove scroll event listener when closing the popper
    window.removeEventListener('scroll', handleCloseMobile)
  };

  const handleCloseSubMenu = () => {
    handleCloseProduct();
    handleCloseService();
    handleCloseAbout();
    handleCloseMedia();
    handleCloseMobile();
    
  };

  const [expandedProduct, setProductExpanded] = React.useState(false);
  const [expandedService, setServiceExpanded] = React.useState(false);
  const [expandedAbout, setAboutExpanded] = React.useState(false);
  const [expandedMedia, setMediaExpanded] = React.useState(false);

  const handleExpandProduct = () => {
    setProductExpanded(prev => !prev);
    setAboutExpanded(false);
    setMediaExpanded(false);
    setServiceExpanded(false);
    // event.target.innerText = expandedAbout ? 'Show More' : 'Show Less';
  };


  const handleExpandService = () => {
    setServiceExpanded(prev => !prev);
    setAboutExpanded(false);
    setMediaExpanded(false);
    setProductExpanded(false);
    // event.target.innerText = expandedAbout ? 'Show More' : 'Show Less';
  };


  const handleExpandAbout = () => {
    setAboutExpanded(prev => !prev);
    setMediaExpanded(false);
    setProductExpanded(false);
    setServiceExpanded(false);
    // event.target.innerText = expandedAbout ? 'Show More' : 'Show Less';
  };


  const handleExpandMedia = () => {
    setMediaExpanded(prev => !prev);
    setAboutExpanded(false);
    setProductExpanded(false);
    setServiceExpanded(false);
    // event.target.innerText = expandedAbout ? 'Show More' : 'Show Less';
  };

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          width: "100%",
          top: 0,
          left: 0,
          backgroundColor: common.white,
          zIndex: 100,
          transform: "translateZ(0)",
        }}
      >
        {/* <Container> */}
        <Grid
          container
          spacing={0}
          alignItems="center"
          justifyContent="space-between"
          direction="row"
          sx={{
            padding: isMediumScreen
              ? "0 15px"
              : { xs: "15px 8px", md: "0 24px" },
          }}
        >
          <Grid item sx={{ ml: { xs: 1, md: 1 } }}>
            <Link
              variant="link"
              to={RouterList.home._0}
              component={RouterLink}
              onClick={handleCloseSubMenu}
              onMouseOver={handleCloseSubMenu}
            >
              <Box
                sx={{
                  width: {xs:"150px",md:"238px"},
                  height: {xs:'30px',md:"58px"},
                  backgroundImage: `url(${ImgCliniOps})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPositionY:'center'
                  // border:"1px solid red"
                }}
              />
            </Link>
          </Grid>
          {/* <Grid item xs={true} sx={{border:"2px solid teal",}} /> */}
          <Grid
            item
            sx={{
              mr: 0,
              ml: isMediumScreen ? 0 : 10,
              display: { xs: "none", md: "block" },
              flexWrap: "wrap",
            }}
          >
            <Link
              color={blueGrey[800]}
              className="co-hdr-menu__root"
              underline="none"
              variant="h6"
              onClick={handleCloseSubMenu}
              onMouseOver={handleOpenProduct}
              onMouseLeave={handleLinkMouseLeaveProduct}  // use the new function
              id="co-hdr-menu-product"
            >
              Products
              <KeyboardArrowDownOutlinedIcon
                fontSize="small"
                sx={{ ml: 0.5, mr: -1 }}
              />
            </Link>
            <Popper
              placement="bottom-start"
              anchorEl={anchorProduct}
              open={Boolean(anchorProduct)}
              onMouseEnter={handlePopperMouseEnterProduct}  // cancel timer if hovered
              onMouseLeave={handlePopperMouseLeaveProduct}  // close popper when leavin
              sx={{ zIndex: 10 }}
            >
              <Stack
                sx={{
                  backgroundColor: "#",
                  boxShadow: `0 0 16px 0 ${blueGrey[800]}`,
                  borderRadius: "0 0 4px 4px",
                }}
              >
                <Link
                  color="red"
                  underline="none"
                  className="co-hdr-menu__child"
                  to={RouterList.product._0}
                  component={RouterLink}
                  onClick={handleCloseProduct}
                >
                  Products Overview
                </Link>
                <Link
                  color="red"
                  underline="none"
                  className="co-hdr-menu__child"
                  to={RouterList.product.connect}
                  component={RouterLink}
                  onClick={handleCloseProduct}
                >
                  Connect
                </Link>
                <Link
                  color="#FFFFFF"
                  underline="none"
                  className="co-hdr-menu__child"
                  to={RouterList.product.edge}
                  component={RouterLink}
                  onClick={handleCloseProduct}
                >
                  Edge
                </Link>
                <Link
                  color="#FFFFFF"
                  underline="none"
                  className="co-hdr-menu__child"
                  to={RouterList.product.conduct}
                  component={RouterLink}
                  onClick={handleCloseProduct}
                >
                  Conduct
                </Link>
                {/* <Link
                  color="#FFFFFF"
                  underline="none"
                  className="co-hdr-menu__child"
                  to={RouterList.product.insights}
                  component={RouterLink}
                  onClick={handleCloseProduct}
                >
                  Insights
                </Link> */}
              </Stack>
            </Popper>
          </Grid>
          <Grid item sx={{ mr: 0, display: { xs: "none", md: "block" } }}>
            <Link
              color={blueGrey[800]}
              className="co-hdr-menu__root"
              underline="none"
              variant="h6"
              onClick={handleCloseSubMenu}
              onMouseOver={handleOpenService}
              onMouseLeave={handleLinkMouseLeaveService} // schedule close
              id="co-hdr-menu-service"
            >
              Services
              <KeyboardArrowDownOutlinedIcon
                fontSize="small"
                sx={{ ml: 0.5, mr: -1 }}
              />
            </Link>
            <Popper
              placement="bottom-start"
              anchorEl={anchorService}
              open={Boolean(anchorService)}
              onMouseEnter={handlePopperMouseEnterService}
              onMouseLeave={handlePopperMouseLeaveService}
              sx={{ zIndex: 10 }}
            >
              <Stack
                sx={{
                  backgroundColor: common.white,
                  boxShadow: `0 0 16px 0 ${blueGrey[800]}`,
                  borderRadius: "0 0 4px 4px",
                }}
              >
                <Link
                  color={blueGrey[800]}
                  underline="none"
                  className="co-hdr-menu__child"
                  to={RouterList.service._0}
                  component={RouterLink}
                  onClick={handleCloseService}
                >
                  Services Overview
                </Link>
                <Link
                  color={blueGrey[800]}
                  underline="none"
                  className="co-hdr-menu__child"
                  to={RouterList.service.studysetup}
                  component={RouterLink}
                  onClick={handleCloseService}
                >
                  Study Setup
                </Link>
                <Link
                  color={blueGrey[800]}
                  underline="none"
                  className="co-hdr-menu__child"
                  to={RouterList.service.clinicaldatamanagement}
                  component={RouterLink}
                  onClick={handleCloseService}
                >
                  Clinical Data Services
                </Link>
                {/* <Link color={blueGrey[800]} underline="none" className="co-hdr-menu__child" to={RouterList.service.biostatisticprogramming} component={RouterLink} onClick={handleCloseService}>Biostatistics &amp; Statistical Programming</Link> */}
                <Link
                  color={blueGrey[800]}
                  underline="none"
                  className="co-hdr-menu__child"
                  to={RouterList.service.supporthelpdesk}
                  component={RouterLink}
                  onClick={handleCloseService}
                >
                  Support &amp; Helpdesk
                </Link>
              </Stack>
            </Popper>
          </Grid>
          <Grid item sx={{ mr: 0, display: { xs: "none", md: "block" } }}>
            <Link
              color={blueGrey[800]}
              className="co-hdr-menu__root"
              underline="none"
              variant="h6"
              to={RouterList.partnerships.partnerships}
              component={RouterLink}
              onClick={handleCloseSubMenu}
              onMouseOver={handleCloseSubMenu}
            >
              Partnerships
            </Link>
          </Grid>
          <Grid item sx={{ mr: 0, display: { xs: "none", md: "block" } }}>
            <Link
              color={blueGrey[800]}
              className="co-hdr-menu__root"
              underline="none"
              variant="h6"
              onClick={handleOpenAbout}
              onMouseOver={handleOpenAbout}
              onMouseLeave={handleLinkMouseLeaveAbout}
              id="co-hdr-menu-about"
            >
              About
              <KeyboardArrowDownOutlinedIcon
                fontSize="small"
                sx={{ ml: 0.5, mr: -1 }}
              />
            </Link>
            <Popper
              placement="bottom-start"
              anchorEl={anchorAbout}
              open={Boolean(anchorAbout)}
              onClose={handleCloseAbout}
              onMouseEnter={handlePopperMouseEnterAbout}
              onMouseLeave={handlePopperMouseLeaveAbout}
              sx={{ zIndex: 10 }}
            >
              <Stack
                sx={{
                  backgroundColor: common.white,
                  boxShadow: `0 0 16px 0 ${blueGrey[800]}`,
                  borderRadius: "0 0 4px 4px",
                }}
              >
                <Link
                  color={blueGrey[800]}
                  underline="none"
                  className="co-hdr-menu__child"
                  to={RouterList.about.mission}
                  component={RouterLink}
                  onClick={handleCloseAbout}
                >
                  Mission
                </Link>
                <Link
                  color={blueGrey[800]}
                  underline="none"
                  className="co-hdr-menu__child"
                  to={RouterList.about.leadershipteam}
                  component={RouterLink}
                  onClick={handleCloseAbout}
                >
                  Leadership Team
                </Link>
                <Link
                  color={blueGrey[800]}
                  underline="none"
                  className="co-hdr-menu__child"
                  to={RouterList.about.career}
                  component={RouterLink}
                  onClick={handleCloseAbout}
                >
                  Careers
                </Link>
              </Stack>
            </Popper>
          </Grid>
          <Grid item sx={{ mr: 0, display: { xs: "none", md: "block" } }}>
            <Link
              color={blueGrey[800]}
              className="co-hdr-menu__root"
              underline="none"
              variant="h6"
              href="#"
              onClick={handleOpenMedia}
              onMouseOver={handleOpenMedia}
              onMouseLeave={handleLinkMouseLeaveMedia}
              id="co-hdr-menu-media"
            >
              Media
              <KeyboardArrowDownOutlinedIcon
                fontSize="small"
                sx={{ ml: 0.5, mr: -1 }}
              />
            </Link>
            <Popper
              placement="bottom-start"
              anchorEl={anchorMedia}
              open={Boolean(anchorMedia)}
              onClose={handleCloseMedia}
              onMouseEnter={handlePopperMouseEnterMedia}
              onMouseLeave={handlePopperMouseLeaveMedia}
              sx={{ zIndex: 10 }}
            >
              <Stack
                sx={{
                  backgroundColor: common.white,
                  boxShadow: `0 0 16px 0 ${blueGrey[800]}`,
                  borderRadius: "0 0 4px 4px",
                }}
              >
                <Link
                  color={blueGrey[800]}
                  underline="none"
                  className="co-hdr-menu__child"
                  to={RouterList.media.newsroom}
                  component={RouterLink}
                  onClick={handleCloseMedia}
                >
                  Newsroom
                </Link>
                <Link
                  color={blueGrey[800]}
                  underline="none"
                  className="co-hdr-menu__child"
                  to={RouterList.media.customerstory}
                  component={RouterLink}
                  onClick={handleCloseMedia}
                >
                  Customer Stories
                </Link>
                <Link
                  color={blueGrey[800]}
                  underline="none"
                  className="co-hdr-menu__child"
                  to={RouterList.media.pressrelease}
                  component={RouterLink}
                  onClick={handleCloseMedia}
                >
                  Press Release
                </Link>
                {/* <Link
                  color={blueGrey[800]}
                  underline="none"
                  className="co-hdr-menu__child"
                  to={RouterList.media.blog}
                  component={RouterLink}
                  onClick={handleCloseMedia}
                >
                  Blogs
                </Link> */}
              </Stack>
            </Popper>
          </Grid>
          <Grid
            item
            sx={{
              mr: isMediumScreen ? 1 : 10,
              display: { xs: "none", md: "block" },
            }}
          >
            <Link
              color={blueGrey[800]}
              className="co-hdr-menu__root"
              underline="none"
              variant="h6"
              to={RouterList.contact._0}
              component={RouterLink}
              onClick={handleCloseSubMenu}
              onMouseOver={handleCloseSubMenu}
            >
              Contact
            </Link>
          </Grid>
          {/* {hideButtons && ( */}
            <>
              <Grid item sx={{ mr: 1, display: { xs: "none", md: hideButtons ? 'none' : 'block' } }}>
                <Button
                  variant="contained"
                  sx={{
                    background: "#042D43",
                    textTransform: "none",
                    borderRadius: "12px",
                    "&:hover": {
                      background: "#042D43",
                      boxShadow: "none",
                    },
                  }}
                  to={RouterList.login._0}
                  component={RouterLink}
                  onClick={handleCloseSubMenu}
                  onMouseOver={handleCloseSubMenu}
                  startIcon={<AccountCircleOutlinedIcon />}
                >
                  Login
                </Button>
              </Grid>
              <Grid item sx={{ mr: 2, display: { xs: "none", md: hideButtons ? 'none' : 'block' } }}>
                <Button
                  variant="contained"
                  sx={{
                    background: "#03a9f4",
                    textTransform: "none",
                    borderRadius: "12px",
                    "&:hover": {
                      background: "#",
                      boxShadow: "none",
                    },
                  }}
                  to={RouterList.contact.demo}
                  component={RouterLink}
                  onClick={handleCloseSubMenu}
                  onMouseOver={handleCloseSubMenu}
                >
                  Request Demo
                </Button>
              </Grid>
            </>
          {/* )} */}

          {/* mobile menu items */}
          <Grid
            item
            sx={{
              display: hideButtons ? "block" : {xs:"block", md: "none" },
            }}
          >
            <MenuOutlinedIcon
              // ref={mobileIconRef}
              fontSize="large"
              onClick={handleOpenMobile}
              sx={{ cursor: "pointer" }}
              // onMouseOver={handleOpenMobile}
              id="co-hdr-menu-mobile"
            />
           <Popper open={open} anchorEl={anchorMobile} placement='bottom-start' transition sx={{zIndex:1500}}>
           {({TransitionProps}) => (
           <ClickAwayListener onClickAway={handleCloseMobile}>   
                <Fade {...TransitionProps}>
                  <Paper>
                    <Stack
                      sx={{
                        pt: 2,
                        backgroundColor: common.white,
                        boxShadow: `0 0 16px 0 ${blueGrey[800]}`,
                        borderRadius: "0 0 4px 4px",
                        zIndex: 10,
                      }}
                    >
                      {hideButtons ? (
                        <>
                          <Box sx={{ p: 1, zIndex: 10 }}>
                            <Button
                              variant="contained"
                              sx={{
                                background: "#042D43",
                                textTransform: "none",
                                borderRadius: "12px",
                                "&:hover": {
                                  background: "#042D43",
                                  boxShadow: "none",
                                },
                              }}
                              to={RouterList.login._0}
                              component={RouterLink}
                              onClick={handleCloseSubMenu}
                              startIcon={<AccountCircleOutlinedIcon />}
                            >
                              Login
                            </Button>
                          </Box>
                          <Box sx={{ p: 1 }}>
                            <Button
                              variant="contained"
                              size="small"
                              sx={{
                                background: "#03a9f4",
                                textTransform: "none",
                                borderRadius: "12px",
                                "&:hover": {
                                  background: "#",
                                  boxShadow: "none",
                                },
                              }}
                              to={RouterList.contact.demo}
                              component={RouterLink}
                              onClick={handleCloseSubMenu}
                            >
                              Request Demo
                            </Button>
                          </Box>
                        </>
                      ) : (
                        <>
                          <Link
                            color={blueGrey[800]}
                            className="co-hdr-menu__root--mobile"
                            underline="none"
                            variant="h6"
                            onClick={handleExpandProduct}
                             id="co-hdr-menu-product"
                          >
                            Products
                          </Link>
                          <Collapse in={expandedProduct} timeout="auto" unmountOnExit>
                            <Stack>
                            <Link
                                color={blueGrey[800]}
                                underline="none"
                                className="co-hdr-menu__child"
                                to={RouterList.product._0}
                                component={RouterLink}
                                onClick={handleCloseProduct}
                              >
                                Products Overview
                              </Link>
                              <Link
                                color={blueGrey[800]}
                                underline="none"
                                className="co-hdr-menu__child"
                                to={RouterList.product.connect}
                                component={RouterLink}
                                onClick={handleCloseProduct}
                              >
                                Connect
                              </Link>
                              <Link
                                color={blueGrey[800]}
                                underline="none"
                                className="co-hdr-menu__child"
                                to={RouterList.product.edge}
                                component={RouterLink}
                                onClick={handleCloseProduct}
                              >
                                Edge
                              </Link>
                              <Link
                                color={blueGrey[800]}
                                underline="none"
                                className="co-hdr-menu__child"
                                to={RouterList.product.conduct}
                                component={RouterLink}
                                onClick={handleCloseProduct}
                              >
                                Conduct
                              </Link>
                              {/* <Link
                                color={blueGrey[800]}
                                underline="none"
                                className="co-hdr-menu__child"
                                to={RouterList.product.insights}
                                component={RouterLink}
                                onClick={handleCloseProduct}
                              >
                                Insights
                              </Link> */}
                            </Stack>
                          </Collapse>
                          <Link
                            color={blueGrey[800]}
                            className="co-hdr-menu__root--mobile"
                            underline="none"
                            variant="h6"
                            onClick={handleExpandService}
                            id="co-hdr-menu-service"
                          >
                            Services
                          </Link>
                          <Collapse in={expandedService} timeout="auto" unmountOnExit>
                            <Stack>
                            <Link
                                color={blueGrey[800]}
                                underline="none"
                                className="co-hdr-menu__child"
                                to={RouterList.service._0}
                                component={RouterLink}
                                onClick={handleCloseService}
                              >
                                Services Overview
                              </Link>
                              <Link
                                color={blueGrey[800]}
                                underline="none"
                                className="co-hdr-menu__child"
                                to={RouterList.service.studysetup}
                                component={RouterLink}
                                onClick={handleCloseService}
                              >
                                Study Setup
                              </Link>
                              <Link
                                color={blueGrey[800]}
                                underline="none"
                                className="co-hdr-menu__child"
                                to={RouterList.service.clinicaldatamanagement}
                                component={RouterLink}
                                onClick={handleCloseService}
                              >
                                Clinical Data Services
                              </Link>
                              <Link
                                color={blueGrey[800]}
                                underline="none"
                                className="co-hdr-menu__child"
                                to={RouterList.service.supporthelpdesk}
                                component={RouterLink}
                                onClick={handleCloseService}
                              >
                                Support &amp; Helpdesk 
                              </Link>
                            </Stack>
                          </Collapse>
                          <Link
                            color={blueGrey[800]}
                            className="co-hdr-menu__root--mobile"
                            underline="none"
                            variant="h6"
                            to={RouterList.partnerships.partnerships}
                            component={RouterLink}
                            onClick={handleCloseSubMenu}
                          >
                            Partnerships
                          </Link>
                          <Link
                            expand={{ expandedAbout }}
                            color={blueGrey[800]}
                            className="co-hdr-menu__root--mobile"
                            underline="none"
                            variant="h6"
                            onClick={handleExpandAbout}
                            id="co-hdr-menu-about"
                          >
                            About
                          </Link>
                          <Collapse in={expandedAbout} timeout="auto" unmountOnExit>
                            <Stack>
                              <Link
                                color={blueGrey[800]}
                                underline="none"
                                className="co-hdr-menu__child"
                                to={RouterList.about.mission}
                                component={RouterLink}
                                onClick={handleCloseAbout}
                              >
                                Mission
                              </Link>
                              <Link
                                color={blueGrey[800]}
                                underline="none"
                                className="co-hdr-menu__child"
                                to={RouterList.about.leadershipteam}
                                component={RouterLink}
                                onClick={handleCloseAbout}
                              >
                                Leadership Team
                              </Link>
                              <Link
                                color={blueGrey[800]}
                                underline="none"
                                className="co-hdr-menu__child"
                                to={RouterList.about.career}
                                component={RouterLink}
                                onClick={handleCloseAbout}
                              >
                                Careers
                              </Link>
                            </Stack>
                          </Collapse>
                          <Link
                            color={blueGrey[800]}
                            className="co-hdr-menu__root--mobile"
                            underline="none"
                            variant="h6"
                            onClick={handleExpandMedia}
                            id="co-hdr-menu-media"
                          >
                            Media
                          </Link>
                          <Collapse in={expandedMedia} timeout="auto" unmountOnExit>
                            <Stack>
                              <Link
                                color={blueGrey[800]}
                                underline="none"
                                className="co-hdr-menu__child"
                                to={RouterList.media.newsroom}
                                component={RouterLink}
                                onClick={handleCloseMedia}
                              >
                                Newsroom
                              </Link>
                              <Link
                                color={blueGrey[800]}
                                underline="none"
                                className="co-hdr-menu__child"
                                to={RouterList.media.customerstory}
                                component={RouterLink}
                                onClick={handleCloseMedia}
                              >
                                Customer Stories
                              </Link>
                              <Link
                                color={blueGrey[800]}
                                underline="none"
                                className="co-hdr-menu__child"
                                to={RouterList.media.pressrelease}
                                component={RouterLink}
                                onClick={handleCloseMedia}
                              >
                                Press Release
                              </Link>
                            </Stack>
                          </Collapse>
                          <Link
                            color={blueGrey[800]}
                            className="co-hdr-menu__root--mobile"
                            underline="none"
                            variant="h6"
                            to={RouterList.contact._0}
                            component={RouterLink}
                            onClick={handleCloseSubMenu}
                            id="co-hdr-menu-contact"
                          >
                            Contact
                          </Link>
                          <Divider />
                          <Box sx={{ p: 1 }}>
                            <Button
                              variant="contained"
                              style={{
                                background: "#042D43",
                                textTransform: "none",
                              }}
                              to={RouterList.login._0}
                              component={RouterLink}
                              onClick={handleCloseSubMenu}
                              startIcon={<AccountCircleOutlinedIcon />}
                            >
                              Login
                            </Button>
                          </Box>
                          <Box sx={{ p: 1 }}>
                            <Button
                              variant="contained"
                              size="small"
                              style={{
                                background: "#03a9f4",
                                textTransform: "none",
                              }}
                              to={RouterList.contact.demo}
                              component={RouterLink}
                              onClick={handleCloseSubMenu}
                            >
                              Request Demo
                            </Button>
                          </Box>
                        </>
                      )}
                    </Stack>
                  </Paper>
                </Fade>  
            </ClickAwayListener>

           )}
            </Popper>
          </Grid>
        </Grid>
        {/* </Container> */}
        <Divider />
      </Box>
    </>
  );
};

export default template;



// use this code to render the menu items in the drawer component
// import React from "react";
// import { HashLink as RouterLink } from "react-router-hash-link";
// import {
//   Box,
//   Container,
//   Grid,
//   Divider,
//   Link,
//   Button,
//   Popper,
//   Drawer,
//   Stack,
//   Collapse,
//   useTheme,
//   useMediaQuery,
//   IconButton,
// } from "@mui/material";
// import { ClickAwayListener } from "@mui/base";
// import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
// import CloseIcon from "@mui/icons-material/Close";
// import { blueGrey, common } from "@mui/material/colors";
// import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
// import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

// import ImgCliniOps from "../../../img/CliniOpsLOGO.png";
// import RouterList from "../../router";

// const template = () => {
//   const theme = useTheme();
//   const isMediumScreen = useMediaQuery(theme.breakpoints.between("900", "1470"));
//   // hideButtons is true for screens between 900px and 1225px.
//   const hideButtons = useMediaQuery(theme.breakpoints.between("900", "1225"));
//   // isExtraSmall: below 900px – use the Drawer for full mobile menu.
//   const isExtraSmall = useMediaQuery(theme.breakpoints.down("900"));

//   // -------------------
//   // Desktop submenu states & handlers (unchanged)
//   const [anchorProduct, setAnchorProduct] = React.useState(null);
//   const handleOpenProduct = (event) => {
//     if (anchorProduct !== event.currentTarget) {
//       handleCloseSubMenu();
//       setAnchorProduct(event.currentTarget);
//       document.querySelector("#co-hdr-menu-product")?.classList.add("hover");
//     }
//   };
//   const handleCloseProduct = () => {
//     document.querySelector("#co-hdr-menu-product")?.classList.remove("hover");
//     setAnchorProduct(null);
//   };

//   const [anchorService, setAnchorService] = React.useState(null);
//   const handleOpenService = (event) => {
//     if (anchorService !== event.currentTarget) {
//       handleCloseSubMenu();
//       setAnchorService(event.currentTarget);
//       document.querySelector("#co-hdr-menu-service")?.classList.add("hover");
//     }
//   };
//   const handleCloseService = () => {
//     document.querySelector("#co-hdr-menu-service")?.classList.remove("hover");
//     setAnchorService(null);
//   };

//   const [anchorAbout, setAnchorAbout] = React.useState(null);
//   const handleOpenAbout = (event) => {
//     if (anchorAbout !== event.currentTarget) {
//       handleCloseSubMenu();
//       setAnchorAbout(event.currentTarget);
//       document.querySelector("#co-hdr-menu-about")?.classList.add("hover");
//     }
//   };
//   const handleCloseAbout = () => {
//     document.querySelector("#co-hdr-menu-about")?.classList.remove("hover");
//     setAnchorAbout(null);
//   };

//   const [anchorMedia, setAnchorMedia] = React.useState(null);
//   const handleOpenMedia = (event) => {
//     if (anchorMedia !== event.currentTarget) {
//       handleCloseSubMenu();
//       setAnchorMedia(event.currentTarget);
//       document.querySelector("#co-hdr-menu-media")?.classList.add("hover");
//     }
//   };
//   const handleCloseMedia = () => {
//     document.querySelector("#co-hdr-menu-media")?.classList.remove("hover");
//     setAnchorMedia(null);
//   };

//   // -------------------
//   // Mobile Menu Implementation

//   // For the Popper (used when hideButtons is true and screen is not extra small)
//   const [anchorMobile, setAnchorMobile] = React.useState(null);
//   const handleMobileMenuClick = (event) => {
//     if (!isExtraSmall && hideButtons) {
//       // Toggle popper: if already open, close it.
//       if (anchorMobile === event.currentTarget) {
//         setAnchorMobile(null);
//       } else {
//         setAnchorMobile(event.currentTarget);
//       }
//     } else if (isExtraSmall) {
//       // For extra small screens, toggle the Drawer.
//       toggleDrawer();
//     }
//   };

//   // Drawer for full mobile menu (for extra-small screens only)
//   const [drawerOpen, setDrawerOpen] = React.useState(false);
//   const toggleDrawer = () => {
//     // Optionally close any desktop submenus
//     handleCloseSubMenu();
//     setDrawerOpen((prev) => !prev);
//   };

//   // When a mobile menu item is clicked, close the popper or drawer accordingly.
//   const handleMenuClick = () => {
//     handleCloseSubMenu();
//     if (isExtraSmall) {
//       setDrawerOpen(false);
//     } else if (hideButtons) {
//       setAnchorMobile(null);
//     }
//   };

//   // Close desktop submenus (without affecting mobile menu state)
//   const handleCloseSubMenu = () => {
//     handleCloseProduct();
//     handleCloseService();
//     handleCloseAbout();
//     handleCloseMedia();
//   };

//   // Expand states for mobile submenus in the Drawer
//   const [expandedProduct, setProductExpanded] = React.useState(false);

//   const handleExpandProduct = (event) => {
//     setAboutExpanded(false);
//     setMediaExpanded(false);
//     setServiceExpanded(false);
//     setProductExpanded(!expandedMedia);
//     // event.target.innerText = expandedAbout ? 'Show More' : 'Show Less';
//   };

//   const [expandedService, setServiceExpanded] = React.useState(false);

//   const handleExpandService = (event) => {
//     setAboutExpanded(false);
//     setMediaExpanded(false);
//     setProductExpanded(false);
//     setServiceExpanded(!expandedMedia);
//     // event.target.innerText = expandedAbout ? 'Show More' : 'Show Less';
//   };

//   const [expandedAbout, setAboutExpanded] = React.useState(false);

//   const handleExpandAbout = (event) => {
//     setMediaExpanded(false);
//     setProductExpanded(false);
//     setServiceExpanded(false);
//     setAboutExpanded(!expandedAbout);
//     // event.target.innerText = expandedAbout ? 'Show More' : 'Show Less';
//   };

//   const [expandedMedia, setMediaExpanded] = React.useState(false);

//   const handleExpandMedia = (event) => {
//     setAboutExpanded(false);
//     setProductExpanded(false);
//     setServiceExpanded(false);
//     setMediaExpanded(!expandedMedia);
//     // event.target.innerText = expandedAbout ? 'Show More' : 'Show Less';
//   };

//   // -------------------
//   return (
//     <>
//       <Box
//         sx={{
//           position: "fixed",
//           width: "100%",
//           top: 0,
//           left: 0,
//           backgroundColor: common.white,
//           zIndex: 100,
//           transform: "translateZ(0)",
//         }}
//       >
//         <Grid
//           container
//           spacing={0}
//           alignItems="center"
//           justifyContent="space-between"
//           direction="row"
//           sx={{
//             padding: isMediumScreen
//               ? "0 15px"
//               : { xs: "15px 8px", md: "0 24px" },
//           }}
//         >
//           {/* Logo */}
//           <Grid item sx={{ ml: { xs: 1, md: 1 } }}>
//             <Link
//               variant="link"
//               to={RouterList.home._0}
//               component={RouterLink}
//               onClick={handleCloseSubMenu}
//               onMouseOver={handleCloseSubMenu}
//             >
//               <Box
//                 sx={{
//                   width: { xs: "150px", md: "238px" },
//                   height: { xs: "30px", md: "58px" },
//                   backgroundImage: `url(${ImgCliniOps})`,
//                   backgroundSize: "contain",
//                   backgroundRepeat: "no-repeat",
//                   backgroundPositionY: "center",
//                 }}
//               />
//             </Link>
//           </Grid>

//           {/* Desktop Menu Items */}
//           <Grid
//             item
//             sx={{
//               mr: 0,
//               ml: isMediumScreen ? 0 : 10,
//               display: { xs: "none", md: "block" },
//               flexWrap: "wrap",
//             }}
//           >
//             <Link
//               color={blueGrey[800]}
//               className="co-hdr-menu__root"
//               underline="none"
//               variant="h6"
//               onClick={handleCloseSubMenu}
//               onMouseOver={handleOpenProduct}
//               id="co-hdr-menu-product"
//             >
//               Products
//               <KeyboardArrowDownOutlinedIcon
//                 fontSize="small"
//                 sx={{ ml: 0.5, mr: -1 }}
//               />
//             </Link>
//             <Popper
//               placement="bottom-start"
//               anchorEl={anchorProduct}
//               open={Boolean(anchorProduct)}
//               onClose={handleCloseProduct}
//               onMouseLeave={handleCloseProduct}
//               sx={{ zIndex: 10 }}
//             >
//               <Stack
//                 sx={{
//                   backgroundColor: "#",
//                   boxShadow: `0 0 16px 0 ${blueGrey[800]}`,
//                   borderRadius: "0 0 4px 4px",
//                 }}
//               >
//                 <Link
//                   color="red"
//                   underline="none"
//                   className="co-hdr-menu__child"
//                   to={RouterList.product._0}
//                   component={RouterLink}
//                   onClick={handleCloseProduct}
//                 >
//                   Products Overview
//                 </Link>
//                 <Link
//                   color="red"
//                   underline="none"
//                   className="co-hdr-menu__child"
//                   to={RouterList.product.connect}
//                   component={RouterLink}
//                   onClick={handleCloseProduct}
//                 >
//                   Connect
//                 </Link>
//                 <Link
//                   color="#FFFFFF"
//                   underline="none"
//                   className="co-hdr-menu__child"
//                   to={RouterList.product.edge}
//                   component={RouterLink}
//                   onClick={handleCloseProduct}
//                 >
//                   Edge
//                 </Link>
//                 <Link
//                   color="#FFFFFF"
//                   underline="none"
//                   className="co-hdr-menu__child"
//                   to={RouterList.product.conduct}
//                   component={RouterLink}
//                   onClick={handleCloseProduct}
//                 >
//                   Conduct
//                 </Link>
//                 <Link
//                   color="#FFFFFF"
//                   underline="none"
//                   className="co-hdr-menu__child"
//                   to={RouterList.product.insights}
//                   component={RouterLink}
//                   onClick={handleCloseProduct}
//                 >
//                   Insights
//                 </Link>
//               </Stack>
//             </Popper>
//           </Grid>

//           {/* Services */}
//           <Grid item sx={{ mr: 0, display: { xs: "none", md: "block" } }}>
//             <Link
//               color={blueGrey[800]}
//               className="co-hdr-menu__root"
//               underline="none"
//               variant="h6"
//               onClick={handleCloseSubMenu}
//               onMouseOver={handleOpenService}
//               id="co-hdr-menu-service"
//             >
//               Services
//               <KeyboardArrowDownOutlinedIcon
//                 fontSize="small"
//                 sx={{ ml: 0.5, mr: -1 }}
//               />
//             </Link>
//             <Popper
//               placement="bottom-start"
//               anchorEl={anchorService}
//               open={Boolean(anchorService)}
//               onClose={handleCloseService}
//               onMouseLeave={handleCloseService}
//               sx={{ zIndex: 10 }}
//             >
//               <Stack
//                 sx={{
//                   backgroundColor: common.white,
//                   boxShadow: `0 0 16px 0 ${blueGrey[800]}`,
//                   borderRadius: "0 0 4px 4px",
//                 }}
//               >
//                 <Link
//                   color={blueGrey[800]}
//                   underline="none"
//                   className="co-hdr-menu__child"
//                   to={RouterList.service._0}
//                   component={RouterLink}
//                   onClick={handleCloseService}
//                 >
//                   Services Overview
//                 </Link>
//                 <Link
//                   color={blueGrey[800]}
//                   underline="none"
//                   className="co-hdr-menu__child"
//                   to={RouterList.service.studysetup}
//                   component={RouterLink}
//                   onClick={handleCloseService}
//                 >
//                   Study Setup
//                 </Link>
//                 <Link
//                   color={blueGrey[800]}
//                   underline="none"
//                   className="co-hdr-menu__child"
//                   to={RouterList.service.clinicaldatamanagement}
//                   component={RouterLink}
//                   onClick={handleCloseService}
//                 >
//                   Clinical Data Services
//                 </Link>
//                 <Link
//                   color={blueGrey[800]}
//                   underline="none"
//                   className="co-hdr-menu__child"
//                   to={RouterList.service.supporthelpdesk}
//                   component={RouterLink}
//                   onClick={handleCloseService}
//                 >
//                   Support &amp; Helpdesk
//                 </Link>
//               </Stack>
//             </Popper>
//           </Grid>

//           {/* Partnerships */}
//           <Grid item sx={{ mr: 0, display: { xs: "none", md: "block" } }}>
//             <Link
//               color={blueGrey[800]}
//               className="co-hdr-menu__root"
//               underline="none"
//               variant="h6"
//               to={RouterList.partnerships.partnerships}
//               component={RouterLink}
//               onClick={handleCloseSubMenu}
//               onMouseOver={handleCloseSubMenu}
//             >
//               Partnerships
//             </Link>
//           </Grid>

//           {/* About */}
//           <Grid item sx={{ mr: 0, display: { xs: "none", md: "block" } }}>
//             <Link
//               color={blueGrey[800]}
//               className="co-hdr-menu__root"
//               underline="none"
//               variant="h6"
//               href="#"
//               onClick={handleOpenAbout}
//               onMouseOver={handleOpenAbout}
//               id="co-hdr-menu-about"
//             >
//               About
//               <KeyboardArrowDownOutlinedIcon
//                 fontSize="small"
//                 sx={{ ml: 0.5, mr: -1 }}
//               />
//             </Link>
//             <Popper
//               placement="bottom-start"
//               anchorEl={anchorAbout}
//               open={Boolean(anchorAbout)}
//               onClose={handleCloseAbout}
//               onMouseLeave={handleCloseAbout}
//               sx={{ zIndex: 10 }}
//             >
//               <Stack
//                 sx={{
//                   backgroundColor: common.white,
//                   boxShadow: `0 0 16px 0 ${blueGrey[800]}`,
//                   borderRadius: "0 0 4px 4px",
//                 }}
//               >
//                 <Link
//                   color={blueGrey[800]}
//                   underline="none"
//                   className="co-hdr-menu__child"
//                   to={RouterList.about.mission}
//                   component={RouterLink}
//                   onClick={() => {
//                     handleCloseAbout();
//                     handleMenuClick();
//                   }}
//                 >
//                   Mission
//                 </Link>
//                 <Link
//                   color={blueGrey[800]}
//                   underline="none"
//                   className="co-hdr-menu__child"
//                   to={RouterList.about.leadershipteam}
//                   component={RouterLink}
//                   onClick={() => {
//                     handleCloseAbout();
//                     handleMenuClick();
//                   }}
//                 >
//                   Leadership Team
//                 </Link>
//                 <Link
//                   color={blueGrey[800]}
//                   underline="none"
//                   className="co-hdr-menu__child"
//                   to={RouterList.about.career}
//                   component={RouterLink}
//                   onClick={() => {
//                     handleCloseAbout();
//                     handleMenuClick();
//                   }}
//                 >
//                   Careers
//                 </Link>
//               </Stack>
//             </Popper>
//           </Grid>

//           {/* Media */}
//           <Grid item sx={{ mr: 0, display: { xs: "none", md: "block" } }}>
//             <Link
//               color={blueGrey[800]}
//               className="co-hdr-menu__root"
//               underline="none"
//               variant="h6"
//               href="#"
//               onClick={handleOpenMedia}
//               onMouseOver={handleOpenMedia}
//               id="co-hdr-menu-media"
//             >
//               Media
//               <KeyboardArrowDownOutlinedIcon
//                 fontSize="small"
//                 sx={{ ml: 0.5, mr: -1 }}
//               />
//             </Link>
//             <Popper
//               placement="bottom-start"
//               anchorEl={anchorMedia}
//               open={Boolean(anchorMedia)}
//               onClose={handleCloseMedia}
//               onMouseLeave={handleCloseMedia}
//               sx={{ zIndex: 10 }}
//             >
//               <Stack
//                 sx={{
//                   backgroundColor: common.white,
//                   boxShadow: `0 0 16px 0 ${blueGrey[800]}`,
//                   borderRadius: "0 0 4px 4px",
//                 }}
//               >
//                 <Link
//                   color={blueGrey[800]}
//                   underline="none"
//                   className="co-hdr-menu__child"
//                   to={RouterList.media.newsroom}
//                   component={RouterLink}
//                   onClick={() => {
//                     handleCloseMedia();
//                     handleMenuClick();
//                   }}
//                 >
//                   Newsroom
//                 </Link>
//                 <Link
//                   color={blueGrey[800]}
//                   underline="none"
//                   className="co-hdr-menu__child"
//                   to={RouterList.media.customerstory}
//                   component={RouterLink}
//                   onClick={() => {
//                     handleCloseMedia();
//                     handleMenuClick();
//                   }}
//                 >
//                   Customer Stories
//                 </Link>
//                 <Link
//                   color={blueGrey[800]}
//                   underline="none"
//                   className="co-hdr-menu__child"
//                   to={RouterList.media.pressrelease}
//                   component={RouterLink}
//                   onClick={() => {
//                     handleCloseMedia();
//                     handleMenuClick();
//                   }}
//                 >
//                   Press Release
//                 </Link>
//               </Stack>
//             </Popper>
//           </Grid>

//           {/* Contact */}
//           <Grid
//             item
//             sx={{
//               mr: isMediumScreen ? 1 : 10,
//               display: { xs: "none", md: "block" },
//             }}
//           >
//             <Link
//               color={blueGrey[800]}
//               className="co-hdr-menu__root"
//               underline="none"
//               variant="h6"
//               to={RouterList.contact._0}
//               component={RouterLink}
//               onClick={handleCloseSubMenu}
//               onMouseOver={handleCloseSubMenu}
//             >
//               Contact
//             </Link>
//           </Grid>

//           {/* Desktop Login + Request Demo */}
//           <>
//             <Grid
//               item
//               sx={{
//                 mr: 1,
//                 display: { xs: "none", md: hideButtons ? "none" : "block" },
//               }}
//             >
//               <Button
//                 variant="contained"
//                 sx={{
//                   background: "#042D43",
//                   textTransform: "none",
//                   borderRadius: "12px",
//                   "&:hover": {
//                     background: "#042D43",
//                     boxShadow: "none",
//                   },
//                 }}
//                 to={RouterList.login._0}
//                 component={RouterLink}
//                 onClick={handleCloseSubMenu}
//                 onMouseOver={handleCloseSubMenu}
//                 startIcon={<AccountCircleOutlinedIcon />}
//               >
//                 Login
//               </Button>
//             </Grid>
//             <Grid
//               item
//               sx={{
//                 mr: 2,
//                 display: { xs: "none", md: hideButtons ? "none" : "block" },
//               }}
//             >
//               <Button
//                 variant="contained"
//                 sx={{
//                   background: "#03a9f4",
//                   textTransform: "none",
//                   borderRadius: "12px",
//                   "&:hover": {
//                     background: "#",
//                     boxShadow: "none",
//                   },
//                 }}
//                 to={RouterList.contact.demo}
//                 component={RouterLink}
//                 onClick={handleCloseSubMenu}
//                 onMouseOver={handleCloseSubMenu}
//               >
//                 Request Demo
//               </Button>
//             </Grid>
//           </>

//           {/* Mobile Hamburger Icon and Conditional Mobile Menu */}
//           <Grid item sx={{ display: hideButtons? "block" : { xs: "block", md: "none" } }}>
//             <MenuOutlinedIcon
//               fontSize="large"
//               sx={{ cursor: "pointer" }}
//               onClick={handleMobileMenuClick}
//               id="co-hdr-menu-mobile"
//             />
//             {/* If not extra small and hideButtons is true, show the Popper with buttons */}
//             {hideButtons && (
//               <ClickAwayListener onClickAway={() => setAnchorMobile(null)}>
//                 <Popper
//                   placement="bottom-start"
//                   anchorEl={anchorMobile}
//                   open={Boolean(anchorMobile)}
//                   onClose={() => setAnchorMobile(null)}
//                   sx={{ zIndex: 1500 }}
//                 >
//                   <Stack
//                     sx={{
//                       pt: 2,
//                       backgroundColor: common.white,
//                       boxShadow: `0 0 16px 0 ${blueGrey[800]}`,
//                       borderRadius: "0 0 4px 4px",
//                     }}
//                   >
//                     <Box sx={{ p: 1 }}>
//                       <Button
//                         variant="contained"
//                         sx={{
//                           background: "#042D43",
//                           textTransform: "none",
//                           borderRadius: "12px",
//                           "&:hover": {
//                             background: "#042D43",
//                             boxShadow: "none",
//                           },
//                         }}
//                         to={RouterList.login._0}
//                         component={RouterLink}
//                         onClick={() => setAnchorMobile(null)}
//                         startIcon={<AccountCircleOutlinedIcon />}
//                       >
//                         Login
//                       </Button>
//                     </Box>
//                     <Box sx={{ p: 1 }}>
//                       <Button
//                         variant="contained"
//                         size="small"
//                         sx={{
//                           background: "#03a9f4",
//                           textTransform: "none",
//                           borderRadius: "12px",
//                           "&:hover": {
//                             background: "#",
//                             boxShadow: "none",
//                           },
//                         }}
//                         to={RouterList.contact.demo}
//                         component={RouterLink}
//                         onClick={() => setAnchorMobile(null)}
//                       >
//                         Request Demo
//                       </Button>
//                     </Box>
//                   </Stack>
//                 </Popper>
//               </ClickAwayListener>
//             )}
//             {/* If extra small, use the Drawer to render full mobile menu */}
//             {isExtraSmall && (
//               <Drawer
//                 anchor="right"
//                 open={drawerOpen}
//                 onClose={toggleDrawer}
//                 sx={{ zIndex: 1500 }}
//               >
//                 <Box
//                   sx={{
//                     width: 280,
//                     backgroundColor: common.white,
//                     height: "100%",
//                     p: 2,
//                   }}
//                 >
//                   {/* Close Icon */}
//                   <Box sx={{ display: "flex", justifyContent: "space-between",alignItems:"center" }}>
//                   <Link
//                     variant="link"
//                     to={RouterList.home._0}
//                     component={RouterLink}
//                     onClick={handleMenuClick}
//                   >
//                     <Box
//                       sx={{
//                         width: { xs: "150px" },
//                         height: { xs: "30px" },
//                         backgroundImage: `url(${ImgCliniOps})`,
//                         backgroundSize: "contain",
//                         backgroundRepeat: "no-repeat",
//                         backgroundPositionY: "center",
//                       }}
//                     />
//                   </Link>
//                     <IconButton onClick={toggleDrawer}>
//                       <CloseIcon />
//                     </IconButton>
//                   </Box>
//                   {/* Full Mobile Menu: all menu items and buttons */}
//                   <Link
//                     color={blueGrey[800]}
//                     className="co-hdr-menu__root--mobile"
//                     underline="none"
//                     variant="h6"
//                     to={RouterList.product._0}
//                     component={RouterLink}
//                     onClick={handleExpandProduct}
//                   >
//                     Products
//                   </Link>
//                   <Collapse in={expandedProduct} timeout="auto" unmountOnExit>
//                     <Stack>
//                       <Link
//                         color={blueGrey[800]}
//                         underline="none"
//                         className="co-hdr-menu__child"
//                         to={RouterList.product.connect}
//                         component={RouterLink}
//                         onClick={() => {
//                           handleCloseProduct();
//                           setDrawerOpen(false);
//                         }}
//                       >
//                         Connect
//                       </Link>
//                       <Link
//                         color={blueGrey[800]}
//                         underline="none"
//                         className="co-hdr-menu__child"
//                         to={RouterList.product.edge}
//                         component={RouterLink}
//                         onClick={() => {
//                           handleCloseAbout();
//                           setDrawerOpen(false);
//                         }}
//                       >
//                         Edge
//                       </Link>
//                       <Link
//                         color={blueGrey[800]}
//                         underline="none"
//                         className="co-hdr-menu__child"
//                         to={RouterList.product.conduct}
//                         component={RouterLink}
//                         onClick={() => {
//                           handleCloseAbout();
//                           setDrawerOpen(false);
//                         }}
//                       >
//                         Conduct
//                       </Link>
//                       <Link
//                         color={blueGrey[800]}
//                         underline="none"
//                         className="co-hdr-menu__child"
//                         to={RouterList.product.insights}
//                         component={RouterLink}
//                         onClick={() => {
//                           handleCloseAbout();
//                           setDrawerOpen(false);
//                         }}
//                       >
//                         Insights
//                       </Link>
//                     </Stack>
//                   </Collapse>
//                   <Link
//                     color={blueGrey[800]}
//                     className="co-hdr-menu__root--mobile"
//                     underline="none"
//                     variant="h6"
//                     to={RouterList.service._0}
//                     component={RouterLink}
//                     onClick={handleExpandService}
//                     id="co-hdr-menu-service"
//                   >
//                     Services
//                   </Link>
//                   <Collapse in={expandedService} timeout="auto" unmountOnExit>
//                     <Stack>
//                       <Link
//                         color={blueGrey[800]}
//                         underline="none"
//                         className="co-hdr-menu__child"
//                         to={RouterList.service.studysetup}
//                         component={RouterLink}
//                         onClick={() => {
//                           handleCloseService();
//                           setDrawerOpen(false);
//                         }}
//                       >
//                         Study Setup
//                       </Link>
//                       <Link
//                         color={blueGrey[800]}
//                         underline="none"
//                         className="co-hdr-menu__child"
//                         to={RouterList.product.clinicaldatamanagement}
//                         component={RouterLink}
//                         onClick={() => {
                          
//                           setDrawerOpen(false);
//                         }}
//                       >
//                         Clinical Data Services
//                       </Link>
//                       <Link
//                         color={blueGrey[800]}
//                         underline="none"
//                         className="co-hdr-menu__child"
//                         to={RouterList.product.supporthelpdesk}
//                         component={RouterLink}
//                         onClick={() => {
//                           handleCloseService();
//                           setDrawerOpen(false);
//                         }}
//                       >
                        
//                       </Link>
                    
//                     </Stack>
//                   </Collapse>
//                   <Link
//                     color={blueGrey[800]}
//                     className="co-hdr-menu__root--mobile"
//                     underline="none"
//                     variant="h6"
//                     to={RouterList.partnerships.partnerships}
//                     component={RouterLink}
//                     onClick={handleMenuClick}
//                   >
//                     Partnerships
//                   </Link>
//                   <Link
//                     expand={{ expandedAbout }}
//                     color={blueGrey[800]}
//                     className="co-hdr-menu__root--mobile"
//                     underline="none"
//                     variant="h6"
//                     onClick={handleExpandAbout}
//                     id="co-hdr-menu-about"
//                   >
//                     About
//                   </Link>
//                   <Collapse in={expandedAbout} timeout="auto" unmountOnExit>
//                     <Stack>
//                       <Link
//                         color={blueGrey[800]}
//                         underline="none"
//                         className="co-hdr-menu__child"
//                         to={RouterList.about.mission}
//                         component={RouterLink}
//                         onClick={() => {
//                           handleCloseAbout();
//                           setDrawerOpen(false);
//                         }}
//                       >
//                         Mission
//                       </Link>
//                       <Link
//                         color={blueGrey[800]}
//                         underline="none"
//                         className="co-hdr-menu__child"
//                         to={RouterList.about.leadershipteam}
//                         component={RouterLink}
//                         onClick={() => {
//                           handleCloseAbout();
//                           setDrawerOpen(false);
//                         }}
//                       >
//                         Leadership Team
//                       </Link>
//                       <Link
//                         color={blueGrey[800]}
//                         underline="none"
//                         className="co-hdr-menu__child"
//                         to={RouterList.about.career}
//                         component={RouterLink}
//                         onClick={() => {
//                           handleCloseAbout();
//                           setDrawerOpen(false);
//                         }}
//                       >
//                         Careers
//                       </Link>
//                     </Stack>
//                   </Collapse>
//                   <Link
//                     color={blueGrey[800]}
//                     className="co-hdr-menu__root--mobile"
//                     underline="none"
//                     variant="h6"
//                     onClick={handleExpandMedia}
//                     id="co-hdr-menu-media"
//                   >
//                     Media
//                   </Link>
//                   <Collapse in={expandedMedia} timeout="auto" unmountOnExit>
//                     <Stack>
//                       <Link
//                         color={blueGrey[800]}
//                         underline="none"
//                         className="co-hdr-menu__child"
//                         to={RouterList.media.newsroom}
//                         component={RouterLink}
//                         onClick={() => {
//                           handleCloseMedia();
//                           setDrawerOpen(false);
//                         }}
//                       >
//                         Newsroom
//                       </Link>
//                       <Link
//                         color={blueGrey[800]}
//                         underline="none"
//                         className="co-hdr-menu__child"
//                         to={RouterList.media.customerstory}
//                         component={RouterLink}
//                         onClick={() => {
//                           handleCloseMedia();
//                           setDrawerOpen(false);
//                         }}
//                       >
//                         Customer Stories
//                       </Link>
//                       <Link
//                         color={blueGrey[800]}
//                         underline="none"
//                         className="co-hdr-menu__child"
//                         to={RouterList.media.pressrelease}
//                         component={RouterLink}
//                         onClick={() => {
//                           handleCloseMedia();
//                           setDrawerOpen(false);
//                         }}
//                       >
//                         Press Release
//                       </Link>
//                     </Stack>
//                   </Collapse>
//                   <Link
//                     color={blueGrey[800]}
//                     className="co-hdr-menu__root--mobile"
//                     underline="none"
//                     variant="h6"
//                     to={RouterList.contact._0}
//                     component={RouterLink}
//                     onClick={handleMenuClick}
//                     id="co-hdr-menu-contact"
//                   >
//                     Contact
//                   </Link>
//                   <Divider />
//                   <Box sx={{ p: 1 }}>
//                     <Button
//                       variant="contained"
//                       style={{
//                         background: "#042D43",
//                         textTransform: "none",
//                       }}
//                       to={RouterList.login._0}
//                       component={RouterLink}
//                       onClick={() => setDrawerOpen(false)}
//                       startIcon={<AccountCircleOutlinedIcon />}
//                     >
//                       Login
//                     </Button>
//                   </Box>
//                   <Box sx={{ p: 1 }}>
//                     <Button
//                       variant="contained"
//                       size="small"
//                       style={{
//                         background: "#03a9f4",
//                         textTransform: "none",
//                       }}
//                       to={RouterList.contact.demo}
//                       component={RouterLink}
//                       onClick={() => setDrawerOpen(false)}
//                     >
//                       Request Demo
//                     </Button>
//                   </Box>
//                 </Box>
//               </Drawer>
//             )}
//           </Grid>
//         </Grid>
//         <Divider />
//       </Box>
//     </>
//   );
// };

// export default template;



