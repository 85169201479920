import React from "react";
import { Grid, Box, Typography, Stack,Alert, Link } from "@mui/material";
import { teal, blueGrey } from "@mui/material/colors";

import { PageHero, PageContentV2 } from "../../component/page";
import LizHurlburt from "../../../img/media/customer-story/_1/LizHurlburt.jpg";
import Europian from "../../../img/media/customer-story/_1/Euoropian.png";
// import ImgEndo from "../../../img/media/customer-story/_1/Endocrinology-Diabetes-and-Metabolism-Journal.png";
// import ImgCIMED from "../../../img/media/customer-story/_1/CIMED-logo.png";
// import ImgMSMCNH from "../../../img/media/customer-story/_1/MSMC-NH-transB.png";
// import ImgKRMH from "../../../img/media/customer-story/_1/KRMHospital_TransB.png";
// import ImgNH from '../../../img/media/customer-story/_1/Narayana-Hrudayalaya-Limited-New-Logo4.png';





// Page Imports
import _0 from "./customer-story_15/_0";
import { Block } from "@mui/icons-material";

const template = () => {
  return (
    <>
      <PageHero color="rgba(3, 169, 244, 0.1)">
        <_0 />
      </PageHero>
      <PageContentV2>
        <Grid item>
        <Typography variant="subtitle2" paragraph>
        Stepping in for Data Management and Analysis:  Study on Catheter-related blood stream infections (CRBSI), for post-marketing experience with Neutrolin® at 20 dialysis units across Germany</Typography>
          <Typography variant="body7" paragraph>
          CorMedix Inc., is a biopharmaceutical company with a mission to develop and commercialize therapeutic products for the prevention and treatment of life-threatening conditions and diseases. In a post marketing study involving a catheter lock solution in patients undergoing chronic hemodialysis, CorMedix tapped the expertise of CliniOps midway through the study to help consolidate the clinical data, build a working data base and provide statistical analysis. 
          </Typography>
          <Typography variant="body7" paragraph>
          The study collected data from January 2014 to September 2016 involving 201 patients who were undergoing chronic hemodialysis and were at risk of catheter-related blood stream infections (CRBSI). Patients were spread across 20 sites and there were a total of 15,464 dialysis sessions.  
          </Typography>
        <Typography variant="body7" paragraph>
        CliniOps got to work quickly to complete data entry into a single source file, ran validity checks to assure medical consistency, flag false data entries, and confirm accuracy. After data base lock, CliniOps performed statistical analysis and validation prior to submitting report on time. 
        </Typography>
          

          <blockquote
            style={{ borderLeft: `4px solid ${blueGrey[100]}`, marginLeft: -1 }}
          >
            <Stack
              spacing={4}
              sx={{ pl: 4 }}
              direction={{ xs: "column", md: "row" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minWidth: "220px",
                  width: "220px",
                  height: "250px",
                  borderRadius: 2,
                  backgroundImage: `url(${LizHurlburt})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "top center",
                }}
              />
              <Box>
            <Typography variant="body7" paragraph>
              <em>
              “CorMedix is at the forefront of developing therapies to prevent and treat multi-drug and pan drug resistant bacteria and fungi which threatens public health globally. When we conduct studies, we collaborate with technology providers who have deep domain expertise.  In our post marketing study, we called upon CliniOps to manage and analyze our data. CliniOps was very easy to work with, quite agile and adaptable. The on-time completion of this important study and its results have led CorMedix to further expand into a much larger study, LOCK IT-100.”
              </em>
            </Typography>
            <Typography variant="overline" paragraph>
              <strong>
              Liz M. Hurlburt, M.S.
              <br></br>
              Executive Vice President & Head, Clinical and Medical Affairs, Cormedix Inc.
              
            
              </strong>
            </Typography>
          </Box>
            </Stack>
          </blockquote>
        
        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '175px',
                width: '175px',
                height: '80px',
                borderRadius: 2,
                marginTop: '30px',
                backgroundImage: `url(${Europian})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
            />
            <Box>
            <Typography paragraph>
            The results of the clinical study, <Link href="https://link.springer.com/content/pdf/10.1007/s10096-017-3157-7.pdf" target={"_blank"} underline="hover">“Postmarketing experience with Neutrolin® (taurolidine, heparin, calcium citrate) catheter lock solution in hemodialysis patients”</Link>, was published in European Journal of Clinical Microbiology & Infectious Diseases, on December 6, 2017.
            </Typography>
            </Box>
        </Stack>
        </Alert>

        </Grid>
      </PageContentV2>
    </>
  );
};

export default template;
