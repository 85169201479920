import React from 'react';
import { Grid, Typography, Stack } from '@mui/material';

import { PageSectionLeftImg } from '../../../component/page';
import { Product } from '../../../component/page';

import ImgTelemedicine from '../../../../img/product/connect/telemedicine-new.png';
import CustomTypography from '../../../component/customTypography';

const template = () => {
  return (
    <>
      <Grid container direction='column' spacing={0} sx={{ mt: 0 }}>
            {/* Telemedicine  */}
          <Grid item>
              <Product
                ImgSrc={ImgTelemedicine}
                Name="Telemedicine"
                Summary={
                  <>
                  <CustomTypography variant='body2' sx={{ mt: 1 }}>
                    The Telemedicine module allows the patient to interact with the physician and nurse from home. 
                    This is conducted via the mobile app over a secured network via built-in video conference functionality 
                    on the patient's Smartphone, Tablet, or Web browser. 
                    <br></br><br></br>
                    The patient can choose an on-site visit, a tele-visit, or even a home-visit by a mobile visiting nurse or a phlebotomist. 
                    This significantly increases patient retention, patient engagement, and protocol adherence.
                </CustomTypography>
                </>
                }
              />
              
          </Grid>
      </Grid>
    </>
  );
}

export default template;