import React from 'react';
import { Typography } from '@mui/material';
import { blueGrey } from '@mui/material/colors';

const template = () => {
  return (
    <>
      <Typography variant="h9" sx={{paddingBottom:"15px"}}  component="h1">
      Off-line Data Collection with sites in Africa: Study to Evaluate Safety, Immunogenicity and Efficacy of PfSPZ Vaccine in HIV Negative and HIV Positive Tanzanian Adults 
      </Typography>
      
      <a href="https://sanaria.com/" target={'_blank'} class="linkcolor">Sanaria</a>
      
    </>
  );
}

export default template;