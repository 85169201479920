import React from 'react';
// import { Grid } from '@mui/material';
// import { teal } from '@mui/material/colors';

// import { PageHero, PageContent } from '../../component/page';


// Page Imports
import _0 from './customer-story_1/_0';
import _1 from './customer-story_1/_1';
import { Grid, Box, Typography } from '@mui/material';
import { teal, blueGrey } from '@mui/material/colors';

import { PageHero, PageContentV2 } from '../../component/page';

// Page Imports
// import _0 from './customer-story/_0';

const template = () => {
  return (
    <>
      <PageHero color="rgba(3, 169, 244, 0.1)">
        <_0 />
      </PageHero>
      <PageContentV2>
        <Grid item>
          <_1 />
        </Grid>
      </PageContentV2>
    </>
  );
}

export default template;