import React from 'react';
import { Box, Grid } from '@mui/material';


// Page Imports
import _0 from './press-release/_0';
import _1 from './press-release/_1';
import _2 from './press-release/_2';

const template = () => {
  return (
    <>
        <Box>
        <_0 />
        </Box>
      <Box>
        <Box>
        <_2/>
        </Box>
        <Grid sx={{position:"relative",zIndex:"1",marginTop:{xs:"-80px",sm:"-100px",md:"-200px"},padding:{xs:"5px 20px 5px 20px",md:'5px 80px 80px 80px'}}}>
        <_1/>
        </Grid> 
    </Box>
      
    </>
  );
}

export default template;