import React from 'react';
import {Box, Grid, Typography} from '@mui/material';

import { Product } from '../../../component/page';


import ImgEHR from '../../../../img/product/_0/EHR.png';
import ImgAddOn1 from '../../../../img/product/_0/AddOn1.png';
import ImgWearbles from '../../../../img/product/_0/wearables.png';
import ImgSSO from '../../../../img/product/_0/SSO.png';
import ImgCustomInt from '../../../../img/product/_0/CustomInt.png';

const template = () => {
    return (
        <>
        <Grid container direction='column' spacing={0} sx={{ mt: 0 }}>
            {/* EHR  */}
          <Grid item>
              <Product
                ImgSrc={ImgEHR}
                Name="Connector for EHR Integration"
                className="co-image-connect"
                Summary={
                  <>
                  <Typography variant='body2' sx={{ mt: 1 }}>
                    CliniOps offers standard APIs to integrate with 3rd party applications. Electronic health records have remained 
                    isolated in the Islands for years. With CliniOps and its partner ecosystem, an integrated EHR to EDC system can get 
                    clinical trials started rapidly. SMART on FHIR data transfer standards that utilize a GCP-compliant process for controlling 
                    the data. CliniOps is agnostic to the Data Source and makes standardized data available across EHR systems.
                </Typography>
                </>
                }
              />
              
          </Grid>
            {/* AddOn  */}
          <Grid item sx={{ pt: '20px !important' }}>
              <Product
                ImgSrc={ImgAddOn1}
                Name="Connector for Lab Integration"
                className="co-image-connect"
                Summary={
                  <>
                  <Typography variant='body2' sx={{ mt: 1 }}>
                    CliniOps offers standard APIs to integrate with 3rd party laboratories. A study can get data from one or more 
                    central labs, and each lab can be configured for lab normal ranges based on the protocol and lab manuals provided 
                    by the labs. CROs can get near real-time access to lab data and reconcile those with the study data to speed up the 
                    review process and make faster decisions.
                </Typography>
                </>
                }
              />
              
          </Grid>
            {/* Connector for Wearables & Sensors  */}
          <Grid item sx={{ pt: '20px !important' }}>
              <Product
                ImgSrc={ImgWearbles}
                Name="Connector for Wearables & Sensors"
                className="co-image-connect"
                Summary={
                  <>
                  <Typography variant='body2' sx={{ mt: 1 }}>
                    The CliniOps mobile app can seamlessly connect with several wearables, sensors, and devices. 
                    The CliniOps system is integrated with devices to collect heart rate, blood oxygen, electrocardiogram, 
                    respiratory rate, blood pressure, temperature, and other measurements.<br></br>
                    Device data is collected via the app from the patient's home and transmitted to the patient database, 
                    which the study team can access securely based on their access control. <br></br>
                    The system supports several data formats: alpha, numeric, image, voice, video, and wave formats.<br></br> 
                    CliniOps also has built-in real-time alerts triggered if the device data shows abnormal fluctuations or 
                    exceeds normal ranges.

                </Typography>
                </>
                }
              />
              
          </Grid>
            {/* Connector to Single Sign On (SSO)  */}
          <Grid item sx={{ pt: '20px !important' }}>
              <Product
                ImgSrc={ImgSSO}
                Name="Connector to Single Sign On (SSO)"
                className="co-image-connect"
                Summary={
                  <>
                  <Typography variant='body2' sx={{ mt: 1 }}>
                    CliniOps offers a single-sign-on capability for the study team, enabling them to access an industry-standard 
                    authentication service to switch between clinical trial platforms quickly, easily, and painlessly amidst the complex 
                    trial process. This capability enables 600,000+ users globally across 20 global pharmaceutical companies to 
                    strengthen security, streamline access, raise productivity, and help achieve digital transformation initiatives 
                    for sponsors.
                </Typography>
                </>
                }
              />
              
          </Grid>
            {/* Custom Integration  */}
          <Grid item sx={{ pt: '20px !important' }}>
              <Product
                ImgSrc={ImgCustomInt}
                Name="Custom Integration"
                className="co-image-connect"
                Summary={
                  <>
                  <Typography variant='body2' sx={{ mt: 1 }}>
                    Integration with ecosystem partner applications, including, but not limited to, medical devices, Biometric devices, 
                    LMS systems, Payment systems, Call centers, etc.
                </Typography>
                </>
                }
              />
              
          </Grid>
      </Grid>
        </>
    );
};
export default template;