import React from 'react';
import { Grid, Typography, Stack } from '@mui/material';

import { PageSectionRightImg } from '../../../component/page';
import { Product } from '../../../component/page';

import ImgDS from "../../../../img/product/insights/data_science.png";
import CustomTypography from '../../../component/customTypography';

const template = () => {
  return (
    <>
    <Grid container direction='column' spacing={0} sx={{ mt: 0 }}>
         
        <Grid item>
            <Product
              ImgSrc={ImgDS}
              Name="Data Science for Life Science"
              Summary={
                <>
                <CustomTypography variant='body2' sx={{ mt: 1}}>
                CliniOps Data Science for Life Science feature has built-in Artificial Intelligence (AI), Machine Learning (ML), and 
                Natural Language Processing (NLP) capabilities. These capabilities help the Study team to streamline operations 
                and proactively make informed decisions, leveraging the power of data science. 
                <br></br><br></br>
                It is based on intelligent algorithms to analyze study trends and patterns and is useful in various scenarios across 
                Study Setup, Study Conduct, and Study Closeout activities. Predictive tools are available for enrollment projections, 
                patient recruitment timelines, and potential protocol deviations, enabling better planning and resource allocation. 
                <br></br><br></br>
                Visualization is also available to track trial data across different geographic locations, improving site performance 
                management and identifying regional trends.
              </CustomTypography>
              </>
              }
            />
            
        </Grid>
    </Grid>
    </>
  );
}

export default template;