import React from "react";
import { Grid, Box, Typography, Stack,Alert, Link } from "@mui/material";
import { teal, blueGrey } from "@mui/material/colors";
import { PageHero, PageContentV2 } from "../../component/page";

import ImgGary from "../../../img/media/customer-story/_1/Gary_Weil.jpg";
import ASTMHLogo from '../../../img/media/customer-story/_1/ASTMHLogo.png';
import ImgPlosNTD from '../../../img/media/customer-story/_1/PLOS-NTD_Logo.png';
import RSTMH_logo from '../../../img/media/customer-story/_1/RSTMH_logo.png';
import PLOSMedicine from '../../../img/media/customer-story/_1/PLOSMedicine.png';
import AfricacomLogo from '../../../img/media/customer-story/_1/Africa.comLogo.png';
import WHOLogo from '../../../img/media/customer-story/_1/WHOLogo.png';
import GatesNotes from '../../../img/media/customer-story/_1/GatesNotes.png';
import CorNTD from '../../../img/media/customer-story/_1/CorNTD.png';
import ICMR_VCRC from '../../../img/media/customer-story/_1/ICMR-VCRC.png';
import SyngeneLogo from '../../../img/media/customer-story/_1/Syngene-logo.png';
import MSPPLogo from '../../../img/media/customer-story/_1/MSPP-logo.png';
import UniversitasIndonesia from '../../../img/media/customer-story/_1/Universitas-Indonesia.png';
import PNGIMR from '../../../img/media/customer-story/_1/PNGIMR_headweb.webp';
import MOHFiji from '../../../img/media/customer-story/_1/MOHFiji-LOGO.png';
import Merck_Co from '../../../img/media/customer-story/_1/Merck_&_Co.png';
import EisaiLogo from '../../../img/media/customer-story/_1/Eisai_logo.png';
import GSKLogo2022 from '../../../img/media/customer-story/_1/GSK_logo_2022.png';
import ImgWashU from '../../../img/media/customer-story/_1/WashU-logo.png';


// Page Imports
import _0 from "./customer-story_17/_0";
import { Block } from "@mui/icons-material";

const template = () => {
  return (
    <>
      <PageHero color="rgba(3, 169, 244, 0.1)">
        <_0 />
      </PageHero>
      <PageContentV2>
        <Grid item>
        <Typography variant="subtitle2" paragraph>
        Lymphatic Filariasis study in Papua New Guinea, Indonesia, India, Haiti, and Fiji: A Clinical trial with 26,836 participants in LMIC countries, with offline/online capture using mobile devices        </Typography>
        <Typography variant="body7" paragraph>
        Performance of large scale clinical trials with tens of thousands of participants located in remote communities in LMIC countries is a huge undertaking. Detailed protocols, IRB approvals, and cooperation with local and national health ministries are paramount. But, success requires skillful and dedicated teams on the ground who have local knowledge and the proper tools to efficiently perform the study. Here we provide a case study of a collaboration between CliniOps and a strong clinical research team.
        </Typography>
        <Typography variant="body7" paragraph>
            Investigators from Death to Onchocerciasis and Lymphatic Filariasis (DOLF)<sup>1</sup> project of Washington University School of Medicine, St. Louis, with financial support from one of the top Global Health Foundations, conducted a multicenter clinical trial (CT) to test the safety of double- and triple-drug community mass drug administration regiments for lymphatic filariasis (LF).<sup>2</sup> The open-label, cluster-randomized study involved 26,836 participants who were treated for LF with either a single oral dose of a triple drug “IDA” regimen (ivermectin, 200 μg/kg; diethylcarbamazine, 6 mg/kg; plus albendazole, a fixed dose of 400 mg) or with the reference two-drug combination DA (diethylcarbamazine plus albendazole). The multicenter study was conducted in five LMIC countries where LF is endemic, namely, Papua New Guinea, Indonesia, India, Haiti, and Fiji) between  October 2016 and November 2017.<sup>3</sup>
        </Typography>

        <Typography variant="body7" paragraph>
        This pioneering study was in support of the World Health Organization’s (WHO) Global Programme to Eliminate Lymphatic Filariasis (GPELF) to stop transmission of the infection by mass administration of anthelmintic drugs.<sup>4</sup>        </Typography>
        <Typography variant="body7" paragraph>
        Endemic in more than 50 LMIC countries, LF is a neglected tropical disease that is a candidate for global elimination. Mosquitos  transmit the small worm parasites that cause disease (commonly known as elephantiasis) in people.  Although the number of infections has been reduced by 74% since GPELF was initiated in 2000, there are still some 50 million infected people in the world, and more than 750 million people are at risk of acquiring the infection.<sup>5</sup>        </Typography>
        <Typography variant="body7" paragraph>
        To assure community involvement before the CT started, the study teams visited study sites to meet with local health officials, community leaders, and staff at local health centers. Study teams used smart tablets to enroll patients, record their intake of medication and record adverse events that sometimes occur after any treatment. Study teams went house to house or used temporary health stations to enroll and monitor participants. Study teams returned to a base-station that had internet connectivity and electricity each evening. Tablet devices were synchronized with the backend database at that time and data were uploaded to a cloud server. This provided near real-time access to quality data for the study monitors.        </Typography>
        <Typography variant="body7" paragraph>
        As the study’s key technology partner, CliniOps deployed it’s unified platform approach including electronic data capture (EDC), offline eSource or direct data capture (DDC), accessible through smart tablets used by site teams. Also included were remote source data verification (rSDV) and near real-time monitoring of the study data, for the clinical operations and data management teams to use.  Data collection was standardized and collected digitally at the point of care in very difficult regions with spotty internet access. Temporary satellite internet connections were required in some sites. Data quality was maintained at a very high level with validation checks and automated alerts in the eSource solution, which was fully functional in offline/online modes. Electronic case report forms (eCRFs) and the overall system complied with International Council for Harmonization on Good Clinical Practice (ICH GCP) and CDASH/CDISC standards. The eCRFs and data transfer systems were subjected to robust user acceptability testing prior to trial initiation. To counter irregular internet access due to remote location of certain sites, CliniOps’ application allowed offline data collection and later online synch when internet connections were available. This ensured the study team to be fully functional without requiring constant internet connectivity.         </Typography>
        <Typography variant="body7" paragraph>
        Results from the multicenter study showed that the more effective IDA treatment was as safe as DA, which had previously been the standard treatment regimen used for LF elimination in the study countries. Based in part on results from this study, the WHO formally endorsed IDA for use to eliminate LF in many parts of the world in November 2017. Since then, more than 150 million treatment doses of IDA have been distributed; this improved (and safe) treatment has the potential to accelerate LF elimination in many regions.<sup>6,7</sup> 
        </Typography>

          <blockquote
            style={{ borderLeft: `4px solid ${blueGrey[100]}`, marginLeft: -1 }}
          >
            <Stack
              spacing={4}
              sx={{ pl: 4 }}
              direction={{ xs: "column", md: "row" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minWidth: "220px",
                  width: "220px",
                  height: "250px",
                  borderRadius: 2,
                  backgroundImage: `url(${ImgGary})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "top center",
                }}
              />
              <Box>
            <Typography variant="body7" paragraph>
              <em>
              “This pioneering clinical trial showed that IDA is as safe as DA  as a mass drug administration regimen for elimination of Lymphatic Filariasis. The WHO’s formal review of our study and subsequent endorsement of IDA for use in it’s LF elimination program is a strong endorsement of our study’s contribution to the goal of global LF elimination. With many thousands of study participants, our investigators and site teams worked tirelessly to complete the project(s) in a professional and timely manner. Our study benefitted from the assistance of CliniOps, which provided a reliable, high-level data collection platform that met stringent regulatory requirements and included offline capability for data entry and management in remote areas. Local personnel in several of our sites were not tech savvy, but they learned to use the tablet-based data system quickly and benefitted from training and prompt technical support from CliniOps.”
              </em>
            </Typography>
            <Typography variant="overline" paragraph>
              <strong>
              Gary J. Weil, MD
              <br></br>
              Professor, Washington University School of Medicine              
              </strong>
            </Typography>
          </Box>
            </Stack>
          </blockquote>
          <Typography  paragraph>
          1. <a href= "http://www.dolf.wustl.edu/?p=3385" target={'_blank'} class="linkcolor">Washington University School of Medicine</a>  
          </Typography>
          <Typography paragraph>
          2. <a href= "https://dolfproject.wustl.edu/about/ida-for-lf-studies/" target={'_blank'} class="linkcolor">https://dolfproject.wustl.edu/about/ida-for-lf-studies/</a>  
          </Typography>
          <Typography paragraph>
          3. <a href= "https://journals.plos.org/plosmedicine/article?id=10.1371/journal.pmed.1002839" target={'_blank'} class="linkcolor">https://journals.plos.org/plosmedicine/article?id=10.1371/journal.pmed.1002839</a>  
          </Typography>
          <Typography paragraph>
          4. <a href= "https://www.who.int/teams/control-of-neglected-tropical-diseases/lymphatic-filariasis/global-programme-to-eliminate-lymphatic-filariasis" target={'_blank'} class="linkcolor">https://www.who.int/teams/control-of-neglected-tropical-diseases/lymphatic-filariasis/global-programme-to-eliminate-lymphatic-filariasis</a>  
          </Typography>
          <Typography paragraph>
          5. <a href= "https://www.who.int/publications/i/item/who-wer9741-513-524" target={'_blank'} class="linkcolor">https://www.who.int/publications/i/item/who-wer9741-513-524</a>  
          </Typography>
          <Typography variant="body7" paragraph>
          6. Gary J. Weil, et al. Lessons from Large Scale Tolerability and Acceptability Studies of Triple Drug Mass Drug Administration Performed to Support Policy Change and Accelerate Elimination of Lymphatic Filariasis. Am. J. Med. Hyg., 106 (Suppl 5), 2022, pp 13-17): <a href= "https://www.ajtmh.org/view/journals/tpmd/106/5_Suppl/article-p13.xml" target={'_blank'} class="linkcolor">https://www.ajtmh.org/view/journals/tpmd/106/5_Suppl/article-p13.xml</a>  
          </Typography>
          <Typography variant="body7" paragraph>
          7. Puroshothaman Jambulingan, et.al. Country Reports on Practical Aspects of Conducting Large-Scale Community Studies of the Tolerability of Mass Drug Administration with Ivermectin/Diethylcarbamazine/Albendazole for Lymphatic Filariasis. Am. J. Trop. Med Hyg., 106 (Suppl 5), 2022, pp 18-25: <a href= "https://www.ajtmh.org/view/journals/tpmd/106/5_Suppl/article-p13.xml" target={'_blank'} class="linkcolor">https://www.ajtmh.org/view/journals/tpmd/106/5_Suppl/article-p13.xml</a>  
          </Typography>
        
          <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
     <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
     <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '175px',
                width: '175px',
                height: '80px',
                borderRadius: 2,
                marginTop: '30px',
                backgroundImage: `url(${ASTMHLogo})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
          />
            <Box>
            <Typography paragraph>
            A publication titled,  “<Link href="https://www.ajtmh.org/view/journals/tpmd/106/5_Suppl/article-p13.xml" target={"_blank"} underline="hover">Lessons from Large-Scale Tolerability and Acceptability Studies of Triple Drug Mass Drug Administration Performed to Support Policy Change and Accelerate Elimination of Lymphatic Filariasis</Link>”, was published in The American Journal of Tropical Medicine and Hygiene, on May 15, 2022.
            </Typography>
            </Box>
        </Stack>
        </Alert>
          <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
     <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
     <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '175px',
                width: '175px',
                height: '80px',
                borderRadius: 2,
                marginTop: '30px',
                backgroundImage: `url(${ASTMHLogo})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
          />
            <Box>
            <Typography paragraph>
            A publication titled,  “<Link href="https://www.ajtmh.org/view/journals/tpmd/106/5_Suppl/article-p18.xml" target={"_blank"} underline="hover">Country Reports on Practical Aspects of Conducting Large-Scale Community Studies of the Tolerability of Mass Drug Administration with Ivermectin/Diethylcarbamazine/Albendazole for Lymphatic Filariasis</Link>”, was published in The American Journal of Tropical Medicine and Hygiene, on March 15, 2022.
            </Typography>
            </Box>
        </Stack>
        </Alert>

        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '175px',
                width: '175px',
                height: '80px',
                borderRadius: 2,
                marginTop: '30px',
                backgroundImage: `url(${ImgPlosNTD})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
            />
            <Box>
            <Typography paragraph>
            A publication titled, “<Link href="https://journals.plos.org/plosntds/article?id=10.1371/journal.pntd.0010096" target={"_blank"} underline="hover">Safety and efficacy of mass drug administration with a single-dose triple-drug regimen of albendazole + diethylcarbamazine + ivermectin for lymphatic filariasis in Papua New Guinea: An open-label, cluster-randomised trial</Link>”, was published in the PLOS Neglected Tropical Diseases Journal, on February 9, 2022.
            </Typography>
            </Box>
        </Stack>
        </Alert>
        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '175px',
                width: '175px',
                height: '80px',
                borderRadius: 2,
                marginTop: '30px',
                backgroundImage: `url(${ImgPlosNTD})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
            />
            <Box>
            <Typography paragraph>
            A publication titled, “<Link href="https://journals.plos.org/plosntds/article?id=10.1371/journal.pntd.0009294" target={"_blank"} underline="hover">An open label, randomized clinical trial to compare the tolerability and efficacy of ivermectin plus diethylcarbamazine and albendazole vs. diethylcarbamazine plus albendazole for treatment of brugian filariasis in Indonesia</Link>”, was published in the PLOS Neglected Tropical Diseases Journal, on March 29, 2021.
            </Typography>
            </Box>
        </Stack>
        </Alert>
        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '175px',
                width: '175px',
                height: '80px',
                borderRadius: 2,
                marginTop: '30px',
                backgroundImage: `url(${ImgPlosNTD})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
            />
            <Box>
            <Typography paragraph>
            A publication titled, “<Link href="https://journals.plos.org/plosntds/article?id=10.1371/journal.pntd.0009069" target={"_blank"} underline="hover">An open label, block randomized, community study of the safety and efficacy of co-administered ivermectin, diethylcarbamazine plus albendazole vs. diethylcarbamazine plus albendazole for lymphatic filariasis in India</Link>”, was published in the PLOS Neglected Tropical Diseases Journal, on February 16, 2021.
            </Typography>
            </Box>
        </Stack>
        </Alert>
        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '175px',
                width: '175px',
                height: '80px',
                borderRadius: 2,
                marginTop: '30px',
                backgroundImage: `url(${RSTMH_logo})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
            />
            <Box>
            <Typography paragraph>
            A publication titled, “<Link href="https://academic.oup.com/inthealth/article/13/Supplement_1/S60/6043669" target={"_blank"} underline="hover">A triple-drug treatment regimen to accelerate elimination of lymphatic filariasis: From conception to delivery</Link>”, was published in The Royal Society of Tropical Medicine and Hygiene, on December 22, 2020.
            </Typography>
            </Box>
        </Stack>
        </Alert>
        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '175px',
                width: '175px',
                height: '80px',
                borderRadius: 2,
                marginTop: '30px',
                backgroundImage: `url(${ImgPlosNTD})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
            />
            <Box>
            <Typography paragraph>
            A publication titled, “<Link href="https://journals.plos.org/plosntds/article?id=10.1371/journal.pntd.0008106" target={"_blank"} underline="hover">The safety of combined triple drug therapy with ivermectin, diethylcarbamazine and albendazole in the neglected tropical diseases co-endemic setting of Fiji: A cluster randomised trial</Link>”, was published in the PLOS Neglected Tropical Diseases Journal, on March 16, 2020.
            </Typography>
            </Box>
        </Stack>
        </Alert>
        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '175px',
                width: '175px',
                height: '80px',
                borderRadius: 2,
                marginTop: '30px',
                backgroundImage: `url(${ImgPlosNTD})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
            />
            <Box>
            <Typography paragraph>
            A publication titled, “<Link href="https://journals.plos.org/plosntds/article?id=10.1371/journal.pntd.0007541" target={"_blank"} underline="hover">Dosing pole recommendations for lymphatic filariasis elimination: A height-weight quantile regression modeling approach</Link>”, was published in the PLOS Neglected Tropical Diseases Journal, on July 17, 2019.
            </Typography>
            </Box>
        </Stack>
        </Alert>
        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '175px',
                width: '175px',
                height: '80px',
                borderRadius: 2,
                marginTop: '30px',
                backgroundImage: `url(${PLOSMedicine})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
            />
            <Box>
            <Typography paragraph>
            A publication titled, “<Link href="https://journals.plos.org/plosmedicine/article?id=10.1371/journal.pmed.1002839" target={"_blank"} underline="hover">The safety of double- and triple-drug community mass drug administration for lymphatic filariasis: A multicenter, open-label, cluster-randomized study</Link>”, was published in the PLOS Medicine, on June 24, 2019.
            </Typography>
            </Box>
        </Stack>
        </Alert>
        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '175px',
                width: '175px',
                height: '80px',
                borderRadius: 2,
                marginTop: '30px',
                backgroundImage: `url(${AfricacomLogo})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
            />
            <Box>
            <Typography paragraph>
            An article titled, “<Link href="https://www.africa.com/how-kenyas-fight-against-elephantiasis-is-becoming-a-blueprint-for-africa-and-the-world/" target={"_blank"} underline="hover">How Kenya’s Fight Against Elephantiasis is Becoming a Blueprint for Africa and the World</Link>”, was published in the PLOS Neglected Tropical Diseases Journal, on April 4, 2019.
            </Typography>
            </Box>
        </Stack>
        </Alert>

        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Box
                  xs="auto"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minWidth: '175px',
                    width: '175px',
                    height: '80px',
                    borderRadius: 2,
                    marginTop: '30px',
                    backgroundImage: `url(${ASTMHLogo})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top center'
                }}
              />
                <Box>
                <Typography paragraph>
                The study was published at the “<Link href="https://www.astmh.org/ASTMH/media/2017-Annual-Meeting/ASTMH-2017-Abstract-Book.pdf" target={"_blank"} underline="hover">American Society of Tropical Medicine and Hygiene (ASTMH)</Link>”, Annual Conference, on November 07, 2017.
                </Typography>
                </Box>
            </Stack>
            </Alert>
        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Box
                  xs="auto"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minWidth: '175px',
                    width: '175px',
                    height: '80px',
                    borderRadius: 2,
                    marginTop: '30px',
                    backgroundImage: `url(${GatesNotes})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top center'
                }}
              />
                <Box>
                <Typography paragraph>
                A blog post by Bill Gates titled, “<Link href="https://www.gatesnotes.com/Mass-Drug-Administration-in-Tanzania" target={"_blank"} underline="hover">A massive success, How to stop disease by treating everyone—even healthy people</Link>”, regarding the mass drug administration campaign to combat lymphatic filariasis, and treat the entire at-risk population to break the cycle of transmission, was published on November 06, 2017.
                </Typography>
                </Box>
            </Stack>
            </Alert>
        
        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Box
                  xs="auto"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minWidth: '175px',
                    width: '175px',
                    height: '80px',
                    borderRadius: 2,
                    marginTop: '30px',
                    backgroundImage: `url(${WHOLogo})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top center'
                }}
              />
                <Box>
                <Typography paragraph>
                WHO published a report on “<Link href="https://www.who.int/publications/i/item/9789241511957" target={"_blank"} underline="hover">Validation of elimination of lymphatic filariasis as a public health problem</Link>”, on 3 January 2017.
                </Typography>
                </Box>
            </Stack>
            </Alert>
        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Stack display={"block"}>
        <Box
                  xs="auto"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minWidth: '175px',
                    width: '175px',
                    height: '80px',
                    borderRadius: 2,
                    marginTop: '30px',
                    backgroundImage: `url(${CorNTD})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top center'
                }}
              />
        <Box
                  xs="auto"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minWidth: '175px',
                    width: '175px',
                    height: '80px',
                    borderRadius: 2,
                    marginTop: '30px',
                    backgroundImage: `url(${ImgWashU})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top center'
                }}
              />
                </Stack>
                <Box>
                <Typography paragraph>
                A blogpost titled, “<Link href="https://dolfproject.wustl.edu/dolf-shows-off-innovative-electronic-data-capture-tool-for-clinical-trials-at-cor-ntd-meeting-november-10-2016/" target={"_blank"} underline="hover">DOLF Shows off Innovative Electronic Data Capture Tool for Clinical Trials at COR-NTD Meeting</Link>”, was published Washington University School of Medicine website, on November 10, 2016.
                </Typography>
                </Box>
            </Stack>
            </Alert>
        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Stack display={"block"}>
        <Box
                  xs="auto"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minWidth: '175px',
                    width: '175px',
                    height: '80px',
                    borderRadius: 2,
                    marginTop: '30px',
                    backgroundImage: `url(${ICMR_VCRC})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top center'
                }}
              />
        <Box
                  xs="auto"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minWidth: '175px',
                    width: '175px',
                    height: '80px',
                    borderRadius: 2,
                    marginTop: '30px',
                    backgroundImage: `url(${SyngeneLogo})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top center'
                }}
              />
        <Box
                  xs="auto"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minWidth: '175px',
                    width: '175px',
                    height: '80px',
                    borderRadius: 2,
                    marginTop: '30px',
                    backgroundImage: `url(${MSPPLogo})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top center'
                }}
              />
        <Box
                  xs="auto"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minWidth: '175px',
                    width: '175px',
                    height: '80px',
                    borderRadius: 2,
                    marginTop: '30px',
                    backgroundImage: `url(${UniversitasIndonesia})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top center'
                }}
              />
        <Box
                  xs="auto"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minWidth: '175px',
                    width: '175px',
                    height: '80px',
                    borderRadius: 2,
                    marginTop: '30px',
                    backgroundImage: `url(${PNGIMR})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top center'
                }}
              />
        <Box
                  xs="auto"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minWidth: '175px',
                    width: '175px',
                    height: '80px',
                    borderRadius: 2,
                    marginTop: '30px',
                    backgroundImage: `url(${MOHFiji})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top center'
                }}
              />
                </Stack>
                <Box>
                <Typography paragraph>
                The study was conducted in collaboration with <Link href="https://main.icmr.nic.in/institutes/icmr-vcrc-puducherry" target={"_blank"} underline="hover">ICMR-VCRC</Link> and <Link href="https://www.syngeneintl.com/" target={"_blank"} underline="hover"> Syngene</Link>, India, <Link href="https://www.mspp.gouv.ht/" target={"_blank"} underline="hover">Ministre de la Santé Publique et de la Population</Link>, Haiti, 
                <Link href="https://www.ui.ac.id/" target={"_blank"} underline="hover"> Universitas Indonesia</Link>, Indonesia, <Link href="https://www.pngimr.org.pg/" target={"_blank"} underline="hover"> PNG Institute of Medical Research</Link>, Papua New Guinea, 
                <Link href="https://www.health.gov.fj/" target={"_blank"} underline="hover"> Ministry of Health & Medical Services</Link>, Fiji.
                </Typography>
                </Box>
            </Stack>
            </Alert>
        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Stack display={"block"}>
        <Box
                  xs="auto"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minWidth: '175px',
                    width: '175px',
                    height: '80px',
                    borderRadius: 2,
                    marginTop: '30px',
                    backgroundImage: `url(${Merck_Co})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top center'
                }}
              />
        <Box
                  xs="auto"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minWidth: '175px',
                    width: '175px',
                    height: '80px',
                    borderRadius: 2,
                    marginTop: '30px',
                    backgroundImage: `url(${EisaiLogo})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top center'
                }}
              />
        <Box
                  xs="auto"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minWidth: '175px',
                    width: '175px',
                    height: '80px',
                    borderRadius: 2,
                    marginTop: '30px',
                    backgroundImage: `url(${GSKLogo2022})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top center'
                }}
              />
                </Stack>
                <Box>
                <Typography paragraph>
                All three drugs ivermectin, diethylcarbamazine, and albendazole, are donated by pharmaceutical companies (<Link href="https://www.merck.com/" target={"_blank"} underline="hover">Merck</Link>, <Link href="https://www.eisai.com/" target={"_blank"} underline="hover">Eisai</Link> and  
                <Link href="https://www.gsk.com/" target={"_blank"} underline="hover"> GlaxoSmithKline</Link> respectively) through the World Health Organisation (WHO) led global drug donation program.
                </Typography>
                </Box>
            </Stack>
            </Alert>
        
        </Grid>
      </PageContentV2>
    </>
  );
};

export default template;

/*

<Link href="" target={"_blank"} underline="hover"></Link>

*/