import React from 'react';
import { Box, Grid, Typography, Container } from '@mui/material';

import ImgCliniOpsInsights from '../../../../img/product/insights/cliniops_insights_logo.png';

const template = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="row"
      sx={{ py: 4 }}
    >
    <Container maxWidth='xl'>
    <Grid 
        container
        direction={{ xs: 'column', md: 'row' }} 
        spacing={{ xs: 4, md: 12 }}
        alignItems={{ xs: 'center', md: 'flex-start' }}
        justifyContent={{ xs: 'center', md: 'flex-start' }}
    >
    <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-start' } }}>
      <img 
        src={ImgCliniOpsInsights} 
        className="co-image-product"
        alt="CliniOps Conduct" 
        style={{ width: '100%', maxWidth: '250px' }} 
      />
      </Grid>
      <Grid item xs={12} md={9}>
      <Typography variant="h7" sx={{ maxWidth: '1000px',lineHeight:"40px" ,textAlign: { xs: 'center', md: 'left' }, py:1 }}>
       This powerful analytics and reporting module integrated into the CliniOps 
        product suite enables clinical trial teams to access real-time, data-driven 
        insights, empower faster decision-making and enhance operational 
        efficiency, resulting in improved patient outcomes.
        <br></br>
        <br></br>
      </Typography>
        <Typography variant='body5' sx={{lineHeight:"28px"}}>
        Insights is a powerful analytics and reporting module integrated into the CliniOps product suite. It enables clinical trial teams to access real-time, data-driven insights, empowering faster decision-making, enhanced operational efficiency, and better patient outcomes. This feature allows you to generate on-demand or scheduled reports tailored to specific needs, from operational to clinical outcomes, safety data, or recruitment progress. Reports are automatically updated as new data is entered. Through robust biostatistical tools, interactive dashboards, and predictive analytics, CliniOps Insights transforms raw clinical data into actionable intelligence, supporting the entire lifecycle of a clinical trial, from planning to regulatory submission.  
        </Typography>
      </Grid>
      </Grid>
      </Container>
    </Box>
  );
};

export default template;
