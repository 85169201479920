import React from 'react';
import { Grid, Typography, Stack } from '@mui/material';

import { PageSectionRightImg } from '../../../component/page';
import { Product } from '../../../component/page';


import ImgEPro from '../../../../img/product/connect/epro.png';
import ImgParticipantArt from '../../../../img/product/connect/Participant_Artfct.png';
import CustomTypography from '../../../component/customTypography';

const template = () => {
  return (
    <>
    <Grid container direction='column' spacing={0} sx={{ mt: 0 }}>
          {/* Participant Artifacts  */}
        <Grid item>
            <Product
              ImgSrc={ImgParticipantArt}
              Name="Participant Artifacts"
              Summary={
                <>
                <CustomTypography variant='body2' sx={{ mt: 1}}>
                An important aspect of decentralized trials is to keep the patient constantly engaged with the study team. 
                This will ensure that the patient doesn’t feel disconnected, gradually gains confidence, and gets comfortable with 
                the remote model. 
                <br></br><br></br>
                The CliniOps platform keeps the patient constantly aware of study-related details throughout the process. 
                The Patient App supports study documents, videos, newsletters, and other related information that the study can 
                share as it progresses.
              </CustomTypography>
              </>
              }
            />
            
        </Grid>
    </Grid>
    </>
  );
}

export default template;