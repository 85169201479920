import React from "react";
import { Stack, TextField, Button, Box, Grid, Typography } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ConvertToForm from "./../../../../js/convertFormElementsToJSON";
import { DEMO_FORM_SUBMIT_URL } from "./../../../../js/consts";
import submitData from "./../../../../js/submitData";

const textFieldBaseStyles = {
  "& .MuiOutlinedInput-root": {
    backgroundColor: "#FFFFFF",
    "@media (max-width:900px)": {
      "& .MuiInputBase-input": {
        fontSize: "14px",
        lineHeight: 1.5,         // ensure enough line height
      },
    },
    // boxShadow: "0 3px 3px rgba(0, 0, 0, 0.16)", // from 2nd image
    // "& fieldset": {
    //   border: "1px solid #1E75C6",
    // },
    // "&:hover fieldset": {
    //   borderColor: "#1E75C6",
    // },
    // "&.Mui-focused fieldset": {
    //   borderColor: "#1E75C6",
    // },
  },
};

export const typographyBaseStyles = {
  "&.MuiTypography-root": {
    marginBottom:"5px",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#000000",
  },
};

const template = () => {
  const [openSnackbar, setOpenSnackBar] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("");

  const handleSnackbarClose = (reason) => {
    setOpenSnackBar(false);
  };

  const actionSnackbar = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );
  const [disabled, setDisabled] = React.useState(false);
  async function handleSubmit(e) {
    setDisabled(true);
    e.preventDefault();
    const formData = ConvertToForm(e.target.elements, [
      "fullName",
      "email",
      "phone",
      "organization",
      "cityCountry",
      "message",
    ]);
    const data = await submitData({
      formData: {
        data: formData,
        subject: "Request A Demo",
      },
      url: DEMO_FORM_SUBMIT_URL,
    });
    if (data?.message === "Mail send") {
      setOpenSnackBar(true);
      setMessage("Your application has been submitted.");
      setSeverity("success");
      e.target.reset();
    } else {
      setOpenSnackBar(true);
      setMessage("Form submission failed");
      setSeverity("error");
    }
    setDisabled(false);
  }
  return (
    <>
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={message}
        action={actionSnackbar}
        sx={{ mt: "70px", boxShadow: 8 }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={severity}
          variant="filled"
        >
          {message}
        </Alert>
      </Snackbar>
      <Box
        sx={{
          maxWidth: "1000px",
          width: "90%", // responsive width
          backgroundColor: "#FFFFFF",
          border: "1px solid #FFFFFF",
          boxShadow: "0 3px 3px rgba(0, 0, 0, 0.16)",
          borderRadius: "8px",
          py: { xs: 3, md: 5 },
          px: { xs: 3, md: 10 },
          margin: "auto",
          position:"relative",
          zIndex:5
        }}
      >
        <form onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Typography variant="h8" sx={{textAlign: "center",textShadow: "0 3px 3px rgba(0, 0, 0, 0.16)", }}>
              Request Demo
            </Typography>
            <Stack>
              <Typography sx={typographyBaseStyles}>Full Name</Typography>
              <TextField
                sx={textFieldBaseStyles}
                size="small"
                // placeholder="e.g. John Doe"
                name="fullName"
                variant="outlined"
                fullWidth
                required
              />
            </Stack>
            <Stack>
              <Typography sx={typographyBaseStyles}>Organization</Typography>
              <TextField
                sx={textFieldBaseStyles}
                size="small"
                name="organization"
                variant="outlined"
                fullWidth
                required
              />
            </Stack>
            <Stack>
              <Typography sx={typographyBaseStyles}>Email</Typography>
              <TextField
                sx={textFieldBaseStyles}
                size="small"
                // placeholder="e.g. john.doe@example.com"
                name="email"
                type="email"
                variant="outlined"
                fullWidth
                required
              />
            </Stack>
            <Stack>
              <Typography sx={typographyBaseStyles}>Phone</Typography>
              <TextField
                sx={textFieldBaseStyles}
                size="small"
                // placeholder="e.g. +1 123 456 7890"
                name="phone"
                variant="outlined"
                fullWidth
                required
              />
            </Stack>
            <Stack>
              <Typography sx={typographyBaseStyles}>
                City &amp; Country
              </Typography>
              <TextField
                sx={textFieldBaseStyles}
                size="small"
                name="cityCountry"
                variant="outlined"
                fullWidth
                required
              />
            </Stack>
            <Stack>
              <Typography sx={typographyBaseStyles}>Message</Typography>
              <TextField
                sx={textFieldBaseStyles}
                size="small"
                name="message"
                variant="outlined"
                fullWidth
                required
                multiline
                rows={4}
              />
            </Stack>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              justifyContent="center"
              mt={2}
            >
              <Button
                size="large"
                variant="contained"
                sx={{
                  backgroundColor: "#8296A1",
                  color: "#FFFFFF",
                  fontWeight: 400,
                  fontSize: "14px",
                  borderRadius: "8px",
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "#8296A1",
                  },
                  width: { xs: "100%", sm: "auto" },
                }}
              >
                Cancel
              </Button>

              <Button
                id="submitButton"
                size="large"
                variant="contained"
                type="submit"
                disabled={disabled}
                sx={{
                  backgroundColor: "#042D43",
                  color: "#FFFFFF",
                  fontWeight: 400,
                  fontSize: "14px",
                  borderRadius: "8px",
                  textTransform: "capitalize",
                  whiteSpace: "nowrap",
                  "&:hover": {
                    backgroundColor: "#042D43",
                  },
                  width: { xs: "100%", sm: "auto" },
                }}
              >
                Send Message
              </Button>
            </Stack>
          </Stack>
        </form>
      </Box>
    </>
  );
};

export default template;
