import React from 'react';
import { Grid,Box } from '@mui/material';
import Banner from '../../../img/login/loginV2.png';


const template = () => {
  return (
    <>
    <Box>
    <Box sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: {xs:"70px 0 0 0",md:"90px 0 0 0"},
      overflow: "hidden",
      zIndex:1

      }}>
  <img
    src={`${Banner}`}
    alt=""
    style={{ 
      width: "100%", 
      maxHeight: "100%", 
      objectFit: "contain" }}
  />
  </Box>
  <Box sx={{
          position: 'absolute',
          top: { xs: '70px', md: '100px' },
          left: { xs: '30px', md: '70px' },
          right: 0,
          bottom: 0,
          display: 'flex',
          color: '#FFFFFF',
          textAlign: 'topleft',
          fontSize: { xs: '20px', sm: '40px', md: '60px' }, // Responsive font size
          fontWeight: 300,
          textShadow: '3px 3px 3px rgba(0, 0, 0, 0.16)'
          // fontFamily: 'Segoe UI',
        }}>
        Login
  </Box>
  </Box>

    </>
  );
}

export default template;