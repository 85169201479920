import React from 'react';
import { Grid, Typography, Stack } from '@mui/material';

// import { PageSectionLeftImg } from '../../../component/page';
import { Conduct } from '../../../component/page';

// import ImgIRT from '../../../../img/product/conduct/eirtcro.png';
import ImgCoder from '../../../../img/product/conduct/Coder.png';
import CustomTypography from '../../../component/customTypography';

const template = () => {
  return (
    <>
      {/* <PageSectionLeftImg
        heading={
          <Stack direction="row" spacing={1} alignItems='center' justifyContent={{xs: 'center', md: 'flex-start'}}>
            <Typography variant="h2" component="h2" className="co-page-heading" sx={{ textAlign: { xs: 'center', md: 'left'} }}>
              IRT
            </Typography>
          </Stack>
        }
        imgUrl={ImgIRT}
        imgTitle="IRT"
      >
        <Typography sx={{ mt: 1 }}>
          CliniOps IRT module (Interactive Response Technology) leverages interactive web response systems (IWRS) to support patient randomization for blinded studies. CROs can use this module to monitor the IP inventory at site on a real-time basis and take decisions accordingly.
        </Typography>
      </PageSectionLeftImg> */}
      
      <Grid container direction='column' spacing={0} sx={{ mt: 0 }}>
            {/* Coder  */}
          <Grid item>
              <Conduct
                ImgSrc={ImgCoder}
                Name="Coder"
                className="co-image-conduct"
                Summary={
                  <>
                  <CustomTypography variant='body2' sx={{ mt: 1 }}>
                    The CliniOps Coder module possesses auto-coding functionality with speed and accuracy to support coding for any clinical trial, 
                    including customizable workflows for manual coding when needed. Verbatim terms are mapped to standard codes based on the 
                    World Health Organization Drug Dictionaries (WHODrug) and the Medical Dictionary for Regulatory Affairs (MedDRA). <br></br>
                    Coded values are stored directly within the CliniOps EDC database.
                </CustomTypography>
                </>
                }
              />
              
          </Grid>
      </Grid>
    </>
  );
}

export default template;