import React from 'react';
import { Grid, Divider, Box } from '@mui/material';
import { teal } from '@mui/material/colors';

import { PageHero, PageContent } from '../../component/page';

// Page Imports
import _0 from './quote/_0';
import _1 from './quote/_1';
import _2 from './quote/_2';
import _3 from './quote/_3';

const template = () => {
  return (
    <>
      {/* <PageHero color={teal}> */}
        <_0 />
      {/* </PageHero> */}
      <Box sx={{py:5,backgroundColor: "#F2F2F2"}}>
        <Grid item>
          <_3/>
          <_2/>
          <_1 />
        </Grid>
      </Box>
    </>
  );
}

export default template;