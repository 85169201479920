import React from 'react';
import { Grid, Typography, Stack } from '@mui/material';

import { PageSectionRightImg } from '../../../component/page';
import { Product } from '../../../component/page';

import ImgDRR from "../../../../img/product/insights/data_review_report.png";
import CustomTypography from '../../../component/customTypography';

const template = () => {
  return (
    <>
    <Grid container direction='column' spacing={0} sx={{ mt: 0 }}>
          {/* eConsent (Remote)  */}
        <Grid item>
            <Product
              ImgSrc={ImgDRR}
              Name="Data Review Reporting"
              Summary={
                <>
                <CustomTypography variant='body2' sx={{ mt: 1}}>
                Data review reporting in clinical trials ensures data accuracy, completeness, and integrity throughout the study. 
                These reports systematically evaluate collected data, identifying discrepancies, outliers, or missing information that 
                may impact the validity of the trial. 
                <br></br><br></br>
                Regular data reviews help maintain the trial's quality by allowing for early detection of issues and prompt 
                corrective actions. Customizable, user-friendly dashboards allow clinical teams to monitor key metrics, patient data, 
                and trial performance indicators in real-time. Automated, context-driven alerts notify stakeholders about critical 
                events such as adverse events, enrollment progress, or protocol deviations, ensuring immediate corrective actions.
              </CustomTypography>
              </>
              }
            />
            
        </Grid>
    </Grid>
    </>
  );
}

export default template;