import React from 'react';
import { Typography, Button, Box } from '@mui/material';
import { HashLink as Link } from 'react-router-hash-link';
import RouterList from "../../router";

function RequestQuoteButton() {
  return (
    <Box 
      display="flex" 
      alignItems="center" 
      justifyContent="space-between" 
      flexWrap='wrap'
      gap='5px'
      sx={{ p: 2 }}
    >
      <Typography variant="body2" sx={{ marginRight: 2 }}>
        Interested in implementing CliniOps for your Clinical Trials?
      </Typography>
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to={RouterList.contact.quote} 
      >
        REQUEST QUOTE
      </Button>
    </Box>
  );
}

export default RequestQuoteButton;
