import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

// import ImgBg from '../../../../img/service/clinical-data-management/CDS.png';
// import ImgBg from "../../../../img/home/wave2-1.png";
import ImgTopWave from "../../../../img/home/wave1.png";
import ImgBottomWave from "../../../../img/home/wave2.png";
import ImgHandLaptop from "../../../../img/service/clinical-data-management/handlaptop.png";

const Template = () => {
  const theme = useTheme();
  const isTab = useMediaQuery(theme.breakpoints.between(900,1235))
  return (
    <>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          // backgroundColor: "#F2F2F2",
          overflow: "visible",
          margin: {xs:"50px 0 50px 0",md:"50px 0 100px 0",}
          // textAlign: "center",
        }}
      >
        {/* Top Wave Background */}
        <Box
          component="img"
          src={ImgTopWave}
          alt="Top Wave"
          sx={{
            position: "absolute",
            top: 0,
            width: "100%",
            height: "auto",
            zIndex: 1,
            objectFit: "contain",
          }}
        />

        {/* Bottom Wave Background */}
        <Box
          component="img"
          src={ImgBottomWave}
          alt="Bottom Wave"
          sx={{
            position: "absolute",
            bottom: {xs:-40,md:-100},
            width: "100%",
            height: "auto",
            zIndex: 3,
            objectFit: "contain",
          }}
        />

        {/* Center Content */}
        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            src={ImgHandLaptop}
            alt=""
            sx={{
              width: {xs:"80%",md:"500px"},
              maxWidth:"100%",
              height: "auto",
              objectFit: "contain",
              zIndex: 2,
            }}
          />

          <Box
            sx={{
              maxWidth: "100%",
              backgroundColor: "#F2F2F2",
              minHeight: "500px",
              // minHeight:{xs:'300px',md:'600px'},
              zIndex: 1,
              padding: isTab ? "20px 0 100px 0" : {xs:"20px 0 100px 0",md:'20px'},
              // marginBottom: "-50px"
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
                margin: "auto",
                padding: { xs: "20px", md: "10px 0" },
              }}
            >
              <Typography
              variant='h3'
                sx={{
                  lineHeight: "24px",
                }}
              >
                 CliniOps offers comprehensive services as a Technology and Data Partner. CliniOps offers a partnership built on an Unified platform geared towards addressing needs of biopharma industry from concept to commercialization. Promotes heightened efficiency to enable the realization of having one secure, flexible, and affordable holistic solution for effective clinical trial management.
                 <br></br><br></br>
                 Our services enhances collaborative partnerships with every stakeholder through a platform that seamlessly integrates existing systems via a proprietary patented technology that is easily accessible offline or online. Delivers centralized and decentralized clinical trial methodologies founded on innovative data science for continuity of studies even during times of uncertainty. Equips the industry with an agile platform, capable of data collection at point of care, for real time and accurate evidence generation and to support study investigator’s ability to make informed decisions.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Template;



// import React from 'react';
// import { Box, Typography, Button, Stack } from '@mui/material';


// const template = () => {
//     return (
//       <>
//         <Box 
//         sx={{ 
//             display:'flex', 
//             textAlign: {xs: "center", md: "left"}, 
//             alignItems: "center", 
//             justifyContent: 'center', 
//             // marginBottom:3, 
//             marginTop:3}}>
//           <Typography variant="body1" sx={{ color: "#003B5C", mx:{xs:2, md: "", lg: 15}}}>
//           CliniOps offers comprehensive clinical data management, biostatistics and medical writing services. Our data management team handles creation of Data Management Plan (DMP) query management, data review, cleaning and analysis, ensuring compliance with regulatory standards and study protocols. Client is also in complete control with continuous access to data with constant support from CliniOps data managers. 
//           <br></br><br></br>
//           Our experienced and knowledgeable data science team offer high-quality, cost-effective, best in class biostatistics services. We have rich experience in biostatistics analysis & reporting. Our biostatistics services offer you accurate data analysis and the ability to comply with regulatory requirements. Our services include Randomization, Statistical Analysis Plan (SAP), annotation of CRFs, Statistical Programming, Tables, Listings and Figures (TLFs), ADaM/SDTM and Define.xml generation. Our deep regulatory knowledge & domain expertise in biostatistics services helps you to navigate the complexities of study design, calculation of sample sizes, study randomization, Data Visualization etc.
//           </Typography>
//         </Box>
//       </>
//     );
//   }
  
//   export default template;