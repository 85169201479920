import React, { useState } from "react";

import {
  Box,
  Container,
  Grid,
  Typography,
  Chip,
  Stack,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
  Divider,
  MenuItem,
  SvgIcon
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import locationIcon from "../../img/about/careers/job_location_img.png";
import { blueGrey } from "@mui/material/colors";
import ImgBottomWave from "../../img/home/wave2.png";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ConvertToForm from "./../../js/convertFormElementsToJSON";
import { RESUME_FORM_SUBMIT_URL } from "./../../js/consts";
import { SubmitAttachFormData } from "./../../js/submitData";
import ImgPDF from "../../img/about/careers/pdf_img.png";
import ImgChevronDown from "../../img/about/careers/chevron-thin-down.png";
import RouterList from "../router";
import { HashLink as RouterLink } from "react-router-hash-link";

// Common boxShadow + border
// const textFieldBaseStyles = {
//   "& .MuiOutlinedInput-root": {
//     boxShadow: "0 3px 3px rgba(0, 0, 0, 0.16)",
//     "& fieldset": {
//       border: "1px solid #1E75C6",
//     },
//     "&:hover fieldset": {
//       borderColor: "#1E75C6",
//     },
//     "&.Mui-focused fieldset": {
//       borderColor: "#1E75C6",
//     },
//   },
// };

const locationFieldStyles = {
  // ...textFieldBaseStyles, // get the same boxShadow/border as others
  width: { xs: "100%", md: "60%" }, // half width on md screens
  "& .MuiSelect-icon": {
     fontSize:"40px",
     color:"#1E75C6"
  },
};

const bodyTextStyles = {
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#000000",
};

function ThinExpandLessIcon(props){
  return (
    <SvgIcon {...props}>
      {/* Custom path with thinner lines */}
      <path
        d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"
        stroke="currentColor"
        strokeWidth="0.1" 
        fill="currentColor"     
      />
    </SvgIcon>
  );
}

const JobListingPage = (props) => {
  const [color] = useState(props.color);
  const [title] = useState(props.title);
  const [locations] = useState(props.location || []);
  const [selectedLocation, setSelectedLocation] = useState(locations[0] || "");
  const [summary] = useState(props.summary);
  const [disabled, setDisabled] = React.useState(false);
  const [resumeFile, setResumeFile] = useState(null);
  const [coverLetterFile, setCoverLetterFile] = useState(null);
  const [open, setOpen] = React.useState(false);
  const formRef = React.useRef(null);
  const formRefSubmit = React.useRef(null);
  const [openSnackbar, setOpenSnackBar] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("");

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setResumeFile(e.target.files[0]);
    } else {
      setResumeFile(null);
    }
  };

  const handleCoverLetterChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setCoverLetterFile(e.target.files[0]);
    } else {
      setCoverLetterFile(null);
    }
  };

  const handleSnackbarClose = (reason) => {
    setOpenSnackBar(false);
  };

  const actionSnackbar = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  const handleApplyJob = () => {
    setOpen(true);
  };
  const handleDialogClose = () => {
    formRefSubmit.current.click();
  };

  const handleDialogCancel = () => {
    setOpen(false);
  };

  async function handleSubmit(e) {
    setDisabled(true);
    e.preventDefault();
    const formData = ConvertToForm(formRef.current.elements, [
      "title",
      "firstName",
      "lastName",
      "email",
      "location",
    ]);

    const dataArray = new FormData();

    Object.keys(formData).forEach((item) => {
      dataArray.append(item, formData[item]);
    });
    // dataArray.append("resume", formRef.current.elements.resume.files[0]);
    // dataArray.append("subject", "Resume");

    // Append the resume file (if selected)
    const resumeInput = formRef.current.elements.resume;
    if (resumeInput && resumeInput.files && resumeInput.files[0]) {
      dataArray.append("resume", resumeInput.files[0]);
    }
    // Append the cover letter file (if selected)
    const coverLetterInput = formRef.current.elements.coverLetter;
    if (
      coverLetterInput &&
      coverLetterInput.files &&
      coverLetterInput.files[0]
    ) {
      dataArray.append("coverLetter", coverLetterInput.files[0]);
    }

    dataArray.append("subject", "Resume");

    const data = await SubmitAttachFormData({
      formData: dataArray,
      url: RESUME_FORM_SUBMIT_URL,
    });
    // if (data?.message === "Mail send") {
    //   setOpenSnackBar(true);
    // } else {
    //   alert("Form submission failed");
    // }

    if (data?.message === "Mail send") {
      setMessage(
        "Thanks for contacting us! We will be in touch with you shortly."
      );
      setSeverity("success");
      setOpenSnackBar(true);
    } else {
      setMessage("Form submission failed");
      setSeverity("error");
      setOpenSnackBar(true);
    }
    setDisabled(false);
    setOpen(false);
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
        PaperProps={{
          sx: {
            // Hide the scrollbar in both Firefox & Chrome
            "&::-webkit-scrollbar": { display: "none" },
            scrollbarWidth: "none",
            p: {xs:1,md:4},
            border: "1px solid #CED2D4",
            borderRadius: "21px",
            boxShadow: "20px 20px 10px rgba(0,0,0,0.16)",
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            fontWeight: 300,
            fontSize: {md:"26px"},
            lineHeight: {xs:"35px",md:"60px"},
            color: "#042D43",
            // Drop shadow: Y=3, Blur=3, ~16% alpha
            textShadow: "0 3px 3px rgba(0, 0, 0, 0.16)",
          }}
        >
          Job Application Form
        </DialogTitle>
        <DialogContent
          sx={{
            // (Optional) can also hide it here if needed:
            "&::-webkit-scrollbar": { display: "none" },
            scrollbarWidth: "none",
          }}
        >
          <form ref={formRef} onSubmit={handleSubmit}>
            <Stack
              spacing={2}
              sx={{ minWidth: "100%", maxWidth: "600px", margin: "0 auto" }}
            >
              {/* Position Title */}
              <Box>
                <Typography variant="body4" sx={bodyTextStyles}>
                  Position Title
                </Typography>
                <Typography variant="body2" sx={bodyTextStyles}>{title}</Typography>
                {/* Hidden field to submit job title */}
                <input type="hidden" name="title" value={title} />
              </Box>
              {/* Select Location */}
              <Box>
                <Typography
                variant="body2"
                  sx={{ ...bodyTextStyles, mb: 1 }}
                >
                  Select location:
                </Typography>
                <TextField
                  select
                  size="small"
                  fullWidth
                  value={selectedLocation}
                  onChange={(e) => {
                    setSelectedLocation(e.target.value);
                  }}
                  SelectProps={{
                    IconComponent:ThinExpandLessIcon,
                  }}
                  sx={locationFieldStyles}
                  name="location"
                >
                  {locations.map((loc) => {
                  const country = loc.split(',').pop().trim();
                  return (
                    <MenuItem key={loc} value={loc} sx={{ fontWeight: 400 }}>
                      {country}
                    </MenuItem>
                  );
                })}
              </TextField>
              </Box>
              {/* Applicant Name (First & Last) */}
              <Box>
                <Typography
                  variant="body4"
                  sx={{ ...bodyTextStyles, mb: 1 }}
                >
                  Applicant Name
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="firstName"
                      placeholder="[ First Name ]"
                      variant="outlined"
                      fullWidth
                      required
                      size="small"
                      
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="lastName"
                      placeholder="[ Last Name ]"
                      variant="outlined"
                      fullWidth
                      required
                      size="small"
                      
                    />
                  </Grid>
                </Grid>
              </Box>
              {/* Applicant Email ID */}
              <Box>
                <Typography
                  variant="body4"
                  sx={{ ...bodyTextStyles, mb: 1 }}
                >
                  Applicant Email ID
                </Typography>
                <TextField
                  type="email"
                  variant="outlined"
                  fullWidth
                  required
                  name="email"
                  size="small"
                />
              </Box>
              {/* Cover Letter (File) */}
              <Box sx={{ paddingBottom: "1em" }}>
                <Typography
                variant="body2"
                  sx={{ ...bodyTextStyles, mb: 0.5 }}
                >
                  Cover Letter
                </Typography>
                <Button
                  variant="contained"
                  component="label"
                  sx={{
                    backgroundColor: "#03A9F5",
                    fontWeight: 400,
                    fontSize: "14px",
                    textTransform: "capitalize",
                    padding: "5px 30px",
                    borderRadius: "12px",
                    "&:hover": {
                      // Force the same background & remove outline
                      backgroundColor: "#03A9F5",
                      border: "none",
                      boxShadow: "none",
                    },
                  }}
                >
                  Choose File
                  <input
                    type="file"
                    name="coverLetter"
                    hidden
                    onChange={handleCoverLetterChange}
                  />
                </Button>
                {coverLetterFile && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      mt: 1,
                    }}
                  >
                    <Box
                      component="img"
                      src={ImgPDF}
                      sx={{
                        width: "20px",
                      }}
                    />
                    <Typography variant="body2" sx={{ color: "#1E75C6" }}>
                      {coverLetterFile.name}
                    </Typography>
                  </Box>
                )}
              </Box>
              {/* Resume (File) */}
              <Box>
                <Typography
                variant="body2"
                  sx={{ ...bodyTextStyles, mb: 0.5 }}
                >
                  Resume
                </Typography>
                <Button
                  variant="contained"
                  component="label"
                  sx={{
                    backgroundColor: "#03A9F5",
                    fontWeight: 400,
                    fontSize: "14px",
                    textTransform: "capitalize",
                    padding: "5px 30px",
                    borderRadius: "12px",
                    "&:hover": {
                      // Force the same background & remove outline
                      backgroundColor: "#03A9F5",
                      border: "none",
                      boxShadow: "none",
                    },
                  }}
                >
                  Choose File
                  <input
                    type="file"
                    name="resume"
                    hidden
                    onChange={handleFileChange}
                  />
                </Button>

                {/* If a file is chosen, show its name below */}
                {resumeFile && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      mt: 1,
                    }}
                  >
                    <Box
                      component="img"
                      src={ImgPDF}
                      sx={{
                        width: "20px",
                      }}
                    />
                    <Typography variant="body2" sx={{ color: "#1E75C6" }}>
                      {resumeFile.name}
                    </Typography>
                  </Box>
                )}
              </Box>
              {/* Divider before bottom buttons */}
              <Divider sx={{ my: 2 }} />
              {/* Hidden form submit button (triggered by handleDialogClose) */}
              <input
                type="submit"
                ref={formRefSubmit}
                style={{ display: "none" }}
              />
            </Stack>
          </form>
        </DialogContent>

        <DialogActions sx={{ justifyContent: "center", gap: 3 }}>
          <Button
            variant="outlined"
            onClick={handleDialogCancel}
            disabled={disabled}
            sx={{
              minWidth: 100,
              backgroundColor: "#8296A1",
              color: "#FFFFFF",
              textTransform: "capitalize",
              fontSize: "20px",
              fontWeight: "400",
              padding: "4px 30px",
              borderRadius: "12px",
              border: "none",
              "&:hover": {
                // Force the same background & remove outline
                backgroundColor: "#8296A1",
                border: "none",
                boxShadow: "none",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleDialogClose}
            disabled={disabled}
            sx={{
              minWidth: 100,
              backgroundColor: "#042D43",
              color: "#FFFFFF",
              textTransform: "capitalize",
              fontSize: "20px",
              fontWeight: "400",
              padding: "4px 30px",
              borderRadius: "12px",
              border: "none",
              "&:hover": {
                // Force the same background & remove outline
                backgroundColor: "#042D43",
                border: "none",
                boxShadow: "none",
              },
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        open={openSnackbar}
        autoHideDuration={10000}
        onClose={handleSnackbarClose}
        message={message}
        action={actionSnackbar}
        sx={{ mt: "70px", boxShadow: 8 }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={severity}
          variant="filled"
        >
          {message}
        </Alert>
      </Snackbar>

      <Box
        sx={{
          // width: "100%",
          py: {xs:5,md:15},
          backgroundImage: `url(${ImgBottomWave})`,
          backgroundRepeat: "no-repeat",
          backgroundPositionY: "100px",
          backgroundSize: "contain",
          // backgroundColor: '#f0f4f7',  // optional page background
        }}
      >
        <Box sx={{ width: '75%', margin: "auto",padding:{xs:"30px 10px 0 10px",md:"0"} }}>
          <Typography
            variant="body2"
            sx={{
              mb: 2,
              color: "#1E75C6",
              fontSize:"20px",
              fontWeight:300,
              cursor: "pointer",
              textDecoration:'none',
              // optional: left align
            }}
            component={RouterLink}
            to={RouterList.about.career}
            
          >
            &lt; Back to Career Openings
          </Typography>
        </Box>

        {/* Card / Container */}
        <Box mt={10}>
          <Box
            sx={{
              width:"75%",
              // width:"100%",
              margin: "0 auto",
              py: {xs:1,md:4},
              px: { xs: 4, md: 10 },
              backgroundColor: "#fff",
              borderRadius: 2,
              boxShadow: 2,
            }}
          >
            {/* Title & Location (center-aligned) */}
            <Box sx={{ textAlign: "center", mb: 3 }}>
              <Typography
                variant="h7"
                sx={{
                  mb: 3,
                  lineHeight: "60px",
                }}
              >
                {title}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "left",
                  gap: 2,
                  mt:2
                }}
              >
                <Box
                  component="img"
                  src={locationIcon}
                  sx={{
                    width: "30px",
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    linHeight: "24px",
                    whiteSpace: "pre-line",
                  }}
                >
                  {locations.join("\n")}
                </Typography>
              </Box>
            </Box>

            {/* Job Description */}
            <Typography
              variant="body2"
              sx={{
                mb: 1.5,
                lineHeight: "24px",
              }}
            >
              {summary}
            </Typography>

            <Divider sx={{ mb: 1.5 }} />

            <Grid container direction="column">
              {props.children}
            </Grid>

            {/* Apply Button (center-aligned) */}
            <Box sx={{ textAlign: "center" }}>
              <Button
                onClick={handleApplyJob}
                variant="contained"
                sx={{
                  backgroundColor: "#042D43",
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: "400",
                  textTransform: "none",
                  padding: "5px 40px",
                  borderRadius: "12px",
                  "&:hover": {
                    backgroundColor: "#042D43",
                    boxShadow: "none",
                  },
                }}
              >
                Apply
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export { JobListingPage };
