import React from 'react';
import { Grid, Typography } from '@mui/material';

import { Product } from '../../../component/page';

import ImgMedCode from '../../../../img/service/clinical-data-management/MedCode.png';
import CustomTypography from '../../../component/customTypography';

const template = () => {
  return (
    <>
      <Grid container direction='column' spacing={0} sx={{ mt: 0 }}>
            {/* Medical Coding  */}
          <Grid item>
              <Product
                ImgSrc={ImgMedCode}
                Name="Medical Coding"
                Summary={
                  <>
                  <CustomTypography variant='body2' sx={{ mt: 1 }}>
                    CliniOps offers comprehensive medical coding services to ensure accurate and consistent classification of clinical trial data. 
                    Our experienced team uses industry-standard coding systems such as MedDRA and WHO-Drug to code adverse events, medical histories, and concomitant medications with precision. We prioritize data quality, compliance, and efficiency, ensuring timely and 
                    reliable results for regulatory submissions. With CliniOps, you can trust that your trial data is accurately represented and aligned with global standards.
                </CustomTypography>
                </>
                }
              />
              
          </Grid>
      </Grid>
    </>
  );
}

export default template;

//Commenting previous code

// import React from 'react';
// import { Typography, Stack } from '@mui/material';

// import { PageSectionRightImg } from '../../../component/page';

// import ImgDatabaseDesign from '../../../../img/service/clinical-data-management/databaseDesign.jpeg';

// const template = () => {
//   return (
//     <>
//       <PageSectionRightImg
//         heading={
//           <Stack direction="row" spacing={1} alignItems='center' justifyContent={{xs: 'center', md: 'flex-start'}}>
//             <Typography variant="h2" component="h2" className="co-page-heading" sx={{ textAlign: {xs: 'center', md: 'left'} }}>
//               Database Design
//             </Typography>
//           </Stack>
//         }
//         imgUrl={ImgDatabaseDesign}
//         imgTitle="Database Design"
//       >
//         <Typography sx={{ mt: 1 }}>
//           Our expert database designers works closely with multidiscipline trial team to incorporate and implement protocol requirements and user specifications for database design.
//         </Typography>
//         <Typography sx={{ mt: 1 }}>
//           Our database development will include FDA compliance, database validation and documentation, version control tracking and documentation, product accountability.
//         </Typography>
//       </PageSectionRightImg>
//     </>
//   );
// }

// export default template;