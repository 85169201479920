import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import NurseImage from "../../../../img/service/_0/nurse_with_tablet_2.png";
import waveImg from "../../../../img/home/wave2.png";
import counter1 from "../../../../img/product/_0/counter_1.png";
import counter2 from "../../../../img/product/_0/counter_2.png";
import counter3 from "../../../../img/product/_0/counter_3.png";
import counter4 from "../../../../img/product/_0/counter_4.png";
import counter5 from "../../../../img/product/_0/counter_5.png";

const template = () => {
  const content = [
    {
      img:counter1,
      title: "Unified Platform",
      description:
        "CliniOps offers a partnership built on a Unified platform geared towards addressing the needs of the biopharma industry from protocol to submission.",
    },
    { 
        img:counter2,
      title: "Heightened Efficiency",
      description:
        "CliniOps promotes heightened efficiency to realize one secure, flexible, and affordable holistic solution for effective clinical trial management.",
    },
    {
      img:counter3,
      title: "Collaborative Partnership",
      description:
        "CliniOps services enhance collaborative partnerships with every stakeholder through a platform that seamlessly integrates existing systems using proprietary, patented technology that is easily accessible offline or online.",
    },
    {
      img:counter4,
      title: "Data Science Methodologies",
      description:
        "CliniOps delivers centralized and decentralized clinical trial methodologies focused on innovative data science for the continuity of studies, even during times of uncertainty.",
    },
    {
      img:counter5,
      title: "Agile Platform",
      description:
        "CliniOps equips the industry with an agile platform capable of data collection at the point of care, generating real-time and accurate evidence, and supporting the study investigator's ability to make informed decisions.",
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        padding: { xs: "16px", md: "10px 0" },
        backgroundColor: "#F2F2F2",
        display: "flex",
        flexDirection: "column",
        alignItems:"flex-start",
        position: "relative",
        overflow: "visible",
        height:"auto"
      }}
    >
        {/* Wave Image */}
      <Box
        component="img"
        src={waveImg}
        alt="Wave Background"
        sx={{
            position: "absolute",
            bottom: -70,
            left: 0,
            width: "100%",
            height: "auto",
            zIndex: {xs:1,md:5},
            backgroundImage: `url(${waveImg})`,
            backgroundSize: "cover",
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
        }}
      />

      {/* Layout */}
      <Grid
        container
        spacing={2}
        sx={{
          maxWidth: "1400px",
          margin: "auto",
        }}
      >
        {/* Left Side Image */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "strech",
            zIndex:2
          }}
        >
          <Box
            component="img"
            src={NurseImage}
            alt="Nurse"
            sx={{
              maxWidth: "650px",
              height: 'auto',
              objectFit: "contain",
            }}
          />
        </Grid>

        {/* Right Side Content */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display:'flex',
            flexDirection:"column",
            zIndex:{xs:2,md:0}
            // height:"800px"
            // paddingRight:"50px"
          }}
        >
          {content.map((item, index) => (
            <Box
              key={index}
              sx={{
                paddingY: "8px", // Reduced spacing
                position: "relative",
                display: "flex",
                alignItems: "flex-start",
                "&::before": {
                  content: '""',
                  position: "absolute",
                  left: { xs: "calc(-50vw + 50%)", md: 0 },
                  transform:{ md: "translateX(-50%)"}, 
                  bottom:0,
                  width: { xs: "100vw", md: "100vw" },
                  height: "1px",
                  zIndex: 1,
                  borderBottom:"1px solid rgba(112, 112, 112, 0.2)"
                },
                // Remove border for last item
            "&:last-child::before": {
                borderBottom:"none"
              },
              }}
            >
            
                   {/* Counter Image */}
              <Box
                component="img"
                src={item.img}
                alt={`Counter ${index + 1}`}
                sx={{
                  width: { xs: '40px', md: '50px' },
                  height:{xs:'40px',md:'50px'},
                  marginRight: "16px",
                //   flexShrink: 0,
                }}
              /> 

                <Box sx={{ flex: 1,marginTop:"-8px" }}>
                <Typography
                  variant="subtitle1"
                >
                  {item.title}
                </Typography>     
                <Typography
                  variant="body2"
                  sx={{
                    margin: "5px 0 10px 0",
                    lineHeight:"24px",
                  }}
                >
                  {item.description}
                </Typography>
                </Box>
             
            </Box>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default template;
