import React from 'react';
import { Grid,Box } from '@mui/material';
import { teal } from '@mui/material/colors';

import Banner from '../../../../img/contact/requestQuoteBanner.png';


const template = () => {
  return (
    <>
    <Box>
    <Box sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: {xs:"70px 0 0 0",md:"100px 0 0 0"},
      overflow: "hidden",

      }}>
  <img
    src={`${Banner}`}
    alt=""
    style={{ 
      maxWidth: "100%", 
      maxHeight: "100%", 
      objectFit: "contain" }}
  />
  </Box>
  <Box sx={{
          position: 'absolute',
          top: { xs: '70px', md: '100px' },
          left: { xs: '10px', md: '50px' },
          right: 0,
          bottom: 0,
          display: 'flex',
          color: '#FFFFFF',
          textAlign: 'topleft',
          fontSize: { xs: '20px', sm: '40px', md: '60px' }, // Responsive font size
          fontWeight: 300,
          textShadow: "0 3px 3px rgba(0, 0, 0, 0.16)",
          // fontFamily: 'Segoe UI',
        }}>
      Request Quote 
  </Box>
  </Box>

    </>
  );
}

export default template;


// import React from 'react';
// import { Typography } from '@mui/material';

// const template = () => {
//   return (
//     <>
//       <Typography variant="h1" component="h1">
//       Request a Quote
//       </Typography>
//       <Typography variant="subtitle1" sx={{ mt: 1 }}>
//       Interested in the CliniOps Advantage? Please fill out the form and we'll schedule a live demo at a time that suits you.
//       </Typography>
//     </>
//   );
// }

// export default template;