import React from "react";
import { Grid, Box, Typography, Stack,Alert, Link } from "@mui/material";
import { teal, blueGrey } from "@mui/material/colors";

import { PageHero, PageContentV2 } from "../../component/page";
import LiseDenoeud from "../../../img/media/customer-story/_1/lise_denoeud.jpg";
import UnionLogoWeb from "../../../img/media/customer-story/_1/union_logo_web.png";
// import bmcInfectiousDiseases from "../../../img/media/customer-story/_1/MDGH.svg";
import bmcInfectiousDiseases from "../../../img/media/customer-story/_1/logoBMCInfectiousDiseasesM_340.png";
import bmcPublicHealth from "../../../img/media/customer-story/_1/logo-bmc-public-health-m_340.png";
import ImgUnitaid from "../../../img/media/customer-story/_1/UNITAID_Logo_tagline_RGB_Col.png";
import ImgKenyaMin from "../../../img/media/customer-story/_1/MOH---Kenya.png";
import ImgCameroon from "../../../img/media/customer-story/_1/Republic_of_Cameroon.png";
import ImgUniSheff from "../../../img/media/customer-story/_1/University-of-Sheffield.png";





// Page Imports
import _0 from "./customer-story_16/_0";
import { Block } from "@mui/icons-material";

const template = () => {
  return (
    <>
      <PageHero color="rgba(3, 169, 244, 0.1)">
        <_0 />
      </PageHero>
      <PageContentV2>
        <Grid item>
        <Typography variant="subtitle2"  paragraph>
        Mobile Technologies Assist Intervention Study to Integrate Pediatric TB Services Into Child Healthcare Services (INPUT Study), in Cameroon and Kenya</Typography>
        <Typography variant="body7" paragraph dangerouslySetInnerHTML={{ __html: 'Highly infectious, TB remains a public health concern in Africa particularly among children between 0 and 15 years of age. The World Health Organization (WHO) only started reporting pediatric-specific (<15 years) TB disease estimates in 2012. We now know that children represent 12 percent of the global TB burden (1.2 million of 10 million) and 16% of TB deaths (230,000 of 1.5 million in 2020) each year.<sup>1</sup> Africa has one third or about 320,000 of the total pediatric cases, with many more undiagnosed.<sup>2</sup>' }} />
        <Typography variant="body7" paragraph>
        To respond to growing TB pediatric cases, the Elizabeth Glaser Pediatric AIDS Foundation (EGPAF), with support from global health organization Unitaid, initiated an intervention study to integrate pediatric TB services into child healthcare services in Africa (INPUT). EGPAF collaborated with Unitaid, University of Sheffield, Kenya Ministry of Health, and Cameroon Ministry of Public Health, to set up study conducted in 12 hospitals evenly representing the countries of Cameroon and Kenya. 
        </Typography>
        <Typography variant="body7" paragraph>
        From March 2019 to August 2021, a population of 790 children below 5 years old took part, with the consent of parents or care givers in a cluster-randomized Stepped-wedge Trial. District-level hospitals and their health centers in Cameroon and Kenya were formed into study clusters to assess the effect on TB diagnosis capacities of integrating TB services into key child healthcare services, maternal neonatal and child health (MNCH) services, under-5 clinic, pediatric outpatient services, nutrition services, pediatric antiretroviral therapy (ART) services and primary health care.
        </Typography>
        <Typography variant="body7" paragraph>
        All sites started under standard-of-care (SOC) and - at randomly assigned points in time - they transitioned to the intervention program. The comparison between standard-of-care and intervention allowed to measure the effectiveness of integrating pediatric TB services into child health services.
        </Typography>
        <Typography variant="body7" paragraph>
        To manage efficient study operations from enrolment, informed consent, to data collection and remote monitoring, all the way to study closure, CliniOps was EGPAF’s technology partner. With a unified and mobile platform, study coordinators and staff in the 12 clinical sites spread across disparate areas, were able to access and enter data in digital platforms without delay. All data was collected in complete offline mode, using digital tablets without any dependency on  internet connections, and then synched as soon as WIFI is available. The study was successfully completed in August 2021. 
        </Typography>
          

          <blockquote
            style={{ borderLeft: `4px solid ${blueGrey[100]}`, marginLeft: -1 }}
          >
            <Stack
              spacing={4}
              sx={{ pl: 4 }}
              direction={{ xs: "column", md: "row" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minWidth: "220px",
                  width: "220px",
                  height: "250px",
                  borderRadius: 2,
                  backgroundImage: `url(${LiseDenoeud})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "top center",
                }}
              />
              <Box>
            <Typography variant="body7" paragraph>
              <em>
              “The success of our studies to integrate TB care, including detection and treatment for children goes a long way to control the spread of TB in high burden countries including those in Africa. Our evidence generation strategy which covers our clinical trial initiative in Cameroon and Kenya would not have been possible without our conscientious study and site teams, our partnership with those countries’ health ministries, and the willingness of the participants through their parents and caregivers. To minimize the burden on sites and most specially on the patient, considering high data collection volume in 12 sites with 1,716 participants, EGPAF is grateful for the strong and reliable technology support of CliniOps through their unified and mobile technologies.”
              </em>
            </Typography>
            <Typography variant="overline" paragraph>
              <strong>
              Lise Denoeud, MD, PhD
              <br></br>
              Senior Research Officer, Elizabeth Glaser Pediatric AIDS Foundation
              
            
              </strong>
            </Typography>
          </Box>
            </Stack>
          </blockquote>
          <Typography variant="body7" paragraph>
          <sup>1</sup>Pediatric Tuberculosis Research and Development: Progress, Priorities and Funding Opportunities, Jan 21, 2022. <a href= "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8877806/" target={'_blank'} class="linkcolor">https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8877806/</a>  
          </Typography>
          <Typography variant="body7" paragraph>
          <sup>2</sup>ReliefWeb. African Union and WHO urge swift action against childhood tuberculosis. World; August 24, 2022. Accessed 2 September 2022. <a href= "https://reliefweb.int/report/world/african-union-and-who-urge-swift-action-against-childhood-tuberculosis" target={'_blank'} class="linkcolor">https://reliefweb.int/report/world/african-union-and-who-urge-swift-action-against-childhood-tuberculosis</a>  
          </Typography>
        
          <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
     <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
     <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '175px',
                width: '175px',
                height: '80px',
                borderRadius: 2,
                marginTop: '30px',
                backgroundImage: `url(${bmcPublicHealth})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
          />
            <Box>
            <Typography paragraph>
            The protocol of the study, “<Link href="https://bmcpublichealth.biomedcentral.com/articles/10.1186/s12889-020-08741-2" target={"_blank"} underline="hover">Integrating pediatric TB services into child healthcare services in Africa: study protocol for the INPUT cluster-randomized stepped wedge trial</Link>”, was published on BMC Public Health, on May 06, 2020.
            </Typography>
            </Box>
        </Stack>
        </Alert>

        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '175px',
                width: '175px',
                height: '80px',
                borderRadius: 2,
                marginTop: '30px',
                backgroundImage: `url(${bmcInfectiousDiseases})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
            />
            <Box>
            <Typography paragraph>
            The results of the clinical study, “<Link href="https://bmcinfectdis.biomedcentral.com/articles/10.1186/s12879-023-08216-w" target={"_blank"} underline="hover">HIV matters when diagnosing TB in young children: an ancillary analysis in children enrolled in the INPUT stepped wedge cluster randomized study</Link>”, was published in BMC Infectious Diseases, on April 17, 2023.
            </Typography>
            </Box>
        </Stack>
        </Alert>

        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'left',
                minWidth: '175px',
                width: '175px',
                height: '80px',
                borderRadius: 0,
                marginTop: '0px',
                backgroundImage: `url(${UnionLogoWeb})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top left'
            }}
            />
            <Box>
            <Typography paragraph>
            The results of the study was presented at <Link href="https://conf2022.theunion.org/" target={"_blank"} underline="hover">The Union Conference 2022</Link>, in the following sessions:
            </Typography>
            <Typography>
                •	Satellites and Workshops: <Link href="https://pedaids.org/event/the-union-world-conference-on-lung-health-2022/" target={"_blank"} underline="hover">Updated WHO guidelines on the management of TB in children and adolescents</Link>
            </Typography>
            <Typography>
                •	Oral Abstract Sessions: <Link href="https://pedaids.org/event/the-union-world-conference-on-lung-health-2022/" target={"_blank"} underline="hover">Child and host and community</Link>
            </Typography>
            </Box>
        </Stack>
        </Alert>

        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Box
                xs="auto"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minWidth: '175px',
                    width: '175px',
                    height: '80px',
                    borderRadius: 2,
                    marginTop: '10px',
                    // marginBottom: '10px',
                    backgroundImage: `url(${ImgUnitaid})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top center'
                }} 
                />
            <Box>
            <Typography paragraph>
            The study was funded by <Link href="https://unitaid.org/" target={"_blank"} underline="hover">Unitaid</Link>
            </Typography>
            </Box>
        </Stack>
        </Alert>

        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Stack display={"block"}>
        <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '175px',
                width: '175px',
                height: '80px',
                borderRadius: 2,
                marginTop: '10px',
                // marginBottom: '10px',
                backgroundImage: `url(${ImgKenyaMin})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top left'
            }}
            />
            <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '175px',
                width: '175px',
                height: '80px',
                borderRadius: 2,
                marginTop: '10px',
                // marginBottom: '10px',
                backgroundImage: `url(${ImgCameroon})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top left'
            }}
            />
            <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '175px',
                width: '175px',
                height: '80px',
                borderRadius: 2,
                marginTop: '10px',
                // marginBottom: '10px',
                backgroundImage: `url(${ImgUniSheff})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top left'
            }}
            /></Stack>
            <Box>
            <Typography paragraph>
            The study was conducted in collaboration with <Link href="https://www.health.go.ke/" target={"_blank"} underline="hover">Kenya Ministry of Health</Link>, <Link href="https://www.minsante.cm/" target={"_blank"} underline="hover">Cameroon Ministry of Public Health</Link>, and <Link href="https://www.sheffield.ac.uk/" target={"_blank"} underline="hover">University of Sheffield</Link>
            </Typography>
            </Box>
        </Stack>
        </Alert>

        </Grid>
      </PageContentV2>
    </>
  );
};

export default template;
