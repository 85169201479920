import React from 'react';
import { Box, Grid, Typography, Container } from '@mui/material';

import ImgCliniOpsEdge from '../../../../img/product/edge/CliniOpsEdge.png';

const template = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="row"
      sx={{ py: 4 }}
    >
    <Container maxWidth="xl">
    <Grid 
        container
        direction={{ xs: 'column', md: 'row' }} 
        spacing={{ xs: 4, md: 12 }}
        alignItems={{ xs: 'center', md: 'flex-start' }}
        justifyContent={{ xs: 'center', md: 'flex-start' }}
    >
    <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-start' } }}>
      <img 
        src={ImgCliniOpsEdge} 
        className="co-image-product"
        alt="CliniOps Edge" 
        style={{ width: '100%', maxWidth: '200px' }}
      />
      </Grid>
      <Grid item xs={12} md={9}>
      <Typography variant="h7"sx={{ maxWidth: '1000px',lineHeight:"40px", textAlign: { xs: 'center', md: 'left' }, py:1 }}>
        Purpose-built for high-quality & real-time electronic data collection at the source 
        (eSource), ensuring very clean and high-quality data is collected digitally at the point of care.
      </Typography>
        <br></br><br></br>
      <Typography variant='body5' sx={{lineHeight:"28px"}}>
      CliniOps Edge is purpose-built for high-quality, real-time electronic data collection at the point of care (eSource). This application runs on standard mobile devices in complete offline mode, with all checks and balances, ensuring very clean and high quality data is collected digitally.
       </Typography>
      </Grid>
      </Grid>
      </Container>
    </Box>
  );
};

export default template;
