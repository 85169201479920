import React from 'react';
import { Grid, Box } from '@mui/material';
import { teal } from '@mui/material/colors';

import contactConduct from '../../../../img/contact/contactConduct.png';
import contactC from '../../../../img/contact/contactC.png';
import contactGroup from '../../../../img/contact/contactGroup.png';

const template = () => {
  return (
    <>
      <Box sx={{height:"100px"}}>

      </Box>
    </>
  );
};

export default template;