import React from "react";
import { Grid, Box, Typography, Stack,Alert, Link } from "@mui/material";
import { teal, blueGrey } from "@mui/material/colors";
import { PageHero, PageContentV2 } from "../../component/page";

import ImgGary from "../../../img/media/customer-story/_1/Gary_Weil.jpg";
import ASTMHLogo from '../../../img/media/customer-story/_1/ASTMHLogo.png';
import ImgPlosNTD from '../../../img/media/customer-story/_1/PLOS-NTD_Logo.png';
import RSTMH_logo from '../../../img/media/customer-story/_1/RSTMH_logo.png';
import PLOSMedicine from '../../../img/media/customer-story/_1/PLOSMedicine.png';
import AfricacomLogo from '../../../img/media/customer-story/_1/Africa.comLogo.png';
import WHOLogo from '../../../img/media/customer-story/_1/WHOLogo.png';
import GatesNotes from '../../../img/media/customer-story/_1/GatesNotes.png';
import CorNTD from '../../../img/media/customer-story/_1/CorNTD.png';
import ICMR_VCRC from '../../../img/media/customer-story/_1/ICMR-VCRC.png';
import SyngeneLogo from '../../../img/media/customer-story/_1/Syngene-logo.png';
import MSPPLogo from '../../../img/media/customer-story/_1/MSPP-logo.png';
import UniversitasIndonesia from '../../../img/media/customer-story/_1/Universitas-Indonesia.png';
import PNGIMR from '../../../img/media/customer-story/_1/PNGIMR_headweb.webp';
import MOHFiji from '../../../img/media/customer-story/_1/MOHFiji-LOGO.png';
import Merck_Co from '../../../img/media/customer-story/_1/Merck_&_Co.png';
import EisaiLogo from '../../../img/media/customer-story/_1/Eisai_logo.png';
import GSKLogo2022 from '../../../img/media/customer-story/_1/GSK_logo_2022.png';
import ImgWashU from '../../../img/media/customer-story/_1/WashU-logo.png';
import SandeepAthalye from '../../../img/media/customer-story/_1/SandeepAthalye.jpeg';
import ExpertOpinion from '../../../img/media/customer-story/_1/Expert_Opinion_on_Biological_Therapy.png';
import MedRxiv from '../../../img/media/customer-story/_1/MedRxiv_logo.png';
import BusinessLine from '../../../img/media/customer-story/_1/TheHindu-BusinessLine.png';


// Page Imports
import _0 from "./customer-story_18/_0";
import { Block } from "@mui/icons-material";

const template = () => {
  return (
    <>
      <PageHero color="rgba(3, 169, 244, 0.1)">
        <_0 />
      </PageHero>
      <PageContentV2>
        <Grid item>
        <Typography variant="subtitle2" paragraph>
        Patient Centricity through Telemedicine Amidst Pandemic: A Clinical Trial for Treatment of COVID-19 in India, across 20+ sites, supported in 10+ languages
        </Typography>
        <Typography variant="body7" paragraph>
            Covid 19 pandemic was raging in September 2020 with a cumulative number of cases globally at 32,730,945.1  Clinical trials (CT) to test viability of certain treatments for those affected by Covid were being conducted amidst much uncertainty. Entry to clinic or hospital sites were restricted to prevent further infections. Patients with severe acute respiratory distress syndrome (ARDS) due to COVID-19, were among those impacted more since the patient party (family members) were not allowed to enter the hospital facility due to covid restrictions, consequently, procedural activities including patient consent was difficult to obtain.
        </Typography>
        <Typography variant="body7" paragraph>
            Telemedicine became the go-to technology by a few study teams to respond to restrictions and continue clinical trials. Biocon Biologics, a global biopharma company, was among those who deployed digital technologies to successfully complete a multi-centre phase IV clinical trial to evaluate the safety and efficacy of Itolizumab for the treatment of cytokine release syndrome (CRS) in moderate to severe acute respiratory distress syndrome (ARDS) patients, due to COVID 19, in 2020.        
        </Typography>
        <Typography variant="body7" paragraph>
            As the technology partner of Biocon Biologics, CliniOps understood the necessity of quickly and safely setting up the digital platform, to ensure study is not delayed, since time is vital with lives lost at an alarming rate.2  Approximately 300 patients were recruited in 20+ sites using CliniOps’ eConsent (electronic consent) including Video Consent as an option, to obtain the assent of patients or their ‘Legally Authorized Representatives (LAR)’ to participate in the  study. Patients were engaged from the convenience of their homes via ePRO (electronic Patient Reported Outcome) for the 30 day, 45-day and 60-day follow-up visits, thereby significantly minimizing unnecessary trips to the hospitals and providing much needed relief to ailing patients and their caregivers. The study team had access to the data for real time monitoring of the patients.  CliniOps platform also offered multi-lingual functionality with the translated content supported in 10+ different languages that contributed to putting patients at ease.         
        </Typography>
        <Typography variant="body7" paragraph>
            The study completed successfully with Biocon’s example of operationalizing patient centricity through digital transformation keeping patients’ safety and convenience at the foremost. 
        </Typography>

          <blockquote
            style={{ borderLeft: `4px solid ${blueGrey[100]}`, marginLeft: -1 }}
          >
            <Stack
              spacing={4}
              sx={{ pl: 4 }}
              direction={{ xs: "column", md: "row" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minWidth: "220px",
                  width: "220px",
                  height: "250px",
                  borderRadius: 2,
                  backgroundImage: `url(${SandeepAthalye})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "top center",
                }}
              />
              <Box>
            <Typography variant="body7" paragraph>
              <em>
              "Biocon’s goal is to improve patient outcomes with approaches both novel and time tested to meet every challenge, pandemic included. We are a strong believer of digital transformation of clinical trials, and our partnership with  CliniOps, helped us with an innovative platform that offered complete digital experience for sites and patients. With telemedicine, participants were enrolled and engaged online via the platform. The participants’ safety and convenience were always a priority in line with our strategic imperative of  patient centricity. We enjoyed our partnership with CliniOps and their very responsive customer support team"
              </em>
            </Typography>
            <Typography variant="overline" paragraph>
              <strong>
              Dr. Sandeep Athalye, MD
              <br></br>
              Chief Development Officer, Biocon Biologics             
              </strong>
            </Typography>
          </Box>
            </Stack>
          </blockquote>
        
          <Typography paragraph>
          1, 2 Coronavirus disease (COVID-19) Situation Report 28 September 2020.  World Health Organization. <a href= "https://www.who.int/docs/default-source/coronaviruse/situation-reports/20200928-weekly-epi-update.pdf" target={'_blank'} class="linkcolor">https://www.who.int/docs/default-source/coronaviruse/situation-reports/20200928-weekly-epi-update.pdf</a>  
          </Typography>
        
          <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
     <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
     <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '175px',
                width: '175px',
                height: '80px',
                // border: '1px solid red',
                borderRadius: 2,
                // marginTop: '30px',
                backgroundImage: `url(${ExpertOpinion})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
          />
            <Box>
            <Typography paragraph>
            The results of the study titled, ‘<Link href="https://pubmed.ncbi.nlm.nih.gov/37073744/" target={"_blank"} underline="hover">RE covery and SUR vival of patients with moderate to severe acute RE spiratory distress syndrome (ARDS) due to C OVID-19: a multicenter, single-arm, Phase IV itolizumab T rial: RESURRECT</Link>’, was published by Expert Opinion on Biological Therapy, Volume 23, 2023 - Issue 5, on April 28, 2023.
            </Typography>
            </Box>
        </Stack>
        </Alert>
          <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
     <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
     <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '175px',
                width: '175px',
                height: '80px',
                borderRadius: 2,
                marginTop: '30px',
                backgroundImage: `url(${MedRxiv})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
          />
            <Box>
            <Typography paragraph>
            The results of the study titled, ‘<Link href="https://www.medrxiv.org/content/10.1101/2021.10.25.21265462v2" target={"_blank"} underline="hover">REcovery and SURvival of patients with moderate to severe acute REspiratory distress syndrome (ARDS) due to COVID-19: a multicentre, single-arm, Phase IV itolizumab Trial: RESURRECT</Link>’, was published by medRxiv, on October 28, 2021.
            </Typography>
            </Box>
        </Stack>
        </Alert>


          <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
     <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
     <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '175px',
                width: '175px',
                height: '80px',
                borderRadius: 2,
                // marginTop: '30px',
                backgroundImage: `url(${BusinessLine})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
          />
            <Box>
            <Typography paragraph>
            Biocon rolls out <Link href="https://www.thehindubusinessline.com/companies/biocon-to-roll-out-phase-4-post-marketing-surveillance-for-itolizumab-to-treat-covid-19-patients/article32724407.ece" target={"_blank"} underline="hover">Phase 4 clinical trial for Itolizumab to treat Covid-19 patients</Link>, after Itolizumab received ‘Restricted Emergency Use’ authorisation from <Link href="https://cdsco.gov.in/" target={"_blank"} underline="hover" >CDSCO</Link>, September 29, 2020.
            </Typography>
            </Box>
        </Stack>
        </Alert>

        
        
        </Grid>
      </PageContentV2>
    </>
  );
};

export default template;
