import { Box, Typography } from "@mui/material";
import React from "react";

const template = () => {
  return (
    <Box
      sx={{
        textAlign: "left",
        px: { xs: 5, md: 1 },
        pb: 5,
        pt:5,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        maxWidth: 800,
        mx: "auto",
        position:"relative",
        zIndex:5,
      }}
    >
      <Typography
        variant="h2"
        sx={{
          lineHeight: "30px",
        }}
      >
        Interested in the CliniOps Advantage?
      </Typography>
      <Typography
      variant="body2"
        sx={{
          mt: 1,
          lineHeight: "24px",
        }}
      >
        Please fill out the form, and we’ll schedule a live demo at a time that
        suits you.
      </Typography>
    </Box>
  );
};

export default template;
