import React from "react";
import { Grid, Box, Typography,Stack,Alert, Link } from "@mui/material";
import { teal, blueGrey } from "@mui/material/colors";

import { PageHero, PageContentV2 } from "../../component/page";
import ImgSue from "../../../img/media/customer-story/_1/SueMcKinney.jpg";
// Page Imports
import _0 from "./customer-story-3/_0";
import ImgASP from '../../../img/media/customer-story/_1/ASP-Logo.png';
import ImgCrilaHealth from '../../../img/media/customer-story/_1/crila_Health.png';
import ImgJMUST from '../../../img/media/customer-story/_1/JMUST.png';

const template = () => {
  return (
    <>
      <PageHero color="rgba(3, 169, 244, 0.1)">
        <_0 />
      </PageHero>
      <PageContentV2>
        <Grid item>
          <Typography variant="subtitle2" paragraph>
          Partnership with Crila Health: Clinical Trial on Prostate Health, conducted across multiple sites in Mexico
          </Typography>
          <Typography variant="body7" paragraph>
            Lower urinary tract symptoms (LUTS) is an indicator of prostate
            health. A clinical study involving the herbal supplement oral CRILA®
            and its effect on Latino men with LUTS was set to start when the
            Covid 19 pandemic hit in early 2020. Suddenly, the two clinical
            sites in Mexico were being assessed for patient safety and no clear
            answers were available with still-evolving news of the pandemic. The
            risk to proceed and then not complete the study was daunting. Long
            months of preparation for the study was in danger of a full stop.
          </Typography>
          <Typography variant="body7" paragraph>
            Crila Health CEO, Sue McKinney, was not giving up. The open label
            observational clinical study which would assess the efficacy of
            Crila@ for prostate health were expected to validate the findings in
            previous studies conducted in Asia. Any delay would impact further
            studies in the pipeline. Was there a way to proceed with the trials
            remotely? The “yes” answer came fast with a partnership with
            CliniOps.
          </Typography>
          <Typography variant="body7" paragraph>
            Through CliniOps, the use of electronic consent (eConsent) allowed
            patients to be easily enrolled into the study. CliniOps also
            facilitated data gathering in cloud based electronic repository for
            documents. As needed by the sites, CliniOps also made available the
            use of their systems’ telemedicine feature.
          </Typography>
          <Typography variant="body7">
            In the midst of the pandemic, the clinical study sponsored by Crila
            Health, completed without undue burden on the patients.
          </Typography>
          <blockquote
            style={{ borderLeft: `4px solid ${blueGrey[100]}`, marginLeft: -1 }}
          >
            <Stack spacing={4} sx={{ pl: 4 }} direction={{xs: "column", md: "row"}}>
            <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: '220px',
              width: '220px',
              height: '220px',
              borderRadius: 2,
              backgroundImage: `url(${ImgSue})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'top center'
            }}
          />
            <Box >
              <Typography variant="body7" paragraph>
                <em>
                 "Deciding to proceed with our clinical study on prostate
                  health at the height of the pandemic was very difficult and
                  risky. Our partnership with CliniOps was significant in making
                  it possible for the study team to safely enroll patients
                  through eConsent and for data to be reliably and securely
                  gathered and stored in a cloud-based repository. The study was
                  completed and outcomes demonstrated Crila’s effectiveness in
                  promoting prostate health. In no small measure, do we
                  attribute the successful completion of our studies to the
                  innovative use of CliniOps technology."
                </em>
              </Typography>
              <Typography variant="overline" paragraph>
                <strong>
                Sue McKinney 
                <br></br>
                Executive Director, Crila Health
                </strong>
              </Typography>
            </Box>
            </Stack>
          </blockquote>

         
          <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
             <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }}>
          <Box
            xs="auto"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: '114px',
              width: '114px',
              height: '80px',
              borderRadius: 2,
              backgroundImage: `url(${ImgASP})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'top center'
            }}
          />
            <Box >
              <Typography paragraph>
                The results of the study titled{" "}
                <Link href="https://crilaforprostate.com/pages/asp-2022-research" target='_blank' underline="hover">
               
                  ‘Crila® Herbal Supplement Reduces Lower Urinary Tract Symptoms
                  in Latino Men’
              
                </Link>
                was part of a poster presentation at the American Society of
                Pharmacognosy, July 23-28, 2022, Charleston, USA
              </Typography>
            </Box>
            </Stack>
            </Alert>
          
            <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
             <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }}>
             <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '114px',
                width: '114px',
                height: '80px',
                borderRadius: 2,
                backgroundImage: `url(${ImgCrilaHealth})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
          />
             <Box >
              <Typography paragraph>
                The success of the study and CliniOps involvement therein was
                acknowledged by Crila Health, in{" "}
                <Link  href="https://www.linkedin.com/pulse/new-research-results-crila-herbal-supplement-reduces-symptoms-/" target="_blank" underline="hover">
                
                  ‘New Research Results: Crila Herbal Supplement Reduces
                  Symptoms in 88% of Prostate Study Patients’
               
                </Link>{" "}
                on August 13, 2022
              </Typography>
            </Box>
            </Stack>
            </Alert>

            <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
            <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }}>
            <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '114px',
                width: '114px',
                height: '80px',
                borderRadius: 2,
                backgroundImage: `url(${ImgJMUST})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
          />
            <Box >
              <Typography paragraph>
                <Link href="https://jmust.org/elib/journal/doi/10.35460/2546-1621.2022-0052/full" target="_blank" underline="hover"> 
                A Case Study titled ‘Use of Decentralized Clinical Technologies
                in Lower Urinary Tract Symptoms’
                </Link>{" "}
                is published in the Journal of
                Medicine, University of Santo Tomas, in the October 2022 issue,
                authored by Dr. Nadina Jose & Avik Pal
              </Typography>
            </Box>
            </Stack>
            </Alert>
        </Grid>
      </PageContentV2>
    </>
  );
};

export default template;
