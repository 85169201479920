import React from "react";
import { Grid, Typography, Box, useTheme, useMediaQuery } from "@mui/material";
import ImgTopWave from "../../../img/partnerships/topwave.png";
import ImgBottomWave from "../../../img/home/wave2.png";
// import ImgBottomwave from '../../../img/partnerships/bottomwave.png';
import ImgPartnership from "../../../img/partnerships/partnershipImage.png";
import { BorderAllOutlined } from "../../../../node_modules/@mui/icons-material/index";
import { colors } from "../../../../node_modules/@mui/material/index";

const template = () => {
  const theme = useTheme();
  const isTab = useMediaQuery(theme.breakpoints.between(900,1235))
  return (
    <>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          // backgroundColor: "#F2F2F2",
          overflow: "visible",
          margin:{xs:'50px 0 30px 0',md:"50px 0 100px 0"},
          // textAlign: "center",
        }}
      >
        {/* Top Wave Background */}
        <Box
          component="img"
          src={ImgTopWave}
          alt="Top Wave"
          sx={{
            position: "absolute",
            top: 0,
            width: "100%",
            height: "auto",
            zIndex: 1,
            objectFit: "contain",
          }}
        />

        {/* Bottom Wave Background */}
        <Box
          component="img"
          src={ImgBottomWave}
          alt="Bottom Wave"
          sx={{
            position: "absolute",
            bottom: {xs:-40,md:-100},
            width: "100%",
            height: "auto",
            zIndex: 3,
            objectFit: "contain",
          }}
        />

        {/* Center Content */}
        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            src={ImgPartnership}
            alt=""
            sx={{
              width: "500px",
              height: "auto",
              objectFit: "contain",
              zIndex: 2,
            }}
          />

          <Box
            sx={{
              maxWidth: "100%",
              backgroundColor: "#F2F2F2",
              minHeight: "300px",
              zIndex: 1,
              // padding: "20px 0",
              padding: isTab ? "20px 0 100px 0" : {xs:"20px 0 100px 0",md:'20px'},
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
                margin: "auto",
                padding: { xs: "20px", md: "10px 0" },
              }}
            >
              <Typography
              variant='h2'
                sx={{
                  lineHeight: "42.56px",
                }}
              >
                Let's build something great together!
              </Typography>
              <Typography
              variant="body2"
                sx={{
                  lineHeight: "24px",
                }}
              >
                At CliniOps, we are committed to building meaningful and
                long-term partnerships with organizations that share our passion
                for innovation and growth. We believe collaboration is key to
                unlocking new opportunities and delivering exceptional value to
                our customers. We always seek to build strong, mutually
                beneficial relationships with organizations that share our
                vision and commitment to excellence.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{position:"relative",bottom:{xs:-25,sm:0,md:-35},zIndex:4,}}>
          <Typography
            sx={{
              color: "#FFFFFF",
              fontSize:{xs:"18px",sm:"35px",md:"42px"},
              fontWeight:"300",
              textAlign:"center",
              textShadow: "0px 3px 3px rgba(0, 0, 0, 0.1608)",
            }}
          >
            Technology Partners
          </Typography>
        </Box>
      </Box>
      {/* <box style={{display: 'flex',flexDirection: 'column',}}>
<box style={{width: '100%', height: '513px',marginTop:'20px'}}>
  <img
    src={`${ImgTopwave}`}
    alt="Topwave"
    style={{
      width: '100%',
      display: 'block',
      objectFit: 'cover',
      height:'100%',
      
    }}
  />
</box >
<box style={{position:'relative',}}>
<img
    src={`${ImgBottomwave}`}
    alt="Bottomwave"
    style={{
        width: '100%',
        display: 'block',
        objectFit: 'cover',
        height:'956',
        marginTop:'-530px'
    }}
  />
<box style={{
    // border: "1px solid red", //for visualizing the box
    display:'block',
    position: 'absolute', // Position this box over the image
      top: '50%', // Center vertically on the image
      left: '50%', // Center horizontally on the image
      transform: 'translate(-50%, -210%)', // Adjust for perfect centering
      // border: '1px solid red', //for visualizing the box
      padding: '10px',
      textAlign: 'center',
      width:'55%'
    }}>
  <box>
      <Typography style={{
        // border: "1px solid red", //for visualizing the box
  color:"#07A7F9",
  fontWeight: 400,
  fontFamily: 'Segoe UI',
  fontSize: '25px',
  textAlign:"left"
  }}> 
  Let's build something great together!
      </Typography>
  </box>
  <box>
  <Typography style={{
    color:"#042D43",
    fontWeight: 400,
    fontFamily: 'Segoe UI',
    fontSize: '15px',
    lineHeight:"22px",
    textAlign:"justify",
    // border:"1px solid red"
  }}>
  At CliniOps, we are committed to building meaningful and long-term partnerships with organizations that share our passion for innovation and growth. We believe collaboration is key to unlocking new opportunities and delivering exceptional value to our customers. We always seek to build strong, mutually beneficial relationships with organizations that share our vision and commitment to excellence.
  </Typography>
  </box>
</box>
<box style={{
    // border: "1px solid red", //for visualizing the box
    display:'block',
    position: 'absolute', // Position this box over the image
      // top: '50%', // Center vertically on the image
      bottom:'20px',
      left: '50%', // Center horizontally on the image
      transform: 'translate(-50%, -70%)', // Adjust for perfect centering
      padding: '10px',
      textAlign: 'center',
    }}>

<Typography style={{
    color:"#FFFFFF",
    fontWeight: 400,
    fontFamily: 'Segoe UI',
    fontSize: '60px',
    // lineHeight: 'no',
    textAlign:"justify"
}}>
  Technology Partners
</Typography>
</box>
</box>
<box style={{ 
  // border: "1px solid red",
  height: '150px', 
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center' }}>
  <box style={{ width: "70%" }}>
    <Typography style={{
      color: "#042D43",
      fontWeight: 400,
      fontFamily: 'Segoe UI',
      fontSize: '15px',
      lineHeight: '24px',
      textAlign: "justify"
    }}>
      Our technology ecosystem partners are key collaborators in driving innovation and creating powerful solutions that transform industries. We integrate their expertise with our cutting-edge platforms to deliver seamless, scalable technologies that address complex challenges. Together, we empower businesses to unlock new opportunities, enhance efficiency, and accelerate growth in an increasingly digital world.
    </Typography>
  </box>
</box>
</box> */}
    </>
  );
};

export default template;
