import React from 'react';
import { Grid, Typography, Stack } from '@mui/material';

// import { PageSectionRightImg } from '../../../component/page';
import { Conduct } from '../../../component/page';

// import ImgEConsent from '../../../../img/product/conduct/econsentcro.png';
import ImgePRO from '../../../../img/product/conduct/ePRO.png';
import CustomTypography from "../../../component/customTypography";

const template = () => {
  return (
    <>
      {/* <PageSectionRightImg
        heading={
          <Stack direction="row" spacing={1} alignItems='center' justifyContent={{xs: 'center', md: 'flex-start'}}>
            <Typography variant="h2" component="h2" className="co-page-heading" sx={{ textAlign: { xs: 'center', md: 'left'} }}>
              eConsent
            </Typography>
          </Stack>
        }
        imgUrl={ImgEConsent}
        imgTitle="eConsent"
      >
        <Typography sx={{ mt: 1 }}>
          CliniOps eConsent module facilitate the consent process. CRO can use this module to monitor the compliance of the sites' consent process without compromising on patient PHI information. PHI information is redacted based on access control. Signed informed consent forms with the time stamp and geo location can be accessed through this module.
        </Typography>
      </PageSectionRightImg> */}
      
      <Grid container direction='column' spacing={0} sx={{ mt: 0 }}>
            {/* ePRO  */}
          <Grid item>
              <Conduct
                ImgSrc={ImgePRO}
                Name="ePRO"
                className="co-image-conduct"
                Summary={
                  <>
                  <CustomTypography variant='body2' sx={{ mt: 1 }}>
                    Data captured from CliniOps Connect App on patients' smartphones and tablets is displayed via the ePRO 
                    (Electronic Patient Reported Outcome) module in the Conduct portal. 
                    <br></br><br></br>
                    The study team can access patient data remotely in real-time and display any site-patient interactions. 
                    Patients and sites are supported through automated alerts and notifications via SMS, email, or push notifications.
                </CustomTypography>
                </>
                }
              />
              
          </Grid>
      </Grid>
    </>
  );
}

export default template;