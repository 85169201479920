import React from 'react';
import { Grid, Typography, Stack } from '@mui/material';

import { Conduct } from '../../../component/page';

import ImgrSDV from '../../../../img/product/conduct/rSDV_Conduct.png';
import CustomTypography from '../../../component/customTypography';

const template = () => {
  return (
    <>
      <Grid container direction='column' spacing={0} sx={{ mt: 0 }}>
            {/* rSDV  */}
          <Grid item>
              <Conduct
                ImgSrc={ImgrSDV}
                Name="rSDV"
                className="co-image-conduct"
                Summary={
                  <>
                  <CustomTypography variant='body2' sx={{ mt: 1 }}>
                    CliniOps rSDV (Remote source data verification) functionality is enabled through centralized access control so that specific users 
                    can access site data remotely. Data that require SDV are configured ahead of time to drastically minimize the on-site SDV 
                    effort since most data is collected as an electronic source (eSource) at the site and may not need SDV. 
                    <br></br><br></br>
                    The ability to remotely perform this task instead of on-site SDV saves significant time, money, and effort, reduces travel to sites, 
                    and keeps the study moving forward without interruptions. It also allows clients such as CROs to re-purpose staff from performing 
                    costly onsite visits to focusing on other quality oversight activities such as targeted rSDV, trend analysis, RMP 
                    (Risk Management Plan), monitoring risk mitigation strategies, etc. 
                </CustomTypography>
                </>
                }
              />
              
          </Grid>
      </Grid>
    </>
  );
}

export default template;