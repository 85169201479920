import React, { useState } from "react";
import { Container, Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { yellow } from "@mui/material/colors";

import { TechnologyTile } from "../../../component/home";

import ImgImprovementData from "../../../../img/home/hm-improvement-data.svg";
import ImgImprovementRetention from "../../../../img/home/hm-improvement-retention.svg";
import ImgImprovementCost from "../../../../img/home/hm-improvement-cost.svg";
import ImgImprovementSetup from "../../../../img/home/hm-improvement-setup.svg";
import ImgImprovementCloseout from "../../../../img/home/hm-improvement-closeout.svg";
import ImgGarywellAvatar from "../../../../img/home/profgaryweil_avatar.png";
import ImgRobertAvatar from "../../../../img/home/robertKrummen_avatar.png";
import ImgSandeepAvatar from "../../../../img/home/sandeepathalye_avatar.png";
import ImgStephenAvatar from "../../../../img/home/stephenhoffman_avatar.png";
import ImgAppolinaireAvatar from "../../../../img/home/Dr_appolinaireTiam_avatar.png";
import ImgTestimonialWU from "../../../../img/home/washington_universtiy_logo.png";
import ImgTestimonialEGPAF from "../../../../img/home/EGPAF_logo.png";
import ImgTestimonialVektor from "../../../../img/home/vektor_medical_logo.png";
// import ImgTestimonialSanaria from "../../../../img/home/sanaria_logo.png";
import ImgTestimonialSanaria from "../../../../img/home/sanaria_logo_v2.png";
import ImgTestimonialBiocon from "../../../../img/home/biocon_biologics_logo.png";
import ImgWaveTop from "../../../../img/home/wave1.png";
import ImgWaveBottom from "../../../../img/home/wave2.png";
import ImgWave from "../../../../img/home/wave2-1.png";
import CustomCarousel from "../CustomCarousel";

const template = () => {
  const isSmallScreen = useMediaQuery("(max-width:900px)");
  const [isExpanded, setIsExpanded] = useState(false); // state for toggling description

  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };

  const slides = [
    {
      image: ImgTestimonialWU,
      title: `Lymphatic Filariasis study in Papua New Guinea, Indonesia, India, Haiti, and Fiji`,
      subTitle: `A clinical trial with 26,836 participants in LMIC countries, with offline/online capture using mobile devices.`,
      description: `“With many thousands of study participants, our investigators and site teams worked tirelessly to complete the project(s) in a professional and timely manner.###
      Our study benefitted from the assistance of CliniOps, which provided a reliable, high-level data collection platform that met stringent regulatory requirements and included offline capability for data entry and management in remote areas.### 
      Local personnel in several of our sites were not tech savvy, but they learned to use the tablet-based data system quickly and benefitted from training and prompt technical support from CliniOps.”`,
      customerAvatar: ImgGarywellAvatar,
      customerName:
        "Gary J. Weil, MD Professor ###Washington University School of Medicine",
    },
    {
      image: ImgTestimonialEGPAF,
      title: `A cluster randomized trial to improve Maternal-Child Outcomes`,
      subTitle: `HIV-positive pregnant women in Lesotho, Africa`,
      description: `"Our collaboration with CliniOps made it possible for us to use smart solutions to recruit participants in remote sites as well as track their tests and visits efficiently in complete offline mode.###
      The accomplishment of the study, with assistance from CliniOps technology platform, is another step towards our objective to strengthen local healthcare response to needs of mother, child, or family affected by HIV."`,
      customerAvatar: ImgAppolinaireAvatar,
      customerName:
        "Dr.Appolinaire Tiam VP, ###Technical Strategies & Innovation ###Elizabeth Glaser Pediatric Aids Foundation",
    },
    {
      image: ImgTestimonialVektor,
      title: `Configurable Platform for handling adjudication, complex workflows and imaging data`,
      subTitle: `Speeds development in ground-breaking clinical trial on Cardiac Arrhythmia`,
      description: `We found CliniOps’s platform to be flexible and adaptable, offering features that handled our complex clinical workflow seamlessly.###
      Their team was excellent and their responsiveness was impressive. CliniOps listened to our requirements and configured the system the way it needed to be done.###CliniOps’s platform was a key reason that we were able to successfully complete 
      our study on time and on budget. In terms of our clinical trial plans going forward, when we are ready for our next study, CliniOps will be among our first calls.
      `,
      customerAvatar: ImgRobertAvatar,
      customerName:
        "Krummen, Esq., ###President & Chief Executive Officer, ###Vektor Medical, Inc. ",
    },
    {
      image: ImgTestimonialBiocon,
      title: `Patient Centricity Through Telemedicine`,
      subTitle: `A Clinical Trial for Treatment of COVID-19 in India, across 20+ sites`,
      description: `"We are a strong believer of digital transformation of clinical trials, and our partnership with  CliniOps helped us with an innovative platform that offered complete digital experience for sites and patients.###
      With telemedicine, participants were enrolled and engaged online via the platform. The participants’ safety and convenience were always a priority in line with our strategic imperative of patient centricity. We enjoyed our partnership with CliniOps and their very responsive customer support team”`,
      customerAvatar: ImgSandeepAvatar,
      customerName:
        "Dr. Sandeep Athalye, MD ###Chief Development Officer ###Biocon Biologics",
    },
    {
      image: ImgTestimonialSanaria,
      title: `Enabling Remote Monitoring in a Malaria study`,
      subTitle: `Regimen Optimization Trial of PfSPZ Vaccine in Equatorial Guinea`,
      description: `“With our vaccines, Sanaria is fully committed to contribute to the prevention of malaria infection and transmission in exposed individuals and communities. Our regimen optimization trial of Sanaria® PfSPZ Vaccine in Equatorial Guinea involved efficient study monitoring.###
      When travel constraints hindered regular on-site visits by monitors, CliniOps remote monitoring capabilities helped us bring efficiency to the process and complete the study, leveraging smart technologies in difficult geographies in Africa.”`,
      customerAvatar: ImgStephenAvatar,
      customerName: "Dr. Stephen Hoffman ###CEO ###Sanaria, Inc.",
    },
  ];

  const data = [
    { number: "5000X", text: "Improvement in Data Quality" },
    { number: "3X", text: "Better patient retention" },
    { number: "30%", text: "Reduction in Clinical Trial Cost" },
    { number: "10X", text: "Improvement in the clinical trial study setup" },
    {
      number: "5X",
      text: "Improvement in data access with real-time data collection and AI analysis",
    },
    { number: "5X", text: "Improvement in time for study closeout" },
  ];

  const renderItem = (slide) => (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column-reverse", md: "row" }, // Switch to column if expanded
        justifyContent: "center",
        alignItems: "center",
        gap: "24px",
        padding: "2em",
        borderRadius: "20px",
        // height: { xs: "auto", md: "600px" }, // Fixed height for larger screens
        minHeight: { xs: "auto", md: "500px" },
        height: "auto",
        // height:"auto",
      }}
    >
      {/* Left Section - Customer Description */}
      <Box
        sx={{
          flex: 1,
          textAlign: "left",
        }}
      >
        <img
          src={slide.image}
          alt="Customer Logo"
          style={{
            maxWidth: "100%",
            height: "100px",
            marginBottom: "16px",
            objectFit: "contain",
            objectPosition: "left center",
          }}
        />
        <Typography
          variant="body3"
          sx={{
            lineHeight: "1.6",
          }}
        >
          {slide?.title}
        </Typography>
        <Typography
          variant="h4"
          sx={{
            lineHeight: "1.6",
          }}
        >
          {slide?.subTitle}
        </Typography>

        <Box
          sx={{
            overflow: "hidden",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: isSmallScreen && !isExpanded ? 3 : "none", // Clamp only on small screens when collapsed
            lineClamp: isSmallScreen && !isExpanded ? 3 : "none", // Fallback for other browsers
            transition: "all 0.3s ease-in-out", // Smooth transition for the toggle
          }}
        >
          {slide.description.split("###").map((line, idx) => (
            <Typography
              key={idx}
              variant="body2"
              sx={{
                mb: 1,
                lineHeight: "1.6",
              }}
            >
              {line}
            </Typography>
          ))}
        </Box>

        {/* Know More Button */}
        {isSmallScreen && (
          <button
            onClick={toggleDescription}
            style={{
              marginTop: "16px",
              backgroundColor: "transparent",
              color: "#1E75C6",
              border: "none",
              fontSize: "20px",
              fontWeight: "400",
              cursor: "pointer",
            }}
          >
            {isExpanded ? "Minimize" : "Know More..."}
          </button>
        )}
      </Box>

      {/* Right Section - Customer Avatar and Details */}
      <Box
        sx={{
          flex: 0.4,
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={slide.customerAvatar}
          alt={slide.customerName}
          style={{
            // width: "",
            height: "200px",
            borderRadius: "50%",
            objectFit: "cover",
            marginBottom: "16px",
          }}
        />
        {slide.customerName.split("###").map((line, idx) => (
          <Typography
            key={idx}
            variant="h5"
          >
            {line}
          </Typography>
        ))}
      </Box>
    </Box>
  );

  const customStyles = {
    wrapper: {
      width: "90%",
      margin: "0 auto",
      border: "1px solid #CED2D4",
      boxShadow: "10px 10px 8px rgba(0, 0, 0, 0.2)",
      backgroundColor: "#FFFFFF",
      borderRadius: "20px",
    },
    slide: { width: "100%" },
    arrows: {
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      fontSize: "24px",
      cursor: "pointer",
    },
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    arrowOutside: true,
    arrowOutsideOffset: "-80px", // Place the arrows consistently
    adaptiveHeight: true,
    dotsPosition: {
      top: isSmallScreen ? "10px" : "20px",
    },
  };

  return (
    <>
      {/* Section Above Carousel */}
      <Box
        sx={{
          width: "100%",
          textAlign: "center",
          padding: { xs: "20px", md: "30px" },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "center",
          alignItems: "strech",
          gap: { xs: "1em", md: "3em" },
        }}
      >
        <Typography
          variant="h1Blue"
          sx={{
            marginBottom: "16px",
            textAlign: { xs: "center", md: "end" },
            width: { xs: "100%", md: "600px" },
            lineHeight: 1.2,
          }}
        >
          {/* {isSmallScreen ? ( */}
          <Typography
            variant="h1Blue"
            sx={{
              display: { xs: "block", md: "none" },
              lineHeight: 1.2,
            }}
          >
            Our Patented Technology Drivers Superior Results
          </Typography>

          {/* // ) : ( */}
          <ul
            style={{
              listStyle: "none",
              padding: 0,
              margin: 0,
              display: isSmallScreen ? "none" : "block",
            }}
          >
            <li>Our Patented</li>
            <li>Technology</li>
            <li>Drivers</li>
            <li>Superior</li>
            <li>Results</li>
          </ul>
          {/* // )} */}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "center",
            alignItems: "center",
            // width:{xs:'60%',md:'50%'},
            gap: "24px",
            margin: { xs: "auto", md: "0" },
          }}
        >
          <Typography
            variant="body2"
            sx={{
              textAlign: { xs: "center", md: "left" },
              lineHeight: "1.3",
              maxWidth: { xs: "100%", md: "600px" },
              margin: { xs: "auto" },
            }}
          >
            <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
              {data.map((item, index) => (
                <li
                  key={index}
                  style={{
                    display: "flex",
                    gap: "8px",
                    marginBottom: "4px",
                    alignItems:'center',
                      // window.innerWidth <= 900
                      //   ? "center" // For xs or small screens
                      //   : item.text.includes("\n") || item.text.length > 50
                      //   ? "flex-start" // For multi-line content on larger screens
                      //   : "center", // For single-line content on larger screens
                    flexDirection: isSmallScreen ? "column" : "row",
                    color: "",
                  }}
                >
                  <span
                    style={{
                      fontSize: "26px",
                      fontWeight: "600",
                      flexShrink: 0, // Prevent shrinking of the number
                    }}
                  >
                    {item.number}
                  </span>
                  <span>{item.text}</span>
                </li>
              ))}
            </ul>
          </Typography>
        </Box>
      </Box>

      {/* Carousel Section */}
      <Box
        sx={{
          width: "100%",
          textAlign: "center",
          padding: { xs: "20px", md: "50px" },
          backgroundImage: `url(${ImgWaveTop}), url(${ImgWaveBottom})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top center, bottom center",
          backgroundSize: "contain",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {/* Section Title */}
        <Typography
          variant="h1White"
          sx={{
            textShadow: "0px 3px 3px rgba(0, 0, 0, 0.1608)",
            marginBottom: "50px",
            marginTop: { xs: "-15px",sm:"-5px" ,md: 0 },
          }}
        >
          Customer Stories
        </Typography>

        {/* Carousel */}
        <CustomCarousel
          slides={slides}
          renderItem={renderItem}
          customStyles={customStyles}
          settings={settings}
        />
      </Box>
    </>
  );
};

export default template;
