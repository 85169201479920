import React from "react";
import { Grid, Box, Typography, Stack,Alert, Link } from "@mui/material";
import { teal, blueGrey } from "@mui/material/colors";

import { PageHero, PageContentV2 } from "../../component/page";
import ImgStephen from "../../../img/media/customer-story/_1/DrStephenHoffman.jpg";
import Imgbmc1 from "../../../img/media/customer-story/_1/BMCMalariaJournal.png";
import ImgAJTMH_ASTMH from "../../../img/media/customer-story/_1/AJTMH_ASTMHLogo.png";
import ImgASTMH from "../../../img/media/customer-story/_1/ASTMHLogo.png";
import ImgGasComp1 from "../../../img/media/customer-story/_1/GasComp2.png";
import ImgAMEGLNGSONA from "../../../img/media/customer-story/_1/GasComp5.png";
import ImgMin from "../../../img/media/customer-story/_1/Ministry_EG.png";
import ImgIfkara from "../../../img/media/customer-story/_1/IfakaraHealthInstitute.png";
import ImgSwiss from "../../../img/media/customer-story/_1/SwissTPH.png";
import ImgSanaria from "../../../img/media/customer-story/_1/sanaria-logo-small.png";
import ImgMCD from "../../../img/media/customer-story/_1/MCDGlobalHealth.png";


// Page Imports
import _0 from "./customer-story_9/_0";

const template = () => {
  return (
    <>
      <PageHero color="rgba(3, 169, 244, 0.1)">
        <_0 />
      </PageHero>
      <PageContentV2>
        <Grid item>
          <Typography variant="subtitle2" paragraph>
          Enabling Remote Monitoring in a Malaria study: Regimen Optimization Trial of PfSPZ Vaccine in Equatorial Guinea</Typography>
          <Typography variant="body7" paragraph>
          In 2021, WHO African Region reported 234 million cases of malaria.<sup>1,2</sup> As an infectious disease, malaria remains a constant threat particularly in Sub-Saharan Africa.  Sanaria, a biotechnology company that is developing vaccines protective against <i>Plasmodium falciparum</i> infection in humans, conducted a 9 month clinical study from July 2018 to March 2019 on Bioko Island in Equatorial Guinea, Central Africa.<sup>2,3</sup> The study was a phase 1, randomized, double-blind, placebo controlled regimen optimization study of  Sanaria<sup>®</sup> PfSPZ Vaccine in 104 healthy Equatoguinean adults to determine if a condensed, rapid immunization regimen is safe and efficacious. 
          </Typography>
          <Typography variant="body7" paragraph>
          Compared to a gold standard 16-week regimen, the study employed three condensed regimens with durations of 4 weeks or less and outcomes were assessed for safety, tolerability, immunogenicity, and protective efficacy against controlled human malaria infection (CHMI). The following organizations collaborated on the study: Sanaria Inc., Government of Equatorial Guinea, Ifakara Health Institute, Swiss Tropical & Public Health Institute, Medical Care Development (MCD), and a consortium of oil companies led by Marathon Oil Corporation.
          </Typography>
          <Typography variant="body7" paragraph>
          CliniOps was contracted by Sanaria to provide its technology platform to support source data collection using digital tablets in complete offline mode (eSource) and facilitate remote monitoring & remote source data verification (rSDV). The mobile-based, offline data collection ensured quality data at point-of-care.
          </Typography>

          <Typography variant="body7" paragraph>
          By leveraging CliniOps remote monitoring functionality, on-site visits by study monitors were reduced and any possible challenges due to unexpected travel constraints were minimized. CliniOps directly uploaded the data from safety laboratory testing and immunological assays which enabled the study team to review the clinical and research lab results along with eCRF data.  
          </Typography>

          <Typography variant="body7" paragraph>
          “At CliniOps, we are constantly learning and improving to align our technologies according to the needs of every study and clinical site,” said Avik Pal, CEO, CliniOps. “Particularly for public health studies targeting remote areas, CliniOps endeavoured to develop technologies for quality data collection in complete offline mode, and to enable seamless remote monitoring capabilities”.    
          </Typography>

        
          <blockquote
            style={{ borderLeft: `4px solid ${blueGrey[100]}`, marginLeft: -1 }}
          >
            <Stack
              spacing={4}
              sx={{ pl: 4 }}
              direction={{ xs: "column", md: "row" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minWidth: "220px",
                  width: "220px",
                  height: "220px",
                  borderRadius: 2,
                  backgroundImage: `url(${ImgStephen})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "top center",
                }}
              />
              <Box>
            <Typography variant="body7" paragraph>
              <em>
              “With our vaccines, Sanaria is fully committed to contribute to the prevention of malaria infection and  transmission in exposed individuals and communities. Our regimen optimization trial of Sanaria® PfSPZ Vaccine in Equatorial Guinea involved efficient study monitoring. When travel constraints hindered regular on-site visits by monitors, CliniOps remote monitoring capabilities helped us bring efficiency to the process and complete the study, leveraging smart technologies in difficult geographies in Africa”</em>
            </Typography>
            <Typography variant="overline" paragraph>
              <strong>
                Dr. Stephen Hoffman
                <br />
                CEO, Sanaria Inc 
              </strong>
            </Typography>
          </Box>
            </Stack>
          </blockquote>

          <Typography variant="body7" paragraph>
          References:    
          </Typography>

          <Typography variant="body7" paragraph>
          1. World Health Organization, 2022. World Malaria Report 2022. Geneva, Switzerland: World Health Organization.
          </Typography>

          <Typography variant="body7" paragraph>
          2. Jongo SA, Church LWP, Nchama VUNN, et al. Multi-Dose Priming Regimens of PfSPZ Vaccine: Safety and Efficacy against Controlled Human Malaria Infection in Equatoguinean Adults. The American Journal of Tropical Medicine and Hygiene. 2022;106(4):1215-1226.
          </Typography>

          <Typography variant="body7" paragraph>
          3. Mpina, M., Stabler, T.C., Schindler, T. et al. Diagnostic performance and comparison of ultrasensitive and conventional rapid diagnostic test, thick blood smear and quantitative PCR for detection of low-density <i>Plasmodium falciparum</i> infections during a controlled human malaria infection study in Equatorial Guinea. Malar J 21, 99 (2022)
          </Typography>

        
        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '175px',
                width: '175px',
                height: '80px',
                borderRadius: 2,
                marginTop: '30px',
                backgroundImage: `url(${Imgbmc1})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
            />
            <Box>
            <Typography paragraph>
            A publication titled, <Link href="https://malariajournal.biomedcentral.com/articles/10.1186/s12936-022-04103-y" target={"_blank"} underline="hover">"Diagnostic performance and comparison of ultrasensitive and conventional rapid diagnostic test, thick blood smear and quantitative PCR for detection of low-density <i>Plasmodium falciparum</i> infections during a controlled human malaria infection study in Equatorial Guinea”</Link>, was published in the BMC | Malaria Journal, on March 24, 2022.</Typography>
            </Box>
        </Stack>
        </Alert>
        
        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '175px',
                width: '175px',
                height: '80px',
                borderRadius: 2,
                marginTop: '30px',
                backgroundImage: `url(${ImgAJTMH_ASTMH})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
            />
            <Box>
            <Typography paragraph>
            A publication titled, <Link href="https://www.ajtmh.org/view/journals/tpmd/106/4/article-p1215.xml" target={"_blank"} underline="hover">"Multi-Dose Priming Regimens of PfSPZ Vaccine: Safety and Efficacy against Controlled Human Malaria Infection in Equatoguinean Adults”</Link>, was published in The American Journal of Tropical Medicine and Hygiene, on Feb 07, 2022. 
            </Typography>
            </Box>
        </Stack>
        </Alert>

        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '175px',
                width: '175px',
                height: '80px',
                borderRadius: 2,
                marginTop: '30px',
                backgroundImage: `url(${ImgASTMH})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
          />
          
           <Box>
           <Typography paragraph>
           The results of this study were presented at the 68th Annual Meeting of the American Society for Tropical Medicine and Hygiene, 20-24 November 2019, National Harbor, Maryland. Abstract 1049: <Link href="https://scholar.google.com/citations?view_op=view_citation&hl=en&user=-VnsCw0AAAAJ&citation_for_view=-VnsCw0AAAAJ:WF5omc3nYNoC" target={"_blank"} underline="hover">Safety and Protective Efficacy Against Controlled Human Malaria Infection of Multi-Dose Priming Regimens of PfSPZ Vaccine with and without Boost in Equatorial Guinean Adults</Link>.
           </Typography>
           </Box>
          </Stack>
        </Alert>
        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
          <stack>
        <Box
                xs="auto"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minWidth: '175px',
                    width: '175px',
                    height: '80px',
                    borderRadius: 2,
                    backgroundImage: `url(${ImgMin})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top center'
                }}
            />
        <Box
                xs="auto"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minWidth: '175px',
                    width: '175px',
                    height: '80px',
                    borderRadius: 2,
                    backgroundImage: `url(${ImgIfkara})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top center'
                }}
            />
            <Box
                    xs="auto"
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minWidth: '175px',
                        width: '175px',
                        height: '80px',
                        borderRadius: 2,
                        backgroundImage: `url(${ImgSwiss})`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'top center'
                    }}
                />
            </stack>
            <Box>
            <Typography paragraph>
            This work was supported by a unique Public-Private Partnership.  Public entities included the Ministry of Mines and Hydrocarbons and the Ministry of Health and Social Welfare, <Link href="https://www.guineaecuatorialpress.com/" target={"_blank"} underline="hover">Government of Equatorial Guinea</Link>, the <Link href="https://www.ihi.or.tz/" target={"_blank"} underline="hover">Ifakara Health Institute</Link>, and the <Link href="https://www.swisstph.ch/en/" target={"_blank"} underline="hover">Swiss Tropical and Public Health Institute</Link>.
            </Typography>
            </Box>
        </Stack>
        </Alert>
        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Stack display={"block"}>
        <Box
                xs="auto"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minWidth: '175px',
                    width: '175px',
                    height: '30px',
                    borderRadius: 2,
                    backgroundImage: `url(${ImgSanaria})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top center'
                }}
            />
        <Box
                xs="auto"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minWidth: '175px',
                    width: '175px',
                    height: '100px',
                    borderRadius: 2,
                    backgroundImage: `url(${ImgGasComp1})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top center'
                }}
            />
            <Box
                xs="auto"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minWidth: '175px',
                    width: '175px',
                    height: '100px',
                    borderRadius: 2,
                    backgroundImage: `url(${ImgAMEGLNGSONA})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top center'
                }}
            />
            <Box
                xs="auto"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'left',
                    minWidth: '175px',
                    width: '175px',
                    height: '80px',
                    borderRadius: 2,
                    backgroundImage: `url(${ImgMCD})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top center'
                }}
            /></Stack>
            <Box>
            <Typography paragraph>
            Private partners included <Link href="https://sanaria.com/" target={"_blank"} underline="hover">Sanaria Inc.</Link> and a consortium of oil companies led by <Link href="https://www.marathonoil.com/operations/eg/" target={"_blank"} underline="hover">Marathon EG Production Limited</Link> and including <Link href="https://www.chevron.com/stories/chevron-noble-energy" target={"_blank"} underline="hover">Noble Oil Services</Link>, <Link href="https://www.atlanticmethanol.com/home.html" target={"_blank"} underline="hover">Atlantic Methanol Production Company</Link>, <Link href="https://www.eglng.com/" target={"_blank"} underline="hover">EG LNG</Link> and <Link href="http://sonagas-ge.com/en/" target={"_blank"} underline="hover">Sonagas</Link>.
            </Typography>
            <Typography paragraph>
            A US-based non-governmental organization, <Link href="https://www.mcd.org/" target={"_blank"} underline="hover">MCD Global Health</Link> provided logistics and all on-site support.
            </Typography>
            </Box>
        </Stack>
        </Alert>
        </Grid>
      </PageContentV2>
    </>
  );
};

export default template;
