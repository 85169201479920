import React from 'react';
import { Grid, Typography } from '@mui/material';

import { Product } from '../../../component/page';

import ImgCTMSConfig from '../../../../img/service/study-setup/CTMSConfig.png';
import CustomTypography from '../../../component/customTypography';

const template = () => {
  return (
    <>
      <Grid container direction='column' spacing={0} sx={{ mt: 0 }}>
            {/* Medical Coding Design  */}
          <Grid item>
              <Product
                ImgSrc={ImgCTMSConfig}
                Name="CTMS Configuration"
                Summary={
                  <>
                  <CustomTypography variant='body2' sx={{ mt: 1 }}>
                    CliniOps offers tailored CTMS (Clinical Trial Management System) configuration services to optimize 
                    clinical trial operations. Our team customizes CTMS platform to streamline study workflows, from site 
                    management to data collection and reporting. By aligning the system with study-specific requirements, 
                    CliniOps ensures enhanced efficiency, compliance, and real-time tracking. The CTMS module is configured 
                    to monitor study progress site wise along with the performance, deadlines and milestones.
                </CustomTypography>
                </>
                }
              />
              
          </Grid>
      </Grid>
    </>
  );
}

export default template;