import React from "react";
import { Box, Typography } from "@mui/material";

const template = () => {
  return (
    <Box
      sx={{
        textAlign: {xs:'center',md:"left"},
        position: "relative", // so it sits above WaveBackground
        zIndex: 1,            // wave is behind at zIndex: 0
        pt:{xs:1,md:1},
        mx: "auto",
        maxWidth: {xs:"100%",md:"600px"},
        px: { xs: 5, md: 1 },
      }}
    >
      <Typography
        variant="h2"
        sx={{
          lineHeight:'26px',
          mb: 1,
        }}
      >
        Enter your world of CliniOps
      </Typography>
      <Typography
      variant="body2"
        sx={{
          lineHeight:"24px"
        }}
      >
        after successful authentication, CliniOps Conduct configured to your role 
        will open in a new window.
      </Typography>
    </Box>
  );
}

export default template;
