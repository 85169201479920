import React from "react";
import { HashLink as RouterLink } from "react-router-hash-link";
import { Grid, Box, Typography, Button, Stack } from "@mui/material";

import { PageSectionRightImg } from "../../../component/page";
import RouterList from "../../../router";

import ImgCliniOpsEdge from "../../../../img/product/_0/site-app.jpeg";
import ImgLogo from "../../../../img/product/edge/CliniOpsEdge.png";
import topWaveImg from "../../../../img/home/wave1.png";
import BottomWaveImg from "../../../../img/home/wave2.png";
import NurseImg from "../../../../img/home/NurseWithTablet.png";
import counter1 from "../../../../img/product/_0/counter_1.png"
import counter2 from "../../../../img/product/_0/counter_2.png"
import counter3 from "../../../../img/product/_0/counter_3.png"
import counter4 from "../../../../img/product/_0/counter_4.png"


const template = () => {

    const content = [
    {
      number:counter1,
      text: "The CliniOps platform is designed to streamline the entire clinical trial process, from patient enrollment to data collection and analysis.",
    },
    {
      number:counter2,
      text: "The CliniOps platform is structured to mimic the real world and provide a sense of familiarity and comfort. This ensures a user-friendly experience for all stakeholders, making it an invaluable tool for modern clinical trials.",
    },
    {
      number:counter3,
      text: `The CliniOps platform is designed with the study team's stakeholders 
      and users in mind. It offers access to real-time data for informed 
      decision-making and seamless collaboration, ensuring that each user 
      is always up-to-date and well-informed. Providing real-time data for 
      informed decision-making and seamless collaboration enhances 
      efficiency, offers scalable and context-aware user journeys, and 
      reduces costs.`,
    },
    {
      number:counter4,
      text: `CliniOps Unified Platform for Reimagined Clinical Trials has consistently 
      demonstrated its configurability and scalability. As clinical trial demands 
      evolve, the CliniOps platform empowers users to meet the demands of 
      trials spanning several therapeutic areas and the journey through 
      regulatory pathways across phases I, II, III, IV, observational, and RWE 
      trials. This flexibility empowers the stakeholders and users to control 
      their clinical trial processes with greater certainty and better results.`,
    },
  ];
  return(
  
      <Box
      sx={{
        position: "relative",
        overflow: "hidden", // so we don't show scrollbars for the wave images
        minHeight: "100vh",
      }}
      >
        {/* TOP WAVE (on top, pinned at top center) */}
      <Box
        component="img"
        src={topWaveImg}
        alt="top wave"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "auto",
          objectFit: "contain",
          zIndex: 1,
        }}
      />
        {/* Inner container for title + grid */}
          {/* Title */}
        <Box sx={{
           position: 'absolute',
           top: { xs: "50px", md: "80px" },
           left: '50%',
           transform: 'translate(-50%, -50%)',
           zIndex: 2,
           textAlign: 'center',
           width: "100%",
           px: 2,
        }}>
        <Typography
            variant="h1White"
            sx={{
              textAlign: "center",
                mb: { xs: 6, md: 6 },
                textShadow: "0px 3px 3px rgba(0, 0, 0, 0.1608)",
            }}
          >
            Unique Value Propositions
          </Typography>
        </Box>
       
        <Box
         sx={{
          // above the background, below the bottom wave
          pt: { xs: 10, md: 15 }, // create space so top wave doesn't overlap title
          pb: { xs: 10, md: 10 }, // extra bottom space to accommodate nurseImg & wave
          maxWidth: "1200px",
          mx: "auto",
          px: 2,
          position:"relative"
        }}
        >  
          {/* Two-column layout */}
          <Grid container spacing={3}>
            {/* LEFT COLUMN: Numbered boxes */}
            <Grid
              item
              xs={12}
              md={7}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 3,
                position:"relative",
                zIndex:4,
              }}
            >
              {content.map((item,index) => (
                <Box
                  key={index}
                  sx={{
                    p: 3,
                    borderRadius: "10px",
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #1E75C6",
                    boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  {/* Circular number block */}
                  <Box
                    component='img'
                    src={item.number}
                      alt=""
                      sx={{
                        width: { xs: '40px', md: '50px' },
                        height:{xs:'40px',md:'50px'},
                        flexShrink: 0,
                        mt: { xs: 0.5, md: 1 },
                      }}
                  />
                  <Typography
                  variant="h3"
                    sx={{
                        lineHeight: { xs: 1.5, md: 1.6 },
                    }}
                  >
                    {item.text}
                  </Typography>
                </Box>
              ))}
            </Grid>
  
            {/* RIGHT COLUMN: Nurse Image */}
            <Grid
              item
              xs={12}
              md={5}
              sx={{
                // Let the image fill the container from bottom
                display: "flex",
                alignItems: "flex-end", // pin at bottom if container taller
                justifyContent: "center",
              }}
              >
                <Box
                component="img"
                src={NurseImg}
                alt="Nurse with tablet"
                sx={{
                  // width: "100%",
                  maxWidth: {xs:400,md:700},
                  height: "auto",
                  objectFit: "contain",
                  // If you want it pinned to bottom within its box:
                  objectPosition: "bottom",
                  position:"relative",
                  zIndex:1
                }}
              />
              </Grid>
              </Grid>
              </Box>

      {/* BOTTOM WAVE (above the nurseImg) */}
      <Box
        component="img"
        src={BottomWaveImg}
        alt="bottom wave"
        sx={{
          position: "absolute",
          bottom: 50,
          left: 0,
          width: "100%",
          height: "auto",
          objectFit: "contain",
          zIndex: 3, // wave is "above" the nurse image
        }}
      />
      </Box>
  )
};

export default template;
