import React from 'react';
import { Grid, Typography } from '@mui/material';

import { Product } from '../../../component/page';

import ImgMCConfig from '../../../../img/service/study-setup/MCConfig.png';
import CustomTypography from '../../../component/customTypography';

const template = () => {
  return (
    <>
      <Grid container direction='column' spacing={0} sx={{ mt: 0 }}>
            {/* Medical Coding Configuration  */}
          <Grid item>
              <Product
                ImgSrc={ImgMCConfig}
                Name="Medical Coding Configuration"
                Summary={
                  <>
                  <CustomTypography variant='body2' sx={{ mt: 1 }}>
                    CliniOps offers specialized medical coding configuration services to ensure accurate and consistent coding of clinical trial data. 
                    Our team configures coding systems like MedDRA and WHO Drug to align with study protocols and regulatory standards. 
                    Coder module can be configured to map different coding libraries as per client requirements. 
                    <br></br><br></br>
                    This would streamline the management of adverse events, lab results, and concomitant medications, ensuring compliance with 
                    global standards.
                </CustomTypography>
                </>
                }
              />
              
          </Grid>
      </Grid>
    </>
  );
}

export default template;