import React from 'react';
import { Grid,Box,Stack,Container } from '@mui/material';
// import { teal } from '@mui/material/colors';
import _News2024 from './_News2024';
const template = () => {
  return (
    <>
    {/* <Box sx={{
          position: 'relative',
          zIndex: 2,
        }}> */}
    <Grid container  sx={{marginTop:{xs:"-80px",sm:"-100px",md:"-200px"},padding:"5px 20px 80px 20px",}}>
      <Grid item xs={12} md={11} margin="auto">
      <_News2024/>
      </Grid>
    </Grid >
    {/* </Box> */}
    
    </>
  );
}
export default template;