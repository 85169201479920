import React from "react";
import { Grid, Box, Typography, Stack,Alert, Link } from "@mui/material";
import { teal, blueGrey } from "@mui/material/colors";

import { PageHero, PageContentV2 } from "../../component/page";
import Imgfda from "../../../img/media/customer-story/_1/FDA-Logo.jpg";
import ImgHealthy from "../../../img/media/customer-story/_1/Healthy.png";
import ImgDaya from "../../../img/media/customer-story/_1/Daya.jpg";


// Page Imports
import _0 from "./customer-story_6/_0";

const template = () => {
  return (
    <>
      <PageHero color="rgba(3, 169, 244, 0.1)">
        <_0 />
      </PageHero>
      <PageContentV2>
        <Grid item>
          <Typography variant="subtitle2" paragraph>
          Clinical performance of a smartphone-enabled home based urine testing platform compared to lab based standard of care testing
          </Typography>
          <Typography variant="body7" paragraph>
          Diagnostic devices used directly by patients in privacy, can be challenging. In August 2016, Scanadu started an interventional clinical trial to evaluate and validate clinical performance of the Scanadu Urine Device by intended patients.</Typography>
          <Typography variant="body7" paragraph>
          To help assure seamless and friendly usability of the device, Scanadu partnered with CliniOps. With about 500 patients to interface with, CliniOps configured the system with the capability to compare strip test against standard of care. Multiple modules of the CliniOps Platform were used to support the study including e-Source, EDC, eConsent to recruit, engage patients and efficiently track data. </Typography>
          <Typography variant="body7" paragraph>
          Done in 2 sites at Bay Area, San Francisco, California, the study required three touchpoints for the participants: At the first station in site clinic, the participants read and signed informed consent form in smart tablets shared by site coordinator; at second station, after receiving instructions, the participants went to private area to do urine tests by themselves; after the test, the site coordinators recorded the outcome in same smart tablets along with other demographic data; as final touchpoint, the participants used their personal smart phone to go to an ePRO app and respond to a survey about their experience with the device. With CliniOps' integrated platform, the 16 urine parameters of the device, the outcome of each test and experience survey results were recorded and analyzed without hitches. </Typography>

          <Typography variant="body7" paragraph>
          Avik Pal, CEO of CliniOps shared, “Device validation studies require technical acuity on our part to be able to customize applications that focused on high data quality and compliance to ensure regulatory approval. I am very proud of my engineering team for quick and superior quality work each time. Suffice it to say that our diligent efforts amply benefitted the study. When we received the FDA 510(k) approval news, we were all very delighted.”  </Typography>

          
        
          <blockquote
            style={{ borderLeft: `4px solid ${blueGrey[100]}`, marginLeft: -1 }}
          >
            <Stack
              spacing={4}
              sx={{ pl: 4 }}
              direction={{ xs: "column", md: "row" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minWidth: "220px",
                  width: "220px",
                  height: "220px",
                  borderRadius: 2,
                  backgroundImage: `url(${ImgDaya})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "top center",
                }}
              />
              <Box>
            <Typography variant="body7" paragraph>
               <em>
               "eSource solution is a great compliment for clinical trials. The off-line data capture with point-of-care edit checks gives us quality data in real time. The solution provides integrated workflows, flexible configuration and a simple user interface design. CliniOps provided excellent customer support and stood by us for clinical trials supporting FDA submissions."
                </em>
               </Typography>
               <Typography variant="overline" paragraph>
              <strong>
                Daya Ranamukhaarachchi, PhD
                <br />
                Vice President, Regulatory Affairs, Scanadu
              </strong>
            </Typography>
              </Box>
            </Stack>
          </blockquote>
          
          <Typography variant="body7" paragraph>
          Scanadu’s Urine Device received US FDA 510(k) clarence in September 2018, and is the first smartphone-enabled home urine testing platform that can conduct five common tests, protein, glucose, leukocyte, nitrite, and ketone as part of the platform.
          </Typography>
        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '114px',
                width: '114px',
                height: '80px',
                borderRadius: 0,
                marginTop: '20px',
                backgroundImage: `url(${Imgfda})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
          />
          
           <Box>
           <Typography paragraph>
           Mobihealthnews covered the news titled <Link href="https://www.mobihealthnews.com/content/inui-health-formerly-scanadu-announces-fda-cleared-home-urine-testing-platform" target={"_blank"} underline="hover">‘inui Health, formerly Scanadu, announces FDA-cleared home urine testing platform’</Link>, published on September 18, 2018
           </Typography>
           </Box>
          
          </Stack>
     </Alert>
     <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '114px',
                width: '114px',
                height: '80px',
                borderRadius: 0,
                marginTop: '20px',
                backgroundImage: `url(${ImgHealthy})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
          />
          
           <Box>
           <Typography paragraph>
           Unui Health was eventually acquired by Healthy.io as covered in this article by Mobihealthnews, titled <Link href="https://www.mobihealthnews.com/news/healthyio-acquires-fellow-smartphone-urinalysis-startup-inui-health" target={"_blank"} underline="hover">‘Healthy.io acquires fellow smartphone urinalysis startup Inui Health’</Link>, published on June 26, 2020
           </Typography>
           </Box>
          
          </Stack>
     </Alert>
     
    

        {/* </Alert> */}

          {/* <blockquote
            style={{ borderLeft: `4px solid ${blueGrey[100]}`, marginLeft: -1 }}
          >
            <Box sx={{ pl: 4 }}>
              <Typography paragraph>
                A Case Study titled ‘Use of Decentralized Clinical Technologies
                in Lower Urinary Tract Symptoms’ is published in the Journal of
                Medicine, University of Santo Tomas, in the October 2022 issue,
                authored by Dr. Nadina Jose & Avik Pal
              </Typography>
            </Box>
          </blockquote> */}
        </Grid>
      </PageContentV2>
    </>
  );
};

export default template;
