import React from 'react';
import { Grid, Divider, Stack, Box } from '@mui/material';
import { teal } from '@mui/material/colors';

import { PageHero, PageContent } from '../../component/page';
import topWave from "../../../img/home/wave1.png";
import bottomWave from "../../../img/home/wave2.png";
// Page Imports
import _0 from './_0/_0';
// import _1 from './_0/_1';
// import _2 from './_0/_2';
// import _3 from './_0/_3';

import _1 from './new_contact/_1'
import _2 from './new_contact/_2'
import _3 from './new_contact/_3'
import _4 from './new_contact/_4'
import _5 from './new_contact/_5'
import _6 from './new_contact/_6'
import _7 from './new_contact/_7'


const template = () => {
  return (
    <>
    {/* COmmenting out the code with old design */}
      {/* <PageHero color={teal}> */}
        <_0 />
      {/* </PageHero> */}
      {/* <PageContent>
        <Grid item xs={12}>
          <Grid container spacing={{xs:5, md:10}}>
            <Grid item xs={12} md={6}>
            <_1 /> 
            </Grid>
            <Grid item xs={12} md={6}>
              <Divider sx={{ mb: 3, mt: -2, display: {xs:'block', md:'none'} }} />
              <Stack spacing={4}>
               <_2 /> 
                <Divider />
                <_3 /> 
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </PageContent> */}



    {/* Starting off with new design */}
    
    {/* <_1/> */}
    <Box sx={{
      position:"relative"
      }}>
      <Box sx={{
        backgroundColor: "#F2F2F2",
        backgroundImage: `url(${bottomWave})`,
        backgroundRepeat: "no-repeat",
        backgroundPositionY: "100px",
        backgroundSize: "contain", 
        pt: { xs: 8, md: 8 },
        pb: { xs: 6, md: 20 },
        display:"flex",
        flexDirection:"column",
        gap:{xs:"20px",lg:"50px"}}}>
        <Box>
        <_3/> 
        </Box>
        <Box>
        <_4/>
        </Box>
      </Box>
      {/* <Box sx={{backgroundColor:"#fff",mt: { xs: 3, md: 6 }}}> */}
        {/* <Box>
          <_5/>        
        </Box> */}
        <Box sx={{
          position: "relative",
          pt: { xs: 6, md: 8 },
          pb: { xs: 6, md: 8 },
          zIndex:5
        }}>
          <Box
          component="img"
          src={topWave}
          sx={{
            position:"absolute",
            top:{xs:"-20px",md:"-100px"},
            zIndex:-1,
            width:"100%",
            objectFit:"contain"
          }}
          />
          <_6/>
          <_7/>
        </Box>
      {/* </Box> */}

    </Box>
    


    </>
  );
}

export default template;